import { reject, uniq, isNil } from "lodash";

const formatRange = (...values) => {
  if (values.length > 1) {
    return reject(uniq([values[0], values[1]]), isNil).join(" - ");
  } else {
    return values[0];
  }
};

export { formatRange };
