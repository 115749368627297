import LinkModal from "../components/LinkModal";
import { GitMerge } from "react-feather";
import { getArtist } from "../clients/art";

function MergeArtistModal(props) {
  return (
    <LinkModal
      linkRequest={props.linkRequest}
      params={props.params}
      onClose={props.onClose}
      onLink={props.onLink}
      buttonIcon={GitMerge}
      buttonCaption="Merge"
      placeholder="Search artists&hellip;"
      keyName="sourceId"
      nameAttribute="name"
      getRequest={getArtist}
      searchIndex="artists"
      searchTransform={(hit) => ({
        resultType: "artist",
        name: hit.name,
        flavor: hit.dates,
        img: hit.artistImage,
        id: hit.objectID,
      })}
      resultType="artist"
      title="Merge artist"
      toastMessage="The artist was merged"
      description={
        <p className="small text-muted">
          The artist you select will be deleted. All their events and works will
          be moved to {props.targetName}.
        </p>
      }
    />
  );
}

export default MergeArtistModal;
