import { createPortal } from "react-dom";
import { useEffect, useRef } from "react";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { usePortal } from "../hooks/usePortal";

function Modal(props) {
  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, (event) => props.onClose(event));

  const portal = usePortal("modal");

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => document.body.classList.remove("modal-open");
  }, []);

  return createPortal(
    <>
      <div className={`modal-backdrop fade show`} />
      <div className={`modal fade show`} style={{ display: "block" }}>
        <div
          className={`modal-dialog modal-dialog-scrollable ${
            props.size ? `modal-${props.size}` : ""
          }`}
        >
          <div className="modal-content" ref={wrapperRef}>
            <div className="modal-header">
              <h5 className="modal-title">{props.title}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={props.onClose}
              ></button>
            </div>
            <div className="modal-body">{props.body}</div>
            {props.actions && (
              <div className="modal-footer">{props.actions}</div>
            )}
          </div>
        </div>
      </div>
    </>,
    portal
  );
}

export default Modal;
