import * as yup from "yup";
import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import ValidatedField from "../components/ValidatedField";
import toast from "../lib/toast";
import { Formik, Form } from "formik";
import { Save } from "react-feather";
import { prepareFormValues, returnFormValues } from "../lib/form";
import { useRequest } from "../hooks/useRequest";

function ResolveTaskModal(props) {
  const resolveRequest = useRequest(props.request);

  const loading = resolveRequest.loading;
  const error = resolveRequest.error;

  const handleResolve = ({ status, notes }) =>
    resolveRequest.execute({ id: props.id, status, notes }, () => {
      toast("Tasks resolved");
      props.onResolve();
      props.onClose();
    });

  return (
    <Modal
      title="Resolve task"
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          <Formik
            initialValues={prepareFormValues({ status: 3, notes: "" })}
            onSubmit={(values) => handleResolve(returnFormValues(values))}
            validationSchema={yup.object({
              status: yup.number().required(),
              notes: yup.string(),
            })}
          >
            {(formik) => (
              <Form id="resolve-task">
                <ValidatedField
                  formik={formik}
                  label="Status"
                  name="status"
                  as="select"
                >
                  <option value="3">Fixed</option>
                  <option value="2">Unfixable</option>
                </ValidatedField>
                <ValidatedField
                  formik={formik}
                  label="Notes"
                  name="notes"
                  as="textarea"
                  style={{ height: "6rem" }}
                />
              </Form>
            )}
          </Formik>
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            type="submit"
            form="resolve-task"
            disabled={loading}
            icon={Save}
            className="btn btn-success"
          >
            Save
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default ResolveTaskModal;
