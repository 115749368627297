const config = {
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    projectId: "lofty-optics-394208",
    authDomain: "lofty-optics-394208.firebaseapp.com",
    storageBucket: "lofty-optics-394208.appspot.com",
  },
  maps: {
    apiKey: process.env.REACT_APP_MAPS_API_KEY,
  },
  algolia: {
    applicationId: "GSHOBAIAIU",
    apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
  },
  finance_api: {
    url: "https://beta-api-finance.arthurai.com/v1",
  },
  art_api: {
    url: "https://beta-api.arthurai.com/v1",
  },
  import_api: {
    url: "https://beta-api-import.arthurai.com/v1",
  },
  collection_api: {
    url: "https://beta-api-collections.arthurai.com/v1",
  },
  quality_api: {
    url: "https://quality-api-i3kld64b4a-uc.a.run.app/v1",
  },
};

export default config;
