import * as yup from "yup";
import ArrayField from "../components/ArrayField";
import ColumnRow from "../components/ColumnRow";
import ValidatedField from "../components/ValidatedField";
import { Formik, Form } from "formik";
import { get, compact } from "lodash";
import { prepareFormValues, returnFormValues } from "../lib/form";
import { useState, useEffect } from "react";

function ArtistForm(props) {
  const [alternateNames, setAlternateNames] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [roles, setRoles] = useState([]);
  const [styles, setStyles] = useState([]);
  const [awards, setAwards] = useState([]);
  const [education, setEducation] = useState([]);
  const [relatedArtists, setRelatedArtists] = useState([]);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    setAlternateNames(get(props.initialValues, "alternateNames"));
    setNationalities(get(props.initialValues, "nationalities"));
    setRoles(get(props.initialValues, "roles"));
    setStyles(get(props.initialValues, "styles"));
    setAwards(get(props.initialValues, "awards"));
    setEducation(get(props.initialValues, "education"));
    setRelatedArtists(get(props.initialValues, "relatedArtists"));
    setCollections(get(props.initialValues, "collections"));
  }, [props.initialValues]);

  return (
    <Formik
      initialValues={prepareFormValues(
        {
          name: "",
          matchable: "",
          featured: "",
          firstName: "",
          lastName: "",
          gender: "",
          dates: "",
          dateOfBirth: "",
          dateOfDeath: "",
          nationality: "",
          birthCity: "",
          birthRegion: "",
          birthCountry: "",
          deathCity: "",
          deathRegion: "",
          deathCountry: "",
          role: "",
          style: "",
          bio: "",
          bioSource: "",
          officialWebsite: "",
          artistImage: "",
          signatureImage: "",
          linkedinProfile: "",
          facebookProfile: "",
          twitterProfile: "",
          instagramProfile: "",
          representation: "",
          distributor: "",
          copyrightCollective: "",
          ...props.initialValues,
        },
        {
          types: {
            dateOfBirth: "date",
            dateOfDeath: "date",
          },
        }
      )}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(
          returnFormValues({
            ...values,
            alternateNames: compact(alternateNames),
            nationalities: compact(nationalities),
            roles: compact(roles),
            styles: compact(styles),
            awards: compact(awards),
            education: compact(education),
            relatedArtists: compact(relatedArtists),
            collections: compact(collections),
          }),
          ...args
        )
      }
      validationSchema={yup.object({
        name: yup.string().required(),
        matchable: yup.boolean(),
        featured: yup.boolean(),
        firstName: yup.string(),
        lastName: yup.string(),
        gender: yup.string(),
        dates: yup.string(),
        dateOfBirth: yup.object({ date: yup.date().nullable() }),
        dateOfDeath: yup.object({ date: yup.date().nullable() }),
        nationality: yup.string(),
        birthCity: yup.string(),
        birthRegion: yup.string(),
        birthCountry: yup.string(),
        deathCity: yup.string(),
        deathRegion: yup.string(),
        deathCountry: yup.string(),
        role: yup.string(),
        style: yup.string(),
        bio: yup.string(),
        bioSource: yup.string(),
        officialWebsite: yup.string().url(),
        artistImage: yup.string().url(),
        signatureImage: yup.string().url(),
        linkedinProfile: yup.string(),
        facebookProfile: yup.string(),
        twitterProfile: yup.string(),
        instagramProfile: yup.string(),
        representation: yup.string(),
        distributor: yup.string(),
        copyrightCollective: yup.string(),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <h5>Basic Information</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Name"
                    name="name"
                    type="text"
                  />,
                  <ColumnRow
                    columns={[
                      <ValidatedField
                        formik={formik}
                        label="Featured"
                        name="featured"
                        type="checkbox"
                      />,
                      <ValidatedField
                        formik={formik}
                        label="Matchable"
                        name="matchable"
                        type="checkbox"
                      />,
                    ]}
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="First Name"
                    name="firstName"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Last Name"
                    name="lastName"
                    type="text"
                  />,
                ]}
              />
              <ValidatedField
                formik={formik}
                label="Gender"
                name="gender"
                type="text"
              />
              <ValidatedField
                formik={formik}
                label="Bio"
                name="bio"
                as="textarea"
                style={{ height: "6rem" }}
              />
              <ValidatedField
                formik={formik}
                label="Bio Source"
                name="bioSource"
                type="text"
              />
              <ValidatedField
                formik={formik}
                label="Official Website"
                name="officialWebsite"
                type="url"
              />
              <ValidatedField
                formik={formik}
                label="Artist Image"
                name="artistImage"
                type="url"
              />
              <ValidatedField
                formik={formik}
                label="Signature Image"
                name="signatureImage"
                type="url"
              />
              <ArrayField
                label="AlternateNames"
                value={alternateNames}
                onChange={setAlternateNames}
              />

              <h5 className="mt-4">Period</h5>
              <ValidatedField
                formik={formik}
                label="Dates"
                name="dates"
                type="text"
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Date of Birth"
                    name="dateOfBirth"
                    type="date"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Date of Death"
                    name="dateOfDeath"
                    type="date"
                  />,
                ]}
              />

              <h5 className="mt-4">Location</h5>
              <ValidatedField
                formik={formik}
                label="Nationality"
                name="nationality"
                type="text"
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Birth City"
                    name="birthCity"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Birth Region"
                    name="birthRegion"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Birth Country"
                    name="birthCountry"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Death City"
                    name="deathCity"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Death Region"
                    name="deathRegion"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Death Country"
                    name="deathCountry"
                    type="text"
                  />,
                ]}
              />
              <ArrayField
                label="Nationalities"
                value={nationalities}
                onChange={setNationalities}
              />

              <h5 className="mt-4">Art</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Role"
                    name="role"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Style"
                    name="style"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Representation"
                    name="representation"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Distributor"
                    name="distributor"
                    type="text"
                  />,
                ]}
              />
              <ValidatedField
                formik={formik}
                label="Copyright Collective"
                name="copyrightCollective"
                type="text"
              />
              <ArrayField label="Roles" value={roles} onChange={setRoles} />
              <ArrayField label="Styles" value={styles} onChange={setStyles} />
              <ArrayField label="Awards" value={awards} onChange={setAwards} />
              <ArrayField
                label="Education"
                value={education}
                onChange={setEducation}
              />
              <ArrayField
                label="Related Artists"
                value={relatedArtists}
                onChange={setRelatedArtists}
              />
              <ArrayField
                label="Collections"
                value={collections}
                onChange={setCollections}
              />

              <h5 className="mt-4">Social Media</h5>
              <ValidatedField
                formik={formik}
                label="LinkedIn"
                name="linkedinProfile"
                type="text"
              />
              <ValidatedField
                formik={formik}
                label="Facebook"
                name="facebookProfile"
                type="text"
              />
              <ValidatedField
                formik={formik}
                label="Twitter"
                name="twitterProfile"
                type="text"
              />
              <ValidatedField
                formik={formik}
                label="Instagram"
                name="instagramProfile"
                type="text"
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default ArtistForm;
