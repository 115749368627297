import DeleteVenueModal from "../modals/DeleteVenueModal";
import Image from "../components/Image";
import ExternalLink from "../components/ExternalLink";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import PaginatedContainer from "../components/PaginatedContainer";
import RequestContainer from "../components/RequestContainer";
import TruncatedText from "../components/TruncatedText";
import UnlinkVenueModal from "../modals/UnlinkVenueModal";
import { get, isNil } from "lodash";
import { useAutoRequest } from "../hooks/useRequest";
import { useState } from "react";

function VenuesTable(props) {
  const isChildRequest = !isNil(props.parentRequest);

  const [params, setParams] = useState(props.params);
  const request = useAutoRequest(
    props.eagerLoaded ? null : props.request,
    isChildRequest
      ? get(props.parentRequest, `response.data.${props.parentAttribute}`)
      : params
  );

  const event = props.eagerLoaded ? props.request.response.data || {} : null;
  const venues = props.eagerLoaded
    ? get(event, props.eagerLoaded) || []
    : request.response.data || [];
  const responseParams = get(request.response, "config.params") || params;

  const handleParamsChange = (newParams) => {
    const allParams = { ...params, ...newParams };

    if (!("skip" in newParams)) {
      allParams.skip = 0;
    }

    setParams(allParams);

    if (props.onParamsChange) {
      props.onParamsChange(allParams);
    }
  };

  const table = (
    <ObjectTable
      columns={["", "Name", "Subheading", "City", "Country", "Type", "Link"]}
    >
      {venues.map((venue) => (
        <ObjectTableRow
          actionAuthorizerRole={["admin", "editor"]}
          deleteAuthorizerRole={props.unlinkRequest ? null : "admin"}
          viewPath={`/venues/${venue.id}`}
          editPath={`/venues/${venue.id}/edit`}
          deleteModal={
            props.unlinkRequest ? UnlinkVenueModal : DeleteVenueModal
          }
          deleteModalParams={{
            id: venue.id,
            name: venue.name,
            onDelete: () =>
              props.eagerLoaded
                ? props.request.execute()
                : isChildRequest
                ? props.parentRequest.execute()
                : request.execute(),
            request: props.unlinkRequest,
            params: { venueId: venue.id, ...props.unlinkParams },
          }}
          clipboardValue={venue.id}
          key={venue.id}
          columns={[
            venue.imageUrl ? (
              <div className="text-center" style={{ width: "7rem" }}>
                <Image
                  src={venue.imageUrl}
                  alt=""
                  style={{
                    maxWidth: "6rem",
                    maxHeight: "6rem",
                  }}
                />
              </div>
            ) : null,
            <TruncatedText max={40}>{venue.name}</TruncatedText>,
            <TruncatedText max={40}>{venue.subheading}</TruncatedText>,
            venue.city,
            venue.country,
            venue.venueType,
            <ExternalLink href={venue.url} icon={true} />,
          ]}
        />
      ))}
    </ObjectTable>
  );

  return (
    <RequestContainer loading={request.loading}>
      {props.paginated ? (
        <PaginatedContainer
          enabled={!request.loading}
          count={venues.length}
          params={responseParams}
          onNavigate={handleParamsChange}
        >
          {table}
        </PaginatedContainer>
      ) : (
        table
      )}
    </RequestContainer>
  );
}

export default VenuesTable;
