import ArtistsTable from "../../tables/ArtistsTable";
import Authorizer from "../../components/Authorizer";
import BidsTable from "../../tables/BidsTable";
import DeleteOfferModal from "../../modals/DeleteOfferModal";
import FeesTable from "../../tables/FeesTable";
import IconButton from "../../components/IconButton";
import IconLinkButton from "../../components/IconLinkButton";
import LinkArtistModal from "../../modals/LinkArtistModal";
import LinkWorkModal from "../../modals/LinkWorkModal";
import LogModal from "../../modals/LogModal";
import ObjectPropertyList from "../../components/ObjectPropertyList";
import RequestContainer from "../../components/RequestContainer";
import SalePricesTable from "../../tables/SalePricesTable";
import TabCard from "../../components/TabCard";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import WorksTable from "../../tables/WorksTable";
import {
  Edit,
  PlusCircle,
  Link as LinkIcon,
  XCircle,
  Clock,
  ChevronLeft,
  ChevronRight,
} from "react-feather";
import { Link } from "react-router-dom";
import { getArtists, getWorks } from "../../clients/art";
import {
  getOffer,
  getSaleWithOffers,
  linkOfferArtist,
  linkOfferWork,
  listBidsByOffer,
  listFeesByOffer,
  listSalePricesByOffer,
  unlinkOfferArtist,
  unlinkOfferWork,
} from "../../clients/finance";
import { useAutoRequest } from "../../hooks/useRequest";
import { useHistory } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import { useParams } from "react-router-dom";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useRefresh } from "../../hooks/useRefresh";

function ViewOffer() {
  const [activeTab, setActiveTab] = usePersistentState(
    null,
    "ViewOffer:activeTab"
  );
  let { sale_id: saleId, offer_id: id } = useParams();
  const [refreshToken, refresh] = useRefresh(id);
  const getOfferRequest = useAutoRequest(getOffer, id);
  const getSaleRequest = useAutoRequest(getSaleWithOffers, saleId);
  let history = useHistory();
  let [showLinkArtistModal, linkArtistModal] = useModal(LinkArtistModal, {
    linkRequest: linkOfferArtist,
    params: { offerId: id },
    onLink: () => getOfferRequest.execute(null, refresh),
  });
  let [showLinkWorkModal, linkWorkModal] = useModal(LinkWorkModal, {
    saleId: saleId,
    linkRequest: linkOfferWork,
    params: { offerId: id },
    onLink: () => getOfferRequest.execute(null, refresh),
  });

  const sale = getSaleRequest.response.data || {};
  const offer = getOfferRequest.response.data || {};

  let [showDeleteOfferModal, deleteOfferModal] = useModal(DeleteOfferModal, {
    id: offer.id,
    onDelete: () => {
      history.push(`/sales/${saleId}`);
    },
  });
  let [showLogModal, logModal] = useModal(LogModal, {
    filter: {
      type: "offer",
      id,
    },
  });

  let prevOffer = null;
  let nextOffer = null;

  if (sale && sale.offers) {
    const idx = sale.offers.map((offer) => offer.id).indexOf(id);

    if (idx > 0) {
      prevOffer = sale.offers[idx - 1];
    }
    if (idx < sale.offers.length - 1) {
      nextOffer = sale.offers[idx + 1];
    }
  }

  const visitPreviousOffer = () => {
    if (prevOffer) {
      history.push(`/sales/${saleId}/offers/${prevOffer.id}`);
    }
  };

  const visitNextOffer = () => {
    if (nextOffer) {
      history.push(`/sales/${saleId}/offers/${nextOffer.id}`);
    }
  };

  return (
    <>
      <ViewHeader
        breadcrumbs={[
          <Link to={`/sales/${saleId}`}>{`${
            sale.title || sale.externalId || ""
          }`}</Link>,
          `${offer.offerType || ""} ${offer.sequenceNumber || ""}`,
        ]}
        actions={
          <>
            <IconButton
              onClick={visitPreviousOffer}
              icon={ChevronLeft}
              className={`btn btn-primary me-2 ${prevOffer ? "" : "disabled"}`}
            >
              Prev
            </IconButton>
            <IconButton
              onClick={visitNextOffer}
              icon={ChevronRight}
              className={`btn btn-primary me-2 ${nextOffer ? "" : "disabled"}`}
            >
              Next
            </IconButton>
            <Authorizer role={["admin", "editor"]}>
              <IconButton
                onClick={showLogModal}
                icon={Clock}
                className="btn btn-secondary me-2"
              >
                Log
              </IconButton>
              <IconLinkButton
                to={`/sales/${saleId}/offers/${id}/edit`}
                icon={Edit}
                className="btn btn-secondary me-2"
              >
                Edit
              </IconLinkButton>
            </Authorizer>
            <Authorizer role="admin">
              <IconButton
                icon={XCircle}
                onClick={showDeleteOfferModal}
                className="btn btn-secondary"
              >
                Delete
              </IconButton>
            </Authorizer>
          </>
        }
      />
      <ViewContents>
        <RequestContainer
          loading={getOfferRequest.loading}
          error={getOfferRequest.error}
        >
          <ObjectPropertyList
            items={[
              ["ID", offer.id],
              ["Type", offer.offerType],
              ["Sequence number", offer.sequenceNumber],
              ["Sold", { bool: offer.sold }],
              ["Withdrawn", { bool: offer.withdrawn }],
              ["Status", offer.status],
              ["Price", { currency: offer.currency, value: offer.price }],
              [
                "Published price",
                { currency: offer.currency, value: offer.publishedPrice },
              ],
              [
                "Estimate",
                {
                  currency: offer.currency,
                  value: [offer.estimateLow, offer.estimateHigh],
                },
              ],
              [
                "Total fee",
                { currency: offer.currency, value: offer.totalFee },
              ],
              [
                "Starting bid",
                {
                  currency: offer.currency,
                  value: offer.startingBid,
                },
              ],
              ["Opened at", { date: offer.openedAt }],
              ["Closed at", { date: offer.closedAt }],
              ["Sale date", { date: offer.saleDate }],
              ["Link", { url: offer.url }],
              ["Symbols", { array: offer.symbols }],
              ["External ID", offer.externalId],
            ]}
          />
        </RequestContainer>

        <TabCard
          activeTab={activeTab}
          onTabChange={setActiveTab}
          tabs={{
            Artists: {
              title: offer.artistIds
                ? `Artists (${offer.artistIds.length})`
                : null,
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showLinkArtistModal}
                    icon={LinkIcon}
                    className="btn btn-secondary me-2"
                  >
                    Link artist
                  </IconButton>
                  <IconLinkButton
                    to={`/artists/new?offerId=${id}`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create artist
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <ArtistsTable
                  key={refreshToken}
                  request={getArtists}
                  parentRequest={getOfferRequest}
                  parentAttribute="artistIds"
                  unlinkRequest={unlinkOfferArtist}
                  unlinkParams={{ offerId: id }}
                />
              ),
            },
            Bids: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconLinkButton
                    to={`/sales/${saleId}/offers/${id}/bids/new`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create bid
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <BidsTable
                  params={{ id }}
                  offer={offer}
                  request={listBidsByOffer}
                />
              ),
            },
            Fees: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconLinkButton
                    to={`/sales/${saleId}/offers/${id}/fees/new`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create fee
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <FeesTable
                  params={{ id }}
                  offer={offer}
                  request={listFeesByOffer}
                />
              ),
            },
            Prices: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconLinkButton
                    to={`/sales/${saleId}/offers/${id}/sale_prices/new`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create price
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <SalePricesTable
                  params={{ id }}
                  offer={offer}
                  request={listSalePricesByOffer}
                />
              ),
            },
            Works: {
              title: offer.workIds ? `Works (${offer.workIds.length})` : null,
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showLinkWorkModal}
                    icon={LinkIcon}
                    className="btn btn-secondary me-2"
                  >
                    Link work
                  </IconButton>
                  <IconLinkButton
                    to={`/works/new?offerId=${id}`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create work
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <WorksTable
                  request={getWorks}
                  parentRequest={getOfferRequest}
                  parentAttribute="workIds"
                  unlinkRequest={unlinkOfferWork}
                  unlinkParams={{ offerId: id }}
                />
              ),
            },
          }}
        />
      </ViewContents>

      {linkArtistModal}
      {linkWorkModal}
      {deleteOfferModal}
      {logModal}
    </>
  );
}

export default ViewOffer;
