import DeleteJobModal from "../modals/DeleteJobModal";
import IconButton from "../components/IconButton";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import PaginatedContainer from "../components/PaginatedContainer";
import RequestContainer from "../components/RequestContainer";
import TruncatedText from "../components/TruncatedText";
import UndoJobModal from "../modals/UndoJobModal";
import ZonedDateTime from "../components/ZonedDateTime";
import { RotateCcw } from "react-feather";
import { get } from "lodash";
import { useAutoRequest } from "../hooks/useRequest";
import { useModal } from "../hooks/useModal";
import { useState } from "react";

function JobsTable(props) {
  const [params, setParams] = useState({
    job_type: "",
    status: "",
    after: null,
    before: null,
    sortby: "created_at",
    sortorder: "desc",
    ...props.params,
  });
  const request = useAutoRequest(props.request, params);

  const jobs = request.response.data || [];
  const responseParams = get(request.response, "config.params") || params;

  const [showUndoJobModal, undoJobModal] = useModal(UndoJobModal, {
    onUndo: request.execute,
  });

  const handleParamsChange = (newParams) => {
    const allParams = { ...params, ...newParams };

    if (!("skip" in newParams)) {
      allParams.skip = 0;
    }

    setParams(allParams);

    if (props.onParamsChange) {
      props.onParamsChange(allParams);
    }
  };

  const table = (
    <ObjectTable
      columns={[
        {
          title: "Date",
          sortable: true,
          filterable: true,
          currentSortBy: params.sortby === "created_at",
          currentSortOrder: params.sortorder,
          currentFilter: [params.after, params.before],
          filterDates: true,
          onFilter: ([after, before]) =>
            handleParamsChange({ after: after, before: before }),
          onSort: (order) =>
            handleParamsChange({ sortby: "created_at", sortorder: order }),
        },
        {
          title: "Type",
          filterable: true,
          sortable: true,
          currentSortBy: params.sortby === "jobType",
          currentSortOrder: params.sortorder,
          currentFilter: params.job_type,
          filterOptions: [
            "bonhams",
            "christies",
            "invaluable",
            "phillips",
            "sothebys",
          ],
          onFilter: (value) => handleParamsChange({ job_type: value }),
          onSort: (order) =>
            handleParamsChange({ sortby: "jobType", sortorder: order }),
        },
        "Description",
        {
          title: "Status",
          filterable: true,
          sortable: true,
          currentSortBy: params.sortby === "status",
          currentSortOrder: params.sortorder,
          currentFilter: params.status,
          filterOptions: [
            "queued",
            "extracting",
            "transforming",
            "loading",
            "done",
            "failed",
          ],
          onFilter: (value) => handleParamsChange({ status: value }),
          onSort: (order) =>
            handleParamsChange({ sortby: "status", sortorder: order }),
        },
        "Initiator",
      ]}
    >
      {jobs.map((job) => (
        <ObjectTableRow
          viewPath={`/jobs/${job.id}`}
          deleteAuthorizerRole="admin"
          deleteModal={DeleteJobModal}
          deleteModalParams={{
            id: job.id,
            description: job.description,
            onDelete: () => request.execute(),
          }}
          key={job.id}
          columns={[
            <ZonedDateTime date={job.created_at} />,
            job.jobType,
            <TruncatedText max={120}>{job.description}</TruncatedText>,
            job.status,
            job.user.email,
          ]}
          extraActions={() =>
            (job.jobType !== "undo" && job.status === "done" && (
              <IconButton
                onClick={() => showUndoJobModal({ id: job.id })}
                icon={RotateCcw}
                className="btn-sm btn-outline-secondary me-2"
              >
                Undo
              </IconButton>
            ): null)
          }
        />
      ))}
    </ObjectTable>
  );

  return (
    <>
      <RequestContainer loading={request.loading}>
        {props.paginated ? (
          <PaginatedContainer
            enabled={!request.loading}
            count={jobs.length}
            params={responseParams}
            onNavigate={handleParamsChange}
          >
            {table}
          </PaginatedContainer>
        ) : (
          table
        )}
      </RequestContainer>

      {undoJobModal}
    </>
  );
}

export default JobsTable;
