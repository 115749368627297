import * as yup from "yup";
import ArrayField from "../components/ArrayField";
import ColumnRow from "../components/ColumnRow";
import ValidatedField from "../components/ValidatedField";
import { Formik, Form } from "formik";
import { get, compact } from "lodash";
import { prepareFormValues, returnFormValues } from "../lib/form";
import { useState, useEffect } from "react";

function VenueForm(props) {
  const [alternateNames, setAlternateNames] = useState([]);
  const [altAddresses, setAltAddresses] = useState([]);
  const [otherSocials, setOtherSocials] = useState([]);

  useEffect(() => {
    setAlternateNames(get(props.initialValues, "alternateNames"));
    setAltAddresses(get(props.initialValues, "altAddresses"));
    setOtherSocials(get(props.initialValues, "otherSocials"));
  }, [props.initialValues]);

  return (
    <Formik
      initialValues={prepareFormValues({
        name: "",
        subheading: "",
        url: "",
        imageUrl: "",
        city: "",
        country: "",
        address: "",
        placesId: "",
        latitude: "",
        longitude: "",
        venueType: "",
        venueStyle: "",
        description: "",
        founder: "",
        email: "",
        facebookProfile: "",
        twitterProfile: "",
        instagramProfile: "",
        ...props.initialValues,
      })}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(
          returnFormValues({
            ...values,
            alternateNames: compact(alternateNames),
            altAddresses: compact(altAddresses),
            otherSocials: compact(otherSocials),
          }),
          ...args
        )
      }
      validationSchema={yup.object({
        name: yup.string().required(),
        subheading: yup.string(),
        url: yup.string().url(),
        imageUrl: yup.string().url(),
        city: yup.string(),
        country: yup.string(),
        address: yup.string(),
        placesId: yup.string(),
        latitude: yup.string(),
        longitude: yup.string(),
        venueType: yup.string(),
        venueStyle: yup.string(),
        description: yup.string(),
        founder: yup.string(),
        email: yup.string().email(),
        facebookProfile: yup.string(),
        twitterProfile: yup.string(),
        instagramProfile: yup.string(),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <h5>Basic Information</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Name"
                    name="name"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Subheading"
                    name="subheading"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Type"
                    name="venueType"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Style"
                    name="venueStyle"
                    type="text"
                  />,
                ]}
              />
              <ArrayField
                label="Alternate names"
                value={alternateNames}
                onChange={setAlternateNames}
              />
              <ValidatedField
                formik={formik}
                label="Description"
                name="description"
                as="textarea"
                style={{ height: "6rem" }}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Founder"
                    name="founder"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Email"
                    name="email"
                    type="email"
                  />,
                ]}
              />
              <ValidatedField
                formik={formik}
                label="URL"
                name="url"
                type="url"
              />
              <ValidatedField
                formik={formik}
                label="Image URL"
                name="imageUrl"
                type="url"
              />

              <h5 className="mt-4">Location</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="City"
                    name="city"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Country"
                    name="country"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Address"
                    name="address"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Google Places ID"
                    name="placesId"
                    type="text"
                  />,
                ]}
              />
              <ArrayField
                label="Alternate addresses"
                value={altAddresses}
                onChange={setAltAddresses}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Latitude"
                    name="latitude"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Longitude"
                    name="longitude"
                    type="text"
                  />,
                ]}
              />

              <h5 className="mt-4">Social Media</h5>
              <ValidatedField
                formik={formik}
                label="Facebook"
                name="facebookProfile"
                type="text"
              />
              <ValidatedField
                formik={formik}
                label="Twitter"
                name="twitterProfile"
                type="text"
              />
              <ValidatedField
                formik={formik}
                label="Instagram"
                name="instagramProfile"
                type="text"
              />
              <ArrayField
                label="Other social URLs"
                value={otherSocials}
                onChange={setOtherSocials}
                type="url"
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default VenueForm;
