function TruncatedText(props) {
  if (props.children && props.children.length > props.max) {
    return (
      <span title={props.children}>
        {props.children.substr(0, props.max - 1)}&hellip;
      </span>
    );
  } else {
    return props.children || null;
  }
}

export default TruncatedText;
