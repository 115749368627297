import DeleteModal from "../components/DeleteModal";
import { deleteOffer } from "../clients/finance";

function DeleteOfferModal(props) {
  return (
    <DeleteModal
      request={deleteOffer}
      id={props.id}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The offer was deleted"
      title="Delete offer?"
      confirmText="DELETE"
      message={
        <p className="m-0">Do you want to delete the offer permanently?</p>
      }
    />
  );
}

export default DeleteOfferModal;
