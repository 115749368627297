import { useEffect, useState, useRef } from "react";

function useOnScreen(ref, callback) {
  const [element, setElement] = useState(null);
  const observer = useRef(null);
  const callbackRef = useRef(callback);

  const clearObserver = () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };

  useEffect(() => {
    setElement(ref.current);
  }, [ref]);

  useEffect(() => {
    if (!element) return;
    if (!callbackRef.current) return;

    clearObserver();

    observer.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        callbackRef.current();
        clearObserver();
      }
    });
    observer.current.observe(element);

    return () => {
      clearObserver();
    };
  }, [element, callbackRef]);
}

export { useOnScreen };
