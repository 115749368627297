import ValidatedField from "./ValidatedField";
import axios from "axios";
import config from "../config";
import { trim, omit } from "lodash";
import { useFormikContext, useField } from "formik";
import { useState, useEffect } from "react";
import { useThrottle } from "../hooks/useThrottle";

function GeocodedField(props) {
  const [geocodedLocation, setGeocodedLocation] = useState(null);
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const geocode = useThrottle(
    async (location, callback) => {
      if (trim(location)) {
        const response = await axios(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: { address: trim(location), key: config.maps.apiKey },
          }
        );

        if (response.data.results.length > 0) {
          callback(response.data.results[0]);
        }
      }
    },
    (location) => {
      setGeocodedLocation(location);

      if (props.onGeocode) {
        props.onGeocode(location);
      }
    },
    500
  );

  useEffect(() => geocode(field.value), [field.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    setGeocodedLocation(null);

    if (props.onGeocode) {
      props.onGeocode(null);
    }

    setFieldValue(field.name, event.target.value);
  };

  const hint = geocodedLocation ? (
    <span className="small text-muted">
      Found on Google Maps:{" "}
      <a
        href={`https://www.google.com/maps/place/?q=place_id:${geocodedLocation.place_id}`}
        target="_blank"
        rel="noreferrer"
      >
        {geocodedLocation.formatted_address}
      </a>
    </span>
  ) : (
    <span className="small text-muted">No match found on Google Maps</span>
  );

  return (
    <ValidatedField
      {...omit(props, ["onGeocode"])}
      onChange={handleChange}
      hint={hint}
    />
  );
}

export default GeocodedField;
