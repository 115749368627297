import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import toast from "../lib/toast";
import { Send } from "react-feather";
import { publish } from "../clients/collection";
import { useRequest } from "../hooks/useRequest";

function PublishModal(props) {
  const publishRequest = useRequest(publish);

  const handlePublish = () => {
    publishRequest.execute(props.id, () => {
      toast("Crate published");
      props.onClose();
      props.onPublish();
    });
  };

  return (
    <Modal
      title="Publish crate"
      body={
        <RequestContainer
          loading={publishRequest.loading}
          error={publishRequest.error}
          fullscreen={true}
        >
          This will publish the crate immediately, regardless of its position in
          the queue. A notification will be delivered to all users.
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            icon={Send}
            className="btn btn-success"
            onClick={handlePublish}
          >
            Publish
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default PublishModal;
