import DeleteFeeModal from "../modals/DeleteFeeModal";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import RequestContainer from "../components/RequestContainer";
import { useAutoRequest } from "../hooks/useRequest";
import { useState } from "react";

function FeesTable(props) {
  const [params] = useState(props.params);
  const request = useAutoRequest(props.request, params);

  const fees = request.response.data || [];

  return (
    <RequestContainer loading={request.loading}>
      <ObjectTable columns={["Name", "Amount"]}>
        {fees.map((fee) => (
          <ObjectTableRow
            actionAuthorizerRole={["admin", "editor"]}
            deleteAuthorizerRole="admin"
            editPath={`/sales/${props.offer.saleId}/offers/${props.offer.id}/fees/${fee.id}/edit`}
            deleteModal={DeleteFeeModal}
            deleteModalParams={{
              id: fee.id,
              onDelete: () => request.execute(),
            }}
            key={fee.id}
            columns={[fee.name, fee.amount]}
          />
        ))}
      </ObjectTable>
    </RequestContainer>
  );
}

export default FeesTable;
