import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import SaleForm from "../../forms/SaleForm";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { getSale, updateSale } from "../../clients/finance";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function EditSale() {
  let { sale_id: id } = useParams();
  const updateSaleRequest = useRequest(updateSale);
  const getSaleRequest = useAutoRequest(getSale, id);
  let history = useHistory();

  const sale = getSaleRequest.response.data || {};
  const loading = getSaleRequest.loading || updateSaleRequest.loading;
  const error = getSaleRequest.error || updateSaleRequest.error;

  return (
    <SaleForm
      initialValues={getSaleRequest.response.data}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateSaleRequest.execute({ id, data: values }, () => {
          history.goBack();
          toast(`The sale was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[`${sale.title || sale.externalId || ""}`]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </SaleForm>
  );
}

export default EditSale;
