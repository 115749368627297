import AddWorkToCrateModal from "../../modals/AddWorkToCrateModal";
import IconButton from "../../components/IconButton";
import IconLinkButton from "../../components/IconLinkButton";
import Image from "../../components/Image";
import ImageDimensions from "../../components/ImageDimensions";
import LogModal from "../../modals/LogModal";
import ObjectPropertyList from "../../components/ObjectPropertyList";
import Placeholder from "../../components/Placeholder";
import RequestContainer from "../../components/RequestContainer";
import TabCard from "../../components/TabCard";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import { Edit, PlusCircle, XCircle, Clock } from "react-feather";
import { get, isEmpty } from "lodash";
import { getCrate, removeFromCrate } from "../../clients/collection";
import { getWorks } from "../../clients/art";
import UnlinkWorkModal from "../../modals/UnlinkWorkModal";
import UserName from "../../components/UserName";
import { useParams, Link } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

const publishStatusToString = (status) => {
  switch (status) {
    case 0:
      return "Not ready";
    case 1:
      return "Ready";
    case 2:
      return "Current";
    case 3:
      return "Published";
    default:
      return "Unknown";
  }
};

function ViewCrate() {
  let { crate_id: id } = useParams();
  const getWorksRequest = useRequest(getWorks);
  const getCrateRequest = useAutoRequest(getCrate, id, (response) =>
    getWorksRequest.execute(response.data.artwork_populations.works)
  );
  const crate = getCrateRequest.response.data || {
    artwork_populations: { works: [] },
  };

  const reloadCrate = () => getCrateRequest.execute();

  let [showAddWorkToCollectionModal, addWorkToCrateModal] = useModal(
    AddWorkToCrateModal,
    {
      params: { id },
      onAdd: reloadCrate,
      crate,
    }
  );

  let [showUnlinkWorkModal, unlinkWorkModal] = useModal(UnlinkWorkModal, {
    onDelete: reloadCrate,
    request: removeFromCrate,
  });

  let [showLogModal, logModal] = useModal(LogModal, {
    filter: {
      type: "crate",
      id,
    },
  });

  const stitchedWorks = (
    get(getCrateRequest, "response.data.artwork_populations.works") || []
  ).map((workId) => ({
    id: workId,
    work: (getWorksRequest.response.data || []).find(
      (work) => work.id === workId
    ),
  }));

  const loading = getCrateRequest.loading || getWorksRequest.loading;
  const error = getCrateRequest.error || getWorksRequest.error;

  return (
    <>
      <ViewHeader
        breadcrumbs={[`${crate.flavor || ""}`]}
        actions={
          <>
            <IconButton
              onClick={showLogModal}
              icon={Clock}
              className="btn btn-secondary me-2"
            >
              Log
            </IconButton>
            <IconLinkButton
              to={`/crates/${id}/edit`}
              icon={Edit}
              className="btn btn-secondary me-2"
            >
              Edit
            </IconLinkButton>
          </>
        }
      />
      <ViewContents>
        <RequestContainer loading={loading} error={error}>
          <ObjectPropertyList
            items={[
              ["ID", crate.id],
              ["Description", crate.flavor],
              ["Status", publishStatusToString(crate.publish_status)],
              ["Published at", { date: crate.publish_time }],
              ["Latest", { bool: crate.latest }],
              ["Thumbnail", { image: crate.thumbnail }],
              ["Notification title", crate.notification_title],
              ["Notification text", crate.notification_text],
              ["Notification image", { image: crate.notification_image }],
              ["Created at", { date: crate.created_at }],
              ["Updated at", { date: crate.updated_at }],
              ["Creator", <UserName id={crate.creator} />],
            ]}
          />

          <TabCard
            activeTab="Works"
            tabs={{
              Works: {
                body: (
                  <Placeholder empty={isEmpty(stitchedWorks)}>
                    <table className="table table-hover align-middle">
                      <tbody>
                        {stitchedWorks.map((work, index) =>
                          work.work === undefined ? (
                            <tr key={work.id} className="table-danger">
                              <td colSpan="2">Missing work: {work.id}</td>
                              <td className="text-end text-nowrap">
                                <IconButton
                                  onClick={() =>
                                    showUnlinkWorkModal({
                                      title: work.id,
                                      params: { id, work_id: work.id },
                                    })
                                  }
                                  icon={XCircle}
                                  className="btn-sm btn-outline-secondary"
                                >
                                  Delete
                                </IconButton>
                              </td>
                            </tr>
                          ) : (
                            <tr key={work.id}>
                              <td
                                className="text-center"
                                style={{ width: "7rem" }}
                              >
                                {work.work.images &&
                                work.work.images.length > 0 ? (
                                  <Image
                                    src={work.work.images[0].url}
                                    alt=""
                                    style={{
                                      maxWidth: "6rem",
                                      maxHeight: "6rem",
                                    }}
                                  />
                                ) : null}
                              </td>
                              <td>
                                <p className="m-0 fw-bold">
                                  <Link
                                    to={`/artists/${get(work.work, [
                                      "artists",
                                      0,
                                      "id",
                                    ])}`}
                                    className="link-dark"
                                  >
                                    {get(work.work, ["artists", 0, "name"])}
                                  </Link>
                                </p>
                                <p className="m-0">
                                  <Link
                                    to={`/works/${work.id}`}
                                    className="link-secondary"
                                  >
                                    {work.work.title}
                                  </Link>
                                </p>
                                <span className="text-muted">
                                  {work.work.dateText}
                                </span>
                                {work.work.images &&
                                work.work.images.length > 0 ? (
                                  <ImageDimensions
                                    src={work.work.images[0].url}
                                  />
                                ) : null}
                              </td>
                              <td className="text-end text-nowrap">
                                <IconButton
                                  onClick={() =>
                                    showUnlinkWorkModal({
                                      title: work.work.title,
                                      params: { id, work_id: work.id },
                                    })
                                  }
                                  icon={XCircle}
                                  className="btn-sm btn-outline-secondary"
                                >
                                  Delete
                                </IconButton>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </Placeholder>
                ),
                actions: (
                  <IconButton
                    onClick={showAddWorkToCollectionModal}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Add works
                  </IconButton>
                ),
              },
            }}
          />
        </RequestContainer>
      </ViewContents>

      {addWorkToCrateModal}
      {unlinkWorkModal}
      {logModal}
    </>
  );
}

export default ViewCrate;
