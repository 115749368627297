import * as yup from "yup";
import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import ValidatedField from "../components/ValidatedField";
import toast from "../lib/toast";
import { Formik, Form } from "formik";
import { Save } from "react-feather";
import { mintToken } from "../clients/art";
import { prepareFormValues, returnFormValues } from "../lib/form";
import { useRequest } from "../hooks/useRequest";

function MintTokenModal(props) {
  const mintTokenRequest = useRequest(mintToken);

  const loading = mintTokenRequest.loading;
  const error = mintTokenRequest.error;

  const handleSave = ({ physicalTokenId }) =>
    mintTokenRequest.execute(
      { nftContractAddress: props.nftContractAddress, physicalTokenId },
      () => {
        toast("Token added");
        props.onMint();
        props.onClose();
      }
    );

  return (
    <Modal
      title="Mint token"
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          <Formik
            initialValues={prepareFormValues({ physicalTokenId: "" })}
            onSubmit={(values) => handleSave(returnFormValues(values))}
            validationSchema={yup.object({
              physicalTokenId: yup.string().required(),
            })}
          >
            {(formik) => (
              <Form id="create-image">
                <ValidatedField
                  formik={formik}
                  label="Physical token ID"
                  name="physicalTokenId"
                  type="text"
                />
              </Form>
            )}
          </Formik>
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            type="submit"
            form="create-image"
            disabled={loading}
            icon={Save}
            className="btn btn-success"
          >
            Save
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default MintTokenModal;
