import UnlinkModal from "../components/UnlinkModal";

function UnlinkEventModal(props) {
  return (
    <UnlinkModal
      request={props.request}
      params={props.params}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The event was unlinked"
      title="Unlink event?"
      message={
        <p className="m-0">
          Do you want to unlink&nbsp;
          <span className="text-muted">{props.name}</span>? This will not delete
          the event.
        </p>
      }
    />
  );
}

export default UnlinkEventModal;
