import * as chrono from "chrono-node";
import * as yup from "yup";
import ColumnRow from "../components/ColumnRow";
import ValidatedField from "../components/ValidatedField";
import axios from "axios";
import { Formik, Form } from "formik";
import { cloneDeep, isString, isArray } from "lodash";
import { format } from "date-fns";
import { getToken } from "../lib/firebase";
import { prepareFormValues, returnFormValues } from "../lib/form";
import { useAutoRequest } from "../hooks/useRequest";

const getArtaMetadata = async (token) =>
  axios.get("https://admin.arthuranalytics.com/api/getArtaMetadata", {
    headers: { Authorization: `Bearer ${await getToken()}` },
  });

const serializeShippingData = (data) => {
  const serialized = cloneDeep(data);
  if (
    serialized.shippingData &&
    serialized.shippingData.type &&
    serialized.shippingData.subtype
  ) {
    serialized.shippingData.type = JSON.stringify([
      serialized.shippingData.type,
      serialized.shippingData.subtype,
    ]);
    delete serialized.shippingData.subtype;
  }
  if (
    serialized.shippingData &&
    isArray(serialized.shippingData.current_packaging) &&
    serialized.shippingData.current_packaging.length > 0
  ) {
    serialized.shippingData.current_packaging =
      serialized.shippingData.current_packaging[0];
  }
  return serialized;
};

const deserializeShippingData = (data) => {
  const deserialized = cloneDeep(data);
  if (
    deserialized.shippingData &&
    deserialized.shippingData.type &&
    isString(deserialized.shippingData.type)
  ) {
    const [type, subtype] = JSON.parse(deserialized.shippingData.type);
    deserialized.shippingData.type = type;
    deserialized.shippingData.subtype = subtype;
  } else {
    delete deserialized.shippingData.type;
    delete deserialized.shippingData.subtype;
  }
  if (
    deserialized.shippingData &&
    deserialized.shippingData.current_packaging &&
    isString(deserialized.shippingData.current_packaging)
  ) {
    deserialized.shippingData.current_packaging = [
      deserialized.shippingData.current_packaging,
    ];
  } else {
    delete deserialized.shippingData.current_packaging;
  }
  return deserialized;
};

function WorkForm(props) {
  const artaMetadataRequest = useAutoRequest(getArtaMetadata);
  const artaMetadata = artaMetadataRequest.response.data || {
    objects: [],
    packings: [],
  };

  const handleDateChange = (formik, event) => {
    const result = chrono.parse(event.target.value);

    if (result && result[0] && result[0].start) {
      formik.setFieldValue(
        "earliestDate",
        format(result[0].start.date(), "yyyy-MM-dd")
      );
    } else {
      formik.setFieldValue("earliestDate", "");
    }

    if (result && result[0] && result[0].end) {
      formik.setFieldValue(
        "latestDate",
        format(result[0].end.date(), "yyyy-MM-dd")
      );
    } else {
      formik.setFieldValue("latestDate", "");
    }

    return formik.handleChange(event);
  };

  return (
    <Formik
      initialValues={prepareFormValues(
        serializeShippingData({
          artistAlt: "",
          artistRole: "",
          artistModifier: "",
          title: "",
          dateText: "",
          medium: "",
          dimensions: "",
          inscription: "",
          edition: "",
          description: "",
          earliestDate: "",
          latestDate: "",
          width: "",
          height: "",
          depth: "",
          units: "in",
          exhibitionHistory: "",
          provenance: "",
          literature: "",
          catalogRaisonneReference: "",
          condition: "",
          currentLocation: "",
          collectionTag: "",
          lastPrice: "",
          currency: "",
          priority: "",
          nftWalletAddress: "",
          nftContractAddress: "",
          nftTokenId: "",
          image_available: true,
          mintable: false,
          shippingData: {
            type: "",
            current_packaging: "",
            weight: "",
            weight_unit: "lb",
            is_fragile: false,
            is_cites: false,
          },
          ...props.initialValues,
        }),
        {
          types: {
            earliestDate: "date",
            latestDate: "date",
          },
        }
      )}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(
          returnFormValues(deserializeShippingData(values)),
          ...args
        )
      }
      validationSchema={yup.object({
        artistAlt: yup.string(),
        artistRole: yup.string(),
        artistModifier: yup.string(),
        title: yup.string().required(),
        dateText: yup.string(),
        medium: yup.string(),
        dimensions: yup.string(),
        inscription: yup.string(),
        edition: yup.string(),
        description: yup.string(),
        earliestDate: yup.object({ date: yup.date().nullable() }),
        latestDate: yup.object({ date: yup.date().nullable() }),
        width: yup.string(),
        height: yup.string(),
        depth: yup.string(),
        units: yup.string(),
        exhibitionHistory: yup.string(),
        provenance: yup.string(),
        literature: yup.string(),
        catalogRaisonneReference: yup.string(),
        condition: yup.string(),
        currentLocation: yup.string(),
        collectionTag: yup.string(),
        lastPrice: yup.string().matches(/\d/),
        currency: yup.string().matches(/[A-Z]{3}/),
        priority: yup.number().integer(),
        nftWalletAddress: yup.string(),
        nftContractAddress: yup.string(),
        nftTokenId: yup.string(),
        image_available: yup.boolean().default(true),
        mintable: yup.boolean().default(false),
        shippingData: yup.object({
          type: yup.string(),
          current_packaging: yup.string(),
          weight: yup.number(),
          weight_unit: yup.string(),
          is_fragile: yup.boolean(),
          is_cites: yup.boolean(),
        }),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <h5>Basic Information</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Title"
                    name="title"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Date"
                    name="dateText"
                    type="text"
                    onChange={(event) => handleDateChange(formik, event)}
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Earliest Date"
                    name="earliestDate"
                    type="date"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Latest Date"
                    name="latestDate"
                    type="date"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Alternative Artist"
                    name="artistAlt"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Artist Role"
                    name="artistRole"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Artist Modifier"
                    name="artistModifier"
                    type="text"
                  />,
                  "",
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Inscription"
                    name="inscription"
                    as="textarea"
                    style={{ height: "6rem" }}
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Edition"
                    name="edition"
                    as="textarea"
                    style={{ height: "6rem" }}
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Description"
                    name="description"
                    as="textarea"
                    style={{ height: "6rem" }}
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Exhibition History"
                    name="exhibitionHistory"
                    as="textarea"
                    style={{ height: "6rem" }}
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Provenance"
                    name="provenance"
                    as="textarea"
                    style={{ height: "6rem" }}
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Literature"
                    name="literature"
                    as="textarea"
                    style={{ height: "6rem" }}
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Catalog Raisonne Reference"
                    name="catalogRaisonneReference"
                    as="textarea"
                    style={{ height: "6rem" }}
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Condition"
                    name="condition"
                    as="textarea"
                    style={{ height: "6rem" }}
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Image Available"
                    name="image_available"
                    type="checkbox"
                  />,
                ]}
              />

              <h5>Physical Properties</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Medium"
                    name="medium"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Dimensions"
                    name="dimensions"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Width"
                    name="width"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Height"
                    name="height"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Depth"
                    name="depth"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Units"
                    name="units"
                    type="text"
                  />,
                ]}
              />

              <h5>Shipping Information</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Object type"
                    name="shippingData.type"
                    as="select"
                  >
                    <option value=""></option>
                    {artaMetadata.objects.map((item) => (
                      <optgroup key={item.id} label={item.name}>
                        {item.subtypes.map((subtype) => (
                          <option
                            key={subtype.id}
                            value={JSON.stringify([item.id, subtype.id])}
                          >
                            {subtype.name}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </ValidatedField>,
                  <ValidatedField
                    formik={formik}
                    label="Current packaging"
                    name="shippingData.current_packaging"
                    as="select"
                  >
                    <option value=""></option>
                    {artaMetadata.packings.map((item) => (
                      <optgroup key={item.id} label={item.name}>
                        {item.subtypes.map((subtype) => (
                          <option key={subtype.id} value={subtype.id}>
                            {subtype.name}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </ValidatedField>,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Weight"
                    name="shippingData.weight"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Weight unit"
                    name="shippingData.weight_unit"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Fragile"
                    name="shippingData.is_fragile"
                    type="checkbox"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Subject to CITES regulation"
                    name="shippingData.is_cites"
                    type="checkbox"
                  />,
                ]}
              />

              <h5>Sale Information</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Last Price"
                    name="lastPrice"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Currency"
                    name="currency"
                    as="select"
                    currency={true}
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Current Location"
                    name="currentLocation"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Collection Tag"
                    name="collectionTag"
                    type="text"
                  />,
                ]}
              />
              <ValidatedField
                formik={formik}
                label="Priority"
                name="priority"
                type="text"
              />

              <h5>NFT</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="NFT Wallet Address"
                    name="nftWalletAddress"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="NFT Contract Address"
                    name="nftContractAddress"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="NFT Token ID"
                    name="nftTokenId"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Mintable"
                    name="mintable"
                    type="checkbox"
                  />,
                ]}
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default WorkForm;
