import AddWorkModal from "../components/AddWorkModal";
import { addToProgram } from "../clients/collection";
import { get } from "lodash";

function AddWorkToProgramModal(props) {
  return (
    <AddWorkModal
      addRequest={addToProgram}
      onAddMessage="The work was added to the program"
      modalTitle="Add works to program"
      existingWorks={get(props, ["program", "artwork_populations", "works"])}
      {...props}
    />
  );
}

export default AddWorkToProgramModal;
