import UnlinkModal from "../components/UnlinkModal";

function UnlinkOfferModal(props) {
  return (
    <UnlinkModal
      request={props.request}
      params={props.params}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The offer was unlinked"
      title="Unlink offer?"
      message={
        <p className="m-0">
          Do you want to unlink the offer? This will not delete the offer.
        </p>
      }
    />
  );
}

export default UnlinkOfferModal;
