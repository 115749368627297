import { Redirect } from "react-router-dom";
import { getRole } from "../lib/firebase";
import { isArray } from "lodash";
import { useState, useEffect } from "react";

function Authorizer(props) {
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() =>
    (async () =>
      setIsAuthorized(
        isArray(props.role)
          ? props.role.includes(await getRole())
          : (await getRole()) === props.role
      ))()
  );

  if (isAuthorized === true) {
    return props.children;
  } else {
    return props.redirect && isAuthorized === false ? (
      <Redirect to={props.redirect} />
    ) : (
      <></>
    );
  }
}

export default Authorizer;
