import * as yup from "yup";
import ArrayField from "../components/ArrayField";
import ColumnRow from "../components/ColumnRow";
import ValidatedField from "../components/ValidatedField";
import { Formik, Form } from "formik";
import { prepareFormValues, returnFormValues } from "../lib/form";
import { isNil, get, compact } from "lodash";
import { useState, useEffect } from "react";

function OfferForm(props) {
  const [symbols, setSymbols] = useState([]);

  useEffect(
    () => setSymbols(get(props.initialValues, "symbols")),
    [props.initialValues]
  );

  return (
    <Formik
      initialValues={prepareFormValues(
        {
          externalId: "",
          offerType: "lot",
          sequenceNumber: "",
          url: "",
          currency: "",
          estimateLow: "",
          estimateHigh: "",
          startingBid: "",
          price: "",
          publishedPrice: "",
          sold: false,
          withdrawn: false,
          openedAt: "",
          closedAt: "",
          symbols: "",
          ...props.initialValues,
        },
        {
          types: {
            openedAt: "datetime",
            closedAt: "datetime",
          },
        }
      )}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(
          returnFormValues({
            ...values,
            symbols: compact(symbols),
          }),
          ...args
        )
      }
      validationSchema={yup.object({
        externalId: yup.string().required(),
        offerType: yup.string().required(),
        url: yup.string().url(),
        currency: yup.string().matches(/[A-Z]{3}/),
        estimateLow: yup
          .number()
          .positive()
          .test(
            "is-less-than-high-estimate",
            "estimateLow must be less than estimateHigh",
            (value, context) =>
              isNil(value) ||
              isNil(context.parent.estimateHigh) ||
              value < context.parent.estimateHigh
          ),
        estimateHigh: yup
          .number()
          .positive()
          .test(
            "is-more-than-low-estimate",
            "estimateHigh must be more than estimateLow",
            (value, context) =>
              isNil(value) ||
              isNil(context.parent.estimateLow) ||
              value > context.parent.estimateLow
          ),
        startingBid: yup.number().positive(),
        price: yup.number().positive(),
        publishedPrice: yup.number().positive(),
        sold: yup.boolean(),
        openedAt: yup.object({ datetime: yup.date().nullable() }),
        closedAt: yup.object({ datetime: yup.date().nullable() }),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <h5>Basic Information</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Type"
                    name="offerType"
                    as="select"
                  >
                    <option value="lot">lot</option>
                    <option value="work">work</option>
                  </ValidatedField>,
                  <ValidatedField
                    formik={formik}
                    label="Sequence number"
                    name="sequenceNumber"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Opened at"
                    name="openedAt"
                    type="datetime-local"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Closed at"
                    name="closedAt"
                    type="datetime-local"
                  />,
                ]}
              />
              <ArrayField
                label="Symbols"
                value={symbols}
                onChange={setSymbols}
              />
              <ValidatedField
                formik={formik}
                label="URL"
                name="url"
                type="url"
              />
              <ValidatedField
                formik={formik}
                label="External ID"
                name="externalId"
                type="text"
              />
              <h5 className="mt-4">Financial Information</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Price"
                    name="price"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Currency"
                    name="currency"
                    as="select"
                    currency={true}
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Low estimate"
                    name="estimateLow"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="High estimate"
                    name="estimateHigh"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Starting bid"
                    name="startingBid"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Published price (including premium)"
                    name="publishedPrice"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Sold"
                    name="sold"
                    type="checkbox"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Withdrawn"
                    name="withdrawn"
                    type="checkbox"
                  />,
                ]}
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default OfferForm;
