import * as yup from "yup";
import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import ValidatedField from "../components/ValidatedField";
import toast from "../lib/toast";
import { Formik, Form } from "formik";
import { Save } from "react-feather";
import { getCollection, updateCollection } from "../clients/collection";
import { prepareFormValues, returnFormValues } from "../lib/form";
import { useRequest } from "../hooks/useRequest";
import { concat, sortBy, sortedUniq } from "lodash";

function AddTagToCollectionModal(props) {
  const getCollectionRequest = useRequest(getCollection);
  const updateCollectionRequest = useRequest(updateCollection);

  const loading =
    getCollectionRequest.loading || updateCollectionRequest.loading;
  const error = getCollectionRequest.error || updateCollectionRequest.error;

  const handleSave = ({ tags }) =>
    getCollectionRequest.execute(props.params.id, ({ data: collection }) => {
      const allTags = sortedUniq(
        sortBy(concat(collection.tags, tags.trim().split(/\s+/)))
      );

      updateCollectionRequest.execute(
        {
          id: props.params.id,
          data: { ...collection, tags: allTags },
        },
        () => {
          toast("Tags saved");
          props.onAdd();
          props.onClose();
        }
      );
    });

  return (
    <Modal
      title="Add tags to collection"
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          <Formik
            initialValues={prepareFormValues({ tags: "" })}
            onSubmit={(values) => handleSave(returnFormValues(values))}
            validationSchema={yup.object({
              tags: yup.string().required(),
            })}
          >
            {(formik) => (
              <Form id="create-image">
                <ValidatedField
                  formik={formik}
                  label="Tags (separated by space)"
                  name="tags"
                  type="text"
                />
              </Form>
            )}
          </Formik>
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            type="submit"
            form="create-image"
            disabled={loading}
            icon={Save}
            className="btn btn-success"
          >
            Save
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default AddTagToCollectionModal;
