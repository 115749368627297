import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import WorkForm from "../../forms/WorkForm";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { getWork, updateWork } from "../../clients/art";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function EditWork() {
  let { work_id: id } = useParams();
  const updateWorkRequest = useRequest(updateWork);
  const getWorkRequest = useAutoRequest(getWork, id);
  let history = useHistory();

  const work = getWorkRequest.response.data || {};
  const loading = getWorkRequest.loading || updateWorkRequest.loading;
  const error = getWorkRequest.error || updateWorkRequest.error;

  return (
    <WorkForm
      initialValues={getWorkRequest.response.data}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateWorkRequest.execute({ id, data: values }, () => {
          history.goBack();
          toast(`The work was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[`${work.title || ""}`]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </WorkForm>
  );
}

export default EditWork;
