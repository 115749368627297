import LinkModal from "../components/LinkModal";
import { search, getSale } from "../clients/finance";

function LinkSaleModal(props) {
  return (
    <LinkModal
      linkRequest={props.linkRequest}
      params={props.params}
      onClose={props.onClose}
      onLink={props.onLink}
      placeholder="Search sales&hellip;"
      keyName="saleId"
      nameAttribute="title"
      getRequest={getSale}
      searchRequest={search}
      resultType="sale"
      title="Link sale"
      toastMessage="The sale was linked"
    />
  );
}

export default LinkSaleModal;
