import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import VenueForm from "../../forms/VenueForm";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { createVenue } from "../../clients/art";
import { useHistory } from "react-router-dom";
import { useQueryString } from "../../hooks/useQueryString";
import { useRequest } from "../../hooks/useRequest";

function NewVenue() {
  const eventId = useQueryString("eventId");
  const saleId = useQueryString("saleId");
  const parentId = useQueryString("parentId");
  const createRequest = useRequest(createVenue);
  let history = useHistory();

  return (
    <VenueForm
      onSubmit={(values, actions) => {
        const createOneMore =
          window.event.submitter.classList.contains("create-one-more");

        createRequest.execute(
          { ...values, eventId, saleId, parentId },
          (response) => {
            if (createOneMore) {
              actions.resetForm();
            } else {
              history.push(`/venues/${response.data.id}`);
            }
            toast("The venue was created");
          }
        );
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={["New venue"]}
            actions={
              <>
                <IconButton
                  type="submit"
                  disabled={createRequest.loading}
                  icon={Save}
                  className="btn btn-secondary me-2 create-one-more"
                >
                  Save and create one more
                </IconButton>
                <IconButton
                  type="submit"
                  disabled={createRequest.loading}
                  icon={Save}
                  className="btn btn-success"
                >
                  Save
                </IconButton>
              </>
            }
          />
          <ViewContents>
            <RequestContainer
              loading={createRequest.loading}
              error={createRequest.error}
            >
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </VenueForm>
  );
}

export default NewVenue;
