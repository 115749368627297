function LoadIndicator(props) {
  const minHeight = props.minHeight || "11rem";
  const spinnerSize = props.spinnerSize || "5rem";

  if (props.fullscreen) {
    return (
      <>
        {props.children}
        {props.loading ? (
          <div
            className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
            style={{ background: "rgba(255, 255, 255, 0.75)" }}
          >
            <div
              className="spinner-grow"
              style={{ width: spinnerSize, height: spinnerSize }}
            ></div>
          </div>
        ) : null}
      </>
    );
  } else {
    return props.loading ? (
      <div className="position-relative" style={{ minHeight: minHeight }}>
        {props.children}

        <div
          className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
          style={{ background: "rgba(255, 255, 255, 0.75)" }}
        >
          <div
            className="spinner-grow"
            style={{ width: spinnerSize, height: spinnerSize }}
          ></div>
        </div>
      </div>
    ) : (
      props.children
    );
  }
}

export default LoadIndicator;
