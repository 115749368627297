import DeleteArtistModal from "../modals/DeleteArtistModal";
import Image from "../components/Image";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import PaginatedContainer from "../components/PaginatedContainer";
import RequestContainer from "../components/RequestContainer";
import TruncatedText from "../components/TruncatedText";
import UnlinkArtistModal from "../modals/UnlinkArtistModal";
import { get, sortBy, isNil } from "lodash";
import { useAutoRequest } from "../hooks/useRequest";
import { useState } from "react";

function ArtistsTable(props) {
  const isChildRequest = !isNil(props.parentRequest);

  const [params, setParams] = useState({ sortby: "name", ...props.params });
  const request = useAutoRequest(
    props.request,
    isChildRequest
      ? get(props.parentRequest, `response.data.${props.parentAttribute}`)
      : params
  );

  const artists = request.response.data || [];
  const missingArtists =
    isChildRequest && !request.loading
      ? (
          get(props.parentRequest, `response.data.${props.parentAttribute}`) ||
          []
        ).filter(
          (artistId) => !artists.some((artist) => artist.id === artistId)
        )
      : [];
  const responseParams = get(request.response, "config.params") || params;

  const handleParamsChange = (newParams) => {
    const allParams = { ...params, ...newParams };

    if (!("skip" in newParams)) {
      allParams.skip = 0;
    }

    setParams(allParams);

    if (props.onParamsChange) {
      props.onParamsChange(allParams);
    }
  };

  const columns = isChildRequest
    ? ["", "Name", "Dates", "Nationality", "Role", "Works"]
    : [
        "",
        {
          title: "Name",
          sortable: true,
          currentSortBy: params.sortby === "name",
          currentSortOrder: "asc",
          onSort: (order) => handleParamsChange({ sortby: "name" }),
        },
        {
          title: "Dates",
          sortable: true,
          currentSortBy: params.sortby === "birthdate",
          currentSortOrder: "asc",
          onSort: (order) => handleParamsChange({ sortby: "birthdate" }),
        },
        "Nationality",
        "Role",
        "Works",
      ];

  const rows = [
    ...missingArtists.map((artistId) => (
      <ObjectTableRow
        colspan={[6]}
        columns={[`Missing artist: ${artistId}`]}
        deleteModal={
          props.unlinkRequest ? UnlinkArtistModal : DeleteArtistModal
        }
        deleteModalParams={{
          id: artistId,
          name: artistId,
          onDelete: () => props.parentRequest.execute(),
          request: props.unlinkRequest,
          params: { artistId: artistId, ...props.unlinkParams },
        }}
      />
    )),
    ...sortBy(artists, "name").map((artist) => (
      <ObjectTableRow
        actionAuthorizerRole={["admin", "editor"]}
        deleteAuthorizerRole={props.unlinkRequest ? null : "admin"}
        viewPath={`/artists/${artist.id}`}
        editPath={`/artists/${artist.id}/edit`}
        deleteModal={
          props.unlinkRequest ? UnlinkArtistModal : DeleteArtistModal
        }
        deleteModalParams={{
          id: artist.id,
          name: artist.name,
          onDelete: () =>
            isChildRequest ? props.parentRequest.execute() : request.execute(),
          request: props.unlinkRequest,
          params: { artistId: artist.id, ...props.unlinkParams },
        }}
        key={artist.id}
        clipboardValue={artist.id}
        columns={[
          artist.artistImage ? (
            <div className="text-center" style={{ width: "7rem" }}>
              <Image
                src={artist.artistImage}
                alt=""
                style={{
                  maxWidth: "6rem",
                  maxHeight: "6rem",
                }}
              />
            </div>
          ) : null,
          <TruncatedText max={40}>{artist.name}</TruncatedText>,
          artist.dates,
          artist.nationality,
          artist.role,
          artist.work_count || 0,
        ]}
      />
    )),
  ];

  const table = <ObjectTable columns={columns}>{rows}</ObjectTable>;

  return (
    <RequestContainer loading={request.loading}>
      {props.paginated ? (
        <PaginatedContainer
          enabled={!request.loading}
          count={artists.length}
          params={responseParams}
          onNavigate={handleParamsChange}
        >
          {table}
        </PaginatedContainer>
      ) : (
        table
      )}
    </RequestContainer>
  );
}

export default ArtistsTable;
