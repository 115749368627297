import config from "../config";
import { logEvent } from "../lib/log";
import { getRole, getUid } from "../lib/firebase";
import { listWorksByEvent, listArtistsByWork, getWorks } from "./art";
import { merge, omit, get, flatMap, uniq, last } from "lodash";
import { request as baseRequest, virtualBulk } from "./common";
import { v4 as uuidv4 } from "uuid";

const DEFAULT_QUEUE_NAME = "General Crates";

const request = async (token, endpoint, options = {}) =>
  await baseRequest(config.collection_api.url, token, endpoint, options);

const scroll = async (token, endpoint, options = {}) => {
  const data = [];
  const limit = get(options, "params.limit") || 100;
  let start_after = null;
  while (true) {
    try {
      const response = await request(
        token,
        endpoint,
        merge(options, { params: { start_after, limit } })
      );
      data.push(...response.data);
      if (response.data.length < limit) {
        break;
      }
      start_after = last(data).id;
    } catch (error) {
      if (get(error, "response.status") === 400) {
        break;
      } else {
        throw error;
      }
    }
  }
  return { data: data.length > 0 ? data : null };
};

const listCollections = async (token, params) => {
  const collections = await request(token, "collections", { params });
  if (collections.data && (await getRole()) !== "admin") {
    collections.data = collections.data.filter(
      (collection) =>
        collection.owner === getUid() ||
        (collection.editors || []).includes(getUid())
    );
  }
  return collections;
};

const getCollection = async (token, id) =>
  await request(token, `collections/${id}`);

const createCollection = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "collections", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateCollection", data, { type: "collection", id });
  return response;
};

const updateCollection = async (token, { id, data }) => {
  const response = await request(token, `collections/${id}`, {
    method: "put",
    data: {
      ...data,
      blurhash: null,
      compressed_handle: null,
    },
  });
  logEvent("UpdateCollection", data, { type: "collection", id });
  return response;
};

const deleteCollection = async (token, id) => {
  const response = await request(token, `collections/${id}`, {
    method: "delete",
  });
  logEvent("DeleteCollection", {}, { type: "collection", id });
  return response;
};

const addToCollection = async (token, params) => {
  const response = await request(token, `add_to_collection/${params.id}`, {
    method: "post",
    params: omit(params, "id"),
  });
  logEvent("AddToCollection", params, { type: "collection", id: params.id });
  return response;
};

const removeFromCollection = async (token, params) => {
  const response = await request(token, `remove_from_collection/${params.id}`, {
    method: "delete",
    params: omit(params, "id"),
  });
  logEvent("RemoveFromCollection", params, {
    type: "collection",
    id: params.id,
  });
  return response;
};

const editComment = async (token, params) => {
  const response = await request(token, `edit_comment/${params.id}`, {
    method: "put",
    params: omit(params, "id"),
  });
  logEvent("EditComment", params, { type: "collection", id: params.id });
  return response;
};

const editImageUrl = async (token, params) => {
  const response = await request(token, `edit_image_url/${params.id}`, {
    method: "put",
    params: omit(params, "id"),
  });
  logEvent("EditImageUrl", params, { type: "collection", id: params.id });
  return response;
};

const swap = async (token, params) =>
  await request(token, `swap_element_order/${params.id}`, {
    method: "put",
    params: omit(params, "id"),
  });

const listCrates = async (token, params) =>
  await request(token, "contexts", {
    params: merge({ limit: 1000, type: "crate" }, params),
  });

const getCrate = async (token, id) => await request(token, `contexts/${id}`);

const createCrate = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "contexts", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateCrate", data, { type: "crate", id });
  return response;
};

const updateCrate = async (token, { id, data }) => {
  const response = await request(token, `contexts/${id}`, {
    method: "put",
    data,
  });
  logEvent("UpdateCrate", data, { type: "crate", id });
  return response;
};

const addToCrate = async (token, params) => {
  const response = await request(token, `contexts/${params.id}/add_work`, {
    method: "put",
    params: omit(params, "id"),
  });
  logEvent("AddToCrate", params, { type: "crate", id: params.id });
  return response;
};

const removeFromCrate = async (token, params) => {
  const response = await request(token, `contexts/${params.id}/remove_work`, {
    method: "put",
    params: omit(params, "id"),
  });
  logEvent("RemoveFromCrate", params, { type: "crate", id: params.id });
  return response;
};

const listPrograms = async (token, params) => {
  const requests = [];

  if (!params.type || params.type === "art_fair") {
    requests.push(
      request(token, "contexts", {
        params: merge({ limit: 1000 }, params, { type: "art_fair" }),
      })
    );
  }

  if (!params.type || params.type === "auction_week") {
    requests.push(
      request(token, "contexts", {
        params: merge({ limit: 1000 }, params, { type: "auction_week" }),
      })
    );
  }

  const responses = await Promise.allSettled(requests);

  return {
    data: flatMap(
      responses.filter((response) => response.status === "fulfilled"),
      "value.data"
    ),
  };
};

const getProgram = async (token, id) => await request(token, `contexts/${id}`);

const createProgram = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "contexts", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateProgram", data, { type: "program", id });
  return response;
};

const updateProgram = async (token, { id, data }) => {
  const response = await request(token, `contexts/${id}`, {
    method: "put",
    data: data,
  });
  logEvent("UpdateProgram", data, { type: "program", id });
  return response;
};

const addToProgram = async (token, params) => {
  const artists = await listArtistsByWork(token, params.work_id);

  if (artists.data) {
    await virtualBulk(
      token,
      uniq(
        artists.data.map((artist) => ({ id: params.id, artistId: artist.id }))
      ),
      addArtistToProgram
    );
  }

  const response = await request(token, `contexts/${params.id}/add_work`, {
    method: "put",
    params: omit(params, "id"),
  });
  logEvent("AddToProgram", params, { type: "program", id: params.id });
  return response;
};

const removeFromProgram = async (token, params) => {
  const response = await request(token, `contexts/${params.id}/remove_work`, {
    method: "put",
    params: omit(params, "id"),
  });
  logEvent("RemoveFromProgram", params, { type: "program", id: params.id });
  return response;
};

const addEventToProgram = async (token, { id, eventId }) => {
  const works = await listWorksByEvent(token, eventId);

  if (works.data) {
    await virtualBulk(
      token,
      uniq(works.data.map((work) => ({ id, work_id: work.id }))),
      addToProgram
    );
  }

  const response = await request(token, `contexts/${id}/add_event`, {
    method: "put",
    params: { event_id: eventId },
  });
  logEvent("AddEventToProgram", { event_id: eventId }, { type: "program", id });
  return response;
};

const removeEventFromProgram = async (token, { id, eventId }) => {
  const works = await listWorksByEvent(token, eventId);

  if (works.data) {
    await virtualBulk(
      token,
      uniq(works.data.map((work) => ({ id, work_id: work.id }))),
      removeFromProgram
    );
  }

  const response = await request(token, `contexts/${id}/remove_event`, {
    method: "put",
    params: { event_id: eventId },
  });
  logEvent(
    "RemoveEventFromProgram",
    { event_id: eventId },
    { type: "program", id }
  );
  return response;
};

const addArtistToProgram = async (token, { id, artistId }) => {
  const response = await request(token, `contexts/${id}/add_artist`, {
    method: "put",
    params: { artist_id: artistId },
  });
  logEvent(
    "AddArtistToProgram",
    { artist_id: artistId },
    { type: "program", id }
  );
  return response;
};

const removeArtistFromProgram = async (token, { id, artistId }) => {
  const response = await request(token, `contexts/${id}/remove_artist`, {
    method: "put",
    params: { artist_id: artistId },
  });
  logEvent(
    "RemoveArtistFromProgram",
    { artist_id: artistId },
    { type: "program", id }
  );
  return response;
};

const syncProgramEvents = async (token, id) => {
  const program = await getProgram(token, id);
  const events = program.data.events || [];
  const existingWorks =
    get(program, ["data", "artwork_populations", "works"]) || [];

  for (const event of events) {
    const works = await listWorksByEvent(token, event);

    if (works.data) {
      await virtualBulk(
        token,
        uniq(
          works.data
            .filter((work) => !existingWorks.includes(work.id))
            .map((work) => ({ id, work_id: work.id }))
        ),
        addToProgram
      );
    }
  }

  return program;
};

const syncProgramArtists = async (token, id) => {
  const program = await getProgram(token, id);
  const existingArtists = program.data.artists || [];

  const works = await getWorks(
    token,
    get(program, ["data", "artwork_populations", "works"]) || []
  );

  if (works.data) {
    const artists = flatMap(
      works.data.map((work) => work.artists.map((artist) => artist.id))
    );

    if (artists) {
      await virtualBulk(
        token,
        uniq(
          artists
            .filter((artistId) => !existingArtists.includes(artistId))
            .map((artistId) => ({ id, artistId }))
        ),
        addArtistToProgram
      );
    }
  }

  return program;
};

const syncProgram = async (token, id) => {
  await syncProgramEvents(token, id);
  await syncProgramArtists(token, id);
  return await getProgram(token, id);
};

const getRecommendedArtists = async (token, params) =>
  await request(token, `recommendations/artists`, { params });

const getRecommendedWorks = async (token, params) =>
  await request(token, `recommendations/artworks`, { params });

const getLikes = async (token, params) =>
  await request(token, `artreactions`, {
    params: merge(
      {
        context: "crate",
        reaction: "like",
      },
      params
    ),
  });

const listQueues = async (token, params) =>
  await scroll(token, "queues", { params });

const ensureDefaultQueue = async (token) => {
  try {
    const queues = await listQueues(token, {});

    const defaultQueue = queues.data.find(
      (queue) => queue.name === DEFAULT_QUEUE_NAME
    );

    if (defaultQueue) {
      return defaultQueue;
    }
  } catch (error) {
    if (error.response.status !== 404) {
      throw error;
    }
  }

  const id = uuidv4();
  const data = {
    name: DEFAULT_QUEUE_NAME,
    description: "Queue to manage the crates all users see on a daily basis.",
    type: "contexts",
  };
  const response = await request(token, "queues", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateQueue", data, { type: "queue", id });
  return response.data;
};

const addToQueue = async (token, id) => {
  const defaultQueue = await ensureDefaultQueue(token);

  return await request(token, `queues/${defaultQueue.id}/add`, {
    method: "patch",
    params: { object_id: id },
  });
};

const removeFromQueue = async (token, id) => {
  const defaultQueue = await ensureDefaultQueue(token);

  return await request(token, `queues/${defaultQueue.id}/remove`, {
    method: "patch",
    params: { object_id: id },
  });
};

const getQueue = async (token) => {
  const defaultQueue = await ensureDefaultQueue(token);
  return await request(token, `queues/${defaultQueue.id}`);
};

const reorderQueue = async (token, ids) => {
  const defaultQueue = await ensureDefaultQueue(token);

  return await request(token, `queues/${defaultQueue.id}/order`, {
    method: "patch",
    data: { objects: ids },
  });
};

const publish = async (token, id) => {
  const defaultQueue = await ensureDefaultQueue(token);

  return await request(token, `queues/${defaultQueue.id}/publish`, {
    method: "patch",
    params: { object_id: id },
  });
};

const publishCollection = async (token, id) =>
  await request(token, `collections/${id}/publish`, {
    method: "patch",
  });

export {
  listCollections,
  getCollection,
  createCollection,
  updateCollection,
  deleteCollection,
  addToCollection,
  removeFromCollection,
  editComment,
  editImageUrl,
  swap,
  listCrates,
  getCrate,
  createCrate,
  updateCrate,
  addToCrate,
  removeFromCrate,
  listPrograms,
  getProgram,
  createProgram,
  updateProgram,
  addToProgram,
  removeFromProgram,
  addEventToProgram,
  removeEventFromProgram,
  addArtistToProgram,
  removeArtistFromProgram,
  syncProgram,
  getRecommendedArtists,
  getRecommendedWorks,
  getLikes,
  listQueues,
  addToQueue,
  removeFromQueue,
  getQueue,
  reorderQueue,
  publish,
  publishCollection,
};
