import { AlertTriangle } from "react-feather";
import { memo, useState, useEffect } from "react";

const sanitizeUrl = (props) =>
  !props.src ||
  props.src.toLowerCase().startsWith("http://") ||
  props.src.toLowerCase().startsWith("https://")
    ? props
    : { ...props, src: `http://${props.src}` };

const ImageDimensions = memo((props) => {
  const [dimensions, setDimensions] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setDimensions({
        width: img.width,
        height: img.height,
      });
    };
    img.src = sanitizeUrl(props).src;
  }, [props]);

  if (dimensions) {
    if (dimensions.width < 700) {
      return (
        <div
          className="text-nowrap text-danger"
          title="Smaller than recommended minimum width of 700px"
        >
          <AlertTriangle size={16} className="me-1" />
          {dimensions.width} x {dimensions.height}
        </div>
      );
    } else {
      return (
        <div className="text-nowrap">
          {dimensions.width} x {dimensions.height}
        </div>
      );
    }
  } else {
    return null;
  }
});

export default ImageDimensions;
