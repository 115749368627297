import ColumnRow from "../components/ColumnRow";
import IconButton from "../components/IconButton";
import Image from "../components/Image";
import ImageDimensions from "../components/ImageDimensions";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import { PlusCircle, CheckCircle, Save } from "react-feather";
import { addImageToIndex } from "../clients/import";
import { createImage } from "../clients/art";
import { upload, storeURL } from "../lib/filestack";
import { editImageUrl } from "../clients/collection";
import { getWork } from "../clients/art";
import { isString } from "lodash";
import { useAutoRequest, useRequest } from "../hooks/useRequest";
import { useRef, useState } from "react";

const ACCEPTED_TYPES = [
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/webp",
  "video/mp4",
  "video/mpeg",
  "video/webm",
  "video/x-flv",
  "video/x-matroska",
];

const normalizeWorkId = (workId) => {
  if (isString(workId)) {
    return workId.split("_")[0];
  }
  return workId;
};

function UploadImageForm(props) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMode, setUploadMode] = useState("file");
  const [sourceUrl, setSourceUrl] = useState("");
  const [copyright, setCopyright] = useState("");
  const [attribution, setAttribution] = useState("");
  const [source, setSource] = useState("");
  const fileField = useRef(null);

  const createImageRequest = useRequest(createImage);
  const addImageToIndexRequest = useRequest(addImageToIndex);

  const loading = createImageRequest.loading || isUploading;
  const error = createImageRequest.error;

  const handleSave = async () => {
    let url = null;
    let handle = null;

    if (uploadMode === "file") {
      if (fileField.current.files.length === 0) {
        return;
      }

      if (!ACCEPTED_TYPES.includes(fileField.current.files[0].type)) {
        alert("Invalid file type selected");
        return;
      }

      setIsUploading(true);
      const response = await upload(fileField.current.files[0]);
      url = response.url;
      handle = response.handle;
      setIsUploading(false);
    } else {
      if (!sourceUrl) {
        return;
      }

      setIsUploading(true);
      const response = await storeURL(sourceUrl);
      url = response.url;
      handle = response.handle;
      setIsUploading(false);
    }

    createImageRequest.execute(
      {
        url: url,
        name: url,
        copyright: copyright,
        attribution: attribution,
        source: source,
        service: "filestack",
        handle: handle,
        workId: props.work.id,
        priority: -100, // Set a low priority for images uploaded on a collection, making them appear last outside the collection
      },
      (createImageResponse) => {
        addImageToIndexRequest.execute({
          id: createImageResponse.data.id,
          url,
          workId: props.work.id,
          workTitle: props.work.title,
        });
        props.onUpload(url);
      }
    );
  };

  return (
    <RequestContainer
      loading={loading}
      error={error}
      minHeight="1rem"
      spinnerSize="1rem"
    >
      <div className="btn-group btn-group-sm w-100 mb-3">
        <input
          type="radio"
          className="btn-check"
          id="btnradio1"
          autoComplete="off"
          onChange={(event) =>
            setUploadMode(event.target.checked ? "file" : "url")
          }
          checked={uploadMode === "file"}
        />
        <label className="btn btn-outline-secondary" htmlFor="btnradio1">
          Upload from file
        </label>

        <input
          type="radio"
          className="btn-check"
          id="btnradio2"
          autoComplete="off"
          onChange={(event) =>
            setUploadMode(event.target.checked ? "url" : "file")
          }
          checked={uploadMode === "url"}
        />
        <label className="btn btn-outline-secondary" htmlFor="btnradio2">
          Upload from URL
        </label>
      </div>
      <div className="row align-items-center mb-3">
        <div className="col">
          {uploadMode === "file" ? (
            <input
              type="file"
              id="file"
              accept={ACCEPTED_TYPES.join(",")}
              className="form-control"
              ref={fileField}
            />
          ) : (
            <div className="form-floating">
              <input
                type="text"
                placeholder="URL"
                id="sourceUrl"
                name="sourceUrl"
                value={sourceUrl}
                className="form-control"
                onChange={(event) => setSourceUrl(event.target.value)}
              />
              <label htmlFor="sourceUrl">URL</label>
            </div>
          )}
        </div>
        <div className="col-auto">
          <IconButton
            onClick={handleSave}
            disabled={loading}
            icon={Save}
            className="btn btn-success"
          >
            Upload
          </IconButton>
        </div>
      </div>
      <ColumnRow
        columns={[
          <div className="form-floating">
            <input
              type="text"
              placeholder="Copyright"
              id="copyright"
              name="copyright"
              value={copyright}
              className="form-control"
              onChange={(event) => setCopyright(event.target.value)}
            />
            <label htmlFor="copyright">Copyright</label>
          </div>,
          <div className="form-floating">
            <input
              type="text"
              placeholder="Attribution"
              id="attribution"
              name="attribution"
              value={attribution}
              className="form-control"
              onChange={(event) => setAttribution(event.target.value)}
            />
            <label htmlFor="attribution">Attribution</label>
          </div>,
          <div className="form-floating">
            <input
              type="text"
              placeholder="Source"
              id="source"
              name="source"
              value={source}
              className="form-control"
              onChange={(event) => setSource(event.target.value)}
            />
            <label htmlFor="source">Source</label>
          </div>,
        ]}
      />
    </RequestContainer>
  );
}

function SelectCollectionWorkImageModal(props) {
  const getWorkRequest = useAutoRequest(getWork, normalizeWorkId(props.workId));
  const editImageUrlRequest = useRequest(editImageUrl);

  const work = getWorkRequest.response.data || {};

  const handleSelect = (imageUrl) => {
    editImageUrlRequest.execute(
      {
        id: props.collectionId,
        work_id: props.workId,
        image_url: imageUrl,
      },
      () => {
        props.onSelect();
        props.onClose();
      }
    );
  };

  const loading = getWorkRequest.loading || editImageUrlRequest.loading;
  const error = getWorkRequest.error || editImageUrlRequest.error;

  return (
    <Modal
      title="Select image"
      size="lg"
      body={
        <RequestContainer
          loading={loading}
          error={error}
          minHeight="3.8125rem"
          spinnerSize="2.5rem"
        >
          {work.images && work.images.length > 0 && (
            <>
              <table className="table table-hover align-middle mb-3">
                <tbody>
                  {work.images &&
                    work.images.map((image) => (
                      <tr key={image.id}>
                        <td className="text-center" style={{ width: "7rem" }}>
                          <Image
                            src={image.url}
                            alt=""
                            style={{
                              maxWidth: "6rem",
                              maxHeight: "6rem",
                            }}
                          />
                        </td>
                        <td>
                          <ImageDimensions src={image.url} />
                        </td>
                        <td className="text-end">
                          {image.url === props.currentImageUrl ? (
                            <IconButton
                              icon={CheckCircle}
                              className="btn-sm btn-outline-success disabled"
                            >
                              Selected
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => handleSelect(image.url)}
                              icon={PlusCircle}
                              className="btn-sm btn-outline-secondary"
                            >
                              Select
                            </IconButton>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <p className="text-muted text-center">Or upload a new image</p>
            </>
          )}

          <UploadImageForm onUpload={handleSelect} work={work} />
        </RequestContainer>
      }
      onClose={props.onClose}
    />
  );
}

export default SelectCollectionWorkImageModal;
