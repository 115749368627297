import "./ArrayField.css";
import IconButton from "./IconButton";
import { PlusCircle } from "react-feather";
import { useRef } from "react";

function ArrayField(props) {
  const value = [...(props.value || [])];
  const wrapperRef = useRef(null);

  const handleAdd = () => props.onChange([...value, ""]);
  const handleChange = (index, event) =>
    props.onChange([
      ...value.slice(0, index),
      event.target.value,
      ...value.slice(index + 1),
    ]);
  const handleKeyUp = (index, event) => {
    if (
      value[index].length === 0 &&
      (event.key === "Backspace" || event.key === "Delete")
    ) {
      const newValues = [...value.slice(0, index), ...value.slice(index + 1)];
      if (wrapperRef.current && newValues.length) {
        const inputs = wrapperRef.current.querySelectorAll("input");
        inputs[Math.max(0, index - 1)].focus();
      }
      props.onChange(newValues);
    }
  };

  return (
    <div className="array-field mb-3" ref={wrapperRef}>
      <div className="array-field-label">{props.label}</div>
      {value.length > 0 &&
        value.map((item, index) => (
          <input
            className="form-control mb-2"
            type={props.type || "text"}
            key={index}
            value={item}
            onChange={(event) => handleChange(index, event)}
            onKeyUp={(event) => handleKeyUp(index, event)}
          />
        ))}
      <IconButton
        to="/sales/new"
        icon={PlusCircle}
        className="array-field-add btn btn-outline-secondary btn-sm w-100"
        type="button"
        onClick={handleAdd}
      >
        Add
      </IconButton>
    </div>
  );
}

export default ArrayField;
