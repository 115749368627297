import CreateJobModal from "../../modals/CreateJobModal";
import IconButton from "../../components/IconButton";
import IconLinkButton from "../../components/IconLinkButton";
import JobsTable from "../../tables/JobsTable";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import { PlusCircle, Activity } from "react-feather";
import { listJobs } from "../../clients/import";
import { useModal } from "../../hooks/useModal";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useRefresh } from "../../hooks/useRefresh";

function Jobs() {
  const [params, setParams] = usePersistentState(
    {
      skip: 0,
      limit: 100,
    },
    "Jobs:params"
  );

  const [refreshToken, refresh] = useRefresh();

  let [showCreateJobModal, createJobModal] = useModal(CreateJobModal, {
    onSave: refresh,
  });

  return (
    <>
      <ViewHeader
        breadcrumbs={["Imports"]}
        actions={
          <>
            <IconLinkButton
              to="/jobs/auctions"
              icon={Activity}
              className="btn btn-secondary me-2"
            >
              Monitored auctions
            </IconLinkButton>
            <IconButton
              onClick={showCreateJobModal}
              icon={PlusCircle}
              className="btn btn-success"
            >
              Create job
            </IconButton>
          </>
        }
      />
      <ViewContents>
        <JobsTable
          key={refreshToken}
          params={params}
          onParamsChange={setParams}
          request={listJobs}
          paginated={true}
        />
      </ViewContents>

      {createJobModal}
    </>
  );
}

export default Jobs;
