import axios from "axios";
import { merge, isArray, isObject, omit, get, chunk } from "lodash";

const request = async (root, token, endpoint, options = {}) =>
  await axios(
    `${root}/${endpoint}`,
    merge(
      {
        headers: { Authorization: `Bearer ${token}` },
      },
      options
    )
  );

const scroll = async (root, token, endpoint, options = {}) => {
  const data = [];
  const limit = get(options, "params.limit") || 100;
  let skip = 0;
  while (true) {
    try {
      const response = await request(
        root,
        token,
        endpoint,
        merge(options, { params: { skip, limit } })
      );
      data.push(...response.data);
      if (response.data.length < limit) {
        break;
      }
      skip += limit;
    } catch (error) {
      if (get(error, "response.status") === 400) {
        break;
      } else {
        throw error;
      }
    }
  }
  return { data: data.length > 0 ? data : null };
};

const defaultParam = (params, key) => (isObject(params) ? params[key] : params);

const otherParams = (params, key) =>
  isObject(params) ? omit(params, key) : {};

const virtualBulk = async (token, ids, handler) => {
  if (!isArray(ids) || ids.length === 0) {
    return { data: null };
  }

  const handlerWrapper = async (p) => {
    const data = (await handler(token, p)).data;
    data._params = p;
    return data;
  };

  let data = [];
  for (const c of chunk(ids, 4)) {
    const promises = c.map((id) => handlerWrapper(id));
    const responses = await Promise.allSettled(promises);

    data = [
      ...data,
      ...responses
        .filter((response) => response.status === "fulfilled")
        .map((response) => response.value),
    ];
  }
  return { data: data.length > 0 ? data : null };
};

const bulk = async (root, token, endpoint, type, ids) => {
  let data = null;
  if (!isArray(ids) || ids.length === 0) {
    return { data };
  }
  const response = await request(root, token, endpoint, {
    method: "post",
    data: ids,
  });
  if (type) {
    if (response.data[type].length > 0) {
      data = response.data[type];
    }
  } else {
    data = response.data;
  }
  return { data };
};

export { request, scroll, defaultParam, otherParams, virtualBulk, bulk };
