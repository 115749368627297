import Authorizer from "../components/Authorizer";
import ClipboardCopier from "./ClipboardCopier";
import IconButton from "./IconButton";
import IconLinkButton from "./IconLinkButton";
import { Edit, XCircle } from "react-feather";
import { Link } from "react-router-dom";
import { formatNumber } from "../lib/number";
import { isObject, isNumber } from "lodash";
import { useModal } from "../hooks/useModal";

function ObjectTableRow(props) {
  let [showDeleteModal, deleteModal] = useModal(
    props.deleteModal,
    props.deleteModalParams
  );

  const isLink = (element) => {
    return (
      isObject(element) &&
      ((element.type && element.type.isLink) || element.type === Link)
    );
  };

  const renderValue = (value) =>
    isNumber(value) ? formatNumber(value) : value;

  const actionButtons = (
    <div className="btn-group">
      {props.editPath ? (
        <IconLinkButton
          to={props.editPath}
          icon={Edit}
          className="btn-sm btn-outline-secondary"
        >
          Edit
        </IconLinkButton>
      ) : null}
      {props.deleteModal ? (
        props.deleteAuthorizerRole ? (
          <Authorizer role={props.deleteAuthorizerRole}>
            <IconButton
              onClick={showDeleteModal}
              icon={XCircle}
              className="btn-sm btn-outline-secondary"
            >
              Delete
            </IconButton>
          </Authorizer>
        ) : (
          <IconButton
            onClick={showDeleteModal}
            icon={XCircle}
            className="btn-sm btn-outline-secondary"
          >
            Delete
          </IconButton>
        )
      ) : null}
    </div>
  );

  const getColspan = (index) => {
    if (props.colspan) {
      return props.colspan[index];
    }
    return 1;
  };

  return (
    <>
      <tr {...props.rowProps}>
        {props.columns.map((column, index) =>
          isLink(column) ? (
            <td
              className="small"
              key={index}
              style={{ cursor: "pointer" }}
              colSpan={getColspan(index)}
            >
              {renderValue(column)}
            </td>
          ) : props.viewPath ? (
            <td
              className="small"
              key={index}
              style={{ padding: "0" }}
              colSpan={getColspan(index)}
            >
              <Link
                to={props.viewPath}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "0.5rem",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                {renderValue(column)}
              </Link>
            </td>
          ) : (
            <td className="small" key={index} colSpan={getColspan(index)}>
              {renderValue(column)}
            </td>
          )
        )}

        <td className="text-end text-nowrap">
          {props.clipboardValue ? (
            <ClipboardCopier value={props.clipboardValue} />
          ) : null}
          {props.actionAuthorizerRole ? (
            <Authorizer role={props.actionAuthorizerRole}>
              {props.extraActions && props.extraActions()}
              {actionButtons}
            </Authorizer>
          ) : (
            <>
              {props.extraActions && props.extraActions()}
              {actionButtons}
            </>
          )}
        </td>
      </tr>

      {deleteModal}
    </>
  );
}

export default ObjectTableRow;
