import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import toast from "../lib/toast";
import { Save } from "react-feather";
import { createVideo } from "../clients/art";
import { upload, storeURL } from "../lib/filestack";
import { useRef, useState } from "react";
import { useRequest } from "../hooks/useRequest";

const ACCEPTED_TYPES = [
  "application/octet-stream",
  "application/ogg",
  "audio/aac",
  "audio/amr",
  "audio/amr-wb",
  "audio/amr-wb+",
  "audio/m4a",
  "audio/mp3",
  "audio/mp4",
  "audio/mpeg",
  "audio/mpeg",
  "audio/mpeg",
  "audio/mpeg3",
  "audio/ogg",
  "audio/pcma",
  "audio/pcma",
  "audio/pcma-wb",
  "audio/pcmu",
  "audio/pcmu-wb",
  "audio/vorbis",
  "audio/wav",
  "audio/wave",
  "audio/webm",
  "audio/x-m4a",
  "audio/x-matroska",
  "audio/x-mpeg-3",
  "audio/x-ms-wma",
  "audio/x-wav",
  "video/3gp",
  "video/avi",
  "video/h264",
  "video/mov",
  "video/mp2t",
  "video/mp4",
  "video/mpeg",
  "video/mts",
  "video/ogg",
  "video/quicktime",
  "video/vnd.dlna.mpeg-tts",
  "video/webm",
  "video/x-flv",
  "video/x-m4v",
  "video/x-matroska",
  "video/x-ms-wma",
  "video/x-ms-wmv",
  "video/x-msvideo",
];

function UploadVideoModal(props) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMode, setUploadMode] = useState("file");
  const [sourceUrl, setSourceUrl] = useState("");
  const [copyright, setCopyright] = useState("");
  const [attribution, setAttribution] = useState("");
  const [source, setSource] = useState("");
  const fileField = useRef(null);

  const createVideoRequest = useRequest(createVideo);

  const loading = createVideoRequest.loading || isUploading;
  const error = createVideoRequest.error;

  const handleSave = async () => {
    let url = null;
    let handle = null;

    if (uploadMode === "file") {
      if (fileField.current.files.length === 0) {
        return;
      }

      if (!ACCEPTED_TYPES.includes(fileField.current.files[0].type)) {
        alert("Invalid file type selected");
        return;
      }

      setIsUploading(true);
      const response = await upload(fileField.current.files[0]);
      url = response.url;
      handle = response.handle;
      setIsUploading(false);
    } else {
      if (!sourceUrl) {
        return;
      }

      setIsUploading(true);
      const response = await storeURL(sourceUrl);
      url = response.url;
      handle = response.handle;
      setIsUploading(false);
    }

    if (props.skipCreateVideo) {
      props.onSave(url, handle);
      props.onClose();
    } else {
      createVideoRequest.execute(
        {
          url: url,
          name: url,
          copyright: copyright,
          attribution: attribution,
          source: source,
          service: "filestack",
          handle: handle,
          ...props.params,
        },
        (createVideoResponse) => {
          toast("Video created");
          props.onSave();
          props.onClose();
        }
      );
    }
  };

  return (
    <Modal
      title="Upload video"
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          <div className="btn-group btn-group-sm w-100 mb-3">
            <input
              type="radio"
              className="btn-check"
              id="btnradio1"
              autoComplete="off"
              onChange={(event) =>
                setUploadMode(event.target.checked ? "file" : "url")
              }
              checked={uploadMode === "file"}
            />
            <label className="btn btn-outline-secondary" htmlFor="btnradio1">
              Upload from file
            </label>

            <input
              type="radio"
              className="btn-check"
              id="btnradio2"
              autoComplete="off"
              onChange={(event) =>
                setUploadMode(event.target.checked ? "url" : "file")
              }
              checked={uploadMode === "url"}
            />
            <label className="btn btn-outline-secondary" htmlFor="btnradio2">
              Upload from URL
            </label>
          </div>
          {uploadMode === "file" ? (
            <input
              type="file"
              id="file"
              accept={ACCEPTED_TYPES.join(",")}
              className="form-control mb-3"
              ref={fileField}
            />
          ) : (
            <div className="form-floating mb-3">
              <input
                type="text"
                placeholder="URL"
                id="sourceUrl"
                name="sourceUrl"
                value={sourceUrl}
                className="form-control mb-3"
                onChange={(event) => setSourceUrl(event.target.value)}
              />
              <label htmlFor="sourceUrl">URL</label>
            </div>
          )}
          {!props.skipCreateVideo && (
            <>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  placeholder="Copyright"
                  id="copyright"
                  name="copyright"
                  value={copyright}
                  className="form-control"
                  onChange={(event) => setCopyright(event.target.value)}
                />
                <label htmlFor="copyright">Copyright</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  placeholder="Attribution"
                  id="attribution"
                  name="attribution"
                  value={attribution}
                  className="form-control"
                  onChange={(event) => setAttribution(event.target.value)}
                />
                <label htmlFor="attribution">Attribution</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  placeholder="Source"
                  id="source"
                  name="source"
                  value={source}
                  className="form-control"
                  onChange={(event) => setSource(event.target.value)}
                />
                <label htmlFor="source">Source</label>
              </div>
            </>
          )}
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            onClick={handleSave}
            disabled={loading}
            icon={Save}
            className="btn btn-success"
          >
            Save
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default UploadVideoModal;
