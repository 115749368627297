import CollectionForm from "../../forms/CollectionForm";
import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { getCollection, updateCollection } from "../../clients/collection";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function EditCollection() {
  let { collection_id: id } = useParams();
  const updateCollectionRequest = useRequest(updateCollection);
  const getCollectionRequest = useAutoRequest(getCollection, id);
  let history = useHistory();

  const collection = getCollectionRequest.response.data || {};
  const loading =
    getCollectionRequest.loading || updateCollectionRequest.loading;
  const error = getCollectionRequest.error || updateCollectionRequest.error;

  return (
    <CollectionForm
      initialValues={getCollectionRequest.response.data}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateCollectionRequest.execute({ id, data: values }, () => {
          history.goBack();
          toast(`The collection was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[`${collection.name || ""}`]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </CollectionForm>
  );
}

export default EditCollection;
