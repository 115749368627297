import DeleteCollectionModal from "../modals/DeleteCollectionModal";
import IconButton from "../components/IconButton";
import Image from "../components/Image";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import PublishCollectionModal from "../modals/PublishCollectionModal";
import RequestContainer from "../components/RequestContainer";
import UserName from "../components/UserName";
import ZonedDateTime from "../components/ZonedDateTime";
import { CheckSquare, Globe, User, Send } from "react-feather";
import { isString, sum, values } from "lodash";
import { useAutoRequest } from "../hooks/useRequest";
import { useModal } from "../hooks/useModal";
import { useState } from "react";

const publishStatusToString = (status) => {
  switch (status) {
    case 0:
      return "Not ready";
    case 1:
      return "In edit";
    case 2:
      return "Image review";
    case 3:
      return "Info review";
    case 4:
      return "Ready";
    case 5:
      return "Queued";
    case 6:
      return "Current";
    case 7:
      return "Published";
    default:
      return "Unknown";
  }
};

function wordCount(text) {
  if (!isString(text)) {
    return 0;
  }
  return text.split(/\s+/).length;
}

function totalWordCount(collection) {
  let count = wordCount(collection.desc);
  if (!collection.works_data) {
    return count;
  }
  count += sum(
    values(collection.works_data).map((work) => wordCount(work.comment))
  );
  return count;
}

function CollectionsTable(props) {
  const [params, setParams] = useState({
    order: "priority",
    for_sale: false,
    ...props.params,
  });
  const request = useAutoRequest(props.request, params);
  let [showPublishCollectionModal, publishCollectionModal] = useModal(
    PublishCollectionModal,
    {
      onPublish: () => request.execute(),
    }
  );

  const collections = request.response.data || [];

  const handleParamsChange = (newParams) => {
    setParams(newParams);

    if (props.onParamsChange) {
      props.onParamsChange(newParams);
    }
  };

  return (
    <>
      <RequestContainer loading={request.loading} error={request.error}>
        <ObjectTable
          columns={[
            "Access",
            "",
            {
              title: "Name",
              sortable: true,
              filterable: false,
              currentSortBy: params.order === "name",
              currentSortOrder: "desc",
              onSort: (order) =>
                handleParamsChange({
                  ...params,
                  order: "name",
                }),
            },
            {
              title: "Status",
              filterable: true,
              sortable: true,
              currentSortBy: params.order[0] === "publish_status",
              currentSortOrder: params.order[1],
              currentFilter: params.publish_status,
              filterOptions: [
                ["Not ready", 0],
                ["In edit", 1],
                ["Image review", 2],
                ["Info review", 3],
                ["Ready", 4],
                ["Queued", 5],
                ["Current", 6],
                ["Published", 7],
              ],
              onFilter: (value) =>
                handleParamsChange({ ...params, publish_status: value }),
              onSort: (order) =>
                handleParamsChange({
                  ...params,
                  order: ["publish_status", order],
                }),
            },
            {
              title: "Priority",
              sortable: true,
              filterable: false,
              currentSortBy: params.order === "priority",
              currentSortOrder: "desc",
              onSort: (order) =>
                handleParamsChange({
                  ...params,
                  order: "priority",
                }),
            },
            "Byline",
            {
              title: "Works",
              sortable: true,
              filterable: false,
              currentSortBy: params.order === "works_len",
              currentSortOrder: "desc",
              onSort: (order) =>
                handleParamsChange({
                  ...params,
                  order: "works_len",
                }),
            },
            "Word count",
            {
              title: "Created at",
              sortable: true,
              filterable: false,
              currentSortBy: params.order === "created_at",
              currentSortOrder: "desc",
              onSort: (order) =>
                handleParamsChange({
                  ...params,
                  order: "created_at",
                }),
            },
            {
              title: "Published at",
              sortable: true,
              filterable: false,
              currentSortBy: params.order === "publish_time",
              currentSortOrder: "desc",
              onSort: (order) =>
                handleParamsChange({
                  ...params,
                  order: "publish_time",
                }),
            },
            "Owner",
            {
              title: "Subtype",
              sortable: true,
              filterable: false,
              currentSortBy: params.order === "subtype",
              currentSortOrder: "desc",
              onSort: (order) =>
                handleParamsChange({
                  ...params,
                  order: "subtype",
                }),
            },
            {
              title: "For sale",
              sortable: false,
              filterable: true,
              currentFilter: params.for_sale,
              filterBoolean: "Only collections for sale",
              onFilter: (forSale) =>
                handleParamsChange({ ...params, for_sale: forSale }),
            },
          ]}
        >
          {collections.map((collection) => (
            <ObjectTableRow
              viewPath={`/collections/${collection.id}`}
              deleteModal={DeleteCollectionModal}
              deleteModalParams={{
                id: collection.id,
                name: collection.name,
                onDelete: () => request.execute(),
              }}
              clipboardValue={collection.id}
              key={collection.id}
              columns={[
                <span className="text-primary" title={collection.access}>
                  {collection.access === "public" ? (
                    <Globe size={20} />
                  ) : collection.access === "private" ? (
                    <User size={20} />
                  ) : (
                    collection.access
                  )}
                </span>,
                collection.image_url ? (
                  <div className="text-center" style={{ width: "7rem" }}>
                    <Image
                      src={collection.image_url}
                      alt=""
                      style={{
                        maxWidth: "6rem",
                        maxHeight: "6rem",
                      }}
                    />
                  </div>
                ) : null,
                collection.name,
                publishStatusToString(collection.publish_status),
                collection.priority,
                collection.byline,
                collection.works_len,
                totalWordCount(collection),
                <ZonedDateTime date={collection.created_at} />,
                <ZonedDateTime date={collection.publish_time} />,
                <UserName id={collection.owner} />,
                collection.subtype,
                collection.for_sale ? (
                  <span className="text-success">
                    <CheckSquare size={20} />
                  </span>
                ) : (
                  ""
                ),
              ]}
              extraActions={() => (
                <>
                  <IconButton
                    onClick={() =>
                      showPublishCollectionModal({ id: collection.id })
                    }
                    icon={Send}
                    disabled={collection.publish_status !== 5}
                    className="btn-sm btn-outline-primary me-2"
                  >
                    Publish
                  </IconButton>
                </>
              )}
            />
          ))}
        </ObjectTable>
      </RequestContainer>
      {publishCollectionModal}
    </>
  );
}

export default CollectionsTable;
