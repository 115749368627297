import * as yup from "yup";
import ValidatedField from "../components/ValidatedField";
import { Formik, Form } from "formik";
import { prepareFormValues, returnFormValues } from "../lib/form";

function FeeForm(props) {
  return (
    <Formik
      initialValues={prepareFormValues({
        name: "",
        amount: "",
        ...props.initialValues,
      })}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(returnFormValues(values), ...args)
      }
      validationSchema={yup.object({
        name: yup.string().required(),
        amount: yup.number().required().positive(),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <h5>Basic Information</h5>
              <ValidatedField
                formik={formik}
                label="Name"
                name="name"
                type="text"
              />
              <ValidatedField
                formik={formik}
                label="Amount"
                name="amount"
                type="text"
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default FeeForm;
