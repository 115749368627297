import { useState, createElement } from "react";

function useModal(modal, params = {}) {
  const [visible, setVisible] = useState(false);
  const [extraParams, setExtraParams] = useState({});

  return [
    (newExtraParams = {}) => {
      setExtraParams(newExtraParams);
      setVisible(true);
    },
    visible
      ? createElement(
          modal,
          { onClose: () => setVisible(false), ...params, ...extraParams },
          null
        )
      : null,
  ];
}

export { useModal };
