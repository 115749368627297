import { Wind } from "react-feather";

function Placeholder(props) {
  return props.empty ? (
    <div
      className="text-muted d-flex flex-column align-items-center justify-content-center rounded-3 bg-light bg-gradient"
      style={{ minHeight: "11rem" }}
    >
      <p className="mb-3">
        <Wind size={92} />
      </p>
      <p className="mb-0">No data found&hellip;</p>
    </div>
  ) : (
    props.children
  );
}

export default Placeholder;
