import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import SaleForm from "../../forms/SaleForm";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { createEvent } from "../../clients/art";
import { createSale } from "../../clients/finance";
import { formatDate } from "../../lib/date";
import { isNil } from "lodash";
import { useHistory } from "react-router-dom";
import { useQueryString } from "../../hooks/useQueryString";
import { useRequest } from "../../hooks/useRequest";
import { useState } from "react";

function NewSale() {
  const artistId = useQueryString("artistId");
  const eventId = useQueryString("eventId");
  const venueId = useQueryString("venueId");
  const [shouldCreateEvent, setShouldCreateEvent] = useState(isNil(eventId));
  const createSaleRequest = useRequest(createSale);
  const createEventRequest = useRequest(createEvent);
  let history = useHistory();

  const loading = createSaleRequest.loading || createEventRequest.loading;
  const error = createSaleRequest.error || createEventRequest.error;

  return (
    <SaleForm
      onSubmit={(values, actions) => {
        const createOneMore =
          window.event.submitter.classList.contains("create-one-more");

        if (shouldCreateEvent) {
          createSaleRequest.execute(
            { ...values, artistId, venueId },
            (saleResponse) => {
              createEventRequest.execute(
                {
                  name: values.title,
                  startDate: values.startDate,
                  endDate: values.endDate,
                  dates: formatDate(
                    values.startDate || null,
                    values.endDate || null
                  ),
                  url: values.url,
                  eventLive: values.saleType,
                  saleId: saleResponse.data.id,
                },
                (eventResponse) => {
                  if (createOneMore) {
                    actions.resetForm();
                  } else {
                    history.push(`/sales/${saleResponse.data.id}`);
                  }
                  toast("The sale was created");
                }
              );
            }
          );
        } else {
          createSaleRequest.execute(
            { ...values, artistId, eventId, venueId },
            (response) => {
              if (createOneMore) {
                actions.resetForm();
              } else {
                history.push(`/sales/${response.data.id}`);
              }
              toast("The sale was created");
            }
          );
        }
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={["New sale"]}
            actions={
              <>
                <IconButton
                  type="submit"
                  disabled={loading}
                  icon={Save}
                  className="btn btn-secondary me-2 create-one-more"
                >
                  Save and create one more
                </IconButton>
                <IconButton
                  type="submit"
                  disabled={loading}
                  icon={Save}
                  className="btn btn-success"
                >
                  Save
                </IconButton>
              </>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}

              {isNil(eventId) && (
                <div className="form-check mb-3">
                  <input
                    id="create-event-checkbox"
                    type="checkbox"
                    checked={shouldCreateEvent}
                    onChange={(event) =>
                      setShouldCreateEvent(event.target.checked)
                    }
                    className="form-check-input"
                  />
                  <label
                    htmlFor="create-event-checkbox"
                    className="form-check-label"
                  >
                    Automatically create an event for this sale?
                  </label>
                </div>
              )}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </SaleForm>
  );
}

export default NewSale;
