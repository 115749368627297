import "firebase/auth";
import Authorizer from "./Authorizer";
import IconButton from "./IconButton";
import Search from "./Search";
import firebase from "firebase/app";
import { Link, NavLink } from "react-router-dom";
import { LogOut } from "react-feather";

function Nav() {
  return (
    <nav className="navbar navbar-expand navbar-dark bg-dark">
      <div className="container-fluid py-2 px-4">
        <Link className="navbar-brand" to="/">
          <strong>Arthur Admin</strong>
        </Link>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/artists"
                activeClassName="active"
              >
                Artists
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/collections"
                activeClassName="active"
              >
                Collections
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/crates"
                activeClassName="active"
              >
                Crates
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/events"
                activeClassName="active"
              >
                Events
              </NavLink>
            </li>
            <Authorizer role={["admin", "editor"]}>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/jobs"
                  activeClassName="active"
                >
                  Imports
                </NavLink>
              </li>
            </Authorizer>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/programs"
                activeClassName="active"
              >
                Programs
              </NavLink>
            </li>
            <Authorizer role={["admin", "editor"]}>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/qa_tasks"
                  activeClassName="active"
                >
                  QA Tasks
                </NavLink>
              </li>
            </Authorizer>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/sales"
                activeClassName="active"
              >
                Sales
              </NavLink>
            </li>
            <Authorizer role="admin">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/users"
                  activeClassName="active"
                >
                  Users
                </NavLink>
              </li>
            </Authorizer>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/venues"
                activeClassName="active"
              >
                Venues
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/works"
                activeClassName="active"
              >
                Works
              </NavLink>
            </li>
          </ul>
          <Search />
          <IconButton
            onClick={() => firebase.auth().signOut()}
            icon={LogOut}
            className="btn btn-sm btn-outline-light ms-2"
          >
            Log out
          </IconButton>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
