import AssignUserModal from "../../modals/AssignUserModal";
import Authorizer from "../../components/Authorizer";
import IconButton from "../../components/IconButton";
import ObjectPropertyList from "../../components/ObjectPropertyList";
import ObjectTable from "../../components/ObjectTable";
import ObjectTableRow from "../../components/ObjectTableRow";
import Placeholder from "../../components/Placeholder";
import RequestContainer from "../../components/RequestContainer";
import ResolveTaskModal from "../../modals/ResolveTaskModal";
import TabCard from "../../components/TabCard";
import TaskInfoModal from "../../modals/TaskInfoModal";
import TruncatedText from "../../components/TruncatedText";
import UserName from "../../components/UserName";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import ZonedDateTime from "../../components/ZonedDateTime";
import { Link } from "react-router-dom";
import { UserPlus, CheckCircle, Info } from "react-feather";
import {
  getGroup,
  assignGroup,
  assignTask,
  resolveGroup,
  resolveTask,
} from "../../clients/quality";
import { getSale } from "../../clients/finance";
import { getUid } from "../../lib/firebase";
import { getWork } from "../../clients/art";
import { useAutoRequest, useRequest } from "../../hooks/useRequest";
import { useModal } from "../../hooks/useModal";
import { useOnScreen } from "../../hooks/useOnScreen";
import { useParams } from "react-router-dom";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useState, useRef } from "react";

function WorkLink(props) {
  const [work, setWork] = useState(null);
  const ref = useRef(null);

  const request = useRequest(getWork, props.id);

  useOnScreen(ref, () =>
    request.execute(null, (response) => setWork(response.data))
  );

  return (
    <Link to={`/works/${props.id}`} ref={ref}>
      {work ? <TruncatedText max={40}>{work.title}</TruncatedText> : null}
    </Link>
  );
}

WorkLink.isLink = true;

function TasksTable(props) {
  let [showAssignUserModal, assignUserModal] = useModal(AssignUserModal, {
    request: assignTask,
    onAssign: props.onAssign,
  });
  let [showResolveTaskModal, resolveTaskModal] = useModal(ResolveTaskModal, {
    request: resolveTask,
    onResolve: props.onResolve,
  });
  let [showTaskInfoModal, taskInfoModal] = useModal(TaskInfoModal);

  const formatStatus = (status) => {
    switch (status) {
      case 0:
        return <span className="badge bg-secondary">Unresolved</span>;
      case 1:
        return <span className="badge bg-secondary">Assigned</span>;
      case 2:
        return <span className="badge bg-secondary">Unfixable</span>;
      case 3:
        return <span className="badge bg-secondary">Fixed</span>;
      default:
        return <span className="badge bg-light text-dark">Unknown</span>;
    }
  };

  const formatSeverity = (severity) => {
    switch (severity) {
      case 0:
        return <span className="badge bg-light text-dark">Low</span>;
      case 1:
        return <span className="badge bg-warning text-dark">Medium</span>;
      case 2:
        return <span className="badge bg-danger">High</span>;
      case 3:
        return <span className="badge bg-dark">Critical</span>;
      default:
        return <span className="badge bg-light text-dark">Unknown</span>;
    }
  };

  const formatObject = (task) => {
    switch (task.object_type) {
      case "works":
        return <WorkLink id={task.object_id} />;
      default:
        return "";
    }
  };

  return (
    <>
      <Placeholder empty={!props.tasks}>
        <ObjectTable
          columns={[
            "Created at",
            "Object",
            "Reason",
            "Severity",
            "Status",
            "Assignee",
          ]}
        >
          {props.tasks &&
            props.tasks.map((task) => (
              <ObjectTableRow
                actionAuthorizerRole={["admin", "editor"]}
                key={task.id}
                columns={[
                  <ZonedDateTime date={task.created_at} />,
                  formatObject(task),
                  <>
                    <IconButton
                      icon={Info}
                      onClick={() => showTaskInfoModal({ task })}
                      className="btn-sm btn-outline-secondary me-2"
                    />
                    <span>{task.reason}</span>
                  </>,
                  formatSeverity(task.severity),
                  formatStatus(task.status),
                  task.assignee ? (
                    <UserName id={task.assignee} />
                  ) : (
                    <span className="text-muted">Unassigned</span>
                  ),
                ]}
                rowProps={{
                  style: task.status > 1 ? { opacity: 0.25 } : {},
                }}
                extraActions={() =>
                  task.status > 1 ? null : (
                    <>
                      {task.assignee === getUid() ? (
                        <IconButton
                          onClick={() => showResolveTaskModal({ id: task.id })}
                          icon={CheckCircle}
                          className="btn-sm btn-outline-secondary me-2"
                        >
                          Resolve
                        </IconButton>
                      ) : null}
                      <IconButton
                        onClick={() => showAssignUserModal({ id: task.id })}
                        icon={UserPlus}
                        className="btn-sm btn-outline-primary me-2"
                      >
                        Assign
                      </IconButton>
                    </>
                  )
                }
              />
            ))}
        </ObjectTable>
      </Placeholder>
      {assignUserModal}
      {resolveTaskModal}
      {taskInfoModal}
    </>
  );
}

function SaleLink(props) {
  const [sale, setSale] = useState(null);
  const ref = useRef(null);

  const request = useRequest(getSale, props.id);

  useOnScreen(ref, () =>
    request.execute(null, (response) => setSale(response.data))
  );

  return (
    <Link to={`/sales/${props.id}`} ref={ref}>
      {sale ? (
        sale.title ? (
          <>
            {sale.title} ({sale.sellerName})
          </>
        ) : (
          <>
            {sale.externalId} ({sale.sellerName})
          </>
        )
      ) : null}
    </Link>
  );
}

SaleLink.isLink = true;

function ViewQaTask() {
  const [activeTab, setActiveTab] = usePersistentState(
    null,
    "ViewQaTask:activeTab"
  );
  let { group_id: id } = useParams();
  const getGroupRequest = useAutoRequest(getGroup, id);
  let [showAssignUserModal, assignUserModal] = useModal(AssignUserModal, {
    request: assignGroup,
    onAssign: getGroupRequest.execute,
  });
  let [showResolveTaskModal, resolveTaskModal] = useModal(ResolveTaskModal, {
    request: resolveGroup,
    onResolve: getGroupRequest.execute,
  });

  const group = getGroupRequest.response.data || {};

  const formatObject = (group) => {
    switch (group.group_type) {
      case "sales":
        return <SaleLink id={group.group_id} />;
      default:
        return "";
    }
  };

  const hasResolvedTasks = (group.tasks || []).some((task) => task.status > 1);
  const hasUnresolvedTasks = (group.tasks || []).some(
    (task) => task.status < 2
  );

  return (
    <>
      <ViewHeader
        breadcrumbs={[
          <Link to="/qa_tasks">QA tasks groups</Link>,
          `${group.id || ""}`,
        ]}
      />
      <ViewContents>
        <RequestContainer
          loading={getGroupRequest.loading}
          error={getGroupRequest.error}
        >
          <ObjectPropertyList
            items={[
              ["ID", group.id],
              ["Group object", formatObject(group)],
            ]}
          />

          <TabCard
            activeTab={activeTab}
            onTabChange={setActiveTab}
            tabs={{
              Tasks: {
                title: group.tasks ? `Tasks (${group.tasks.length})` : null,
                actions: (
                  <Authorizer role={["admin", "editor"]}>
                    <IconButton
                      onClick={() => showResolveTaskModal({ id: group.id })}
                      icon={CheckCircle}
                      className="btn-sm btn-outline-secondary me-2"
                      disabled={!hasUnresolvedTasks}
                    >
                      Resolve all
                    </IconButton>
                    <IconButton
                      onClick={() => showAssignUserModal({ id: group.id })}
                      icon={UserPlus}
                      className="btn-sm btn-outline-primary"
                      disabled={hasResolvedTasks}
                    >
                      Assign all
                    </IconButton>
                  </Authorizer>
                ),
                body: (
                  <TasksTable
                    key={id}
                    tasks={group.tasks}
                    onAssign={getGroupRequest.execute}
                    onResolve={getGroupRequest.execute}
                  />
                ),
              },
            }}
          />
        </RequestContainer>
      </ViewContents>
      {assignUserModal}
      {resolveTaskModal}
    </>
  );
}

export default ViewQaTask;
