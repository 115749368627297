import firebase from "firebase/app";
import "firebase/auth";
import { get } from "lodash";

import config from "../config";

try {
  firebase.initializeApp(config.firebase);
} catch (err) {
  if (err.code !== "app/duplicate-app") {
    throw err;
  }
}

const persistAuthentication = async (responseUser, callback) => {
  let user = null;

  if (responseUser) {
    const role = get(await responseUser.getIdTokenResult(), ["claims", "role"]);

    if (["admin", "curator", "editor"].includes(role)) {
      user = responseUser;
    } else {
      alert("Authorization failed");
    }
  }

  window.user = user;

  if (callback) {
    callback(!!user);
  }
};

const getToken = async () => {
  if (window.user) {
    return await window.user.getIdToken();
  }

  return null;
};

const getRole = async () => {
  if (window.user) {
    return get(await window.user.getIdTokenResult(), ["claims", "role"]);
  }

  return null;
};

const getUid = () => {
  if (window.user) {
    return window.user.uid;
  }

  return null;
};

const authenticateUsingGoogle = (callback, callbackError) => {
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(googleAuthProvider)
        .then((result) => persistAuthentication(result.user, callback))
        .catch((error) => {
          alert(error.message);
          if (callbackError) {
            callbackError();
          }
        });
    })
    .catch((error) => {
      alert(error.message);
      if (callbackError) {
        callbackError();
      }
    });
};

const authenticateUsingEmail = (email, password, callback, callbackError) => {
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((result) => persistAuthentication(result.user, callback))
        .catch((error) => {
          alert(error.message);
          if (callbackError) {
            callbackError();
          }
        });
    })
    .catch((error) => {
      alert(error.message);
      if (callbackError) {
        callbackError();
      }
    });
};

const reauthenticate = (callback) =>
  firebase.auth().onAuthStateChanged((user) => {
    persistAuthentication(user, callback);
  });

export {
  getToken,
  getUid,
  getRole,
  authenticateUsingGoogle,
  authenticateUsingEmail,
  reauthenticate,
};
