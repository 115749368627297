import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import toast from "../lib/toast";
import { Send } from "react-feather";
import { publishCollection } from "../clients/collection";
import { useRequest } from "../hooks/useRequest";

function PublishCollectionModal(props) {
  const publishCollectionRequest = useRequest(publishCollection);

  const handlePublish = () => {
    publishCollectionRequest.execute(props.id, () => {
      toast("Collection published");
      props.onClose();
      props.onPublish();
    });
  };

  return (
    <Modal
      title="Publish collection"
      body={
        <RequestContainer
          loading={publishCollectionRequest.loading}
          error={publishCollectionRequest.error}
          fullscreen={true}
        >
          This will publish the collection immediately, unpublishing the
          currently published collection.
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            icon={Send}
            className="btn btn-success"
            onClick={handlePublish}
          >
            Publish
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default PublishCollectionModal;
