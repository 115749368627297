import IconButton from "../../components/IconButton";
import VideoForm from "../../forms/VideoForm";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Link } from "react-router-dom";
import { Save } from "react-feather";
import { getVideo, updateVideo, getWork } from "../../clients/art";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function EditVideo() {
  let { work_id: workId, video_id: id } = useParams();
  const getWorkRequest = useAutoRequest(getWork, workId);
  const updateVideoRequest = useRequest(updateVideo);
  const getVideoRequest = useAutoRequest(getVideo, id);
  let history = useHistory();

  const video = getVideoRequest.response.data || {};
  const work = getWorkRequest.response.data || {};
  const loading = getVideoRequest.loading || updateVideoRequest.loading;
  const error = getVideoRequest.error || updateVideoRequest.error;

  return (
    <VideoForm
      initialValues={getVideoRequest.response.data}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateVideoRequest.execute({ id, data: values }, () => {
          history.goBack();
          toast(`The video was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[
              <Link to={`/works/${workId}`}>{work.title || ""}</Link>,
              video.name || video.id,
            ]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </VideoForm>
  );
}

export default EditVideo;
