import ArtistsTable from "../../tables/ArtistsTable";
import Authorizer from "../../components/Authorizer";
import DeleteSaleModal from "../../modals/DeleteSaleModal";
import EventsTable from "../../tables/EventsTable";
import IconButton from "../../components/IconButton";
import IconLinkButton from "../../components/IconLinkButton";
import LinkArtistModal from "../../modals/LinkArtistModal";
import LinkEventModal from "../../modals/LinkEventModal";
import LinkVenueModal from "../../modals/LinkVenueModal";
import LogModal from "../../modals/LogModal";
import ObjectPropertyList from "../../components/ObjectPropertyList";
import OffersTable from "../../tables/OffersTable";
import RequestContainer from "../../components/RequestContainer";
import SaleCurrencyModal from "../../modals/SaleCurrencyModal";
import SaleRelinkWorksModal from "../../modals/SaleRelinkWorksModal";
import SaleSyncModal from "../../modals/SaleSyncModal";
import TabCard from "../../components/TabCard";
import VenuesTable from "../../tables/VenuesTable";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import {
  Edit,
  PlusCircle,
  Link,
  DollarSign,
  RefreshCw,
  XCircle,
  Clock,
} from "react-feather";
import { getArtists, getEvents, getVenues } from "../../clients/art";
import {
  getSale,
  listOffersBySale,
  linkSaleArtist,
  linkSaleEvent,
  linkSaleVenue,
  unlinkSaleArtist,
  unlinkSaleEvent,
  unlinkSaleVenue,
} from "../../clients/finance";
import { useAutoRequest } from "../../hooks/useRequest";
import { useHistory } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import { useParams } from "react-router-dom";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useRefresh } from "../../hooks/useRefresh";

function ViewSale() {
  const [activeTab, setActiveTab] = usePersistentState(
    null,
    "ViewSale:activeTab"
  );
  let { sale_id: id } = useParams();
  const [refreshToken, refresh] = useRefresh(id);
  const getSaleRequest = useAutoRequest(getSale, id);
  let history = useHistory();
  let [showLinkArtistModal, linkArtistModal] = useModal(LinkArtistModal, {
    linkRequest: linkSaleArtist,
    params: { saleId: id },
    onLink: () => getSaleRequest.execute(null, refresh),
  });
  let [showLinkEventModal, linkEventModal] = useModal(LinkEventModal, {
    linkRequest: linkSaleEvent,
    params: { saleId: id },
    onLink: () => getSaleRequest.execute(null, refresh),
  });
  let [showLinkVenueModal, linkVenueModal] = useModal(LinkVenueModal, {
    linkRequest: linkSaleVenue,
    params: { saleId: id },
    onLink: () => getSaleRequest.execute(null, refresh),
  });
  let [showSaleCurrencyModal, saleCurrencyModal] = useModal(SaleCurrencyModal, {
    id,
  });
  let [showSaleRelinkWorksModal, saleRelinkWorksModal] = useModal(
    SaleRelinkWorksModal,
    {
      id,
    }
  );
  let [showSaleSyncModal, saleSyncModal] = useModal(SaleSyncModal, {
    saleId: id,
    onSync: () => getSaleRequest.execute(null, refresh),
  });

  const sale = getSaleRequest.response.data || {};

  let [showLogModal, logModal] = useModal(LogModal, {
    filter: {
      type: "sale",
      id,
    },
  });

  let [showDeleteSaleModal, deleteSaleModal] = useModal(DeleteSaleModal, {
    id: sale.id,
    title: sale.title,
    onDelete: () => {
      history.push("/sales");
    },
  });

  return (
    <>
      <ViewHeader
        breadcrumbs={[`${sale.title || sale.externalId || ""}`]}
        actions={
          <>
            <Authorizer role={["admin", "editor"]}>
              <IconButton
                onClick={showSaleRelinkWorksModal}
                icon={Link}
                className="btn btn-secondary me-2"
              >
                Link all works/events
              </IconButton>
              <IconButton
                onClick={showSaleCurrencyModal}
                icon={DollarSign}
                className="btn btn-secondary me-2"
              >
                Change currency
              </IconButton>
              <IconButton
                onClick={showLogModal}
                icon={Clock}
                className="btn btn-secondary me-2"
              >
                Log
              </IconButton>
              <IconLinkButton
                to={`/sales/${id}/edit`}
                icon={Edit}
                className="btn btn-secondary me-2"
              >
                Edit
              </IconLinkButton>
            </Authorizer>
            <Authorizer role="admin">
              <IconButton
                icon={XCircle}
                onClick={showDeleteSaleModal}
                className="btn btn-secondary"
              >
                Delete
              </IconButton>
            </Authorizer>
          </>
        }
      />
      <ViewContents>
        <RequestContainer
          loading={getSaleRequest.loading}
          error={getSaleRequest.error}
        >
          <ObjectPropertyList
            items={[
              ["ID", sale.id],
              ["External ID", sale.externalId],
              ["Display ID", sale.externalDisplayId],
              ["Source", sale.source],
              ["Seller name", sale.sellerName],
              ["Title", sale.title],
              ["Dates", { dates: [sale.startDate, sale.endDate] }],
              ["Start time", { date: sale.startTime }],
              ["Location", sale.location],
              ["Type", sale.saleType],
              ["Link", { url: sale.url }],
              ["Prices available", { bool: sale.pricesAvailable }],
              ["Bids available", { bool: sale.bidsAvailable }],
            ]}
          />
        </RequestContainer>

        <TabCard
          activeTab={activeTab}
          onTabChange={setActiveTab}
          tabs={{
            Artists: {
              title: sale.artistIds
                ? `Artists (${sale.artistIds.length})`
                : null,
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showLinkArtistModal}
                    icon={Link}
                    className="btn btn-secondary me-2"
                  >
                    Link artist
                  </IconButton>
                  <IconLinkButton
                    to={`/artists/new?saleId=${id}`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create artist
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <ArtistsTable
                  key={refreshToken}
                  request={getArtists}
                  parentRequest={getSaleRequest}
                  parentAttribute="artistIds"
                  unlinkRequest={unlinkSaleArtist}
                  unlinkParams={{ saleId: id }}
                />
              ),
            },
            Events: {
              title: sale.eventIds ? `Events (${sale.eventIds.length})` : null,
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showLinkEventModal}
                    icon={Link}
                    className="btn btn-secondary me-2"
                  >
                    Link event
                  </IconButton>
                  <IconLinkButton
                    to={`/events/new?saleId=${id}`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create event
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <EventsTable
                  key={refreshToken}
                  request={getEvents}
                  parentRequest={getSaleRequest}
                  parentAttribute="eventIds"
                  unlinkRequest={unlinkSaleEvent}
                  unlinkParams={{ saleId: id }}
                  extraActions={(event) => (
                    <IconButton
                      onClick={() => showSaleSyncModal({ eventId: event.id })}
                      icon={RefreshCw}
                      className="btn-sm btn-outline-primary me-2"
                    >
                      Sync
                    </IconButton>
                  )}
                />
              ),
            },
            Venues: {
              title: sale.venueIds ? `Venues (${sale.venueIds.length})` : null,
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showLinkVenueModal}
                    icon={Link}
                    className="btn btn-secondary me-2"
                  >
                    Link venue
                  </IconButton>
                  <IconLinkButton
                    to={`/venues/new?saleId=${id}`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create venue
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <VenuesTable
                  key={refreshToken}
                  request={getVenues}
                  parentRequest={getSaleRequest}
                  parentAttribute="venueIds"
                  unlinkRequest={unlinkSaleVenue}
                  unlinkParams={{ saleId: id }}
                  extraActions={(venue) => (
                    <IconButton
                      onClick={() => showSaleSyncModal({ venueId: venue.id })}
                      icon={RefreshCw}
                      className="btn-sm btn-outline-primary me-2"
                    >
                      Sync
                    </IconButton>
                  )}
                />
              ),
            },
            Offers: {
              title:
                sale.offerCount !== undefined
                  ? `Offers (${sale.offerCount})`
                  : null,
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconLinkButton
                    to={`/sales/${id}/offers/new`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create offer
                  </IconLinkButton>
                </Authorizer>
              ),
              body: <OffersTable params={{ id }} request={listOffersBySale} />,
            },
          }}
        />
      </ViewContents>

      {deleteSaleModal}
      {linkArtistModal}
      {linkEventModal}
      {linkVenueModal}
      {saleCurrencyModal}
      {saleRelinkWorksModal}
      {saleSyncModal}
      {logModal}
    </>
  );
}

export default ViewSale;
