import Authorizer from "../../components/Authorizer";
import IconLinkButton from "../../components/IconLinkButton";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import WorksTable from "../../tables/WorksTable";
import { PlusCircle } from "react-feather";
import { listWorks } from "../../clients/art";
import { usePersistentState } from "../../hooks/usePersistentState";

function Works() {
  const [params, setParams] = usePersistentState(
    {
      skip: 0,
      limit: 100,
    },
    "Works:params"
  );

  return (
    <>
      <ViewHeader
        breadcrumbs={["Works"]}
        actions={
          <Authorizer role={["admin", "editor"]}>
            <IconLinkButton
              to="/works/new"
              icon={PlusCircle}
              className="btn btn-success"
            >
              Create work
            </IconLinkButton>
          </Authorizer>
        }
      />
      <ViewContents>
        <WorksTable
          params={params}
          onParamsChange={setParams}
          request={listWorks}
          paginated={true}
        />
      </ViewContents>
    </>
  );
}

export default Works;
