import IconButton from "../components/IconButton";
import Image from "../components/Image";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import RequestContainer from "../components/RequestContainer";
import UserName from "../components/UserName";
import ZonedDateTime from "../components/ZonedDateTime";
import toast from "../lib/toast";
import { CheckSquare, PlusCircle } from "react-feather";
import { addToQueue, getQueue } from "../clients/collection";
import { sortBy, get } from "lodash";
import { useAutoRequest, useRequest } from "../hooks/useRequest";
import { useState } from "react";

const publishStatusToString = (status) => {
  switch (status) {
    case 0:
      return "Not ready";
    case 1:
      return "Ready";
    case 2:
      return "Current";
    case 3:
      return "Published";
    default:
      return "Unknown";
  }
};

function CratesTable(props) {
  const [params, setParams] = useState({
    order: ["created_at", "desc"],
    startTime: [null, null],
    endTime: [null, null],
    ...props.params,
  });

  const request = useAutoRequest(props.request);
  const addToQueueRequest = useRequest(addToQueue);
  const getQueueRequest = useAutoRequest(getQueue);

  let crates = request.response.data || [];
  let queuedCrates = get(getQueueRequest, "response.data.objects") || [];

  crates = crates.filter((crate) => {
    if (
      params.publish_status &&
      crate.publish_status !== parseInt(params.publish_status)
    ) {
      return false;
    }

    return true;
  });

  crates = sortBy(crates, params.order[0]);

  if (params.order[1] === "desc") {
    crates = crates.reverse();
  }

  const handleParamsChange = (newParams) => {
    setParams(newParams);

    if (props.onParamsChange) {
      props.onParamsChange(newParams);
    }
  };

  const handleAddToQueue = (crateId) =>
    addToQueueRequest.execute(crateId, () => {
      toast("Crate added to queue");
      getQueueRequest.execute();
    });

  const isQueued = (crateId) => queuedCrates.includes(crateId);

  const loading =
    request.loading || getQueueRequest.loading || addToQueueRequest.loading;
  const error =
    request.error || getQueueRequest.error || addToQueueRequest.error;

  return (
    <RequestContainer loading={loading} error={error}>
      <ObjectTable
        columns={[
          "",
          {
            title: "Description",
            sortable: true,
            filterable: false,
            currentSortBy: params.order[0] === "flavor",
            currentSortOrder: params.order[1],
            onSort: (order) =>
              handleParamsChange({ ...params, order: ["flavor", order] }),
          },
          {
            title: "Status",
            filterable: true,
            sortable: true,
            currentSortBy: params.order[0] === "publish_status",
            currentSortOrder: params.order[1],
            currentFilter: params.publish_status,
            filterOptions: [
              ["Not ready", 0],
              ["Ready", 1],
              ["Current", 2],
              ["Published", 3],
            ],
            onFilter: (value) =>
              handleParamsChange({ ...params, publish_status: value }),
            onSort: (order) =>
              handleParamsChange({
                ...params,
                order: ["publish_status", order],
              }),
          },
          {
            title: "Published at",
            sortable: true,
            filterable: false,
            currentSortBy: params.order[0] === "publish_time",
            currentSortOrder: params.order[1],
            onSort: (order) =>
              handleParamsChange({ ...params, order: ["publish_time", order] }),
          },
          {
            title: "Latest",
            sortable: true,
            filterable: false,
            currentSortBy: params.order[0] === "latest",
            currentSortOrder: params.order[1],
            onSort: (order) =>
              handleParamsChange({ ...params, order: ["latest", order] }),
          },
          {
            title: "Created at",
            sortable: true,
            filterable: false,
            currentSortBy: params.order[0] === "created_at",
            currentSortOrder: params.order[1],
            onSort: (order) =>
              handleParamsChange({ ...params, order: ["created_at", order] }),
          },
          "Creator",
        ]}
      >
        {crates.map((crate) => (
          <ObjectTableRow
            viewPath={`/crates/${crate.id}`}
            editPath={`/crates/${crate.id}/edit`}
            clipboardValue={crate.id}
            key={crate.id}
            columns={[
              crate.thumbnail ? (
                <div className="text-center" style={{ width: "7rem" }}>
                  <Image
                    src={crate.thumbnail}
                    alt=""
                    style={{
                      maxWidth: "6rem",
                      maxHeight: "6rem",
                    }}
                  />
                </div>
              ) : null,
              crate.flavor,
              publishStatusToString(crate.publish_status),
              <ZonedDateTime date={crate.publish_time} />,
              crate.latest ? (
                <span className="text-success">
                  <CheckSquare size={20} />
                </span>
              ) : (
                ""
              ),
              <ZonedDateTime date={crate.created_at} />,
              <UserName id={crate.creator} />,
            ]}
            extraActions={() => (
              <IconButton
                onClick={() => handleAddToQueue(crate.id)}
                disabled={isQueued(crate.id)}
                icon={PlusCircle}
                className="btn-sm btn-outline-primary me-2"
              >
                Add to queue
              </IconButton>
            )}
          />
        ))}
      </ObjectTable>
    </RequestContainer>
  );
}

export default CratesTable;
