function ColumnRow(props) {
  return (
    <div className="row align-items-center">
      {props.columns.map((column, index) => (
        <div
          className={
            props.sizes && props.sizes.length > index
              ? `col-${props.sizes[index]}`
              : "col"
          }
          key={index}
        >
          {column}
        </div>
      ))}
    </div>
  );
}

export default ColumnRow;
