import "../components/LinkModal.css";
import IconButton from "../components/IconButton";
import Image from "../components/Image";
import ImageDimensions from "../components/ImageDimensions";
import LoadIndicator from "../components/LoadIndicator";
import Modal from "../components/Modal";
import PaginatedContainer from "../components/PaginatedContainer";
import Placeholder from "../components/Placeholder";
import RequestContainer from "../components/RequestContainer";
import algoliasearch from "algoliasearch";
import config from "../config";
import toast from "../lib/toast";
import { PlusCircle } from "react-feather";
import { get, trim, capitalize, lowerCase, keys, sortBy } from "lodash";
import {
  getWork,
  listArtistsByWork,
  listPopularLabels,
  listWorks,
} from "../clients/art";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { useRequest, useAutoRequest } from "../hooks/useRequest";
import { useState, useRef, useEffect } from "react";
import { useThrottle } from "../hooks/useThrottle";

const client = algoliasearch(
  config.algolia.applicationId,
  config.algolia.apiKey
);

const artistsIndex = client.initIndex("artists");
const worksIndex = client.initIndex("works");

const getPrimaryImage = (work) => {
  if (!work.images || work.images.length < 1) {
    return null;
  }
  return sortBy(work.images, "priority")[0].url;
};

function SearchWorks(props) {
  const [query, setQuery] = useState("");
  const addRequest = useRequest(props.addRequest);
  const listArtistsByWorkRequest = useRequest(listArtistsByWork);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const searchField = useRef(null);

  useEffect(() => searchField.current && searchField.current.focus(), []);

  const throttledSearch = useThrottle(
    async (value, callback) => {
      setIsSearching(true);
      worksIndex
        .search(value, { hitsPerPage: 40 })
        .then(({ hits }) => setSearchResults(hits))
        .finally(() => setIsSearching(false));
    },
    () => {},
    500
  );

  const handleAdd = (workId, imageUrl) =>
    listArtistsByWorkRequest.execute(
      { id: workId },
      (artists) => {
        let artistId = null;
        if (artists.data.length > 0) {
          artistId = artists.data[0].id;
        }
        addRequest.execute(
          {
            work_id: workId,
            artist_id: artistId,
            image_url: imageUrl || "",
            ...props.params,
          },
          () => {
            toast(props.onAddMessage);
            props.onAdd();
          }
        );
      },
      () => {
        addRequest.execute(
          {
            work_id: workId,
            artist_id: null,
            image_url: imageUrl || "",
            ...props.params,
          },
          () => {
            toast(props.onAddMessage);
            props.onAdd();
          }
        );
      }
    );

  const search = (value) => {
    if (trim(value)) {
      throttledSearch(trim(value));
    }
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    search(event.target.value);
  };

  return (
    <>
      <input
        className="form-control mb-3"
        type="search"
        placeholder="Search works&hellip;"
        value={query}
        onChange={handleQueryChange}
        ref={searchField}
      />

      {trim(query) && searchResults.length > 0 && (
        <RequestContainer
          loading={addRequest.loading || isSearching}
          error={addRequest.error}
          minHeight="3.8125rem"
          spinnerSize="2.5rem"
        >
          <Placeholder empty={searchResults.length === 0}>
            <table className="table table-hover align-middle">
              <tbody>
                {searchResults
                  .filter(
                    (work) =>
                      !(props.existingWorks || []).includes(work.objectID) &&
                      work.imageUrl
                  )
                  .map((work) => (
                    <tr key={work.objectID}>
                      <td className="text-center" style={{ width: "7rem" }}>
                        <Image
                          src={work.imageUrl}
                          alt=""
                          style={{
                            maxWidth: "6rem",
                            maxHeight: "6rem",
                          }}
                        />
                      </td>
                      <td>
                        <p className="m-0">{work.title}</p>
                        <span className="text-muted">
                          {work.artists ? (
                            <span>{work.artists.join(", ")}</span>
                          ) : null}
                          {work.artists && work.dateText ? ", " : null}
                          {work.dateText ? <span>{work.dateText}</span> : null}
                        </span>
                        <ImageDimensions src={work.imageUrl} />
                      </td>
                      <td className="text-end">
                        <IconButton
                          onClick={() =>
                            handleAdd(work.objectID, work.imageUrl)
                          }
                          icon={PlusCircle}
                          className="btn-sm btn-outline-secondary"
                        >
                          Add
                        </IconButton>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Placeholder>
        </RequestContainer>
      )}
    </>
  );
}

function SearchByArtist(props) {
  const [id, setId] = useState(null);
  const [query, setQuery] = useState("");
  const [worksQuery, setWorksQuery] = useState("");
  const listWorksRequest = useRequest(listWorks);
  const addRequest = useRequest(props.addRequest);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const searchField = useRef(null);

  useEffect(() => searchField.current && searchField.current.focus(), []);

  const throttledSearch = useThrottle(
    async (value, callback) => {
      setIsSearching(true);
      artistsIndex
        .search(value)
        .then(({ hits }) => setSearchResults(hits))
        .finally(() => setIsSearching(false));
    },
    () => {},
    500
  );

  const throttledListWorksRequestExecute = useThrottle(
    async (value, callback) => listWorksRequest.execute(value),
    () => {},
    500
  );

  const handleAdd = (workId, imageUrl) =>
    addRequest.execute(
      {
        work_id: workId,
        artist_id: id,
        image_url: imageUrl || "",
        ...props.params,
      },
      () => {
        toast(props.onAddMessage);
        props.onAdd();
      }
    );

  let results = searchResults.map((hit) => ({
    resultType: "artist",
    name: hit.name,
    flavor: hit.dates,
    img: hit.artistImage,
    id: hit.objectID,
  }));

  if (id) {
    results = results.filter((result) => result.id === id);
  }

  const listWorksResponseParams =
    get(listWorksRequest.response, "config.params") || {};
  const works = get(listWorksRequest.response, "data") || [];

  const search = (value) => {
    setId(null);

    if (trim(value)) {
      throttledSearch(trim(value));
    }
  };

  const handleArtistQueryChange = (event) => {
    setQuery(event.target.value);
    search(event.target.value);
  };

  const requestWorks = (params) =>
    throttledListWorksRequestExecute({
      sortby: "name",
      shallow: "true",
      filter: "artist",
      skip: 0,
      limit: 25,
      ...params,
    });

  const handleWorkQueryChange = (event) => {
    setWorksQuery(event.target.value);
    requestWorks({ filter_id: id, search: event.target.value });
  };

  const handleActivate = (result) => {
    setWorksQuery("");
    if (id === result.id) {
      setId(null);
    } else {
      setId(result.id);
      requestWorks({ filter_id: result.id });
    }
  };

  const handleFocus = () => search(query);
  return (
    <>
      <input
        className="form-control mb-3"
        type="search"
        placeholder="Search artists&hellip;"
        value={query}
        onChange={handleArtistQueryChange}
        onFocus={handleFocus}
        ref={searchField}
      />

      {trim(query) && results.length > 0 && (
        <LoadIndicator
          loading={isSearching}
          minHeight="3.8125rem"
          spinnerSize="2.5rem"
        >
          <Placeholder empty={results.length === 0}>
            {results.map((result, index) => (
              <div
                key={result.id}
                onClick={() => handleActivate(result)}
                className={`p-2 link-result ${index > 0 ? "border-top" : ""} ${
                  id === result.id ? "active" : ""
                }`}
              >
                {result.name && (
                  <p className="link-result-title m-0 text-truncate">
                    {result.name}
                  </p>
                )}
                {result.flavor && (
                  <p className="link-result-subtitle small text-truncate m-0">
                    {result.flavor}
                  </p>
                )}
              </div>
            ))}
          </Placeholder>
        </LoadIndicator>
      )}

      {id && (
        <>
          <input
            className="form-control mt-4"
            type="search"
            placeholder="Search works&hellip;"
            value={worksQuery}
            onChange={handleWorkQueryChange}
          />

          <div className="mt-3">
            <RequestContainer
              loading={addRequest.loading || listWorksRequest.loading}
              error={addRequest.error}
              minHeight="3.8125rem"
              spinnerSize="2.5rem"
            >
              <PaginatedContainer
                enabled={!listWorksRequest.loading}
                count={works.length}
                params={listWorksResponseParams}
                onNavigate={requestWorks}
              >
                <Placeholder empty={works.length === 0}>
                  <table className="table table-hover align-middle">
                    <tbody>
                      {works
                        .filter(
                          (work) =>
                            !(props.existingWorks || []).includes(work.id)
                        )
                        .map((work) => (
                          <tr key={work.id}>
                            <td
                              className="text-center"
                              style={{ width: "7rem" }}
                            >
                              {getPrimaryImage(work) ? (
                                <Image
                                  src={getPrimaryImage(work)}
                                  alt=""
                                  style={{
                                    maxWidth: "6rem",
                                    maxHeight: "6rem",
                                  }}
                                />
                              ) : null}
                            </td>
                            <td>
                              <p className="m-0">{work.title}</p>
                              <span className="text-muted">
                                {work.dateText}
                              </span>
                              {getPrimaryImage(work) ? (
                                <ImageDimensions src={getPrimaryImage(work)} />
                              ) : null}
                            </td>
                            <td className="text-end">
                              <IconButton
                                onClick={() =>
                                  handleAdd(work.id, getPrimaryImage(work))
                                }
                                icon={PlusCircle}
                                className="btn-sm btn-outline-secondary"
                              >
                                Add
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Placeholder>
              </PaginatedContainer>
            </RequestContainer>
          </div>
        </>
      )}
    </>
  );
}

function SearchLabels(props) {
  const [query, setQuery] = useState("");
  const [autoCompleteResults, setAutoCompleteResults] = useState([]);
  const listWorksRequest = useRequest(listWorks);
  const listArtistsByWorkRequest = useRequest(listArtistsByWork);
  const listPopularLabelsRequest = useAutoRequest(listPopularLabels);
  const autoCompleteLabelsRequest = useRequest(listPopularLabels);
  const addRequest = useRequest(props.addRequest);

  const wrapperRef = useRef(null);
  const searchField = useRef(null);

  useEffect(() => searchField.current && searchField.current.focus(), []);

  const throttledSearchRequestExecute = useThrottle(
    async (value, callback) => listWorksRequest.execute(value),
    () => {},
    500
  );

  const throttledAutoCompleteRequestExecute = useThrottle(
    async (value, callback) =>
      autoCompleteLabelsRequest.execute(value, callback),
    (response) => setAutoCompleteResults(keys(response.data)),
    500
  );

  const handleAdd = (workId, imageUrl) =>
    listArtistsByWorkRequest.execute({ id: workId }, (artists) => {
      let artistId = null;
      if (artists.data.length > 0) {
        artistId = artists.data[0].id;
      }
      addRequest.execute(
        {
          work_id: workId,
          artist_id: artistId,
          image_url: imageUrl || "",
          ...props.params,
        },
        () => {
          toast(props.onAddMessage);
          props.onAdd();
        }
      );
    });

  let results = get(listWorksRequest.response, "data") || [];
  let labels = keys(get(listPopularLabelsRequest.response, "data") || {});

  const listWorksResponseParams =
    get(listWorksRequest.response, "config.params") || {};

  const search = (value) => {
    let sanitizedValue = trim(value);
    if (sanitizedValue === lowerCase(sanitizedValue)) {
      sanitizedValue = capitalize(sanitizedValue);
    }
    if (sanitizedValue) {
      throttledSearchRequestExecute({
        visual_label: sanitizedValue,
        sortby: "visual_label",
        shallow: "true",
        skip: 0,
        limit: 25,
      });
    }
  };

  const setLabelQuery = (value) => {
    setQuery(value);
    search(value);
  };

  const handleLabelQueryChange = (event) => {
    setLabelQuery(event.target.value);

    if (trim(event.target.value)) {
      throttledAutoCompleteRequestExecute({
        search: event.target.value,
      });
    } else {
      setAutoCompleteResults([]);
    }
  };

  const handleAutoCompleteClick = (result) => {
    setLabelQuery(result);
    setAutoCompleteResults([]);
  };

  useOnClickOutside(wrapperRef, () => setAutoCompleteResults([]));

  const handleFocus = () => search(query);

  return (
    <>
      <div className="text-center mb-2">
        <RequestContainer
          loading={listPopularLabelsRequest.loading}
          error={listPopularLabelsRequest.error}
          minHeight="2rem"
          spinnerSize="1.5rem"
        >
          {labels.map((label, count) => (
            <span
              key={label}
              className="badge rounded-pill bg-secondary me-1"
              style={{ cursor: "pointer" }}
              onClick={() => setLabelQuery(label)}
            >
              {label}
            </span>
          ))}
        </RequestContainer>
      </div>

      <div className="position-relative mb-3" ref={wrapperRef}>
        <input
          className="form-control"
          type="search"
          placeholder="Search by label&hellip;"
          value={query}
          onChange={handleLabelQueryChange}
          onFocus={handleFocus}
          ref={searchField}
        />

        {autoCompleteResults ? (
          <div
            className={`${
              trim(query) && results.length > 0
                ? "position-absolute"
                : "position-relative"
            } w-100 list-group`}
            style={{ zIndex: 1070 }}
          >
            <RequestContainer
              loading={autoCompleteLabelsRequest.loading}
              minHeight="2rem"
              spinnerSize="1.5rem"
            >
              {autoCompleteResults.map((result) => (
                <div
                  key={result}
                  className="text-nowrap list-group-item list-group-item-action"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleAutoCompleteClick(result)}
                >
                  {result}
                </div>
              ))}
            </RequestContainer>
          </div>
        ) : null}

        {trim(query) && results.length > 0 && (
          <div className="mt-3">
            <RequestContainer
              loading={
                addRequest.loading ||
                listArtistsByWorkRequest.loading ||
                listWorksRequest.loading
              }
              error={addRequest.error || listArtistsByWorkRequest.error}
              minHeight="3.8125rem"
              spinnerSize="2.5rem"
            >
              <PaginatedContainer
                enabled={!listWorksRequest.loading}
                count={results.length}
                params={listWorksResponseParams}
                onNavigate={(params) => throttledSearchRequestExecute(params)}
              >
                <Placeholder empty={results.length === 0}>
                  <table className="table table-hover align-middle">
                    <tbody>
                      {results
                        .filter(
                          (work) =>
                            !(props.existingWorks || []).includes(work.id)
                        )
                        .map((work) => (
                          <tr key={work.id}>
                            <td
                              className="text-center"
                              style={{ width: "7rem" }}
                            >
                              {getPrimaryImage(work) ? (
                                <Image
                                  src={getPrimaryImage(work)}
                                  alt=""
                                  style={{
                                    maxWidth: "6rem",
                                    maxHeight: "6rem",
                                  }}
                                />
                              ) : null}
                            </td>
                            <td>
                              <p className="m-0">{work.title}</p>
                              <span className="text-muted">
                                {work.dateText}
                              </span>
                            </td>
                            <td className="text-end">
                              <IconButton
                                onClick={() =>
                                  handleAdd(work.id, getPrimaryImage(work))
                                }
                                icon={PlusCircle}
                                className="btn-sm btn-outline-secondary"
                              >
                                Add
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Placeholder>
              </PaginatedContainer>
            </RequestContainer>
          </div>
        )}
      </div>
    </>
  );
}

function EnterWorkId(props) {
  const [id, setId] = useState("");
  const addRequest = useRequest(props.addRequest);
  const getWorkRequest = useRequest(getWork);
  const listArtistsByWorkRequest = useRequest(listArtistsByWork);

  const idField = useRef(null);

  useEffect(() => idField.current && idField.current.focus(), []);

  const handleAdd = () =>
    getWorkRequest.execute(id, (work) => {
      let imageUrl = null;
      if (work.data.images && work.data.images.length > 0) {
        imageUrl = work.data.images[0].url;
      }
      listArtistsByWorkRequest.execute(
        { id: work.data.id },
        (artists) => {
          let artistId = null;
          if (artists.data.length > 0) {
            artistId = artists.data[0].id;
          }
          addRequest.execute(
            {
              work_id: work.data.id,
              artist_id: artistId,
              image_url: imageUrl || "",
              ...props.params,
            },
            () => {
              toast(props.onAddMessage);
              props.onAdd();
              setId("");
            }
          );
        },
        () => {
          addRequest.execute(
            {
              work_id: work.data.id,
              artist_id: null,
              image_url: imageUrl || "",
              ...props.params,
            },
            () => {
              toast(props.onAddMessage);
              props.onAdd();
              setId("");
            }
          );
        }
      );
    });

  return (
    <RequestContainer
      loading={
        addRequest.loading ||
        getWorkRequest.loading ||
        listArtistsByWorkRequest.loading
      }
      error={addRequest.error || getWorkRequest.error}
      minHeight="3.8125rem"
      spinnerSize="2.5rem"
    >
      <div className="row align-items-center">
        <div className="col">
          <input
            className="form-control"
            type="text"
            placeholder="Enter work ID&hellip;"
            value={id}
            onChange={(event) => setId(event.target.value)}
            ref={idField}
          />
        </div>
        <div className="col-auto">
          <IconButton
            onClick={handleAdd}
            icon={PlusCircle}
            className="btn-sm btn-outline-secondary"
          >
            Add
          </IconButton>
        </div>
      </div>
    </RequestContainer>
  );
}

function AddWorkModal(props) {
  const [searchMode, setSearchMode] = useState("work");

  return (
    <Modal
      title={props.modalTitle}
      size="lg"
      body={
        <>
          <ul className="nav nav-pills nav-fill mb-3">
            <li className="nav-item">
              <div
                style={{ cursor: "pointer" }}
                className={`nav-link ${
                  searchMode === "work" ? "active" : ""
                } link-primary`}
                onClick={() => setSearchMode("work")}
              >
                Search works
              </div>
            </li>
            <li className="nav-item">
              <div
                style={{ cursor: "pointer" }}
                className={`nav-link ${
                  searchMode === "artist" ? "active" : ""
                } link-primary`}
                onClick={() => setSearchMode("artist")}
              >
                Search by artist
              </div>
            </li>
            <li className="nav-item">
              <div
                style={{ cursor: "pointer" }}
                className={`nav-link ${
                  searchMode === "label" ? "active" : ""
                } link-primary`}
                onClick={() => setSearchMode("label")}
              >
                Search visual labels
              </div>
            </li>
            <li className="nav-item">
              <div
                style={{ cursor: "pointer" }}
                className={`nav-link ${
                  searchMode === "work_id" ? "active" : ""
                } link-primary`}
                onClick={() => setSearchMode("work_id")}
              >
                Enter work ID
              </div>
            </li>
          </ul>
          {searchMode === "work" ? (
            <SearchWorks {...props} />
          ) : searchMode === "artist" ? (
            <SearchByArtist {...props} />
          ) : searchMode === "work_id" ? (
            <EnterWorkId {...props} />
          ) : (
            <SearchLabels {...props} />
          )}
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default AddWorkModal;
