import DeleteWorkModal from "../modals/DeleteWorkModal";
import Image from "../components/Image";
import ImageDimensions from "../components/ImageDimensions";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import PaginatedContainer from "../components/PaginatedContainer";
import RequestContainer from "../components/RequestContainer";
import TruncatedText from "../components/TruncatedText";
import UnlinkWorkModal from "../modals/UnlinkWorkModal";
import ZonedDateTime from "../components/ZonedDateTime";
import naturalCompare from "natural-compare";
import { CheckSquare } from "react-feather";
import { Link } from "react-router-dom";
import { get, isNil, capitalize } from "lodash";
import { getOffersByWorks } from "../clients/finance";
import { useAutoRequest, useRequest } from "../hooks/useRequest";
import { useState } from "react";

function WorksTable(props) {
  const isChildRequest = !isNil(props.parentRequest);

  const [params, setParams] = useState({
    sortby: "name",
    after: null,
    before: null,
    for_sale: null,
    mintable: null,
    ...props.params,
  });
  const getOffersByWorksRequest = useRequest(getOffersByWorks);
  const request = useAutoRequest(
    props.request,
    isChildRequest
      ? get(props.parentRequest, `response.data.${props.parentAttribute}`)
      : params,
    (response) =>
      getOffersByWorksRequest.execute(response.data.map((work) => work.id))
  );

  let works = request.response.data || [];
  const offers = getOffersByWorksRequest.response.data || [];
  const responseParams = get(request.response, "config.params") || params;

  const getOffer = (work) =>
    offers.find((offer) => offer.workIds.includes(work.id));

  const offerTitle = (work) => {
    const offer = getOffer(work);

    if (!offer) {
      return null;
    }

    return (
      <>
        {capitalize(offer.offerType)} {offer.sequenceNumber}
      </>
    );
  };

  const handleParamsChange = (newParams) => {
    const allParams = { ...params, ...newParams };

    if (!("skip" in newParams)) {
      allParams.skip = 0;
    }

    setParams(allParams);

    if (props.onParamsChange) {
      props.onParamsChange(allParams);
    }
  };

  if (["offer", "reverseoffer"].includes(params.sortby)) {
    works.sort((left, right) => {
      const leftOffer = getOffer(left);
      const rightOffer = getOffer(right);
      const leftValue = leftOffer ? leftOffer.sequenceNumber : "";
      const rightValue = rightOffer ? rightOffer.sequenceNumber : "";
      return naturalCompare(leftValue.toLowerCase(), rightValue.toLowerCase());
    });

    if (params.sortby === "reverseoffer") {
      works.reverse();
    }
  }

  const columns = isChildRequest
    ? ["", "Title", "Dates", "Image size", "Added", "Offer", "For sale"]
    : [
        "",
        {
          title: "Title",
          sortable: true,
          currentSortBy: ["name", "reversename"].includes(params.sortby),
          currentSortOrder: params.sortby === "name" ? "asc" : "desc",
          onSort: (order) =>
            handleParamsChange({
              sortby: order === "desc" ? "reversename" : "name",
            }),
        },
        {
          title: "Dates",
          sortable: true,
          filterable: true,
          currentSortBy: ["date", "reversedate"].includes(params.sortby),
          currentSortOrder: params.sortby === "date" ? "asc" : "desc",
          currentFilter: [params.after, params.before],
          filterDates: true,
          onFilter: ([after, before]) =>
            handleParamsChange({ after: after, before: before }),
          onSort: (order) =>
            handleParamsChange({
              sortby: order === "desc" ? "reversedate" : "date",
            }),
        },
        "Image size",
        {
          title: "Added",
          sortable: true,
          currentSortBy: params.sortby === "created",
          currentSortOrder: "desc",
          onSort: (order) => handleParamsChange({ sortby: "created" }),
        },
        {
          title: "Offer",
          sortable: true,
          currentSortBy: ["offer", "reverseoffer"].includes(params.sortby),
          currentSortOrder: params.sortby === "offer" ? "asc" : "desc",
          onSort: (order) =>
            handleParamsChange({
              sortby: order === "desc" ? "reverseoffer" : "offer",
            }),
        },
        {
          title: "For sale",
          sortable: false,
          filterable: true,
          currentFilter: params.for_sale,
          filterBoolean: "Only works for sale",
          onFilter: (forSale) => handleParamsChange({ for_sale: forSale }),
        },
        {
          title: "Mintable",
          sortable: false,
          filterable: true,
          currentFilter: params.mintable,
          filterBoolean: "Only works that can be minted",
          onFilter: (forSale) => handleParamsChange({ mintable: forSale }),
        },
      ];

  const table = (
    <ObjectTable columns={columns}>
      {works.map((work) => (
        <ObjectTableRow
          actionAuthorizerRole={["admin", "editor"]}
          deleteAuthorizerRole={props.unlinkRequest ? null : "admin"}
          viewPath={`/works/${work.id}`}
          editPath={`/works/${work.id}/edit`}
          deleteModal={props.unlinkRequest ? UnlinkWorkModal : DeleteWorkModal}
          deleteModalParams={{
            id: work.id,
            title: work.title,
            onDelete: () =>
              isChildRequest
                ? props.parentRequest.execute()
                : request.execute(),
            request: props.unlinkRequest,
            params: { workId: work.id, ...props.unlinkParams },
          }}
          clipboardValue={work.id}
          key={work.id}
          extraActions={() =>
            getOffer(work) ? (
              <Link
                to={`/sales/${getOffer(work).saleId}/offers/${
                  getOffer(work).id
                }`}
                className="btn btn-sm btn-outline-primary me-2"
              >
                View offer
              </Link>
            ) : null
          }
          columns={[
            work.images && work.images.length > 0 ? (
              <div className="text-center" style={{ width: "7rem" }}>
                <Image
                  src={work.images[0].url}
                  alt=""
                  style={{
                    maxWidth: "6rem",
                    maxHeight: "6rem",
                  }}
                />
              </div>
            ) : null,
            <TruncatedText max={40}>{work.title}</TruncatedText>,
            <TruncatedText max={40}>{work.dateText}</TruncatedText>,
            work.images && work.images.length > 0 ? (
              <ImageDimensions src={work.images[0].url} />
            ) : null,
            <ZonedDateTime date={work.created_at} />,
            offerTitle(work),
            work.for_sale ? (
              <span className="text-success">
                <CheckSquare size={20} />
              </span>
            ) : (
              ""
            ),
            work.mintable ? (
              <span className="text-success">
                <CheckSquare size={20} />
              </span>
            ) : (
              ""
            ),
          ]}
        />
      ))}
    </ObjectTable>
  );

  return (
    <RequestContainer loading={request.loading}>
      {props.paginated ? (
        <PaginatedContainer
          enabled={!request.loading}
          count={works.length}
          params={responseParams}
          onNavigate={handleParamsChange}
        >
          {table}
        </PaginatedContainer>
      ) : (
        table
      )}
    </RequestContainer>
  );
}

export default WorksTable;
