import * as yup from "yup";
import ColumnRow from "../components/ColumnRow";
import ValidatedField from "../components/ValidatedField";
import { Formik, Form } from "formik";
import { prepareFormValues, returnFormValues } from "../lib/form";

function ImageForm(props) {
  return (
    <Formik
      initialValues={prepareFormValues({
        url: "",
        name: "",
        description: "",
        imageType: "",
        copyright: "",
        attribution: "",
        source: "",
        priority: "",
        design_background: false,
        ...props.initialValues,
      })}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(returnFormValues(values), ...args)
      }
      validationSchema={yup.object({
        url: yup.string().url().required(),
        name: yup.string(),
        description: yup.string(),
        imageType: yup.string(),
        copyright: yup.string(),
        attribution: yup.string(),
        source: yup.string(),
        priority: yup.number().integer(),
        design_background: yup.boolean(),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <ValidatedField
                formik={formik}
                label="URL"
                name="url"
                type="url"
              />
              <ValidatedField
                formik={formik}
                label="Name"
                name="name"
                type="text"
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Description"
                    name="description"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Image Type"
                    name="imageType"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Copyright"
                    name="copyright"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Attribution"
                    name="attribution"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Source"
                    name="source"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Priority"
                    name="priority"
                    type="text"
                  />,
                ]}
              />
              <ValidatedField
                formik={formik}
                label="Design background"
                name="design_background"
                type="checkbox"
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default ImageForm;
