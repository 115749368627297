import Authenticator from "./Authenticator";
import Home from "../views/Home";
import Nav from "./Nav";

import Artists from "../views/artists/Artists";
import NewArtist from "../views/artists/NewArtist";
import EditArtist from "../views/artists/EditArtist";
import ViewArtist from "../views/artists/ViewArtist";

import NewBid from "../views/bids/NewBid";
import EditBid from "../views/bids/EditBid";

import Collections from "../views/collections/Collections";
import NewCollection from "../views/collections/NewCollection";
import EditCollection from "../views/collections/EditCollection";
import ViewCollection from "../views/collections/ViewCollection";

import Programs from "../views/programs/Programs";
import NewProgram from "../views/programs/NewProgram";
import EditProgram from "../views/programs/EditProgram";
import ViewProgram from "../views/programs/ViewProgram";

import Crates from "../views/crates/Crates";
import NewCrate from "../views/crates/NewCrate";
import EditCrate from "../views/crates/EditCrate";
import ViewCrate from "../views/crates/ViewCrate";
import Queue from "../views/crates/Queue";

import Events from "../views/events/Events";
import NewEvent from "../views/events/NewEvent";
import CopyEvent from "../views/events/CopyEvent";
import EditEvent from "../views/events/EditEvent";
import ViewEvent from "../views/events/ViewEvent";

import NewFee from "../views/fees/NewFee";
import EditFee from "../views/fees/EditFee";

import NewSalePrice from "../views/sale_prices/NewSalePrice";
import EditSalePrice from "../views/sale_prices/EditSalePrice";

import Jobs from "../views/jobs/Jobs";
import ViewJob from "../views/jobs/ViewJob";
import Auctions from "../views/jobs/Auctions";

import NewOffer from "../views/offers/NewOffer";
import EditOffer from "../views/offers/EditOffer";
import ViewOffer from "../views/offers/ViewOffer";

import Sales from "../views/sales/Sales";
import NewSale from "../views/sales/NewSale";
import EditSale from "../views/sales/EditSale";
import ViewSale from "../views/sales/ViewSale";

import Venues from "../views/venues/Venues";
import NewVenue from "../views/venues/NewVenue";
import EditVenue from "../views/venues/EditVenue";
import ViewVenue from "../views/venues/ViewVenue";

import Works from "../views/works/Works";
import NewWork from "../views/works/NewWork";
import EditWork from "../views/works/EditWork";
import ViewWork from "../views/works/ViewWork";

import Users from "../views/users/Users";
import EditUser from "../views/users/EditUser";
import ViewUser from "../views/users/ViewUser";

import QaTasks from "../views/qa_tasks/QaTasks";
import ViewQaTask from "../views/qa_tasks/ViewQaTask";

import EditImage from "../views/images/EditImage";

import EditVideo from "../views/videos/EditVideo";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { getRole } from "../lib/firebase";
import { useState, useEffect } from "react";

function UnauthorizedAccess() {
  return (
    <div className="container-fluid p-4">
      <div className="row">
        <div className="col-12">
          <h5>Unauthorized Access</h5>
          <p>You do not have permission to access this page.</p>
        </div>
      </div>
    </div>
  );
}

function AdminRoute({ component: Component, ...rest }) {
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() =>
    (async () => setIsAuthorized((await getRole()) === "admin"))()
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized === true ? (
          <Component {...props} />
        ) : (
          <UnauthorizedAccess />
        )
      }
    />
  );
}

function EditorRoute({ component: Component, ...rest }) {
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() =>
    (async () =>
      setIsAuthorized(["admin", "editor"].includes(await getRole())))()
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized === true ? (
          <Component {...props} />
        ) : (
          <UnauthorizedAccess />
        )
      }
    />
  );
}

function App() {
  return (
    <Authenticator>
      <Router>
        <Nav />
        <Switch>
          <EditorRoute path="/artists/new" component={NewArtist} />
          <EditorRoute path="/artists/:artist_id/edit" component={EditArtist} />
          <Route path="/artists/:artist_id" component={ViewArtist} />
          <Route path="/artists" component={Artists} />

          <Route path="/collections/new" component={NewCollection} />
          <Route
            path="/collections/:collection_id/edit"
            component={EditCollection}
          />
          <Route
            path="/collections/:collection_id"
            component={ViewCollection}
          />
          <Route path="/collections" component={Collections} />

          <EditorRoute path="/programs/new" component={NewProgram} />
          <EditorRoute
            path="/programs/:program_id/edit"
            component={EditProgram}
          />
          <Route path="/programs/:program_id" component={ViewProgram} />
          <Route path="/programs" component={Programs} />

          <Route path="/crates/queue" component={Queue} />
          <Route path="/crates/new" component={NewCrate} />
          <Route path="/crates/:crate_id/edit" component={EditCrate} />
          <Route path="/crates/:crate_id" component={ViewCrate} />
          <Route path="/crates" component={Crates} />

          <EditorRoute path="/events/new" component={NewEvent} />
          <EditorRoute path="/events/copy/:event_id" component={CopyEvent} />
          <EditorRoute path="/events/:event_id/edit" component={EditEvent} />
          <Route path="/events/:event_id" component={ViewEvent} />
          <Route path="/events" component={Events} />

          <EditorRoute path="/jobs/auctions/" component={Auctions} />
          <EditorRoute path="/jobs/:job_id" component={ViewJob} />
          <EditorRoute path="/jobs" component={Jobs} />

          <EditorRoute
            path="/sales/:sale_id/offers/:offer_id/bids/new"
            component={NewBid}
          />
          <EditorRoute
            path="/sales/:sale_id/offers/:offer_id/bids/:bid_id/edit"
            component={EditBid}
          />

          <EditorRoute
            path="/sales/:sale_id/offers/:offer_id/fees/new"
            component={NewFee}
          />
          <EditorRoute
            path="/sales/:sale_id/offers/:offer_id/fees/:fee_id/edit"
            component={EditFee}
          />

          <EditorRoute
            path="/sales/:sale_id/offers/:offer_id/sale_prices/new"
            component={NewSalePrice}
          />
          <EditorRoute
            path="/sales/:sale_id/offers/:offer_id/sale_prices/:sale_price_id/edit"
            component={EditSalePrice}
          />

          <EditorRoute path="/sales/:sale_id/offers/new" component={NewOffer} />
          <EditorRoute
            path="/sales/:sale_id/offers/:offer_id/edit"
            component={EditOffer}
          />
          <Route
            path="/sales/:sale_id/offers/:offer_id"
            component={ViewOffer}
          />

          <EditorRoute path="/sales/new" component={NewSale} />
          <EditorRoute path="/sales/:sale_id/edit" component={EditSale} />
          <Route path="/sales/:sale_id" component={ViewSale} />
          <Route path="/sales" component={Sales} />

          <EditorRoute path="/venues/new" component={NewVenue} />
          <EditorRoute path="/venues/:venue_id/edit" component={EditVenue} />
          <Route path="/venues/:venue_id" component={ViewVenue} />
          <Route path="/venues" component={Venues} />

          <EditorRoute
            path="/works/:work_id/images/:image_id/edit"
            component={EditImage}
          />

          <EditorRoute
            path="/works/:work_id/videos/:video_id/edit"
            component={EditVideo}
          />

          <EditorRoute path="/works/new" component={NewWork} />
          <EditorRoute path="/works/:work_id/edit" component={EditWork} />
          <Route path="/works/:work_id" component={ViewWork} />
          <Route path="/works" component={Works} />

          <AdminRoute path="/users/:user_id/edit" component={EditUser} />
          <AdminRoute path="/users/:user_id" component={ViewUser} />
          <AdminRoute path="/users" component={Users} />

          <EditorRoute path="/qa_tasks/:group_id" component={ViewQaTask} />
          <EditorRoute path="/qa_tasks" component={QaTasks} />

          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </Authenticator>
  );
}

export default App;
