function ViewHeader(props) {
  return (
    <div
      className="container-fluid py-2 px-4 sticky-top"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(10px)",
      }}
    >
      <div className="row align-items-center" style={{ minHeight: "38px" }}>
        <div className="col">
          <nav>
            <ol className="breadcrumb mb-0">
              {props.breadcrumbs.map((item, index) => (
                <li
                  className={`breadcrumb-item ${
                    index === props.breadcrumbs.length - 1 && "active"
                  }`}
                  key={index}
                >
                  {item}
                </li>
              ))}
            </ol>
          </nav>
        </div>
        <div className="col-auto text-end">{props.actions}</div>
      </div>
    </div>
  );
}

export default ViewHeader;
