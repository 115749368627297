import DeleteModal from "../components/DeleteModal";
import { deleteVideo } from "../clients/art";

function DeleteVideoModal(props) {
  const onDelete = () => {
    props.onDelete();
  };

  return (
    <DeleteModal
      request={deleteVideo}
      id={props.id}
      onClose={props.onClose}
      onDelete={onDelete}
      toastMessage="The video was deleted"
      title="Delete video?"
      confirmText="DELETE"
      message={
        <p className="m-0">
          Do you want to delete&nbsp;
          <span className="text-muted">{props.name}</span>
          &nbsp;permanently?
        </p>
      }
    />
  );
}

export default DeleteVideoModal;
