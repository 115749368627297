import DeleteModal from "../components/DeleteModal";
import { deleteJob } from "../clients/import";

function DeleteJobModal(props) {
  return (
    <DeleteModal
      request={deleteJob}
      id={props.id}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The job was deleted"
      title="Delete job?"
      confirmText="DELETE"
      message={
        <p className="m-0">
          Do you want to delete&nbsp;
          <span className="text-muted">{props.description}</span>
          &nbsp;permanently?
        </p>
      }
    />
  );
}

export default DeleteJobModal;
