import { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

function useRefresh(id = null) {
  const didRender = useRef(false);
  const [token, setToken] = useState(uuidv4());
  useEffect(() => {
    if (didRender.current) {
      setToken(uuidv4());
    } else {
      didRender.current = true;
    }
  }, [id]);
  return [token, () => setToken(uuidv4())];
}

export { useRefresh };
