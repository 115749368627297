import IconButton from "../components/IconButton";
import Image from "../components/Image";
import ImageDimensions from "../components/ImageDimensions";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import { Link } from "react-router-dom";
import { PlusCircle, CheckCircle } from "react-feather";
import { getCollection, updateCollection } from "../clients/collection";
import { getWorks } from "../clients/art";
import { isString, uniq, get, flatMap } from "lodash";
import { useAutoRequest, useRequest } from "../hooks/useRequest";

const normalizeWorkId = (workId) => {
  if (isString(workId)) {
    return workId.split("_")[0];
  }
  return workId;
};

function SelectCollectionImageFromWorksModal(props) {
  const getWorksRequest = useRequest(getWorks);
  const getCollectionRequest = useAutoRequest(
    getCollection,
    props.collectionId,
    (response) =>
      getWorksRequest.execute(uniq(response.data.works.map(normalizeWorkId)))
  );
  const updateCollectionRequest = useRequest(updateCollection);
  const works = getWorksRequest.response.data || [];

  const handleSelect = (imageUrl) =>
    updateCollectionRequest.execute(
      { id: props.collectionId, data: { image_url: imageUrl } },
      () => {
        props.onSelect();
        props.onClose();
      }
    );

  const loading = getWorksRequest.loading || getCollectionRequest.loading;
  const error = getWorksRequest.error || getCollectionRequest.error;

  return (
    <Modal
      title="Select image for collection"
      size="lg"
      body={
        <RequestContainer
          loading={loading}
          error={error}
          minHeight="3.8125rem"
          spinnerSize="2.5rem"
        >
          <table className="table table-hover align-middle mb-3">
            <tbody>
              {flatMap(works, (work, index) =>
                work.images.map((image) => (
                  <tr key={image.id}>
                    <td className="text-center" style={{ width: "7rem" }}>
                      <Image
                        src={image.url}
                        alt=""
                        style={{
                          maxWidth: "6rem",
                          maxHeight: "6rem",
                        }}
                      />
                    </td>
                    <td>
                      <p className="m-0 fw-bold">
                        <Link
                          to={`/artists/${get(work, ["artists", 0, "id"])}`}
                          className="link-dark"
                        >
                          {get(work, ["artists", 0, "name"])}
                        </Link>
                      </p>
                      <p className="m-0">
                        <Link
                          to={`/works/${work.id}`}
                          className="link-secondary"
                        >
                          {work.title}
                        </Link>
                      </p>
                      <span className="text-muted">{work.dateText}</span>
                      <ImageDimensions src={image.url} />
                    </td>
                    <td className="text-end">
                      {image.url === props.currentImageUrl ? (
                        <IconButton
                          icon={CheckCircle}
                          className="btn-sm btn-outline-success disabled"
                        >
                          Selected
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => handleSelect(image.url)}
                          icon={PlusCircle}
                          className="btn-sm btn-outline-secondary"
                        >
                          Select
                        </IconButton>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </RequestContainer>
      }
      onClose={props.onClose}
    />
  );
}

export default SelectCollectionImageFromWorksModal;
