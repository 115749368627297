import algoliasearch from "algoliasearch";
import config from "../config";
import { trim, get, isArray } from "lodash";
import { useFormikContext, useField } from "formik";
import { useState, useEffect } from "react";
import { useThrottle } from "../hooks/useThrottle";
import { v4 as uuidv4 } from "uuid";

import "./UserSearchField.css";

const client = algoliasearch(
  config.algolia.applicationId,
  config.algolia.apiKey
);
const index = client.initIndex("arthur_users");

function UserSearchField(props) {
  const [id] = useState(uuidv4());
  const [value, setValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const renderFieldValue = (hit) =>
    get(hit, ["profile.displayName"])
      ? `${hit["profile.displayName"]} <${hit["profile.email"]}>`
      : hit["profile.email"];

  useEffect(() => {
    let isCancelled = false;

    if (field.value) {
      index.getObject(field.value).then((hit) => {
        if (!isCancelled && !value) {
          setValue(renderFieldValue(hit));
        }
      });
    }

    return () => {
      isCancelled = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParams = {};

  if (props.role) {
    if (isArray(props.role)) {
      searchParams.filters = props.role
        .map((role) => `role:${role}`)
        .join(" OR ");
    } else {
      searchParams.filters = `role:${props.role}`;
    }
  }

  const searchRequestExecute = useThrottle(
    async (query, callback) => index.search(query, searchParams).then(callback),
    ({ hits }) => setSearchResults(hits),
    500
  );

  const handleChange = (event) => {
    setValue(event.target.value);
    setFieldValue(props.name, "");

    if (trim(event.target.value)) {
      searchRequestExecute(event.target.value);
    } else {
      setSearchResults([]);
    }
  };

  const handleSetUser = (id, name) => {
    setFieldValue(props.name, id);
    setValue(name);
    setSearchResults([]);
  };

  const renderResult = (hit, index) => {
    const displayName =
      get(hit, ["_highlightResult", "profile.displayName"]) &&
      get(hit, ["_highlightResult", "profile.email"])
        ? `${hit["_highlightResult"]["profile.displayName"].value} <span class="text-muted">&lt;${hit["_highlightResult"]["profile.email"].value}&gt;</span>`
        : get(hit, ["_highlightResult", "profile.email"])
        ? hit["_highlightResult"]["profile.email"].value
        : `${hit["_highlightResult"]["profile.displayName"].value} <span class="text-muted">&lt;${hit["_highlightResult"]["profile.userName"].value}&gt;</span>`;

    return (
      <div
        key={index}
        className="search-result text-nowrap list-group-item list-group-item-action"
        style={{ cursor: "pointer" }}
        onClick={() => handleSetUser(hit["objectID"], renderFieldValue(hit))}
        dangerouslySetInnerHTML={{
          __html: displayName,
        }}
      />
    );
  };

  return (
    <div className="position-relative mb-3">
      <div className="form-floating">
        <input
          type="text"
          value={value}
          onChange={handleChange}
          className={
            props.formik.touched[props.name] && props.formik.errors[props.name]
              ? "form-control is-invalid"
              : "form-control"
          }
          id={id}
          placeholder={props.label}
        />
        <label htmlFor={id}>{props.label}</label>
        {props.formik.touched[props.name] &&
          props.formik.errors[props.name] && (
            <div className="invalid-feedback">
              {props.formik.errors[props.name]}
            </div>
          )}
      </div>
      {searchResults ? (
        <div
          className={`${
            props.inline ? "" : "position-absolute"
          } w-100 list-group`}
        >
          {searchResults.map(renderResult)}
        </div>
      ) : null}
    </div>
  );
}

export default UserSearchField;
