import Modal from "../components/Modal";
import Placeholder from "../components/Placeholder";
import ReactJson from "react-json-view";
import RequestContainer from "../components/RequestContainer";
import ZonedDateTime from "../components/ZonedDateTime";
import { getEvents } from "../lib/log";
import { useAutoRequest } from "../hooks/useRequest";

const getEventsWrapper = async (_token, params) => {
  return await getEvents(params.type, params.id);
};

function LogModal(props) {
  const getEventsRequest = useAutoRequest(getEventsWrapper, props.filter);

  const loading = getEventsRequest.loading;
  const error = getEventsRequest.error;

  const data = getEventsRequest.response.data || [];

  return (
    <Modal
      title="Log"
      size="xl"
      body={
        <RequestContainer loading={loading} error={error}>
          <Placeholder empty={data.length === 0}>
            <table
              className="table align-middle"
              style={{ tableLayout: "fixed" }}
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>User</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((event, index) => (
                  <>
                    <tr key={index}>
                      <td>
                        <small>
                          <ZonedDateTime date={event.timestamp} />
                        </small>
                      </td>
                      <td>{event.user.email}</td>
                      <td>
                        <code>{event.action}</code>
                      </td>
                    </tr>
                    <tr key={`${index}_data`}>
                      <td colSpan={3}>
                        {event.actionData ? (
                          <ReactJson
                            name={false}
                            src={event.actionData}
                            collapseStringsAfterLength={20}
                            groupArraysAfterLength={10}
                            displayObjectSize={false}
                            displayDataTypes={false}
                            displayArrayKey={false}
                            collapsed={true}
                            sortKeys={true}
                            enableClipboard={false}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </Placeholder>
        </RequestContainer>
      }
      onClose={props.onClose}
    />
  );
}

export default LogModal;
