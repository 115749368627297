import config from "../config";
import { formatISO } from "date-fns";
import { logEvent } from "../lib/log";
import { merge, flatMap, uniq } from "lodash";
import {
  request as baseRequest,
  scroll as baseScroll,
  defaultParam,
  otherParams,
  virtualBulk,
  bulk,
} from "./common";
import {
  linkOfferArtist,
  linkOfferWork,
  linkSaleArtist,
  linkSaleEvent,
  linkSaleVenue,
  listOffersByArtist,
  listOffersByWork,
  listSales,
  listSalesByArtist,
  listSalesByEvent,
  listSalesByVenue,
  unlinkOfferArtist,
  unlinkOfferWork,
  unlinkSaleArtist,
  unlinkSaleEvent,
  unlinkSaleVenue,
  createSale,
  createOffer,
  updateSale,
  updateOffer,
  getSale,
} from "./finance";
import { addEventToProgram } from "./collection";
import { v4 as uuidv4 } from "uuid";

const request = async (token, endpoint, options = {}) =>
  await baseRequest(config.art_api.url, token, endpoint, options);

const scroll = async (token, endpoint, options = {}) =>
  await baseScroll(config.art_api.url, token, endpoint, options);

const listArtists = async (token, params) =>
  await request(token, "artists", {
    params: merge({ shallow: "true" }, params),
  });

const listArtistsByEvent = async (token, params) =>
  await scroll(token, "artists", {
    params: merge(
      {
        shallow: "true",
        filter: "event",
        filter_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const listArtistsByWork = async (token, params) =>
  await scroll(token, "artists", {
    params: merge(
      {
        shallow: "true",
        filter: "work",
        filter_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const getArtist = async (token, id) =>
  await request(token, `artists/${id}`, {
    params: { shallow: "true" },
  });

const getArtists = async (token, ids) =>
  await bulk(config.art_api.url, token, "bulk", "artists", ids);

const createArtist = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "artists", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateArtist", data, { type: "artist", id });
  if (data.eventId) {
    await linkEventArtist(token, { artistId: id, eventId: data.eventId });
  }
  if (data.offerId) {
    await linkOfferArtist(token, { artistId: id, offerId: data.offerId });
  }
  if (data.saleId) {
    await linkSaleArtist(token, { artistId: id, saleId: data.saleId });
  }
  if (data.workId) {
    await linkWorkArtist(token, { artistId: id, workId: data.workId });
  }
  return response;
};

const updateArtist = async (token, { id, data }) => {
  const response = await request(token, `artists/${id}`, {
    method: "put",
    data: data,
  });
  logEvent("UpdateArtist", data, { type: "artist", id });
  return response;
};

const deleteArtist = async (token, id) => {
  const sales = await listSalesByArtist(token, id);

  if (sales.data) {
    virtualBulk(
      token,
      sales.data.map((sale) => ({ artistId: id, saleId: sale.id })),
      unlinkSaleArtist
    );
  }

  const offers = await listOffersByArtist(token, id);

  if (offers.data) {
    virtualBulk(
      token,
      offers.data.map((offer) => ({ artistId: id, offerId: offer.id })),
      unlinkOfferArtist
    );
  }

  const response = await request(token, `artists/${id}`, { method: "delete" });
  logEvent("DeleteArtist", {}, { type: "artist", id });
  return response;
};

const listEvents = async (token, params) =>
  await request(token, "events", {
    params: merge({ shallow: "true" }, params),
  });

const listEventsByArtist = async (token, params) =>
  await scroll(token, "events", {
    params: merge(
      {
        shallow: "true",
        filter: "artist",
        filter_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const listEventsByVenue = async (token, params) =>
  await scroll(token, "events", {
    params: merge(
      {
        shallow: "true",
        filter: "venue",
        filter_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const listEventsByWork = async (token, params) =>
  await scroll(token, "events", {
    params: merge(
      {
        shallow: "true",
        filter: "work",
        filter_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const getEvent = async (token, id) =>
  await request(token, `events/${id}`, {
    params: { shallow: "true" },
  });

const getEvents = async (token, ids) =>
  await bulk(config.art_api.url, token, "bulk", "events", ids);

const createEvent = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "events", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateEvent", data, { type: "event", id });
  if (data.artistId) {
    await linkEventArtist(token, { eventId: id, artistId: data.artistId });
  }
  if (data.saleId) {
    await linkSaleEvent(token, { eventId: id, saleId: data.saleId });
  }
  if (data.venueId) {
    await linkEventVenue(token, { eventId: id, venueId: data.venueId });
  }
  if (data.workId) {
    await linkEventWork(token, { eventId: id, workId: data.workId });
  }
  if (data.programId) {
    await addEventToProgram(token, { id: data.programId, eventId: id });
  }
  return response;
};

const updateEvent = async (token, { id, data }) => {
  const response = await request(token, `events/${id}`, {
    method: "put",
    data: data,
  });
  logEvent("UpdateEvent", data, { type: "event", id });
  return response;
};

const deleteEvent = async (token, params) => {
  const id = defaultParam(params, "id");
  const options = otherParams(params, "id");

  if (!options.nested) {
    const sales = await listSalesByEvent(token, id);

    if (sales.data) {
      virtualBulk(
        token,
        sales.data.map((sale) => ({ eventId: id, saleId: sale.id })),
        unlinkSaleEvent
      );
    }
  }

  const response = await request(token, `events/${id}`, { method: "delete" });
  logEvent("DeleteEvent", {}, { type: "event", id });
  return response;
};

const linkEventArtist = async (token, { eventId, artistId }) => {
  const response = await request(token, `event_artist/${eventId}`, {
    method: "post",
    params: { artist_id: artistId },
  });
  logEvent(
    "LinkEventArtist",
    { artist_id: artistId },
    { type: "event", id: eventId }
  );
  return response;
};

const linkEventVenue = async (token, { eventId, venueId }) => {
  const response = await request(token, `event_venue/${eventId}`, {
    method: "post",
    params: { venue_id: venueId },
  });
  logEvent(
    "LinkEventVenue",
    { venue_id: venueId },
    { type: "event", id: eventId }
  );
  return response;
};

const linkEventWork = async (token, { eventId, workId }) => {
  const response = await request(token, `event_work/${eventId}`, {
    method: "post",
    params: { work_id: workId },
  });
  logEvent(
    "LinkEventWork",
    { work_id: workId },
    { type: "event", id: eventId }
  );
  return response;
};

const unlinkEventArtist = async (token, { eventId, artistId }) => {
  const response = await request(token, `event_artist/${eventId}`, {
    method: "delete",
    params: { artist_id: artistId },
  });
  logEvent(
    "UnlinkEventArtist",
    { artist_id: artistId },
    { type: "event", id: eventId }
  );
  return response;
};

const unlinkEventVenue = async (token, { eventId, venueId }) => {
  const response = await request(token, `event_venue/${eventId}`, {
    method: "delete",
    params: { venue_id: venueId },
  });
  logEvent(
    "UnlinkEventVenue",
    { venue_id: venueId },
    { type: "event", id: eventId }
  );
  return response;
};

const unlinkEventWork = async (token, { eventId, workId }) => {
  const response = await request(token, `event_work/${eventId}`, {
    method: "delete",
    params: { work_id: workId },
  });
  logEvent(
    "UnlinkEventWork",
    { work_id: workId },
    { type: "event", id: eventId }
  );
  return response;
};

const getImage = async (token, id) =>
  await request(token, `images/${id}`, {
    params: { shallow: "true" },
  });

const createImage = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "images", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateImage", data, { type: "image", id });
  if (data.workId) {
    await request(token, `work_image/${data.workId}?image_id=${id}`, {
      method: "post",
    });
  }
  return response;
};

const updateImage = async (token, { id, data }) => {
  const response = await request(token, `images/${id}`, {
    method: "put",
    data: data,
  });
  logEvent("UpdateImage", data, { type: "image", id });
  return response;
};

const deleteImage = async (token, id) => {
  const response = await request(token, `images/${id}`, { method: "delete" });
  logEvent("DeleteImage", {}, { type: "image", id });
  return response;
};

const getVideo = async (token, id) =>
  await request(token, `videos/${id}`, {
    params: { shallow: "true" },
  });

const createVideo = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "videos", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateVideo", data, { type: "video", id });
  if (data.workId) {
    await request(token, `work_video/${data.workId}?video_id=${id}`, {
      method: "post",
    });
  }
  return response;
};

const updateVideo = async (token, { id, data }) => {
  const response = await request(token, `videos/${id}`, {
    method: "put",
    data: data,
  });
  logEvent("UpdateVideo", data, { type: "video", id });
  return response;
};

const deleteVideo = async (token, id) => {
  const response = await request(token, `videos/${id}`, { method: "delete" });
  logEvent("DeleteVideo", {}, { type: "video", id });
  return response;
};

const listVenues = async (token, params) =>
  await request(token, "venues", {
    params: merge({ shallow: "true" }, params),
  });

const listChildVenues = async (token, parentId) =>
  await request(token, `venues`, {
    params: { shallow: "true", filter: "parent", filter_id: parentId },
  });

const getVenue = async (token, id) =>
  await request(token, `venues/${id}`, {
    params: { shallow: "true" },
  });

const getVenues = async (token, ids) =>
  await bulk(config.art_api.url, token, "bulk", "venues", ids);

const createVenue = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "venues", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateVenue", data, { type: "venue", id });
  if (data.eventId) {
    await linkEventVenue(token, { venueId: id, eventId: data.eventId });
  }
  if (data.saleId) {
    await linkSaleVenue(token, { venueId: id, saleId: data.saleId });
  }
  if (data.parentId) {
    await linkChildVenue(token, { venueId: id, parentId: data.parentId });
  }
  return response;
};

const updateVenue = async (token, { id, data }) => {
  const response = await request(token, `venues/${id}`, {
    method: "put",
    data: data,
  });
  logEvent("UpdateVenue", data, { type: "venue", id });
  return response;
};

const deleteVenue = async (token, id) => {
  const sales = await listSalesByVenue(token, id);

  if (sales.data) {
    virtualBulk(
      token,
      sales.data.map((sale) => ({ venueId: id, saleId: sale.id })),
      unlinkSaleVenue
    );
  }

  const response = await request(token, `venues/${id}`, { method: "delete" });
  logEvent("DeleteVenue", {}, { type: "venue", id });
  return response;
};

const linkChildVenue = async (token, { parentId, venueId }) => {
  const response = await request(token, `venue_child/${parentId}`, {
    method: "post",
    params: { child_id: venueId },
  });
  logEvent(
    "LinkChildVenue",
    { child_id: venueId },
    { type: "venue", id: parentId }
  );
  return response;
};

const unlinkChildVenue = async (token, { parentId, venueId }) => {
  const response = await request(token, `venue_child/${parentId}`, {
    method: "delete",
    params: { child_id: venueId },
  });
  logEvent(
    "UnlinkChildVenue",
    { child_id: venueId },
    { type: "venue", id: parentId }
  );
  return response;
};

const listWorks = async (token, params) =>
  await request(token, "works", {
    params: merge({ shallow: "true" }, params),
  });

const listWorksByArtist = async (token, params) =>
  await scroll(token, "works", {
    params: merge(
      {
        shallow: "true",
        filter: "artist",
        filter_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const listWorksByEvent = async (token, params) =>
  await scroll(token, "works", {
    params: merge(
      {
        shallow: "true",
        filter: "event",
        filter_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const getWork = async (token, id) =>
  await request(token, `works/${id}`, {
    params: { shallow: "true" },
  });

const getWorks = async (token, ids) =>
  await bulk(config.art_api.url, token, "bulk_works", null, ids);

const createWork = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "works", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateWork", data, { type: "work", id });
  if (data.artistId) {
    await linkWorkArtist(token, { workId: id, artistId: data.artistId });
  }
  if (data.eventId) {
    await linkEventWork(token, { workId: id, eventId: data.eventId });
  }
  if (data.offerId) {
    await linkOfferWork(token, { workId: id, offerId: data.offerId });
  }
  return response;
};

const updateWork = async (token, { id, data }) => {
  const response = await request(token, `works/${id}`, {
    method: "put",
    data: data,
  });
  logEvent("UpdateWork", data, { type: "work", id });
  return response;
};

const deleteWork = async (token, params) => {
  const id = defaultParam(params, "id");
  const options = otherParams(params, "id");

  if (!options.nested) {
    const offers = await listOffersByWork(token, id);

    if (offers.data) {
      virtualBulk(
        token,
        offers.data.map((offer) => ({ workId: id, offerId: offer.id })),
        unlinkOfferWork
      );
    }
  }

  const response = await request(token, `works/${id}`, { method: "delete" });
  logEvent("DeleteWork", {}, { type: "work", id });
  return response;
};

const linkWorkArtist = async (token, { workId, artistId }) => {
  const response = await request(token, `work_artist/${workId}`, {
    method: "post",
    params: { artist_id: artistId },
  });
  logEvent(
    "LinkWorkArtist",
    { artist_id: artistId },
    { type: "work", id: workId }
  );
  return response;
};

const unlinkWorkArtist = async (token, { workId, artistId }) => {
  const response = await request(token, `work_artist/${workId}`, {
    method: "delete",
    params: { artist_id: artistId },
  });
  logEvent(
    "UnlinkWorkArtist",
    { artist_id: artistId },
    { type: "work", id: workId }
  );
  return response;
};

const unlinkWorkImage = async (token, { workId, imageId }) => {
  const response = await request(token, `work_image/${workId}`, {
    method: "delete",
    params: { image_id: imageId },
  });
  logEvent(
    "UnlinkWorkImage",
    { image_id: imageId },
    { type: "work", id: workId }
  );
  return response;
};

const unlinkWorkVideo = async (token, { workId, videoId }) => {
  const response = await request(token, `work_video/${workId}`, {
    method: "delete",
    params: { video_id: videoId },
  });
  logEvent(
    "UnlinkWorkVideo",
    { video_id: videoId },
    { type: "work", id: workId }
  );
  return response;
};

const countByEvent = async (token, eventId) =>
  await request(token, `count`, {
    params: { object_type: "event", id: eventId },
  });

const mergeArtist = async (token, { targetId, sourceId }) => {
  await request(token, `artist_copy_events/${targetId}`, {
    method: "post",
    params: { source_artist_id: sourceId },
  });
  await request(token, `artist_copy_works/${targetId}`, {
    method: "post",
    params: { source_artist_id: sourceId },
  });

  // TODO: Add copy endpoints to finance API
  const sales = await listSalesByArtist(token, sourceId);

  if (sales.data) {
    virtualBulk(
      token,
      sales.data.map((sale) => ({ artistId: targetId, saleId: sale.id })),
      linkSaleArtist
    );
  }

  const offers = await listOffersByArtist(token, sourceId);

  if (offers.data) {
    virtualBulk(
      token,
      offers.data.map((offer) => ({ artistId: targetId, offerId: offer.id })),
      linkOfferArtist
    );
  }

  const response = await deleteArtist(token, sourceId);
  logEvent(
    "MergeArtist",
    { targetId, sourceId },
    { type: "artist", id: sourceId }
  );
  return response;
};

const mergeEvent = async (token, { targetId, sourceId }) => {
  await request(token, `event_copy_artists/${targetId}`, {
    method: "post",
    params: { source_event_id: sourceId },
  });
  await request(token, `event_copy_venues/${targetId}`, {
    method: "post",
    params: { source_event_id: sourceId },
  });
  await request(token, `event_copy_works/${targetId}`, {
    method: "post",
    params: { source_event_id: sourceId },
  });

  // TODO: Add copy endpoints to finance API
  const sales = await listSalesByEvent(token, sourceId);

  if (sales.data) {
    virtualBulk(
      token,
      sales.data.map((sale) => ({ eventId: targetId, saleId: sale.id })),
      unlinkSaleEvent
    );
  }

  const response = await deleteEvent(token, sourceId);
  logEvent(
    "MergeEvent",
    { targetId, sourceId },
    { type: "event", id: sourceId }
  );
  return response;
};

const mergeVenue = async (token, { targetId, sourceId }) => {
  await request(token, `venue_copy_events/${targetId}`, {
    method: "post",
    params: { source_venue_id: sourceId },
  });

  // TODO: Add copy endpoints to finance API
  const sales = await listSalesByVenue(token, sourceId);

  if (sales.data) {
    virtualBulk(
      token,
      sales.data.map((sale) => ({ venueId: targetId, saleId: sale.id })),
      unlinkSaleVenue
    );
  }

  const response = await deleteVenue(token, sourceId);
  logEvent(
    "MergeVenue",
    { targetId, sourceId },
    { type: "venue", id: sourceId }
  );
  return response;
};

const listPopularLabels = async (token, params) =>
  await request(token, "popular_labels", {
    params: merge({ limit: 25 }, params),
  });

const syncEventArtistsFromWorks = async (token, id) => {
  const artists = await listArtistsByEvent(token, id);
  const works = await listWorksByEvent(token, id);

  if (works.data) {
    const workArtists = await virtualBulk(
      token,
      works.data.map((work) => work.id),
      listArtistsByWork
    );

    if (workArtists.data) {
      const newArtistIds = uniq(
        flatMap(workArtists.data, (artists) =>
          artists.map((artist) => artist.id)
        )
      ).filter(
        (artistId) =>
          !(artists.data || []).some((artist) => artist.id === artistId)
      );

      await virtualBulk(
        token,
        newArtistIds.map((artistId) => ({ eventId: id, artistId })),
        linkEventArtist
      );
    }
  }

  logEvent("SyncEventArtistsFromWorks", { id }, { type: "event", id });
  return { data: null };
};

const workBeginSale = async (
  token,
  { id, price, currency, shouldCreateOffer }
) => {
  const work = await getWork(token, id);

  if (work.data) {
    await updateWork(token, {
      id,
      data: { ...work.data, lastPrice: price, currency, for_sale: true },
    });

    if (shouldCreateOffer) {
      let offers = await listOffersByWork(token, id);

      if (offers.data) {
        offers = offers.data.filter((offer) => offer.externalId === id);
      }

      if (offers.length > 0) {
        await virtualBulk(
          token,
          offers.map((offer) => ({
            id: offer.id,
            data: { ...offer, sold: false, price, currency },
          })),
          updateOffer
        );

        for (const offer of offers) {
          const sale = await getSale(token, offer.saleId);

          if (sale.data) {
            await updateSale(token, {
              id: sale.data.id,
              data: {
                ...sale.data,
                startDate: formatISO(new Date(), { representation: "date" }),
                endDate: null,
                pricesAvailable: false,
              },
            });
          }
        }
      } else {
        const existingSale = await listSales(token, {
          source: "arthur",
          externalId: id,
          limit: 1,
        });

        const sale =
          existingSale.data && existingSale.data.length > 0
            ? { data: existingSale.data[0] }
            : await createSale(token, {
                id: uuidv4(),
                source: "arthur",
                externalId: id,
                saleType: "online",
                startDate: formatISO(new Date(), { representation: "date" }),
                title: `Arthur work for sale: ${work.data.title}`,
              });

        const offer = await createOffer(token, {
          id: uuidv4(),
          saleId: sale.data.id,
          externalId: id,
          offerType: "work",
          currency,
          price,
        });

        await linkOfferWork(token, { offerId: offer.data.id, workId: id });
      }
    }
  }

  return { data: null };
};

const workEndSale = async (token, { id, sold, price, currency }) => {
  const work = await getWork(token, id);

  if (work.data) {
    const offers = await listOffersByWork(token, id);

    if (offers.data) {
      const auctionOffers = offers.data.filter(
        (offer) => offer.externalId !== id
      );
      const nonAuctionOffers = offers.data.filter(
        (offer) => offer.externalId === id
      );

      if (nonAuctionOffers.length > 0) {
        await virtualBulk(
          token,
          nonAuctionOffers.map((offer) => ({
            id: offer.id,
            data: {
              ...offer,
              price: sold ? price : offer.price,
              currency: sold ? currency : offer.currency,
              sold,
            },
          })),
          updateOffer
        );

        for (const offer of nonAuctionOffers) {
          const sale = await getSale(token, offer.saleId);

          if (sale.data) {
            await updateSale(token, {
              id: sale.data.id,
              data: {
                ...sale.data,
                endDate: formatISO(new Date(), { representation: "date" }),
                pricesAvailable: true,
              },
            });
          }
        }
      } else if (auctionOffers.length > 0) {
        await virtualBulk(
          token,
          auctionOffers.map((offer) => ({
            id: offer.id,
            data: {
              ...offer,
              price: sold ? price : offer.price,
              currency: sold ? currency : offer.currency,
              sold,
            },
          })),
          updateOffer
        );
      }
    }

    await updateWork(token, {
      id,
      data: {
        ...work.data,
        for_sale: false,
        lastPrice: sold ? price : work.data.lastPrice,
        currency: sold ? currency : work.data.currency,
      },
    });
  }

  return { data: null };
};

const mintToken = async (token, { nftContractAddress, physicalTokenId }) =>
  await request(token, `nft_contract_address/${nftContractAddress}/mint`, {
    method: "post",
    params: { physical_token_id: physicalTokenId },
  });

export {
  listArtists,
  listArtistsByEvent,
  listArtistsByWork,
  getArtist,
  getArtists,
  createArtist,
  updateArtist,
  deleteArtist,
  listEvents,
  listEventsByArtist,
  listEventsByVenue,
  listEventsByWork,
  getEvent,
  getEvents,
  createEvent,
  updateEvent,
  deleteEvent,
  linkEventArtist,
  linkEventVenue,
  linkEventWork,
  unlinkEventArtist,
  unlinkEventVenue,
  unlinkEventWork,
  getImage,
  createImage,
  updateImage,
  deleteImage,
  getVideo,
  createVideo,
  updateVideo,
  deleteVideo,
  listVenues,
  listChildVenues,
  getVenue,
  getVenues,
  createVenue,
  updateVenue,
  deleteVenue,
  linkChildVenue,
  unlinkChildVenue,
  listWorks,
  listWorksByArtist,
  listWorksByEvent,
  getWork,
  getWorks,
  createWork,
  updateWork,
  deleteWork,
  linkWorkArtist,
  unlinkWorkArtist,
  unlinkWorkImage,
  unlinkWorkVideo,
  countByEvent,
  mergeArtist,
  mergeEvent,
  mergeVenue,
  listPopularLabels,
  syncEventArtistsFromWorks,
  workBeginSale,
  workEndSale,
  mintToken,
};
