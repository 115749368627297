import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import toast from "../lib/toast";
import { DivideCircle } from "react-feather";
import { useRequest } from "../hooks/useRequest";

function UnlinkModal(props) {
  const request = useRequest(props.request);

  const handleUnlink = () => {
    request.execute(props.params, () => {
      toast(props.toastMessage);
      props.onClose();
      props.onDelete();
    });
  };

  const loading = request.loading;
  const error = request.error;

  return (
    <Modal
      title={props.title}
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          {props.message}
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            icon={DivideCircle}
            className="btn btn-secondary"
            onClick={handleUnlink}
          >
            Unlink
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default UnlinkModal;
