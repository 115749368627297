import IconButton from "../../components/IconButton";
import OfferForm from "../../forms/OfferForm";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Link } from "react-router-dom";
import { Save } from "react-feather";
import { createOffer, getSale } from "../../clients/finance";
import { useParams, useHistory } from "react-router-dom";
import { useAutoRequest, useRequest } from "../../hooks/useRequest";

function NewOffer() {
  let { sale_id: saleId } = useParams();
  const getSaleRequest = useAutoRequest(getSale, saleId);
  const createOfferRequest = useRequest(createOffer);
  let history = useHistory();

  const sale = getSaleRequest.response.data || {};

  return (
    <OfferForm
      onSubmit={(values) => {
        createOfferRequest.execute({ ...values, saleId }, (response) => {
          history.push(`/sales/${saleId}/offers/${response.data.id}`);
          toast("The offer was created");
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[
              <Link to={`/sales/${saleId}`}>{`${
                sale.title || sale.externalId || ""
              }`}</Link>,
              "New offer",
            ]}
            actions={
              <IconButton
                type="submit"
                disabled={createOfferRequest.loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer
              loading={createOfferRequest.loading}
              error={createOfferRequest.error}
            >
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </OfferForm>
  );
}

export default NewOffer;
