import ErrorMessage from "./ErrorMessage";
import LoadIndicator from "./LoadIndicator";

function RequestContainer(props) {
  return (
    <>
      {props.error && <ErrorMessage error={props.error} />}
      <LoadIndicator
        loading={props.loading}
        fullscreen={props.fullscreen}
        minHeight={props.minHeight}
        spinnerSize={props.spinnerSize}
      >
        {props.children}
      </LoadIndicator>
    </>
  );
}

export default RequestContainer;
