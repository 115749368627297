import ArrayPresenter from "./ArrayPresenter";
import ColumnRow from "./ColumnRow";
import ExternalLink from "./ExternalLink";
import Image from "./Image";
import ListGroup from "./ListGroup";
import ReactJson from "react-json-view";
import ZonedDateTime from "./ZonedDateTime";
import { CheckSquare, Square } from "react-feather";
import { formatDate } from "../lib/date";
import { formatNumber } from "../lib/number";
import { formatRange } from "../lib/range";
import {
  isObject,
  isNumber,
  isArray,
  isEmpty,
  isNil,
  has,
  compact,
  values,
} from "lodash";

const formatItem = (item) => {
  if (isObject(item)) {
    if (isEmpty(compact(values(item))) && !has(item, "bool")) {
      return null;
    }

    if (has(item, "url")) {
      return <ExternalLink href={item.url} />;
    } else if (has(item, "email")) {
      return (
        <ExternalLink href={`mailto:${item.email}`} caption={item.email} />
      );
    } else if (has(item, "date")) {
      return <ZonedDateTime date={item.date} />;
    } else if (has(item, "dates")) {
      if (isNil(item.dates) || compact(item.dates).length === 0) {
        return null;
      }

      return formatDate(...item.dates);
    } else if (has(item, "bool")) {
      return item.bool ? (
        <span className="text-success">
          <CheckSquare size={20} />
        </span>
      ) : (
        <span className="text-muted">
          <Square size={20} />
        </span>
      );
    } else if (has(item, "array")) {
      if (isNil(item.array) || compact(item.array).length === 0) {
        return null;
      }

      return <ArrayPresenter values={item.array} />;
    } else if (has(item, "currency")) {
      if (isArray(item.value)) {
        return item.value.every(isNil)
          ? null
          : `${item.currency || ""} ${formatRange(
              ...item.value.map(formatNumber)
            )}`;
      } else {
        return isNil(item.value)
          ? null
          : `${item.currency || ""} ${formatNumber(item.value)}`;
      }
    } else if (has(item, "images")) {
      if (item.images && item.images.length > 0) {
        return item.images.map((image) => (
          <Image
            src={image.url}
            alt=""
            style={{
              maxWidth: "6rem",
              maxHeight: "6rem",
            }}
            link={true}
          />
        ));
      } else {
        return null;
      }
    } else if (has(item, "image")) {
      if (item.image) {
        return (
          <Image
            src={item.image}
            alt=""
            style={{
              maxWidth: "6rem",
              maxHeight: "6rem",
            }}
            link={true}
          />
        );
      } else {
        return null;
      }
    } else if (has(item, "json")) {
      if (item.json) {
        return (
          <ReactJson
            name={false}
            src={item.json}
            collapseStringsAfterLength={20}
            groupArraysAfterLength={10}
            displayObjectSize={false}
            displayDataTypes={false}
            displayArrayKey={false}
            collapsed={true}
            sortKeys={true}
            enableClipboard={false}
          />
        );
      } else {
        return null;
      }
    } else {
      return item;
    }
  } else {
    return isNumber(item) ? formatNumber(item) : item;
  }
};

function ObjectPropertyList(props) {
  return (
    <ListGroup
      className="mb-5"
      items={props.items.map((item) => {
        const formatted = formatItem(item[1]);

        if (isNil(formatted)) {
          return null;
        }

        return (
          <ColumnRow
            columns={[<strong>{item[0]}</strong>, formatted]}
            sizes={[2]}
          />
        );
      })}
    />
  );
}

export default ObjectPropertyList;
