import Fuse from "fuse.js";
import LinkModal from "../components/LinkModal";
import { flatten, partial, capitalize } from "lodash";
import {
  getOffer,
  listSalesByEvent,
  listOffersBySale,
} from "../clients/finance";
import { listEventsByWork } from "../clients/art";
import { virtualBulk } from "../clients/common";

const search = async (workId, token, query) => {
  const events = await listEventsByWork(token, workId);

  const sales = flatten(
    (
      await virtualBulk(
        token,
        events.data.map((event) => event.id),
        listSalesByEvent
      )
    ).data
  );

  const offers = flatten(
    (
      await virtualBulk(
        token,
        sales.map((sale) => sale.id),
        listOffersBySale
      )
    ).data
  );

  const fuse = new Fuse(offers, { keys: ["sequenceNumber"] });

  return {
    data: fuse.search(query).map((result) => ({
      id: result.item.id,
      name: `${capitalize(result.item.offerType)} ${
        result.item.sequenceNumber
      }`,
      resultType: "offer",
    })),
  };
};

function LinkOfferModal(props) {
  return (
    <LinkModal
      linkRequest={props.linkRequest}
      params={props.params}
      onClose={props.onClose}
      onLink={props.onLink}
      placeholder="Search offers&hellip;"
      keyName="offerId"
      nameAttribute="sequenceNumber"
      getRequest={getOffer}
      searchRequest={partial(search, props.workId)}
      resultType="offer"
      title="Link offer"
      toastMessage="The offer was linked"
    />
  );
}

export default LinkOfferModal;
