import AssignUserModal from "../modals/AssignUserModal";
import IconButton from "../components/IconButton";
import IconLinkButton from "../components/IconLinkButton";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import PaginatedContainer from "../components/PaginatedContainer";
import RequestContainer from "../components/RequestContainer";
import ResolveTaskModal from "../modals/ResolveTaskModal";
import TruncatedText from "../components/TruncatedText";
import { Link } from "react-router-dom";
import { UserPlus, List, CheckCircle } from "react-feather";
import { assignGroup, resolveGroup } from "../clients/quality";
import { get, omit } from "lodash";
import { getSale } from "../clients/finance";
import { getUid } from "../lib/firebase";
import { useAutoRequest, useRequest } from "../hooks/useRequest";
import { useModal } from "../hooks/useModal";
import { useOnScreen } from "../hooks/useOnScreen";
import { useState, useRef } from "react";

function SaleLink(props) {
  const [sale, setSale] = useState(null);
  const ref = useRef(null);

  const request = useRequest(getSale, props.id);

  useOnScreen(ref, () =>
    request.execute(null, (response) => setSale(response.data))
  );

  return (
    <Link to={`/sales/${props.id}`} ref={ref}>
      {sale ? (
        sale.title ? (
          <>
            <TruncatedText max={40}>{sale.title}</TruncatedText> (
            {sale.sellerName})
          </>
        ) : (
          <>
            {sale.externalId} ({sale.sellerName})
          </>
        )
      ) : null}
    </Link>
  );
}

SaleLink.isLink = true;

function QaTasksTable(props) {
  const [params, setParams] = useState({
    group_type: "",
    assignee: props.assigned ? getUid() : null,
    ...props.params,
  });
  const request = useAutoRequest(props.request, params);
  let [showAssignUserModal, assignUserModal] = useModal(AssignUserModal, {
    request: assignGroup,
    onAssign: props.onRefresh,
  });
  let [showResolveTaskModal, resolveTaskModal] = useModal(ResolveTaskModal, {
    request: resolveGroup,
    onResolve: props.onRefresh,
  });

  const groups = request.response.data || [];
  const responseParams = get(request.response, "config.params") || params;

  const handleParamsChange = (newParams) => {
    const allParams = { ...params, ...newParams };

    if (!("skip" in newParams)) {
      allParams.skip = 0;
    }

    setParams(allParams);

    if (props.onParamsChange) {
      props.onParamsChange(omit(allParams, "assignee"));
    }
  };

  const formatGroupType = (groupType) => {
    switch (groupType) {
      case "sales":
        return "Sale";
      default:
        return "";
    }
  };

  const formatObject = (group) => {
    switch (group.group_type) {
      case "sales":
        return <SaleLink id={group.group_id} />;
      default:
        return "";
    }
  };

  const formatTasks = (group) => {
    const totalCount = group.tasks.length;
    const closedCount = group.tasks.filter((task) => task.status > 1).length;
    const openCount = totalCount - closedCount;

    if (totalCount === openCount) {
      return totalCount;
    } else {
      return (
        <>
          <span>{openCount}</span>
          <span className="text-muted ms-2">({closedCount} closed)</span>
        </>
      );
    }
  };

  const table = (
    <ObjectTable
      columns={[
        {
          title: "Type",
          sortable: false,
          filterable: true,
          currentFilter: params.group_type,
          filterOptions: ["sales"],
          onFilter: (groupType) =>
            handleParamsChange({ group_type: groupType }),
        },
        "",
        "Tasks",
      ]}
    >
      {groups.map((group) => (
        <ObjectTableRow
          actionAuthorizerRole={["admin", "editor"]}
          viewPath={`/qa_tasks/${group.id}`}
          key={group.id}
          columns={[
            formatGroupType(group.group_type),
            formatObject(group),
            formatTasks(group),
          ]}
          extraActions={() => (
            <>
              {props.assigned ? (
                <IconButton
                  onClick={() => showResolveTaskModal({ id: group.id })}
                  icon={CheckCircle}
                  className="btn-sm btn-outline-secondary me-2"
                >
                  Resolve
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => showAssignUserModal({ id: group.id })}
                  icon={UserPlus}
                  className="btn-sm btn-outline-primary me-2"
                >
                  Assign
                </IconButton>
              )}
              <IconLinkButton
                to={`/qa_tasks/${group.id}`}
                icon={List}
                className="btn-sm btn-outline-secondary"
              >
                Tasks
              </IconLinkButton>
            </>
          )}
        />
      ))}
    </ObjectTable>
  );

  return (
    <>
      <RequestContainer loading={request.loading}>
        {
          <PaginatedContainer
            enabled={!request.loading}
            count={groups.length}
            params={responseParams}
            onNavigate={handleParamsChange}
          >
            {table}
          </PaginatedContainer>
        }
      </RequestContainer>
      {assignUserModal}
      {resolveTaskModal}
    </>
  );
}

export default QaTasksTable;
