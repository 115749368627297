import CrateForm from "../../forms/CrateForm";
import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { createCrate } from "../../clients/collection";
import { getUid } from "../../lib/firebase";
import { merge } from "lodash";
import { useHistory } from "react-router-dom";
import { useRequest } from "../../hooks/useRequest";

function NewCrate() {
  const createCrateRequest = useRequest(createCrate);
  let history = useHistory();

  const loading = createCrateRequest.loading;
  const error = createCrateRequest.error;

  return (
    <>
      <CrateForm
        onSubmit={(values) => {
          createCrateRequest.execute(
            merge(
              {
                type: "crate",
                creator: getUid(),
                artwork_populations: { works: [] },
              },
              values
            ),
            (response) => {
              history.push(`/crates/${response.data.id}`);
              toast("The crate was created");
            }
          );
        }}
      >
        {(fields) => (
          <>
            <ViewHeader
              breadcrumbs={["New crate"]}
              actions={
                <IconButton
                  type="submit"
                  disabled={loading}
                  icon={Save}
                  className="btn btn-success"
                >
                  Save
                </IconButton>
              }
            />
            <ViewContents>
              <RequestContainer loading={loading} error={error}>
                {fields}
              </RequestContainer>
            </ViewContents>
          </>
        )}
      </CrateForm>
    </>
  );
}

export default NewCrate;
