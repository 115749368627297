import axios from "axios";
import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import toast from "../lib/toast";
import { Save } from "react-feather";
import { createJob, uploadJob } from "../clients/import";
import { useRequest } from "../hooks/useRequest";
import { useState, useRef } from "react";

const templates = {
  bonhams: '{\n  "externalId": ""\n}',
  christies: '{\n  "url": ""\n}',
  invaluable: '{\n  "externalId": ""\n}',
  phillips: '{\n  "externalId": ""\n}',
  sothebys: '{\n  "externalId": ""\n}',
};

function CreateJobModal(props) {
  const fileField = useRef(null);
  const [jobType, setJobType] = useState("christies");
  const [dataType, setDataType] = useState("json");
  const [description, setDescription] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [data, setData] = useState(templates.christies);
  const createJobRequest = useRequest(createJob);
  const uploadJobRequest = useRequest(uploadJob);

  const handleSave = () => {
    if (dataType === "upload") {
      if (fileField.current.files.length === 0) {
        return;
      }

      uploadJobRequest.execute(fileField.current.files[0].type, (response) => {
        setIsUploading(true);

        axios(response.data.url, {
          method: "put",
          data: fileField.current.files[0],
          headers: {
            "Content-Type": fileField.current.files[0].type,
          },
        })
          .then(() => {
            createJobRequest.execute(
              { jobType, description, data: { upload: response.data.key } },
              () => {
                toast("Job created");
                props.onSave();
                props.onClose();
              }
            );
          })
          .catch((error) => {
            alert(error);
          })
          .then(() => setIsUploading(false));
      });
    } else {
      createJobRequest.execute(
        { jobType, description, data: JSON.parse(data) },
        () => {
          toast("Job created");
          props.onSave();
          props.onClose();
        }
      );
    }
  };

  const handleChangeJobType = (event) => {
    if (data === templates[jobType]) {
      setData(templates[event.target.value]);
    }
    setJobType(event.target.value);
  };

  const loading =
    createJobRequest.loading || uploadJobRequest.loading || isUploading;
  const error = createJobRequest.error || uploadJobRequest.error;

  return (
    <Modal
      title="Create job"
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          <div className="form-floating mb-3">
            <textarea
              id="description"
              className="form-control"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              style={{ height: "6rem" }}
            ></textarea>
            <label htmlFor="description">Description</label>
          </div>

          <div className="form-floating mb-3">
            <select
              id="job_type"
              className="form-select"
              value={jobType}
              onChange={handleChangeJobType}
            >
              <option value="bonhams">bonhams (Bonhams sale)</option>
              <option value="christies">christies (Christie's sale)</option>
              <option value="invaluable">invaluable (Invaluable sale)</option>
              <option value="phillips">phillips (Phillips sale)</option>
              <option value="sothebys">sothebys (Sotheby's sale)</option>
            </select>
            <label htmlFor="job_type">Job type</label>
          </div>

          <div className="form-floating mb-3">
            <select
              id="data_type"
              className="form-select"
              value={dataType}
              onChange={(event) => setDataType(event.target.value)}
            >
              <option value="json">Enter JSON</option>
              <option value="upload">Upload ZIP archive or JSON file</option>
            </select>
            <label htmlFor="data_type">Data type</label>
          </div>

          {dataType === "json" ? (
            <div className="form-floating">
              <textarea
                id="data"
                className="form-control"
                value={data}
                onChange={(event) => setData(event.target.value)}
                style={{ height: "12rem" }}
              ></textarea>
              <label htmlFor="data">Data</label>
            </div>
          ) : null}

          {dataType === "upload" ? (
            <input
              type="file"
              id="file"
              className="form-control"
              ref={fileField}
            />
          ) : null}
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            onClick={handleSave}
            icon={Save}
            className="btn btn-success"
          >
            Save
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default CreateJobModal;
