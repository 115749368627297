import IconButton from "../../components/IconButton";
import IconLinkButton from "../../components/IconLinkButton";
import Image from "../../components/Image";
import ObjectTable from "../../components/ObjectTable";
import ObjectTableRow from "../../components/ObjectTableRow";
import PublishModal from "../../modals/PublishModal";
import RemoveCrateFromQueueModal from "../../modals/RemoveCrateFromQueueModal";
import RequestContainer from "../../components/RequestContainer";
import UserName from "../../components/UserName";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import ZonedDateTime from "../../components/ZonedDateTime";
import { ArrowLeft, ArrowUpCircle, ArrowDownCircle, Send } from "react-feather";
import { getQueue, listCrates, reorderQueue } from "../../clients/collection";
import { useAutoRequest, useRequest } from "../../hooks/useRequest";
import { useModal } from "../../hooks/useModal";

const publishStatusToString = (status) => {
  switch (status) {
    case 0:
      return "Not ready";
    case 1:
      return "Ready";
    case 2:
      return "Current";
    case 3:
      return "Published";
    default:
      return "Unknown";
  }
};

function Queue() {
  const listCratesRequest = useAutoRequest(listCrates);
  const getQueueRequest = useAutoRequest(getQueue);
  const reorderQueueRequest = useRequest(
    reorderQueue,
    null,
    getQueueRequest.execute
  );

  const crates = listCratesRequest.response.data || [];
  const queue = getQueueRequest.response.data || { objects: [] };

  const queuedCrates = queue.objects
    .map((crateId) => crates.find((crate) => crate.id === crateId))
    .filter((crate) => crate);
  const currentCrate =
    queue.current_object &&
    crates.find((crate) => crate.id === queue.current_object);

  const moveUp = (crateId) => {
    const order = queuedCrates.map((crate) => crate.id);
    const idx = order.indexOf(crateId);
    [order[idx - 1], order[idx]] = [order[idx], order[idx - 1]];
    reorderQueueRequest.execute(order);
  };
  const moveDown = (crateId) => {
    const order = queuedCrates.map((crate) => crate.id);
    const idx = order.indexOf(crateId);
    [order[idx], order[idx + 1]] = [order[idx + 1], order[idx]];
    reorderQueueRequest.execute(order);
  };
  let [showPublishModal, publishModal] = useModal(PublishModal, {
    onPublish: () => getQueueRequest.execute(),
  });

  const loading =
    listCratesRequest.loading ||
    getQueueRequest.loading ||
    reorderQueueRequest.loading;
  const error =
    listCratesRequest.error ||
    getQueueRequest.error ||
    reorderQueueRequest.error;

  return (
    <>
      <ViewHeader
        breadcrumbs={["Queue"]}
        actions={
          <IconLinkButton
            to="/crates"
            icon={ArrowLeft}
            className="btn btn-secondary"
          >
            Back
          </IconLinkButton>
        }
      />
      <ViewContents>
        <RequestContainer loading={loading} error={error}>
          <ObjectTable
            columns={[
              "",
              "Description",
              "Status",
              "Published at",
              "Created at",
              "Creator",
            ]}
          >
            {currentCrate && (
              <ObjectTableRow
                viewPath={`/crates/${currentCrate.id}`}
                key={currentCrate.id}
                rowProps={{ className: "table-primary" }}
                columns={[
                  currentCrate.thumbnail ? (
                    <div className="text-center" style={{ width: "7rem" }}>
                      <Image
                        src={currentCrate.thumbnail}
                        alt=""
                        style={{
                          maxWidth: "6rem",
                          maxHeight: "6rem",
                        }}
                      />
                    </div>
                  ) : null,
                  currentCrate.flavor,
                  publishStatusToString(currentCrate.publish_status),
                  <ZonedDateTime date={currentCrate.publish_time} />,
                  <ZonedDateTime date={currentCrate.created_at} />,
                  <UserName id={currentCrate.creator} />,
                ]}
              />
            )}
            {queuedCrates.map((crate, index) => (
              <ObjectTableRow
                viewPath={`/crates/${crate.id}`}
                deleteModal={RemoveCrateFromQueueModal}
                deleteModalParams={{
                  id: crate.id,
                  onDelete: () => getQueueRequest.execute(),
                }}
                clipboardValue={crate.id}
                key={crate.id}
                columns={[
                  crate.thumbnail ? (
                    <div className="text-center" style={{ width: "7rem" }}>
                      <Image
                        src={crate.thumbnail}
                        alt=""
                        style={{
                          maxWidth: "6rem",
                          maxHeight: "6rem",
                        }}
                      />
                    </div>
                  ) : null,
                  crate.flavor,
                  publishStatusToString(crate.publish_status),
                  <ZonedDateTime date={crate.publish_time} />,
                  <ZonedDateTime date={crate.created_at} />,
                  <UserName id={crate.creator} />,
                ]}
                extraActions={() => (
                  <>
                    <div className="btn-group me-2">
                      <IconButton
                        onClick={() => moveUp(crate.id)}
                        icon={ArrowUpCircle}
                        className="btn-sm btn-outline-secondary"
                        disabled={index === 0}
                      />
                      <IconButton
                        onClick={() => moveDown(crate.id)}
                        icon={ArrowDownCircle}
                        className="btn-sm btn-outline-secondary"
                        disabled={index === queuedCrates.length - 1}
                      />
                    </div>
                    <IconButton
                      onClick={() => showPublishModal({ id: crate.id })}
                      icon={Send}
                      className="btn-sm btn-outline-primary me-2"
                    >
                      Publish
                    </IconButton>
                  </>
                )}
              />
            ))}
          </ObjectTable>
        </RequestContainer>
      </ViewContents>

      {publishModal}
    </>
  );
}

export default Queue;
