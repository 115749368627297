import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import ValidatedDateTimeField from "./ValidatedDateTimeField";
import { Field, useField, useFormikContext } from "formik";
import { currencies } from "../lib/currency";
import { omit, isObject } from "lodash";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import "./ValidatedField.css";

function ValidatedField(props) {
  const [id] = useState(uuidv4());
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  if (props.type === "checkbox") {
    return (
      <div className="form-check mb-3">
        <Field
          {...{
            ...omit(props, ["formik", "label"]),
            id,
            placeholder: props.label,
            className: "form-check-input",
          }}
        />
        <label htmlFor={id} className="form-check-label">
          {props.label}
        </label>
      </div>
    );
  } else if (props.type === "datetime-local") {
    return (
      <ValidatedDateTimeField
        {...props}
        id={id}
        setFieldValue={setFieldValue}
        field={field}
      />
    );
  } else if (props.type === "date") {
    return (
      <div className="form-floating mb-3">
        <DatePicker
          {...field}
          {...{
            ...omit(props, ["formik", "label"]),
            id,
            placeholder: props.label,
            className:
              props.formik.touched[props.name] &&
              props.formik.errors[props.name]
                ? `form-control is-invalid`
                : `form-control`,
          }}
          selected={
            (isObject(field.value)
              ? field.value.date && new Date(field.value.date)
              : field.value && new Date(field.value)) || null
          }
          dateFormat="yyyy-MM-dd"
          onChange={(date) => {
            const value = { date: date };
            setFieldValue(field.name, value);
            if (props.onChange) {
              props.onChange({ target: { name: props.name, value } });
            }
          }}
        />
        <label htmlFor={id}>{props.label}</label>
        {props.formik.touched[props.name] &&
          props.formik.errors[props.name] && (
            <div className="invalid-feedback">
              {props.formik.errors[props.name].date}
            </div>
          )}
      </div>
    );
  } else {
    const baseClass = props.as === "select" ? "form-select" : "form-control";
    const children = props.currency ? (
      <>
        <option value=""></option>
        {currencies.map((currency) => (
          <option key={currency} value={currency}>
            {currency}
          </option>
        ))}
      </>
    ) : (
      props.children
    );

    return (
      <div className="form-floating mb-3">
        <Field
          {...{
            ...omit(props, ["formik", "label", "currency"]),
            id,
            children: children,
            placeholder: props.label,
            className:
              props.formik.touched[props.name] &&
              props.formik.errors[props.name]
                ? `${baseClass} is-invalid`
                : `${baseClass}`,
          }}
        />
        <label htmlFor={id}>{props.label}</label>
        {props.formik.touched[props.name] &&
          props.formik.errors[props.name] && (
            <div className="invalid-feedback">
              {props.formik.errors[props.name]}
            </div>
          )}
        {props.hint}
      </div>
    );
  }
}

export default ValidatedField;
