import Fuse from "fuse.js";
import LinkModal from "../components/LinkModal";
import { flatten, partial } from "lodash";
import { getSale } from "../clients/finance";
import { getWork, listWorksByEvent } from "../clients/art";
import { virtualBulk } from "../clients/common";

const search = async (saleId, token, query) => {
  const sale = await getSale(token, saleId);

  const works = flatten(
    (await virtualBulk(token, sale.data.eventIds, listWorksByEvent)).data
  );

  const fuse = new Fuse(works, { keys: ["title"] });

  return {
    data: fuse.search(query).map((result) => ({
      id: result.item.id,
      name: result.item.title,
      img:
        result.item.images &&
        result.item.images.length > 0 &&
        result.item.images[0].url,
      resultType: "work",
    })),
  };
};

function LinkWorkModal(props) {
  return (
    <LinkModal
      linkRequest={props.linkRequest}
      params={props.params}
      onClose={props.onClose}
      onLink={props.onLink}
      placeholder="Search works&hellip;"
      keyName="workId"
      nameAttribute="title"
      getRequest={getWork}
      searchRequest={partial(search, props.saleId)}
      resultType="work"
      title="Link work"
      toastMessage="The work was linked"
    />
  );
}

export default LinkWorkModal;
