import DeleteModal from "../components/DeleteModal";
import { omit } from "lodash";
import { updateWork } from "../clients/art";

const deleteWorkPrice = async (token, { work, title }) =>
  await updateWork(token, {
    id: work.id,
    data: {
      ...work,
      additionalPrices: omit(work.additionalPrices || {}, [title]),
    },
  });

function DeleteWorkPriceModal(props) {
  return (
    <DeleteModal
      request={deleteWorkPrice}
      id={{ work: props.work, title: props.title }}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The price was deleted"
      title="Delete price?"
      confirmText={props.title}
      message={
        <p className="m-0">
          Do you want to delete&nbsp;
          <span className="text-muted">{props.title}</span>
          &nbsp;permanently?
        </p>
      }
    />
  );
}

export default DeleteWorkPriceModal;
