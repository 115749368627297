import EventForm from "../../forms/EventForm";
import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { getEvent, createEvent } from "../../clients/art";
import { omit } from "lodash";
import { useParams, useHistory } from "react-router-dom";
import { useQueryString } from "../../hooks/useQueryString";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function CopyEvent() {
  const programId = useQueryString("programId");
  let { event_id: id } = useParams();
  const getEventRequest = useAutoRequest(getEvent, id);
  const createEventRequest = useRequest(createEvent);
  let history = useHistory();

  const loading = getEventRequest.loading || createEventRequest.loading;
  const error = getEventRequest.error || createEventRequest.error;

  return (
    <EventForm
      initialValues={omit(getEventRequest.response.data, ["id"])}
      enableReinitialize={true}
      onSubmit={(values) => {
        createEventRequest.execute({ ...values, programId }, (response) => {
          if (programId) {
            history.push(`/programs/${programId}`);
          } else {
            history.push(`/events/${response.data.id}`);
          }
          toast("The event was created");
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={["Copy event"]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </EventForm>
  );
}

export default CopyEvent;
