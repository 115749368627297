import * as yup from "yup";
import ColumnRow from "../components/ColumnRow";
import ValidatedField from "../components/ValidatedField";
import { Formik, Form } from "formik";
import { prepareFormValues, returnFormValues } from "../lib/form";

function ProgramForm(props) {
  return (
    <Formik
      initialValues={prepareFormValues(
        {
          flavor: "",
          start_time: "",
          end_time: "",
          latest: false,
          thumbnail: "",
          type: "art_fair",
          subtype: "",
          metadata: {
            venueName: "",
            url: "",
            city: "",
            country: "",
            address: "",
            placesId: "",
            latitude: "",
            longitude: "",
          },
          ...props.initialValues,
        },
        {
          types: {
            start_time: "datetime",
            end_time: "datetime",
          },
        }
      )}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(returnFormValues(values), ...args)
      }
      validationSchema={yup.object({
        type: yup.string().required(),
        subtype: yup.string(),
        flavor: yup.string().required(),
        start_time: yup.object({ datetime: yup.date().required() }).required(),
        end_time: yup.object({ datetime: yup.date().required() }).required(),
        latest: yup.boolean(),
        thumbnail: yup.string().url(),
        metadata: yup.object({
          venueName: yup.string(),
          url: yup.string().url(),
          city: yup.string(),
          country: yup.string(),
          address: yup.string(),
          placesId: yup.string(),
          latitude: yup.string(),
          longitude: yup.string(),
        }),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <h5>Basic Information</h5>
              <ValidatedField
                formik={formik}
                label="Description"
                name="flavor"
                type="text"
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Starts at"
                    name="start_time"
                    type="datetime-local"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Ends at"
                    name="end_time"
                    type="datetime-local"
                  />,
                ]}
              />
              <ValidatedField
                formik={formik}
                label="Thumbnail"
                name="thumbnail"
                type="url"
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Type"
                    name="type"
                    as="select"
                  >
                    <option value="art_fair">art_fair</option>
                    <option value="auction_week">auction_week</option>
                  </ValidatedField>,
                  <ValidatedField
                    formik={formik}
                    label="Subtype"
                    name="subtype"
                    as="select"
                  >
                    <option value=""></option>
                    <option value="featured">featured</option>
                  </ValidatedField>,
                ]}
              />
              <ValidatedField
                formik={formik}
                label={`Latest ${formik.values.type}`}
                name="latest"
                type="checkbox"
              />

              <h5 className="mt-4 pt-3">Location and website</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Venue name"
                    name="metadata.venueName"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="URL"
                    name="metadata.url"
                    type="url"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="City"
                    name="metadata.city"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Country"
                    name="metadata.country"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Address"
                    name="metadata.address"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Google Places ID"
                    name="metadata.placesId"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Latitude"
                    name="metadata.latitude"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Longitude"
                    name="metadata.longitude"
                    type="text"
                  />,
                ]}
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default ProgramForm;
