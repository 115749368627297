import DeleteSaleModal from "../modals/DeleteSaleModal";
import ExternalLink from "../components/ExternalLink";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import PaginatedContainer from "../components/PaginatedContainer";
import RequestContainer from "../components/RequestContainer";
import TruncatedText from "../components/TruncatedText";
import UnlinkSaleModal from "../modals/UnlinkSaleModal";
import ZonedDateTime from "../components/ZonedDateTime";
import { CheckSquare } from "react-feather";
import { formatDate } from "../lib/date";
import { get } from "lodash";
import { useAutoRequest } from "../hooks/useRequest";
import { useState } from "react";

function SalesTable(props) {
  const [params, setParams] = useState({
    source: "",
    saletype: "",
    after: null,
    before: null,
    sortby: "startDate",
    sortorder: "desc",
    ...props.params,
  });
  const request = useAutoRequest(props.request, params);

  const sales = request.response.data || [];
  const responseParams = get(request.response, "config.params") || params;

  const handleParamsChange = (newParams) => {
    const allParams = { ...params, ...newParams };

    if (!("skip" in newParams)) {
      allParams.skip = 0;
    }

    setParams(allParams);

    if (props.onParamsChange) {
      props.onParamsChange(allParams);
    }
  };

  const table = (
    <ObjectTable
      columns={[
        {
          title: "Source",
          filterable: true,
          sortable: true,
          currentSortBy: params.sortby === "source",
          currentSortOrder: params.sortorder,
          currentFilter: params.source,
          filterOptions: [
            "arthur",
            "bonhams",
            "christies",
            "invaluable",
            "sothebys",
            "phillips",
          ],
          onFilter: (value) => handleParamsChange({ source: value }),
          onSort: (order) =>
            handleParamsChange({ sortby: "source", sortorder: order }),
        },
        {
          title: "External ID",
          sortable: true,
          currentSortBy: params.sortby === "externalId",
          currentSortOrder: params.sortorder,
          onSort: (order) =>
            handleParamsChange({ sortby: "externalId", sortorder: order }),
        },
        {
          title: "Dates",
          sortable: true,
          filterable: true,
          currentSortBy: params.sortby === "startDate",
          currentSortOrder: params.sortorder,
          currentFilter: [params.after, params.before],
          filterDates: true,
          onFilter: ([after, before]) =>
            handleParamsChange({ after: after, before: before }),
          onSort: (order) =>
            handleParamsChange({ sortby: "startDate", sortorder: order }),
        },
        "Time",
        {
          title: "Title",
          sortable: true,
          currentSortBy: params.sortby === "title",
          currentSortOrder: params.sortorder,
          onSort: (order) =>
            handleParamsChange({ sortby: "title", sortorder: order }),
        },
        {
          title: "Location",
          sortable: true,
          currentSortBy: params.sortby === "location",
          currentSortOrder: params.sortorder,
          onSort: (order) =>
            handleParamsChange({ sortby: "location", sortorder: order }),
        },
        {
          title: "Type",
          filterable: true,
          sortable: true,
          currentSortBy: params.sortby === "saleType",
          currentSortOrder: params.sortorder,
          currentFilter: params.saletype,
          filterOptions: ["live", "online", "live + online"],
          onFilter: (value) => handleParamsChange({ saletype: value }),
          onSort: (order) =>
            handleParamsChange({ sortby: "saleType", sortorder: order }),
        },
        {
          title: "Offers",
          sortable: true,
          currentSortBy: params.sortby === "offerCount",
          currentSortOrder: params.sortorder,
          onSort: (order) =>
            handleParamsChange({ sortby: "offerCount", sortorder: order }),
        },
        {
          title: "Works",
          sortable: true,
          currentSortBy: params.sortby === "workCount",
          currentSortOrder: params.sortorder,
          onSort: (order) =>
            handleParamsChange({ sortby: "workCount", sortorder: order }),
        },
        "Prices",
        "Bids",
        "Link",
      ]}
    >
      {sales.map((sale) => (
        <ObjectTableRow
          actionAuthorizerRole={["admin", "editor"]}
          deleteAuthorizerRole={props.unlinkRequest ? null : "admin"}
          viewPath={`/sales/${sale.id}`}
          editPath={`/sales/${sale.id}/edit`}
          deleteModal={props.unlinkRequest ? UnlinkSaleModal : DeleteSaleModal}
          deleteModalParams={{
            id: sale.id,
            title: sale.title,
            onDelete: () => request.execute(),
            request: props.unlinkRequest,
            params: { saleId: sale.id, ...props.unlinkParams },
          }}
          clipboardValue={sale.id}
          key={sale.id}
          columns={[
            sale.sellerName ? (
              <>
                {sale.source}{" "}
                <span className="text-muted">({sale.sellerName})</span>
              </>
            ) : (
              sale.source
            ),
            sale.externalDisplayId ? (
              <>
                {sale.externalId}{" "}
                <span className="text-muted">({sale.externalDisplayId})</span>
              </>
            ) : (
              sale.externalId
            ),
            formatDate(sale.startDate, sale.endDate),
            <ZonedDateTime time={sale.startTime} />,
            <TruncatedText max={40}>{sale.title}</TruncatedText>,
            sale.location,
            sale.saleType,
            sale.offerCount,
            sale.workCount,
            sale.pricesAvailable ? (
              <span className="text-success">
                <CheckSquare size={20} />
              </span>
            ) : (
              ""
            ),
            sale.bidsAvailable ? (
              <span className="text-success">
                <CheckSquare size={20} />
              </span>
            ) : (
              ""
            ),
            <ExternalLink href={sale.url} icon={true} />,
          ]}
          extraActions={
            props.extraActions ? () => props.extraActions(sale) : null
          }
        />
      ))}
    </ObjectTable>
  );

  return (
    <RequestContainer loading={request.loading}>
      {props.paginated ? (
        <PaginatedContainer
          enabled={!request.loading}
          count={sales.length}
          params={responseParams}
          onNavigate={handleParamsChange}
        >
          {table}
        </PaginatedContainer>
      ) : (
        table
      )}
    </RequestContainer>
  );
}

export default SalesTable;
