import DatePicker from "react-datepicker";
import Popover from "./Popover";
import axios from "axios";
import config from "../config";
import { getTimeZones } from "@vvo/tzdb";
import { getUnixTime, subSeconds } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";
import { omit, isObject, get, sortBy, uniqBy } from "lodash";
import { useState, useEffect } from "react";

const timeZones = getTimeZones();
let systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
systemTimeZone = systemTimeZone == 'Asia/Calcutta' ? 'Asia/Kolkata' : systemTimeZone;
const systemTimeZoneName = timeZones.find(
  (tz) => tz.name === systemTimeZone
).alternativeName;

function ValidatedDateTimeField(props) {
  const id = props.id;
  const setFieldValue = props.setFieldValue;
  const field = props.field;

  const [timeZone, setTimeZone] = useState("UTC");
  const [timeZoneOverride, setTimeZoneOverride] = useState(null);

  useEffect(() => {
    (async () => {
      const prevOffset = get(field, ["value", "offset"], 0);

      if (timeZoneOverride) {
        const timeZoneOverrideOffset =
          timeZones.find((tz) => tz.alternativeName === timeZoneOverride)
            .currentTimeOffsetInMinutes * 60;

        if (timeZone !== timeZoneOverride) {
          setTimeZone(timeZoneOverride);
        }

        if (timeZoneOverrideOffset !== prevOffset) {
          const newValue = {
            datetime: null,
            ...(isObject(field.value) ? field.value : {}),
            offset: timeZoneOverrideOffset,
          };

          if (field.value.datetime) {
            newValue.datetime = subSeconds(
              field.value.datetime,
              prevOffset - timeZoneOverrideOffset
            );
          }

          setFieldValue(field.name, newValue);
        }
      } else if (props.location) {
        const response = await axios(
          `https://maps.googleapis.com/maps/api/timezone/json`,
          {
            params: {
              location: `${props.location.lat},${props.location.lng}`,
              timestamp: getUnixTime(field.value.datetime || new Date()),
              key: config.maps.apiKey,
            },
          }
        );

        const offset = response.data.dstOffset + response.data.rawOffset;

        if (response.data.timeZoneName !== timeZone) {
          setTimeZone(response.data.timeZoneName);
        }

        if (offset !== prevOffset) {
          const newValue = {
            datetime: null,
            ...(isObject(field.value) ? field.value : {}),
            offset: offset,
          };

          if (field.value.datetime) {
            newValue.datetime = subSeconds(
              field.value.datetime,
              prevOffset - offset
            );
          }

          setFieldValue(field.name, newValue);
        }
      } else {
        if (timeZone !== systemTimeZoneName) {
          setTimeZone(systemTimeZoneName);
        }

        const systemTimeZoneOffset =
          getTimezoneOffset(
            systemTimeZone,
            field.value.datetime || new Date()
          ) * 1e-3;

        if (systemTimeZoneOffset !== prevOffset) {
          const newValue = {
            datetime: null,
            ...(isObject(field.value) ? field.value : {}),
            offset: systemTimeZoneOffset,
          };

          if (field.value.datetime) {
            newValue.datetime = subSeconds(
              field.value.datetime,
              prevOffset - systemTimeZoneOffset
            );
          }

          setFieldValue(field.name, newValue);
        }
      }
    })();
  }, [props.location, timeZoneOverride, get(field, ["value", "datetime"])]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="form-floating mb-3">
      <DatePicker
        {...field}
        {...{
          ...omit(props, ["formik", "label"]),
          id,
          placeholder: props.label,
          className:
            props.formik.touched[props.name] && props.formik.errors[props.name]
              ? `form-control is-invalid`
              : `form-control`,
        }}
        showTimeSelect
        selected={field.value.datetime}
        dateFormat="yyyy-MM-dd HH:mm:ss"
        onChange={(datetime) => {
          const value = { ...field.value, datetime };
          setFieldValue(field.name, value);
          if (props.onChange) {
            props.onChange({ target: { name: props.name, value } });
          }
        }}
      />
      <label htmlFor={id}>{props.label}</label>
      {props.formik.touched[props.name] && props.formik.errors[props.name] && (
        <div className="invalid-feedback">
          {props.formik.errors[props.name].datetime}
        </div>
      )}
      <Popover
        content={
          <select
            className="form-select"
            value={timeZoneOverride}
            onChange={(event) => setTimeZoneOverride(event.target.value)}
          >
            <option value={timeZone}>{timeZone}</option>
            {uniqBy(
              sortBy(timeZones, "alternativeName"),
              "alternativeName"
            ).map((option, index) => (
              <option key={index} value={option.alternativeName}>
                {option.alternativeName}
              </option>
            ))}
          </select>
        }
        width={300}
      >
        {(onClick) => (
          <span className="small text-muted">
            Time zone:{" "}
            <span
              className="text-primary text-decoration-underline"
              style={{ cursor: "pointer" }}
              onClick={onClick}
            >
              {timeZone}
            </span>
          </span>
        )}
      </Popover>
    </div>
  );
}

export default ValidatedDateTimeField;
