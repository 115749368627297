import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import toast from "../lib/toast";
import { RotateCcw } from "react-feather";
import { getJob, createJob } from "../clients/import";
import { useRequest, useAutoRequest } from "../hooks/useRequest";

function UndoJobModal(props) {
  const getJobRequest = useAutoRequest(getJob, props.id);
  const createJobRequest = useRequest(createJob);

  const data = getJobRequest.response.data || {};

  const handleUndo = () => {
    createJobRequest.execute(
      {
        jobType: "undo",
        description: `Undoing job "${data.description}"`,
        data: { id: props.id },
      },
      () => {
        toast("Undoing job");
        props.onUndo();
        props.onClose();
      }
    );
  };

  const loading = getJobRequest.loading || createJobRequest.loading;
  const error = getJobRequest.error || createJobRequest.error;

  return (
    <Modal
      title="Undo import"
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          <p>
            Do you want to undo the import&nbsp;
            <span className="text-muted">&quot;{data.description}&quot;</span>?
          </p>
          <p className="m-0">
            Any events, works, sales or offers that were created by this job
            will be permanently removed.
          </p>
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            onClick={handleUndo}
            icon={RotateCcw}
            className="btn btn-success"
          >
            Undo
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default UndoJobModal;
