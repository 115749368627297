import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import toast from "../lib/toast";
import { XCircle } from "react-feather";
import { useRequest } from "../hooks/useRequest";
import { useState } from "react";

function DeleteModal(props) {
  const request = useRequest(props.request);
  const [isValid, setIsValid] = useState(false);
  const [confirm, setConfirm] = useState("");

  const handleConfirm = (event) => {
    const value = event.target.value;
    setConfirm(value);
    setIsValid(value === props.confirmText);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleDelete(event);
    }
  };

  const handleDelete = () => {
    if (isValid) {
      request.execute(props.id, () => {
        toast(props.toastMessage);
        props.onClose();
        props.onDelete();
      });
    }
  };

  const loading = request.loading;
  const error = request.error;

  return (
    <Modal
      title={props.title}
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          {props.message}
          <p className="mt-3 mb-2 text-danger">
            Type <strong>{props.confirmText}</strong> in the field below to
            proceed:
          </p>
          <input
            type="text"
            className="form-control"
            value={confirm}
            onChange={handleConfirm}
            onKeyDown={handleKeyDown}
          />
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            icon={XCircle}
            className="btn btn-danger"
            onClick={handleDelete}
            disabled={!isValid}
          >
            Delete
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default DeleteModal;
