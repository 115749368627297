import UnlinkModal from "../components/UnlinkModal";

function UnlinkSaleModal(props) {
  return (
    <UnlinkModal
      request={props.request}
      params={props.params}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The sale was unlinked"
      title="Unlink sale?"
      message={
        <p className="m-0">
          Do you want to unlink&nbsp;
          <span className="text-muted">{props.title}</span>? This will not
          delete the sale.
        </p>
      }
    />
  );
}

export default UnlinkSaleModal;
