import * as yup from "yup";
import ColumnRow from "../components/ColumnRow";
import ValidatedField from "../components/ValidatedField";
import { Formik, Form } from "formik";
import { getRole } from "../lib/firebase";
import { prepareFormValues, returnFormValues } from "../lib/form";
import { useEffect, useState } from "react";

function CrateForm(props) {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(
    () =>
      (async () => setIsAdmin(["admin", "editor"].includes(await getRole())))(),
    []
  );

  return (
    <Formik
      initialValues={prepareFormValues({
        flavor: "",
        publish_status: 0,
        latest: false,
        notification_title: "✨ 📦 ✨ New works for you to review on Arthur.",
        notification_text: "Click here to see our newest Crate!",
        notification_image:
          "https://firebasestorage.googleapis.com/v0/b/arthur-53f9b.appspot.com/o/FCMImages%2Fcrate.png?alt=media&token=818b4818-08e3-434b-8eec-fe10f808a979",
        thumbnail: "",
        ...props.initialValues,
      })}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(returnFormValues(values), ...args)
      }
      validationSchema={yup.object({
        flavor: yup.string().required(),
        publish_status: yup.number().integer(),
        latest: yup.boolean(),
        notification_title: yup.string().required(),
        notification_text: yup.string().required(),
        notification_image: yup.string().url().required(),
        thumbnail: yup.string().url(),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <h5>Basic Information</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Description"
                    name="flavor"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Status"
                    name="publish_status"
                    as="select"
                  >
                    <option value="0">Not ready</option>
                    <option value="1">Ready</option>
                    <option value="2">Current</option>
                    <option value="3">Published</option>
                  </ValidatedField>,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Notification title"
                    name="notification_title"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Notification text"
                    name="notification_text"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Notification image"
                    name="notification_image"
                    type="url"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Thumbnail"
                    name="thumbnail"
                    type="url"
                  />,
                ]}
              />
              {isAdmin ? (
                <ValidatedField
                  formik={formik}
                  label="Latest crate"
                  name="latest"
                  type="checkbox"
                />
              ) : null}
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default CrateForm;
