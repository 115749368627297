import ExternalLink from "../components/ExternalLink";
import Image from "../components/Image";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import RequestContainer from "../components/RequestContainer";
import TruncatedText from "../components/TruncatedText";
import { sortBy } from "lodash";

function ArtistVenuesTable(props) {
  const artist = props.parentRequest.response.data || {};

  let venues = [
    ...(artist.collection_venues || []),
    ...(artist.education_venues || []),
  ];

  if (artist.representation_venue) {
    venues.push(artist.representation_venue);
  }

  venues = sortBy(venues, "name");

  return (
    <RequestContainer loading={props.parentRequest.loading}>
      <ObjectTable
        columns={["", "Name", "Subheading", "City", "Country", "Type", "Link"]}
      >
        {venues.map((venue) => (
          <ObjectTableRow
            actionAuthorizerRole={["admin", "editor"]}
            viewPath={`/venues/${venue.id}`}
            editPath={`/venues/${venue.id}/edit`}
            clipboardValue={venue.id}
            key={venue.id}
            columns={[
              venue.imageUrl ? (
                <div className="text-center" style={{ width: "7rem" }}>
                  <Image
                    src={venue.imageUrl}
                    alt=""
                    style={{
                      maxWidth: "6rem",
                      maxHeight: "6rem",
                    }}
                  />
                </div>
              ) : null,
              <TruncatedText max={40}>{venue.name}</TruncatedText>,
              <TruncatedText max={40}>{venue.subheading}</TruncatedText>,
              venue.city,
              venue.country,
              venue.venueType,
              <ExternalLink href={venue.url} icon={true} />,
            ]}
          />
        ))}
      </ObjectTable>
    </RequestContainer>
  );
}

export default ArtistVenuesTable;
