import DeleteEventModal from "../modals/DeleteEventModal";
import ZonedDateTime from "../components/ZonedDateTime";
import Image from "../components/Image";
import ExternalLink from "../components/ExternalLink";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import PaginatedContainer from "../components/PaginatedContainer";
import RequestContainer from "../components/RequestContainer";
import TruncatedText from "../components/TruncatedText";
import UnlinkEventModal from "../modals/UnlinkEventModal";
import { countByEvent } from "../clients/art";
import { get, isNil } from "lodash";
import { useAutoRequest, useRequest } from "../hooks/useRequest";
import { useOnScreen } from "../hooks/useOnScreen";
import { useState, useRef } from "react";

function EventRow(props) {
  const event = props.event;
  const isChildRequest = props.isChildRequest;
  const request = props.request;

  const countRequest = useRequest(countByEvent, event.id);
  const ref = useRef(null);

  useOnScreen(ref, countRequest.execute);

  const data = countRequest.response.data || {};

  return (
    <ObjectTableRow
      actionAuthorizerRole={["admin", "editor"]}
      deleteAuthorizerRole={props.unlinkRequest ? null : "admin"}
      viewPath={`/events/${event.id}`}
      editPath={`/events/${event.id}/edit`}
      deleteModal={props.unlinkRequest ? UnlinkEventModal : DeleteEventModal}
      deleteModalParams={{
        id: event.id,
        name: event.name,
        onDelete: () =>
          isChildRequest ? props.parentRequest.execute() : request.execute(),
        request: props.unlinkRequest,
        params: { eventId: event.id, ...props.unlinkParams },
      }}
      clipboardValue={event.id}
      columns={[
        event.image_url ? (
          <div className="text-center" style={{ width: "7rem" }}>
            <Image
              src={event.image_url}
              alt=""
              style={{
                maxWidth: "6rem",
                maxHeight: "6rem",
              }}
            />
          </div>
        ) : null,
        <TruncatedText max={40}>{event.name}</TruncatedText>,
        event.dates,
        event.eventType,
        event.eventLive,
        <span ref={ref}>{data.works}</span>,
        data.artists,
        <ExternalLink href={event.url} icon={true} />,
        <ZonedDateTime date={event.created_at} />,
      ]}
      extraActions={props.extraActions ? () => props.extraActions(event) : null}
    />
  );
}

function EventsTable(props) {
  const isChildRequest = !isNil(props.parentRequest);

  const [params, setParams] = useState({
    sortby: "name",
    after: null,
    before: null,
    live_online: null,
    ...props.params,
  });

  const request = useAutoRequest(
    props.request,
    isChildRequest
      ? get(props.parentRequest, `response.data.${props.parentAttribute}`)
      : params
  );

  const events = request.response.data || [];
  const missingEvents =
    isChildRequest && !request.loading
      ? (
          get(props.parentRequest, `response.data.${props.parentAttribute}`) ||
          []
        ).filter((eventId) => !events.some((event) => event.id === eventId))
      : [];
  const responseParams = get(request.response, "config.params") || params;

  const handleParamsChange = (newParams) => {
    const allParams = { ...params, ...newParams };

    if (!("skip" in newParams)) {
      allParams.skip = 0;
    }

    setParams(allParams);

    if (props.onParamsChange) {
      props.onParamsChange(allParams);
    }
  };

  const columns = isChildRequest
    ? [
        "",
        "Name",
        "Dates",
        "Type",
        "Live/Online",
        "Works",
        "Artists",
        "Link",
        "Created at",
      ]
    : [
        "",
        {
          title: "Name",
          sortable: true,
          currentSortBy: ["name", "reversename"].includes(params.sortby),
          currentSortOrder: params.sortby === "name" ? "asc" : "desc",
          onSort: (order) =>
            handleParamsChange({
              sortby: order === "desc" ? "reversename" : "name",
            }),
        },
        {
          title: "Dates",
          sortable: true,
          filterable: true,
          currentSortBy: ["date", "reversedate"].includes(params.sortby),
          currentSortOrder: params.sortby === "date" ? "asc" : "desc",
          currentFilter: [params.after, params.before],
          filterDates: true,
          onFilter: ([after, before]) =>
            handleParamsChange({ after: after, before: before }),
          onSort: (order) =>
            handleParamsChange({
              sortby: order === "desc" ? "reversedate" : "date",
            }),
        },
        "Type",
        {
          title: "Live/Online",
          filterable: true,
          currentFilter: params.live_online,
          filterOptions: ["live", "online", "live + online"],
          onFilter: (value) => handleParamsChange({ live_online: value }),
        },
        {
          title: "Works",
          filterable: true,
          currentFilter: params.has_works_only,
          filterBoolean: "Only events with works",
          onFilter: (value) => handleParamsChange({ has_works_only: value }),
        },
        "Artists",
        "Link",
        {
          title: "Created at",
          sortable: true,
          currentSortBy: ["created", "reversecreated"].includes(params.sortby),
          currentSortOrder: params.sortby === "created" ? "asc" : "desc",
          onSort: (order) =>
            handleParamsChange({
              sortby: order === "desc" ? "reversecreated" : "created",
            }),
        },
      ];

  const rows = [
    ...missingEvents.map((eventId) => (
      <ObjectTableRow
        colspan={[9]}
        columns={[`Missing event: ${eventId}`]}
        deleteModal={props.unlinkRequest ? UnlinkEventModal : DeleteEventModal}
        deleteModalParams={{
          id: eventId,
          name: eventId,
          onDelete: () => props.parentRequest.execute(),
          request: props.unlinkRequest,
          params: { eventId: eventId, ...props.unlinkParams },
        }}
      />
    )),
    ...events.map((event) => (
      <EventRow {...props} key={event.id} event={event} />
    )),
  ];

  const table = <ObjectTable columns={columns}>{rows}</ObjectTable>;

  return (
    <RequestContainer loading={request.loading}>
      {props.paginated ? (
        <PaginatedContainer
          enabled={!request.loading}
          count={events.length}
          params={responseParams}
          onNavigate={handleParamsChange}
        >
          {table}
        </PaginatedContainer>
      ) : (
        table
      )}
    </RequestContainer>
  );
}

export default EventsTable;
