import Authorizer from "../../components/Authorizer";
import IconLinkButton from "../../components/IconLinkButton";
import VenuesTable from "../../tables/VenuesTable";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import { PlusCircle } from "react-feather";
import { listVenues } from "../../clients/art";
import { usePersistentState } from "../../hooks/usePersistentState";

function Venues() {
  const [params, setParams] = usePersistentState(
    {
      skip: 0,
      limit: 100,
    },
    "Venues:params"
  );

  return (
    <>
      <ViewHeader
        breadcrumbs={["Venues"]}
        actions={
          <Authorizer role={["admin", "editor"]}>
            <IconLinkButton
              to="/venues/new"
              icon={PlusCircle}
              className="btn btn-success"
            >
              Create venue
            </IconLinkButton>
          </Authorizer>
        }
      />
      <ViewContents>
        <VenuesTable
          params={params}
          onParamsChange={setParams}
          request={listVenues}
          paginated={true}
        />
      </ViewContents>
    </>
  );
}

export default Venues;
