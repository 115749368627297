import CollectionsTable from "../../tables/CollectionsTable";
import IconLinkButton from "../../components/IconLinkButton";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import { PlusCircle } from "react-feather";
import { listCollections } from "../../clients/collection";
import { usePersistentState } from "../../hooks/usePersistentState";

function Collections() {
  const [params, setParams] = usePersistentState(
    {
      type: "arthur_admin",
    },
    "Collections:params"
  );

  return (
    <>
      <ViewHeader
        breadcrumbs={["Collections"]}
        actions={
          <IconLinkButton
            to="/collections/new"
            icon={PlusCircle}
            className="btn btn-success"
          >
            Create collection
          </IconLinkButton>
        }
      />
      <ViewContents>
        <CollectionsTable
          request={listCollections}
          params={params}
          onParamsChange={setParams}
        />
      </ViewContents>
    </>
  );
}

export default Collections;
