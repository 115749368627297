import { memo, useState, useRef } from "react";
import { omit } from "lodash";

const sanitizeUrl = (props) =>
  !props.src ||
  props.src.toLowerCase().startsWith("http://") ||
  props.src.toLowerCase().startsWith("https://")
    ? props
    : { ...props, src: `http://${props.src}` };

const Image = memo((props) => {
  const [isHovered, setIsHovered] = useState(false);
  const imageRef = useRef(null);

  const handleMouseEnter = () => !isHovered && setIsHovered(true);
  const handleMouseLeave = () => isHovered && setIsHovered(false);

  let hoverSize = {
    width: 0,
    height: 0,
  };

  let hoverPos = {
    top: -100000,
    left: -100000,
  };

  if (isHovered && imageRef.current) {
    const windowSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const naturalSize = {
      width: imageRef.current.naturalWidth,
      height: imageRef.current.naturalHeight,
    };

    const rect = imageRef.current.getBoundingClientRect();

    hoverSize = {
      width: rect.width * 4,
      height: rect.height * 4,
    };

    hoverPos = {
      top: rect.height / 2 - hoverSize.height / 2,
      left: rect.width / 2 - hoverSize.width / 2,
    };

    if (
      hoverSize.width > naturalSize.width ||
      hoverSize.height > naturalSize.height
    ) {
      hoverSize = {
        width: naturalSize.width,
        height: naturalSize.height,
      };
    }

    if (hoverSize.height > windowSize.height * 0.9) {
      hoverSize = {
        width: hoverSize.width * (windowSize.height / hoverSize.height) * 0.9,
        height: windowSize.height * 0.9,
      };
    }

    if (hoverSize.width > windowSize.width * 0.9) {
      hoverSize = {
        width: windowSize.width * 0.9,
        height: hoverSize.height * (windowSize.width / hoverSize.width) * 0.9,
      };
    }

    if (rect.top + hoverPos.top + hoverSize.height > windowSize.height) {
      hoverPos.top -=
        rect.top + hoverPos.top + hoverSize.height - windowSize.height;
    }

    if (rect.top + hoverPos.top < 2) {
      hoverPos.top -= rect.top + hoverPos.top - 2;
    }

    if (rect.left + hoverPos.left + hoverSize.width > windowSize.width) {
      hoverPos.left -=
        rect.left + hoverPos.left + hoverSize.width - windowSize.width;
    }

    if (rect.left + hoverPos.left < 2) {
      hoverPos.left -= rect.left + hoverPos.left - 2;
    }
  }

  return (
    <span
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: "relative" }}
    >
      {isHovered ? (
        <img
          src={sanitizeUrl(props).src}
          alt={props.alt}
          className="border-2 shadow-lg rounded-3"
          style={{
            position: "absolute",
            height: `${hoverSize.height}px`,
            width: `${hoverSize.width}px`,
            top: `${hoverPos.top}px`,
            left: `${hoverPos.left}px`,
            zIndex: 1070,
          }}
        />
      ) : null}
      {props.link ? (
        <a href={props.src} target="_blank" rel="noreferrer">
          <img
            {...omit(sanitizeUrl(props), ["link", "alt"])}
            alt={props.alt}
            ref={imageRef}
          />
        </a>
      ) : (
        <img
          {...sanitizeUrl(omit(props, "alt"))}
          alt={props.alt}
          ref={imageRef}
        />
      )}
    </span>
  );
});

export default Image;
