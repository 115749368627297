import UnlinkModal from "../components/UnlinkModal";

function UnlinkWorkModal(props) {
  return (
    <UnlinkModal
      request={props.request}
      params={props.params}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The work was unlinked"
      title="Unlink work?"
      message={
        <p className="m-0">
          Do you want to unlink&nbsp;
          <span className="text-muted">{props.title}</span>? This will not
          delete the work.
        </p>
      }
    />
  );
}

export default UnlinkWorkModal;
