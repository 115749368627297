import UsersTable from "../../tables/UsersTable";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import algoliasearch from "algoliasearch";
import config from "../../config";
import { usePersistentState } from "../../hooks/usePersistentState";

const client = algoliasearch(
  config.algolia.applicationId,
  config.algolia.apiKey
);
const index = client.initIndex("arthur_users");

const listUsers = async (token, params) => {
  let searchParams = {
    offset: params.skip,
    length: params.limit,
  };

  if (params.searchBy) {
    searchParams.restrictSearchableAttributes = [params.searchBy];
  }

  if (params.role) {
    searchParams.filters = `role:${params.role}`;
  }

  const response = await index.search(params.query || "", searchParams);
  return {
    data: response.hits.map((hit) => ({ ...hit, id: hit.objectID })),
    config: { params },
  };
};

function Users() {
  const [params, setParams] = usePersistentState(
    {
      skip: 0,
      limit: 100,
    },
    "Users:params"
  );

  return (
    <>
      <ViewHeader breadcrumbs={["Users"]} />
      <ViewContents>
        <UsersTable
          params={params}
          onParamsChange={setParams}
          request={listUsers}
          paginated={true}
        />
      </ViewContents>
    </>
  );
}

export default Users;
