import axios from "axios";
import DeleteJobModal from "../../modals/DeleteJobModal";
import IconButton from "../../components/IconButton";
import ObjectPropertyList from "../../components/ObjectPropertyList";
import PaginatedContainer from "../../components/PaginatedContainer";
import ReactJson from "react-json-view";
import RequestContainer from "../../components/RequestContainer";
import TabCard from "../../components/TabCard";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import { DownloadCloud, XCircle } from "react-feather";
import { get, sortBy } from "lodash";
import {
  getJob,
  getJobData,
  getJobLogs,
  getJobOutputs,
} from "../../clients/import";
import { useAutoRequest } from "../../hooks/useRequest";
import { useHistory } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import { useParams } from "react-router-dom";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useState, useMemo } from "react";
import { parse } from "papaparse";

const storageRequest = async (token, url) => await axios(url);

function JobDataTab(props) {
  const getJobDataRequest = useAutoRequest(getJobData, props.id);

  const data = getJobDataRequest.response.data || {};

  return (
    <>
      <RequestContainer
        loading={getJobDataRequest.loading}
        error={getJobDataRequest.error}
      >
        <ReactJson
          name={false}
          src={data}
          collapseStringsAfterLength={120}
          groupArraysAfterLength={10}
          displayObjectSize={false}
          displayDataTypes={false}
          displayArrayKey={false}
          sortKeys={true}
        />
      </RequestContainer>
    </>
  );
}

function JobLogsTab(props) {
  const getJobLogsRequest = useAutoRequest(getJobLogs, props.id);

  const logs = getJobLogsRequest.response.data || [];

  const logOrder = ["extract", "transform", "load"];

  return (
    <>
      <RequestContainer
        loading={getJobLogsRequest.loading}
        error={getJobLogsRequest.error}
      >
        {sortBy(logs, (log) => logOrder.indexOf(log.task)).map((log, index) => (
          <div className={`card ${index > 0 ? "mt-3" : ""}`} key={index}>
            <div className="card-header">
              <h6 className="m-0">
                <span className="text-muted">Task:</span> {log.task}
              </h6>
            </div>
            <div className="card-body">
              <pre className="m-0">
                <code>{log.log}</code>
              </pre>
            </div>
          </div>
        ))}
      </RequestContainer>
    </>
  );
}

function JobTransformTab(props) {
  const getJobTransformRequest = useAutoRequest(storageRequest, props.url);

  const transform = getJobTransformRequest.response.data || {};

  return (
    <>
      <RequestContainer
        loading={getJobTransformRequest.loading}
        error={getJobTransformRequest.error}
      >
        <ReactJson
          name={false}
          src={transform}
          collapseStringsAfterLength={120}
          groupArraysAfterLength={10}
          displayObjectSize={false}
          displayDataTypes={false}
          displayArrayKey={false}
          sortKeys={true}
        />
      </RequestContainer>
    </>
  );
}

function JsonOrRaw(props) {
  if (!props.data) {
    return "";
  }

  try {
    const data = JSON.parse(props.data);

    return (
      <ReactJson
        name={false}
        src={data}
        collapseStringsAfterLength={20}
        groupArraysAfterLength={10}
        displayObjectSize={false}
        displayDataTypes={false}
        displayArrayKey={false}
        collapsed={true}
        sortKeys={true}
        enableClipboard={false}
      />
    );
  } catch (e) {
    return props.data;
  }
}

function JobAuditTab(props) {
  const [paginationParams, setPaginationParams] = useState({
    skip: 0,
    limit: 20,
  });

  const getJobAuditRequest = useAutoRequest(storageRequest, props.url);
  const audit = useMemo(
    () => parse(getJobAuditRequest.response.data || "", { header: true }),
    [getJobAuditRequest.response.data]
  );

  const displayedAudits = audit.data.slice(
    paginationParams.skip,
    paginationParams.skip + paginationParams.limit
  );

  return (
    <RequestContainer
      loading={getJobAuditRequest.loading}
      error={getJobAuditRequest.error}
    >
      <PaginatedContainer
        enabled={!getJobAuditRequest.loading}
        count={displayedAudits.length}
        params={paginationParams}
        onNavigate={setPaginationParams}
      >
        <table className="table align-middle" style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th style={{ width: "7rem" }}>Service</th>
              <th>Endpoint</th>
              <th style={{ width: "5rem" }}>Method</th>
              <th>Query</th>
              <th>Body</th>
              <th style={{ width: "5rem" }}>Status</th>
              <th>Response</th>
            </tr>
          </thead>
          <tbody>
            {displayedAudits.map((row, index) => (
              <tr key={paginationParams.skip + index}>
                <td>
                  <pre className="m-0">{row.SERVICE}</pre>
                </td>
                <td>
                  <pre className="m-0">{row.ENDPOINT}</pre>
                </td>
                <td>
                  <pre className="m-0">
                    {row.METHOD && row.METHOD.toUpperCase()}
                  </pre>
                </td>
                <td>
                  {row.QUERY ? (
                    <ReactJson
                      name={false}
                      src={Object.fromEntries(new URLSearchParams(row.QUERY))}
                      collapseStringsAfterLength={20}
                      groupArraysAfterLength={10}
                      displayObjectSize={false}
                      displayDataTypes={false}
                      displayArrayKey={false}
                      collapsed={true}
                      sortKeys={true}
                      enableClipboard={false}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <JsonOrRaw data={row.BODY} />
                </td>
                <td>
                  <code>{row.STATUS}</code>
                </td>
                <td>
                  <JsonOrRaw data={row.RESPONSE} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </PaginatedContainer>
    </RequestContainer>
  );
}

function ViewJob() {
  const [activeTab, setActiveTab] = usePersistentState(
    null,
    "ViewJob:activeTab"
  );
  let { job_id: id } = useParams();
  const getJobRequest = useAutoRequest(getJob, id);
  const getJobOutputsRequest = useAutoRequest(getJobOutputs, id);
  let history = useHistory();

  const job = getJobRequest.response.data || {};
  const jobOutputs = getJobOutputsRequest.response.data || {};

  let [showDeleteJobModal, deleteJobModal] = useModal(DeleteJobModal, {
    id: job.id,
    description: job.description,
    onDelete: () => {
      history.push("/jobs");
    },
  });

  const downloadExtractedData = () => {
    const link = document.createElement("a");
    link.href = jobOutputs["extract.zip"];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const loading = getJobRequest.loading || getJobOutputsRequest.loading;
  const error = getJobRequest.error || getJobOutputsRequest.error;

  let tabs = {
    "Input Data": {
      body: <JobDataTab id={id} />,
    },
    "Pipeline Logs": {
      body: <JobLogsTab id={id} />,
    },
  };

  if (jobOutputs["transform.json"]) {
    tabs = {
      ...tabs,
      "Transformed Data": {
        body: <JobTransformTab id={id} url={jobOutputs["transform.json"]} />,
      },
    };
  }

  if (jobOutputs["audit.csv"]) {
    tabs = {
      ...tabs,
      "Audit Log": {
        body: <JobAuditTab id={id} url={jobOutputs["audit.csv"]} />,
      },
    };
  }

  const actions = jobOutputs["extract.zip"] ? (
    <IconButton
      onClick={downloadExtractedData}
      icon={DownloadCloud}
      className="btn btn-success"
    >
      Download extracted data
    </IconButton>
  ) : null;

  return (
    <>
      <ViewHeader
        breadcrumbs={[`${job.description || job.id || ""}`]}
        actions={
          <IconButton
            icon={XCircle}
            onClick={showDeleteJobModal}
            className="btn btn-secondary"
          >
            Delete
          </IconButton>
        }
      />
      <ViewContents>
        <RequestContainer loading={loading} error={error}>
          <ObjectPropertyList
            items={[
              ["Type", job.jobType],
              ["Description", job.description],
              ["Status", job.status],
              ["Initiator", { email: get(job, "user.email") }],
              ["Date", { date: job.created_at }],
            ]}
          />
        </RequestContainer>

        <TabCard
          activeTab={activeTab}
          onTabChange={setActiveTab}
          tabs={tabs}
          actions={actions}
        />
      </ViewContents>

      {deleteJobModal}
    </>
  );
}

export default ViewJob;
