import UnlinkModal from "../components/UnlinkModal";

function UnlinkVenueModal(props) {
  return (
    <UnlinkModal
      request={props.request}
      params={props.params}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The venue was unlinked"
      title="Unlink venue?"
      message={
        <p className="m-0">
          Do you want to unlink&nbsp;
          <span className="text-muted">{props.name}</span>? This will not delete
          the venue.
        </p>
      }
    />
  );
}

export default UnlinkVenueModal;
