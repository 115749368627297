import CratesTable from "../../tables/CratesTable";
import IconLinkButton from "../../components/IconLinkButton";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import { PlusCircle, List } from "react-feather";
import { listCrates } from "../../clients/collection";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useRefresh } from "../../hooks/useRefresh";

function Crates() {
  const [params, setParams] = usePersistentState({}, "Crates:params");
  const [refreshToken, refresh] = useRefresh();

  return (
    <>
      <ViewHeader
        breadcrumbs={["Crates"]}
        actions={
          <>
            <IconLinkButton
              to="/crates/queue"
              icon={List}
              className="btn btn-secondary me-2"
            >
              Manage queue
            </IconLinkButton>
            <IconLinkButton
              to="/crates/new"
              icon={PlusCircle}
              className="btn btn-success"
            >
              Create crate
            </IconLinkButton>
          </>
        }
      />
      <ViewContents>
        <CratesTable
          request={listCrates}
          params={params}
          onParamsChange={(newParams) => {
            setParams(newParams);
            refresh();
          }}
          key={refreshToken}
        />
      </ViewContents>
    </>
  );
}

export default Crates;
