import AddTagToCollectionModal from "../../modals/AddTagToCollectionModal";
import AddWorkToCollectionModal from "../../modals/AddWorkToCollectionModal";
import DeleteCollectionModal from "../../modals/DeleteCollectionModal";
import IconButton from "../../components/IconButton";
import IconLinkButton from "../../components/IconLinkButton";
import Image from "../../components/Image";
import ImageDimensions from "../../components/ImageDimensions";
import LogModal from "../../modals/LogModal";
import ObjectPropertyList from "../../components/ObjectPropertyList";
import Placeholder from "../../components/Placeholder";
import RequestContainer from "../../components/RequestContainer";
import SelectCollectionImageFromWorksModal from "../../modals/SelectCollectionImageFromWorksModal";
import SelectCollectionWorkImageModal from "../../modals/SelectCollectionWorkImageModal";
import TabCard from "../../components/TabCard";
import UnlinkWorkModal from "../../modals/UnlinkWorkModal";
import UploadImageModal from "../../modals/UploadImageModal";
import UserName from "../../components/UserName";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import {
  Edit,
  PlusCircle,
  XCircle,
  ArrowUpCircle,
  ArrowDownCircle,
  Globe,
  User,
  Image as ImageIcon,
  Clock,
  UploadCloud,
} from "react-feather";
import {
  getCollection,
  updateCollection,
  editComment,
  removeFromCollection,
  swap,
} from "../../clients/collection";
import toast from "../../lib/toast";
import {
  get,
  isEmpty,
  reject,
  sortBy,
  isString,
  sum,
  values,
  uniq,
} from "lodash";
import { getWorks } from "../../clients/art";
import { useHistory, useParams, Link } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";
import { useState } from "react";
import { useThrottle } from "../../hooks/useThrottle";

const publishStatusToString = (status) => {
  switch (status) {
    case 0:
      return "Not ready";
    case 1:
      return "In edit";
    case 2:
      return "Image review";
    case 3:
      return "Info review";
    case 4:
      return "Ready";
    case 5:
      return "Queued";
    case 6:
      return "Current";
    case 7:
      return "Published";
    default:
      return "Unknown";
  }
};

const normalizeWorkId = (workId) => {
  if (isString(workId)) {
    return workId.split("_")[0];
  }
  return workId;
};

const getPrimaryImage = (work) => {
  if (!work.images || work.images.length < 1) {
    return null;
  }
  return sortBy(work.images, "priority")[0].url;
};

function wordCount(text) {
  if (!isString(text)) {
    return 0;
  }
  return text.split(/\s+/).length;
}

function totalWordCount(collection) {
  let count = wordCount(collection.desc);
  if (!collection.works_data) {
    return count;
  }
  count += sum(
    values(collection.works_data).map((work) => wordCount(work.comment))
  );
  return count;
}

function CommentField(props) {
  const editCommentRequest = useRequest(editComment);

  const editCommentRequestExecute = useThrottle(
    async (value, callback) => editCommentRequest.execute(value),
    () => {},
    1000
  );

  const [value, setValue] = useState(
    get(props, ["collection", "works_data", props.work.id, "comment"])
  );

  const handleEdit = (event) => {
    setValue(event.target.value);

    editCommentRequestExecute({
      id: props.collection.id,
      work_id: props.work.id,
      comment: event.target.value,
    });
  };

  return (
    <textarea
      className="form-control"
      placeholder="Comment&hellip;"
      value={value}
      onChange={handleEdit}
      rows={2}
    />
  );
}

function ViewCollection() {
  const [activeTab, setActiveTab] = usePersistentState(
    null,
    "ViewOffer:activeTab"
  );
  let { collection_id: id } = useParams();
  const getWorksRequest = useRequest(getWorks);
  const getCollectionRequest = useAutoRequest(getCollection, id, (response) =>
    getWorksRequest.execute(uniq(response.data.works.map(normalizeWorkId)))
  );
  const updateCollectionRequest = useRequest(updateCollection);
  const collection = getCollectionRequest.response.data || {};
  let history = useHistory();

  let [showDeleteCollectionModal, deleteCollectionModal] = useModal(
    DeleteCollectionModal,
    {
      id: collection.id,
      name: collection.name,
      onDelete: () => {
        history.push("/collections");
      },
    }
  );

  const swapRequest = useRequest(swap);

  const stitchedWorks = (collection.works || []).map((workId) => ({
    id: workId,
    work: (getWorksRequest.response.data || []).find(
      (work) => work.id === normalizeWorkId(workId)
    ),
  }));

  const getImageUrl = (work) =>
    get(collection, ["works_data", work.id, "image_url"]) ||
    getPrimaryImage(work.work);

  const reloadCollection = () => getCollectionRequest.execute();

  let [showAddTagToCollectionModal, addTagToCollectionModal] = useModal(
    AddTagToCollectionModal,
    {
      params: { id },
      onAdd: reloadCollection,
      collection,
    }
  );

  let [showAddWorkToCollectionModal, addWorkToCollectionModal] = useModal(
    AddWorkToCollectionModal,
    {
      params: { id },
      onAdd: reloadCollection,
      collection,
    }
  );

  let [showUnlinkWorkModal, unlinkWorkModal] = useModal(UnlinkWorkModal, {
    onDelete: () => getCollectionRequest.execute(),
    request: removeFromCollection,
  });

  let [showSelectCollectionWorkImageModal, selectCollectionWorkImageModal] =
    useModal(SelectCollectionWorkImageModal, {
      collectionId: id,
      onSelect: reloadCollection,
    });

  let [
    showSelectCollectionImageFromWorksModal,
    selectCollectionImageFromWorksModal,
  ] = useModal(SelectCollectionImageFromWorksModal, {
    collectionId: id,
    onSelect: reloadCollection,
  });

  let [showLogModal, logModal] = useModal(LogModal, {
    filter: {
      type: "collection",
      id,
    },
  });

  let [showUploadImageModal, uploadImageModal] = useModal(UploadImageModal, {
    skipCreateImage: true,
    onSave: (url) =>
      updateCollectionRequest.execute({ id, data: { image_url: url } }, () => {
        toast("The image was uploaded");
        reloadCollection();
      }),
  });

  const moveUp = (workId) => {
    const idx = stitchedWorks.map((work) => work.id).indexOf(workId);

    if (idx > 0) {
      swapRequest.execute(
        {
          id,
          work_id1: workId,
          work_id2: stitchedWorks[idx - 1].id,
        },
        () => getCollectionRequest.execute()
      );
    }
  };

  const moveDown = (workId) => {
    const idx = stitchedWorks.map((work) => work.id).indexOf(workId);

    if (idx >= 0 && idx < stitchedWorks.length - 1) {
      swapRequest.execute(
        {
          id,
          work_id1: workId,
          work_id2: stitchedWorks[idx + 1].id,
        },
        () => getCollectionRequest.execute()
      );
    }
  };

  const removeTag = (tag) => {
    updateCollectionRequest.execute(
      {
        id: id,
        data: {
          ...collection,
          tags: reject(collection.tags, (t) => t === tag),
        },
      },
      () => {
        toast("Tag removed");
        getCollectionRequest.execute();
      }
    );
  };

  const loading =
    getCollectionRequest.loading ||
    getWorksRequest.loading ||
    swapRequest.loading;
  const error =
    getCollectionRequest.error || getWorksRequest.error || swapRequest.error;

  return (
    <>
      <ViewHeader
        breadcrumbs={[`${collection.name || ""}`]}
        actions={
          <>
            <IconButton
              onClick={showSelectCollectionImageFromWorksModal}
              icon={ImageIcon}
              className="btn btn-success me-2"
            >
              Select image from work
            </IconButton>
            <IconButton
              onClick={showUploadImageModal}
              icon={UploadCloud}
              className="btn btn-success me-2"
            >
              Upload image
            </IconButton>
            <IconButton
              onClick={showLogModal}
              icon={Clock}
              className="btn btn-secondary me-2"
            >
              Log
            </IconButton>
            <IconLinkButton
              to={`/collections/${id}/edit`}
              icon={Edit}
              className="btn btn-secondary me-2"
            >
              Edit
            </IconLinkButton>
            <IconButton
              icon={XCircle}
              onClick={showDeleteCollectionModal}
              className="btn btn-secondary"
            >
              Delete
            </IconButton>
          </>
        }
      />
      <ViewContents>
        <RequestContainer loading={loading} error={error}>
          <ObjectPropertyList
            items={[
              ["ID", collection.id],
              ["Name", collection.name],
              ["Description", collection.desc],
              ["Status", publishStatusToString(collection.publish_status)],
              ["Published at", { date: collection.publish_time }],
              [
                "Access",
                <span className="text-primary" title={collection.access}>
                  {collection.access === "public" ? (
                    <Globe size={20} />
                  ) : collection.access === "private" ? (
                    <User size={20} />
                  ) : (
                    collection.access
                  )}
                </span>,
              ],
              ["Byline", collection.byline],
              ["Image", { image: collection.image_url }],
              ["Created at", { date: collection.created_at }],
              ["Updated at", { date: collection.updated_at }],
              ["Owner", <UserName id={collection.owner} />],
              ["Subtype", collection.subtype],
              ["Word count", totalWordCount(collection)],
              ["For sale", { bool: collection.for_sale }],
            ]}
          />

          <TabCard
            activeTab={activeTab}
            onTabChange={setActiveTab}
            tabs={{
              Works: {
                body: (
                  <Placeholder empty={isEmpty(stitchedWorks)}>
                    <table className="table table-hover align-middle">
                      <tbody>
                        {stitchedWorks.map((work, index) =>
                          work.work === undefined ? (
                            <tr key={work.id} className="table-danger">
                              <td colSpan="2">Missing work: {work.id}</td>
                              <td style={{ width: "50%" }}>
                                <CommentField
                                  work={work}
                                  collection={collection}
                                />
                              </td>
                              <td className="text-end text-nowrap">
                                <IconButton
                                  onClick={() =>
                                    showUnlinkWorkModal({
                                      title: work.id,
                                      params: { id, work_id: work.id },
                                    })
                                  }
                                  icon={XCircle}
                                  className="btn-sm btn-outline-secondary"
                                >
                                  Delete
                                </IconButton>
                              </td>
                            </tr>
                          ) : (
                            <tr key={work.id}>
                              <td
                                className="text-center"
                                style={{ width: "7rem" }}
                              >
                                {getImageUrl(work) ? (
                                  <Image
                                    src={getImageUrl(work)}
                                    alt=""
                                    style={{
                                      maxWidth: "6rem",
                                      maxHeight: "6rem",
                                    }}
                                  />
                                ) : null}
                              </td>
                              <td>
                                <p className="m-0 fw-bold">
                                  <Link
                                    to={`/artists/${get(work.work, [
                                      "artists",
                                      0,
                                      "id",
                                    ])}`}
                                    className="link-dark"
                                  >
                                    {get(work.work, ["artists", 0, "name"])}
                                  </Link>
                                </p>
                                <p className="m-0">
                                  <Link
                                    to={`/works/${work.work.id}`}
                                    className="link-secondary"
                                  >
                                    {work.work.title}
                                  </Link>
                                </p>
                                <span className="text-muted">
                                  {work.work.dateText}
                                </span>
                                {getImageUrl(work) ? (
                                  <ImageDimensions src={getImageUrl(work)} />
                                ) : null}
                              </td>
                              <td style={{ width: "50%" }}>
                                <CommentField
                                  work={work}
                                  collection={collection}
                                />
                              </td>
                              <td className="text-end text-nowrap">
                                <div className="btn-group me-2">
                                  <IconButton
                                    onClick={() => moveUp(work.id)}
                                    icon={ArrowUpCircle}
                                    className="btn-sm btn-outline-secondary"
                                    disabled={index === 0}
                                  />
                                  <IconButton
                                    onClick={() => moveDown(work.id)}
                                    icon={ArrowDownCircle}
                                    className="btn-sm btn-outline-secondary"
                                    disabled={
                                      index === stitchedWorks.length - 1
                                    }
                                  />
                                </div>
                                <IconButton
                                  onClick={() =>
                                    showSelectCollectionWorkImageModal({
                                      workId: work.id,
                                      currentImageUrl: getImageUrl(work),
                                    })
                                  }
                                  icon={ImageIcon}
                                  className="btn-sm btn-outline-secondary me-2"
                                />
                                <IconButton
                                  onClick={() =>
                                    showUnlinkWorkModal({
                                      title: work.work.title,
                                      params: { id, work_id: work.id },
                                    })
                                  }
                                  icon={XCircle}
                                  className="btn-sm btn-outline-secondary"
                                >
                                  Delete
                                </IconButton>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </Placeholder>
                ),
                actions: (
                  <IconButton
                    onClick={showAddWorkToCollectionModal}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Add works
                  </IconButton>
                ),
              },
              Tags: {
                body: (
                  <Placeholder empty={isEmpty(collection.tags)}>
                    <table className="table table-hover align-middle">
                      <tbody>
                        {(collection.tags || []).map((tag, index) => (
                          <tr key={index.id}>
                            <td>{tag}</td>
                            <td className="text-end text-nowrap">
                              <IconButton
                                onClick={() => removeTag(tag)}
                                icon={XCircle}
                                className="btn-sm btn-outline-secondary"
                              >
                                Delete
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Placeholder>
                ),
                actions: (
                  <IconButton
                    onClick={showAddTagToCollectionModal}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Add tag
                  </IconButton>
                ),
              },
            }}
          />
        </RequestContainer>
      </ViewContents>

      {addTagToCollectionModal}
      {addWorkToCollectionModal}
      {deleteCollectionModal}
      {logModal}
      {selectCollectionImageFromWorksModal}
      {selectCollectionWorkImageModal}
      {unlinkWorkModal}
      {uploadImageModal}
    </>
  );
}

export default ViewCollection;
