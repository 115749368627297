import { get } from "lodash";
import { getToken } from "../lib/firebase";
import { isNil } from "lodash";
import { useState, useEffect } from "react";

const _useRequest = (
  request,
  autoExecute,
  options = null,
  defaultCallback = null
) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [error, setError] = useState(null);

  const doRequest = (
    force = false,
    optionsOverride = null,
    callback = null,
    errorCallback = null
  ) => {
    if (isNil(request)) {
      return;
    }

    if (!force && !autoExecute) {
      return;
    }

    let cancel = false;

    (async () => {
      try {
        setLoading(true);

        const token = await getToken();

        if (!token) {
          return;
        }

        const response = await request(
          await getToken(),
          isNil(optionsOverride) ? options : optionsOverride
        );

        if (!cancel) {
          setResponse(response);
          setError(null);
        }

        if (callback) {
          callback(response);
        } else if (defaultCallback) {
          defaultCallback(response);
        }
      } catch (error) {
        if (!cancel) {
          setResponse({});
          if (error.request && error.response) {
            setError({
              status: get(error, "response.status"),
              statusText: get(error, "response.statusText"),
              url: get(error, "request.responseURL"),
              message: JSON.stringify(get(error, "response.data.detail")),
            });
          } else {
            setError({ stack: error.stack });
          }
          if (errorCallback) {
            errorCallback(error);
          }
        }
      } finally {
        if (!cancel) {
          setLoading(false);
        }
      }
    })();

    return () => {
      cancel = true;
    };
  };

  useEffect(doRequest, [request, options, autoExecute]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    response,
    loading,
    error,
    execute: (optionsOverride = null, callback = null, errorCallback = null) =>
      doRequest(true, optionsOverride, callback, errorCallback),
  };
};

const useRequest = (request, options = null, defaultCallback = null) =>
  _useRequest(request, false, options, defaultCallback);

const useAutoRequest = (request, options = null, defaultCallback = null) =>
  _useRequest(request, true, options, defaultCallback);

export { useRequest, useAutoRequest };
