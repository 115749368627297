import DeleteModal from "../components/DeleteModal";
import { deleteSale } from "../clients/finance";

function DeleteSaleModal(props) {
  return (
    <DeleteModal
      request={deleteSale}
      id={props.id}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The sale was deleted"
      title="Delete sale?"
      confirmText={props.title}
      message={
        <p className="m-0">
          Do you want to delete&nbsp;
          <span className="text-muted">{props.title}</span>
          &nbsp;permanently?
        </p>
      }
    />
  );
}

export default DeleteSaleModal;
