import Authorizer from "../../components/Authorizer";
import IconButton from "../../components/IconButton";
import IconLinkButton from "../../components/IconLinkButton";
import Image from "../../components/Image";
import ObjectPropertyList from "../../components/ObjectPropertyList";
import PaginatedContainer from "../../components/PaginatedContainer";
import Placeholder from "../../components/Placeholder";
import RequestContainer from "../../components/RequestContainer";
import TabCard from "../../components/TabCard";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import ZonedDateTime from "../../components/ZonedDateTime";
import axios from "axios";
import { Link } from "react-router-dom";
import { Star, Edit2, Lock, Edit } from "react-feather";
import {
  getRecommendedArtists,
  getRecommendedWorks,
  getLikes,
} from "../../clients/collection";
import { getWorks, getArtists } from "../../clients/art";
import { isEmpty, sortBy, get } from "lodash";
import { useAutoRequest, useRequest } from "../../hooks/useRequest";
import { useParams } from "react-router-dom";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useState } from "react";

const getUser = async (token, id) =>
  axios.get(`https://admin.arthuranalytics.com/api/getUser/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

const getUserProfile = async (token, id) =>
  axios.get(`https://admin.arthuranalytics.com/api/getUserProfile/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

const setUserRole = async (token, { id, role }) =>
  axios.post(
    `https://admin.arthuranalytics.com/api/setUserRole/${id}`,
    { role },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

const getPrimaryImage = (work) => {
  if (!work.images || work.images.length < 1) {
    return null;
  }
  return sortBy(work.images, "priority")[0].url;
};

function RecommendedArtists(props) {
  const [params, setParams] = useState({
    user_email: props.email,
    skip: 0,
    limit: 50,
    ...props.params,
  });

  const getArtistsRequest = useRequest(getArtists);
  const getRecommendedArtistsRequest = useAutoRequest(
    getRecommendedArtists,
    params,
    (response) =>
      getArtistsRequest.execute(response.data.map((rec) => rec.artist_id))
  );

  const artists =
    (getRecommendedArtistsRequest.response.data &&
      getArtistsRequest.response.data) ||
    [];

  const responseParams =
    get(getRecommendedArtistsRequest.response, "config.params") || params;

  const loading =
    getRecommendedArtistsRequest.loading || getArtistsRequest.loading;
  const error = getRecommendedArtistsRequest.error || getArtistsRequest.error;

  return (
    <RequestContainer loading={loading} error={error}>
      <PaginatedContainer
        enabled={!loading}
        count={artists.length}
        params={responseParams}
        onNavigate={setParams}
      >
        <Placeholder empty={isEmpty(artists)}>
          <table className="table table-hover align-middle">
            <tbody>
              {artists.map((artist, index) => (
                <tr key={artist.id}>
                  <td className="text-center" style={{ width: "7rem" }}>
                    {artist.artistImage ? (
                      <Image
                        src={artist.artistImage}
                        alt=""
                        style={{
                          maxWidth: "6rem",
                          maxHeight: "6rem",
                        }}
                      />
                    ) : null}
                  </td>
                  <td>
                    <Link to={`/artists/${artist.id}`} className="link-dark">
                      <p className="m-0 fw-bold">{artist.name}</p>
                      <p className="m-0">{artist.nationality}</p>
                      <span className="text-muted">{artist.dates}</span>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Placeholder>
      </PaginatedContainer>
    </RequestContainer>
  );
}

function RecommendedWorks(props) {
  const [params, setParams] = useState({
    user_email: props.email,
    skip: 0,
    limit: 50,
    ...props.params,
  });

  const getWorksRequest = useRequest(getWorks);
  const getRecommendedWorksRequest = useAutoRequest(
    getRecommendedWorks,
    params,
    (response) =>
      getWorksRequest.execute(response.data.map((rec) => rec.work_id))
  );

  const works =
    (getRecommendedWorksRequest.response.data &&
      getWorksRequest.response.data) ||
    [];

  const responseParams =
    get(getRecommendedWorksRequest.response, "config.params") || params;

  const loading = getRecommendedWorksRequest.loading || getWorksRequest.loading;
  const error = getRecommendedWorksRequest.error || getWorksRequest.error;

  return (
    <RequestContainer loading={loading} error={error}>
      <PaginatedContainer
        enabled={!loading}
        count={works.length}
        params={responseParams}
        onNavigate={setParams}
      >
        <Placeholder empty={isEmpty(works)}>
          <table className="table table-hover align-middle">
            <tbody>
              {works.map((work, index) => (
                <tr key={work.id}>
                  <td className="text-center" style={{ width: "7rem" }}>
                    {getPrimaryImage(work) ? (
                      <Image
                        src={getPrimaryImage(work)}
                        alt=""
                        style={{
                          maxWidth: "6rem",
                          maxHeight: "6rem",
                        }}
                      />
                    ) : null}
                  </td>
                  <td>
                    <p className="m-0 fw-bold">
                      <Link
                        to={`/artists/${get(work, ["artists", 0, "id"])}`}
                        className="link-dark"
                      >
                        {get(work, ["artists", 0, "name"])}
                      </Link>
                    </p>
                    <p className="m-0">
                      <Link to={`/works/${work.id}`} className="link-secondary">
                        {work.title}
                      </Link>
                    </p>
                    <span className="text-muted">{work.dateText}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Placeholder>
      </PaginatedContainer>
    </RequestContainer>
  );
}

function Likes(props) {
  const [params, setParams] = useState({
    order: "created_at",
    orde_direction: "desc",
    limit: 50,
    skip: 0,
    user: props.userId,
  });
  const getWorksRequest = useRequest(getWorks);
  const getLikesRequest = useAutoRequest(getLikes, params, (response) =>
    getWorksRequest.execute(response.data.map((rec) => rec.work_id))
  );

  const works = (getLikesRequest.response.data || [])
    .map((rec) => ({
      like: rec,
      work: (getWorksRequest.response.data || []).find(
        (work) => work.id === rec.work_id
      ),
    }))
    .filter(({ work }) => work !== undefined);

  const paginate = ({ skip }) => {
    if (works.length) {
      if (skip < params.skip) {
        setParams({
          ...params,
          skip,
          start_after: null,
          end_before: works[0].like.id,
        });
      } else if (skip > params.skip) {
        setParams({
          ...params,
          skip,
          start_after: works[works.length - 1].like.id,
          end_before: null,
        });
      }
    } else {
      setParams({ ...params, skip: 0 });
    }
  };

  const loading = getLikesRequest.loading || getWorksRequest.loading;
  const error = getLikesRequest.error || getWorksRequest.error;

  return (
    <RequestContainer loading={loading} error={error}>
      <PaginatedContainer
        enabled={!loading}
        count={works.length}
        params={params}
        onNavigate={paginate}
      >
        <Placeholder empty={isEmpty(works)}>
          <table className="table table-hover align-middle">
            <tbody>
              {works.map(({ like, work }, index) => (
                <tr key={like.id}>
                  <td className="text-center" style={{ width: "7rem" }}>
                    {getPrimaryImage(work) ? (
                      <Image
                        src={getPrimaryImage(work)}
                        alt=""
                        style={{
                          maxWidth: "6rem",
                          maxHeight: "6rem",
                        }}
                      />
                    ) : null}
                  </td>
                  <td>
                    <p className="float-end text-muted">
                      <ZonedDateTime date={like.created_at} />
                    </p>
                    <p className="m-0 fw-bold">
                      <Link
                        to={`/artists/${get(work, ["artists", 0, "id"])}`}
                        className="link-dark"
                      >
                        {get(work, ["artists", 0, "name"])}
                      </Link>
                    </p>
                    <p className="m-0">
                      <Link to={`/works/${work.id}`} className="link-secondary">
                        {work.title}
                      </Link>
                    </p>
                    <span className="text-muted">{work.dateText}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Placeholder>
      </PaginatedContainer>
    </RequestContainer>
  );
}

function ViewUser() {
  const [activeTab, setActiveTab] = usePersistentState(
    null,
    "ViewUser:activeTab"
  );
  let { user_id: id } = useParams();
  const getUserRequest = useAutoRequest(getUser, id);
  const getUserProfileRequest = useAutoRequest(getUserProfile, id);
  const setUserRoleRequest = useRequest(setUserRole, null, () =>
    getUserRequest.execute()
  );
  const user = getUserRequest.response.data || {};
  const userProfile = getUserProfileRequest.response.data || {};

  const SetRole = (role) => setUserRoleRequest.execute({ id, role });

  const loading =
    getUserRequest.loading ||
    getUserProfile.loading ||
    setUserRoleRequest.loading;
  const error =
    getUserRequest.error || getUserProfile.error || setUserRoleRequest.error;

  return (
    <>
      <ViewHeader
        breadcrumbs={[`${user.email || ""}`]}
        actions={
          <Authorizer role="admin">
            {!user.role && (
              <>
                <IconButton
                  onClick={() => SetRole("admin")}
                  icon={Star}
                  className="btn btn-success me-2"
                >
                  Grant <strong>admin</strong> role
                </IconButton>
                <IconButton
                  onClick={() => SetRole("editor")}
                  icon={Edit2}
                  className="btn btn-success me-2"
                >
                  Grant <strong>editor</strong> role
                </IconButton>
                <IconButton
                  onClick={() => SetRole("curator")}
                  icon={Edit2}
                  className="btn btn-success me-2"
                >
                  Grant <strong>curator</strong> role
                </IconButton>
              </>
            )}
            {user.role && (
              <IconButton
                onClick={() => SetRole(null)}
                icon={Lock}
                className="btn btn-secondary me-2"
              >
                Revoke <strong>{user.role}</strong> role
              </IconButton>
            )}
            <IconLinkButton
              to={`/users/${id}/edit`}
              icon={Edit}
              className="btn btn-secondary"
            >
              Edit
            </IconLinkButton>
          </Authorizer>
        }
      />
      <ViewContents>
        <RequestContainer loading={loading} error={error}>
          <ObjectPropertyList
            items={[
              ["ID", user.uid],
              ["Photo", { image: user.photoURL }],
              ["Username", userProfile["userName"]],
              ["Display name", userProfile["displayName"]],
              ["Email", user.email],
              ["Role", user.role],
              ["LinkedIn handle", userProfile["linkedinHandle"]],
              ["Facebook handle", userProfile["facebookHandle"]],
              ["Instagram handle", userProfile["instagramHandle"]],
              ["Twitter handle", userProfile["twitterHandle"]],
              ["Website", { url: userProfile["website"] }],
              ["Bio", { url: userProfile["bio"] }],
              ["Verified", { bool: userProfile["verified"] }],
            ]}
          />
        </RequestContainer>

        <TabCard
          activeTab={activeTab}
          onTabChange={setActiveTab}
          tabs={{
            RecommendedArtists: {
              title: "Recommended artists",
              body: user.email ? (
                <RecommendedArtists email={user.email} />
              ) : (
                <Placeholder empty={true} />
              ),
            },
            RecommendedWorks: {
              title: "Recommended works",
              body: user.email ? (
                <RecommendedWorks email={user.email} />
              ) : (
                <Placeholder empty={true} />
              ),
            },
            Likes: {
              body: user.uid ? (
                <Likes userId={user.uid} />
              ) : (
                <Placeholder empty={true} />
              ),
            },
          }}
        />
      </ViewContents>
    </>
  );
}

export default ViewUser;
