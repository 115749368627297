import DeleteModal from "../components/DeleteModal";
import { deleteImage } from "../clients/art";
import { deleteImageFromIndex } from "../clients/import";
import { useRequest } from "../hooks/useRequest";

function DeleteImageModal(props) {
  const deleteImageFromIndexRequest = useRequest(deleteImageFromIndex);

  const onDelete = () => {
    if (props.work) {
      deleteImageFromIndexRequest.execute({
        id: props.id,
        workId: props.work.id,
      });
    }

    props.onDelete();
  };

  return (
    <DeleteModal
      request={deleteImage}
      id={props.id}
      onClose={props.onClose}
      onDelete={onDelete}
      toastMessage="The image was deleted"
      title="Delete image?"
      confirmText="DELETE"
      message={
        <p className="m-0">
          Do you want to delete&nbsp;
          <span className="text-muted">{props.name}</span>
          &nbsp;permanently?
        </p>
      }
    />
  );
}

export default DeleteImageModal;
