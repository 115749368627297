import * as yup from "yup";
import ColumnRow from "../components/ColumnRow";
import ValidatedField from "../components/ValidatedField";
import { Formik, Form } from "formik";
import { prepareFormValues, returnFormValues } from "../lib/form";

function BidForm(props) {
  return (
    <Formik
      initialValues={prepareFormValues(
        {
          bidNumber: "",
          bidType: "online",
          bidderIdentity: "",
          price: "",
          placedAt: "",
          ...props.initialValues,
        },
        {
          types: { placedAt: "datetime" },
        }
      )}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(returnFormValues(values), ...args)
      }
      validationSchema={yup.object({
        bidNumber: yup.number().required().positive().integer(),
        bidType: yup.string(),
        bidderIdentity: yup.string(),
        price: yup.number().required().positive(),
        placedAt: yup.object({ datetime: yup.date().required() }).required(),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <h5>Basic Information</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Type"
                    name="bidType"
                    as="select"
                  >
                    <option value=""></option>
                    <option value="floor">floor</option>
                    <option value="phone">phone</option>
                    <option value="online">online</option>
                    <option value="consignor">consignor</option>
                    <option value="absentee">absentee</option>
                  </ValidatedField>,
                  <ValidatedField
                    formik={formik}
                    label="Bid number"
                    name="bidNumber"
                    type="text"
                  />,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Price"
                    name="price"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Bidder identity"
                    name="bidderIdentity"
                    type="text"
                  />,
                ]}
              />
              <ValidatedField
                formik={formik}
                label="Placed at"
                name="placedAt"
                type="datetime-local"
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default BidForm;
