import LinkModal from "../components/LinkModal";
import { GitMerge } from "react-feather";
import { getEvent } from "../clients/art";

function MergeEventModal(props) {
  return (
    <LinkModal
      linkRequest={props.linkRequest}
      params={props.params}
      onClose={props.onClose}
      onLink={props.onLink}
      buttonIcon={GitMerge}
      buttonCaption="Merge"
      placeholder="Search events&hellip;"
      keyName="sourceId"
      nameAttribute="name"
      getRequest={getEvent}
      searchIndex="events"
      searchTransform={(hit) => ({
        resultType: "event",
        name: hit.name,
        flavor: hit.eventType,
        img: hit.image_url,
        id: hit.objectID,
      })}
      resultType="event"
      title="Merge event"
      toastMessage="The event was merged"
      description={
        <p className="small text-muted">
          The event you select will be deleted. All the artists and works will
          be moved to {props.targetName}.
        </p>
      }
    />
  );
}

export default MergeEventModal;
