import IconButton from "../../components/IconButton";
import ImageForm from "../../forms/ImageForm";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Link } from "react-router-dom";
import { Save } from "react-feather";
import { getImage, updateImage, getWork } from "../../clients/art";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function EditImage() {
  let { work_id: workId, image_id: id } = useParams();
  const getWorkRequest = useAutoRequest(getWork, workId);
  const updateImageRequest = useRequest(updateImage);
  const getImageRequest = useAutoRequest(getImage, id);
  let history = useHistory();

  const image = getImageRequest.response.data || {};
  const work = getWorkRequest.response.data || {};
  const loading = getImageRequest.loading || updateImageRequest.loading;
  const error = getImageRequest.error || updateImageRequest.error;

  return (
    <ImageForm
      initialValues={getImageRequest.response.data}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateImageRequest.execute({ id, data: values }, () => {
          history.goBack();
          toast(`The image was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[
              <Link to={`/works/${workId}`}>{work.title || ""}</Link>,
              image.name || image.id,
            ]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </ImageForm>
  );
}

export default EditImage;
