import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import PaginatedContainer from "../components/PaginatedContainer";
import RequestContainer from "../components/RequestContainer";
import ZonedDateTime from "../components/ZonedDateTime";
import { get } from "lodash";
import { useAutoRequest } from "../hooks/useRequest";
import { useState } from "react";

const statusToString = (status) => {
  switch (status) {
    case 0:
      return "Upcoming";
    case 1:
      return "Monitoring";
    case 2:
      return "Job created";
    case 3:
      return "Messages uploaded";
    case 4:
      return "Finished";
    case 100:
      return "No live auction site";
    case 101:
      return "No messages";
    default:
      return "Unknown";
  }
};

function AuctionsTable(props) {
  const [params, setParams] = useState({
    auction_house: null,
    status: null,
    ...props.params,
  });
  const request = useAutoRequest(props.request, params);

  const auctions = request.response.data || [];
  const responseParams = get(request.response, "config.params") || params;

  const handleParamsChange = (newParams) => {
    const allParams = { ...params, ...newParams };

    if (!("skip" in newParams)) {
      allParams.skip = 0;
    }

    setParams(allParams);

    if (props.onParamsChange) {
      props.onParamsChange(allParams);
    }
  };

  const table = (
    <ObjectTable
      columns={[
        "Start time",
        "End time",
        {
          title: "Source",
          filterable: true,
          sortable: false,
          currentFilter: params.auction_house,
          filterOptions: [
            "arthur",
            "bonhams",
            "christies",
            "invaluable",
            "sothebys",
            "phillips",
          ],
          onFilter: (value) => handleParamsChange({ auction_house: value }),
        },
        {
          title: "Status",
          filterable: true,
          sortable: false,
          currentFilter: params.status,
          filterOptions: [
            ["Upcoming", 0],
            ["Monitoring", 1],
            ["Job created", 2],
            ["Messages uploaded", 3],
            ["Finished", 4],
            ["No live auction site", 100],
            ["No messages", 101],
          ],
          onFilter: (value) => handleParamsChange({ status: value }),
        },
        "Name",
        "External id",
      ]}
    >
      {auctions.map((auction) => (
        <ObjectTableRow
          viewPath={`/sales/${auction.id}`}
          key={auction.id}
          columns={[
            <ZonedDateTime date={auction.monitor_start_time} />,
            <ZonedDateTime date={auction.monitor_end_time} />,
            auction.auction_house,
            statusToString(auction.status),
            auction.name,
            auction.external_id,
          ]}
        />
      ))}
    </ObjectTable>
  );

  return (
    <RequestContainer loading={request.loading}>
      {props.paginated ? (
        <PaginatedContainer
          enabled={!request.loading}
          count={auctions.length}
          params={responseParams}
          onNavigate={handleParamsChange}
        >
          {table}
        </PaginatedContainer>
      ) : (
        table
      )}
    </RequestContainer>
  );
}

export default AuctionsTable;
