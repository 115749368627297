import EventForm from "../../forms/EventForm";
import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { getEvent, updateEvent } from "../../clients/art";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function EditEvent() {
  let { event_id: id } = useParams();
  const updateEventRequest = useRequest(updateEvent);
  const getEventRequest = useAutoRequest(getEvent, id);
  let history = useHistory();

  const event = getEventRequest.response.data || {};
  const loading = getEventRequest.loading || updateEventRequest.loading;
  const error = getEventRequest.error || updateEventRequest.error;

  return (
    <EventForm
      initialValues={getEventRequest.response.data}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateEventRequest.execute({ id, data: values }, () => {
          history.goBack();
          toast(`The event was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[`${event.name || ""}`]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </EventForm>
  );
}

export default EditEvent;
