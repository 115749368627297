import AddWorkPriceModal from "../../modals/AddWorkPriceModal";
import ArtistsTable from "../../tables/ArtistsTable";
import Authorizer from "../../components/Authorizer";
import DeleteWorkModal from "../../modals/DeleteWorkModal";
import DeleteWorkPriceModal from "../../modals/DeleteWorkPriceModal";
import EventsTable from "../../tables/EventsTable";
import IconButton from "../../components/IconButton";
import IconLinkButton from "../../components/IconLinkButton";
import ImagesTable from "../../tables/ImagesTable";
import LinkArtistModal from "../../modals/LinkArtistModal";
import LinkEventModal from "../../modals/LinkEventModal";
import LinkOfferModal from "../../modals/LinkOfferModal";
import LogModal from "../../modals/LogModal";
import MintTokenModal from "../../modals/MintTokenModal";
import ObjectPropertyList from "../../components/ObjectPropertyList";
import ObjectTable from "../../components/ObjectTable";
import ObjectTableRow from "../../components/ObjectTableRow";
import OffersTable from "../../tables/OffersTable";
import RequestContainer from "../../components/RequestContainer";
import TabCard from "../../components/TabCard";
import TokensTable from "../../tables/TokensTable";
import UploadImageModal from "../../modals/UploadImageModal";
import UploadVideoModal from "../../modals/UploadVideoModal";
import VideosTable from "../../tables/VideosTable";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import WorkForSaleModal from "../../modals/WorkForSaleModal";
import {
  Edit,
  PlusCircle,
  Link,
  UploadCloud,
  XCircle,
  Clock,
  ShoppingCart,
  StopCircle,
} from "react-feather";
import {
  getWork,
  linkEventWork,
  linkWorkArtist,
  listArtistsByWork,
  listEventsByWork,
  unlinkEventWork,
  unlinkWorkArtist,
  unlinkWorkImage,
  unlinkWorkVideo,
} from "../../clients/art";
import {
  listOffersByWork,
  linkOfferWork,
  unlinkOfferWork,
} from "../../clients/finance";
import { useAutoRequest } from "../../hooks/useRequest";
import { useHistory } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import { useParams } from "react-router-dom";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useRefresh } from "../../hooks/useRefresh";
import { map } from "lodash";

function PricesTable(props) {
  const prices = props.work.additionalPrices || {};

  return (
    <ObjectTable columns={["Title", "Price", "Currency"]}>
      {map(prices, (v, title) => (
        <ObjectTableRow
          actionAuthorizerRole={["admin", "editor"]}
          deleteAuthorizerRole="admin"
          deleteModal={DeleteWorkPriceModal}
          deleteModalParams={{
            work: props.work,
            title,
            onDelete: props.onDelete,
          }}
          key={title}
          columns={[title, v.price, v.currency]}
        />
      ))}
    </ObjectTable>
  );
}

function ViewWork() {
  const [activeTab, setActiveTab] = usePersistentState(
    null,
    "ViewWork:activeTab"
  );
  let { work_id: id } = useParams();
  const [refreshToken, refresh] = useRefresh(id);
  const getWorkRequest = useAutoRequest(getWork, id);
  const work = getWorkRequest.response.data || {};
  let history = useHistory();
  let [showLinkArtistModal, linkArtistModal] = useModal(LinkArtistModal, {
    linkRequest: linkWorkArtist,
    params: { workId: id },
    onLink: refresh,
  });
  let [showLinkEventModal, linkEventModal] = useModal(LinkEventModal, {
    linkRequest: linkEventWork,
    params: { workId: id },
    onLink: refresh,
  });
  let [showLinkOfferModal, linkOfferModal] = useModal(LinkOfferModal, {
    workId: id,
    linkRequest: linkOfferWork,
    params: { workId: id },
    onLink: refresh,
  });
  let [showMintTokenModal, mintTokenModal] = useModal(MintTokenModal, {
    nftContractAddress: work.nftContractAddress,
    onMint: () => getWorkRequest.execute(),
  });
  let [showAddWorkPriceModal, addWorkPriceModal] = useModal(AddWorkPriceModal, {
    work,
    onAdd: () => getWorkRequest.execute(),
  });
  let [showUploadImageModal, uploadImageModal] = useModal(UploadImageModal, {
    params: { workId: id },
    indexParams: { workId: id, workTitle: work.title },
    onSave: () => getWorkRequest.execute(),
  });
  let [showUploadVideoModal, uploadVideoModal] = useModal(UploadVideoModal, {
    params: { workId: id },
    indexParams: { workId: id, workTitle: work.title },
    onSave: () => getWorkRequest.execute(),
  });
  let [showDeleteWorkModal, deleteWorkModal] = useModal(DeleteWorkModal, {
    id: work.id,
    title: work.title,
    onDelete: () => {
      history.push("/works");
    },
  });
  let [showLogModal, logModal] = useModal(LogModal, {
    filter: {
      type: "work",
      id,
    },
  });
  let [showWorkForSaleModal, workForSaleModal] = useModal(WorkForSaleModal, {
    id: work.id,
    onSave: () => getWorkRequest.execute(null, refresh),
  });

  return (
    <>
      <ViewHeader
        breadcrumbs={[`${work.title || ""}`]}
        actions={
          <>
            <Authorizer role={["admin", "editor"]}>
              {work.for_sale ? (
                <IconButton
                  onClick={showWorkForSaleModal}
                  icon={StopCircle}
                  className="btn btn-primary me-2"
                >
                  End sale
                </IconButton>
              ) : (
                <IconButton
                  onClick={showWorkForSaleModal}
                  icon={ShoppingCart}
                  className="btn btn-primary me-2"
                >
                  Put up for sale
                </IconButton>
              )}
              <IconButton
                onClick={showLogModal}
                icon={Clock}
                className="btn btn-secondary me-2"
              >
                Log
              </IconButton>
              <IconLinkButton
                to={`/works/${id}/edit`}
                icon={Edit}
                className="btn btn-secondary me-2"
              >
                Edit
              </IconLinkButton>
            </Authorizer>
            <Authorizer role="admin">
              <IconButton
                icon={XCircle}
                onClick={showDeleteWorkModal}
                className="btn btn-secondary"
              >
                Delete
              </IconButton>
            </Authorizer>
          </>
        }
      />
      <ViewContents>
        <RequestContainer
          loading={getWorkRequest.loading}
          error={getWorkRequest.error}
        >
          <ObjectPropertyList
            items={[
              ["ID", work.id],
              ["Title", work.title],
              ["Date", work.dateText],
              ["Alternative Artist", work.artistAlt],
              ["Artist Role", work.artistRole],
              ["Artist modifier", work.artistModifier],
              ["Medium", work.medium],
              ["Dimensions", work.dimensions],
              ["Inscription", work.inscription],
              ["Edition", work.edition],
              ["Description", work.description],
              ["Earliest Date", { date: work.earliestDate }],
              ["Latest Date", { date: work.latestDate }],
              ["Width", work.width],
              ["Height", work.height],
              ["Depth", work.depth],
              ["Units", work.units],
              ["Exhibition History", work.exhibitionHistory],
              ["Provenance", work.provenance],
              ["Literature", work.literature],
              ["Catalog Raisonne Reference", work.catalogRaisonneReference],
              ["Condition", work.condition],
              ["Current Location", work.currentLocation],
              ["Collection Tag", work.collectionTag],
              [
                "Last Price",
                { currency: work.currency, value: work.lastPrice },
              ],
              ["For sale", { bool: work.for_sale }],
              ["Mintable", { bool: work.mintable }],
              ["Priority", work.priority],
              ["Visual Labels", { json: work.visual_labels }],
              ["NFT Wallet Address", work.nftWalletAddress],
              ["NFT Contract Address", work.nftContractAddress],
              ["NFT Token ID", work.nftTokenId],
              ["Shipping data", { json: work.shippingData }],
            ]}
          />
        </RequestContainer>

        <TabCard
          activeTab={activeTab}
          onTabChange={setActiveTab}
          tabs={{
            Artists: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showLinkArtistModal}
                    icon={Link}
                    className="btn btn-secondary me-2"
                  >
                    Link artist
                  </IconButton>
                  <IconLinkButton
                    to={`/artists/new?workId=${id}`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create artist
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <ArtistsTable
                  key={refreshToken}
                  params={{ id }}
                  request={listArtistsByWork}
                  unlinkRequest={unlinkWorkArtist}
                  unlinkParams={{ workId: id }}
                />
              ),
            },
            Events: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showLinkEventModal}
                    icon={Link}
                    className="btn btn-secondary me-2"
                  >
                    Link event
                  </IconButton>
                  <IconLinkButton
                    to={`/events/new?workId=${id}`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create event
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <EventsTable
                  key={refreshToken}
                  params={{ id }}
                  request={listEventsByWork}
                  unlinkRequest={unlinkEventWork}
                  unlinkParams={{ workId: id }}
                />
              ),
            },
            Images: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showUploadImageModal}
                    icon={UploadCloud}
                    className="btn btn-success"
                  >
                    Upload image
                  </IconButton>
                </Authorizer>
              ),
              body: (
                <ImagesTable
                  params={{ id }}
                  work={work}
                  request={getWorkRequest}
                  unlinkRequest={unlinkWorkImage}
                  unlinkParams={{ workId: id }}
                />
              ),
            },
            Videos: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showUploadVideoModal}
                    icon={UploadCloud}
                    className="btn btn-success"
                  >
                    Upload video
                  </IconButton>
                </Authorizer>
              ),
              body: (
                <VideosTable
                  params={{ id }}
                  work={work}
                  request={getWorkRequest}
                  unlinkRequest={unlinkWorkVideo}
                  unlinkParams={{ workId: id }}
                />
              ),
            },
            Offers: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showLinkOfferModal}
                    icon={Link}
                    className="btn btn-secondary"
                  >
                    Link offer
                  </IconButton>
                </Authorizer>
              ),
              body: (
                <OffersTable
                  key={refreshToken}
                  params={{ id }}
                  request={listOffersByWork}
                  unlinkRequest={unlinkOfferWork}
                  unlinkParams={{ workId: id }}
                />
              ),
            },
            "Physical tokens": {
              actions: (
                <IconButton
                  onClick={showMintTokenModal}
                  icon={Link}
                  className="btn btn-secondary"
                  disabled={!work.nftContractAddress}
                >
                  Mint token
                </IconButton>
              ),
              body: (
                <TokensTable
                  key={refreshToken}
                  params={{ id }}
                  work={work}
                  unlinkRequest={unlinkOfferWork}
                  unlinkParams={{ workId: id }}
                  onDelete={() => getWorkRequest.execute()}
                />
              ),
            },
            Prices: {
              actions: (
                <IconButton
                  onClick={showAddWorkPriceModal}
                  icon={PlusCircle}
                  className="btn btn-success"
                >
                  Add price
                </IconButton>
              ),
              body: (
                <PricesTable
                  key={refreshToken}
                  work={work}
                  onDelete={() => getWorkRequest.execute()}
                />
              ),
            },
          }}
        />
      </ViewContents>

      {linkArtistModal}
      {linkEventModal}
      {linkOfferModal}
      {uploadImageModal}
      {uploadVideoModal}
      {deleteWorkModal}
      {logModal}
      {workForSaleModal}
      {mintTokenModal}
      {addWorkPriceModal}
    </>
  );
}

export default ViewWork;
