import EventForm from "../../forms/EventForm";
import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { createEvent } from "../../clients/art";
import { formatDate } from "../../lib/date";
import { getSale } from "../../clients/finance";
import { useHistory } from "react-router-dom";
import { useQueryString } from "../../hooks/useQueryString";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function NewEvent() {
  const artistId = useQueryString("artistId");
  const saleId = useQueryString("saleId");
  const venueId = useQueryString("venueId");
  const workId = useQueryString("workId");
  const createEventRequest = useRequest(createEvent);
  const getSaleRequest = useAutoRequest(saleId ? getSale : null, saleId);
  let history = useHistory();

  const loading = getSaleRequest.loading || createEventRequest.loading;
  const error = getSaleRequest.error || createEventRequest.error;

  return (
    <EventForm
      initialValues={
        getSaleRequest.response.data
          ? {
              name: getSaleRequest.response.data.title,
              startDate: getSaleRequest.response.data.startDate,
              endDate: getSaleRequest.response.data.endDate,
              dates: formatDate(
                getSaleRequest.response.data.startDate || null,
                getSaleRequest.response.data.endDate || null
              ),
              url: getSaleRequest.response.data.url,
              eventLive: getSaleRequest.response.data.saleType,
            }
          : {}
      }
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        const createOneMore =
          window.event.submitter.classList.contains("create-one-more");

        createEventRequest.execute(
          { ...values, artistId, saleId, venueId, workId },
          (response) => {
            if (createOneMore) {
              actions.resetForm();
            } else {
              history.push(`/events/${response.data.id}`);
            }
            toast("The event was created");
          }
        );
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={["New event"]}
            actions={
              <>
                <IconButton
                  type="submit"
                  disabled={loading}
                  icon={Save}
                  className="btn btn-secondary me-2 create-one-more"
                >
                  Save and create one more
                </IconButton>
                <IconButton
                  type="submit"
                  disabled={loading}
                  icon={Save}
                  className="btn btn-success"
                >
                  Save
                </IconButton>
              </>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </EventForm>
  );
}

export default NewEvent;
