import IconButton from "../../components/IconButton";
import OfferForm from "../../forms/OfferForm";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Link } from "react-router-dom";
import { Save } from "react-feather";
import { getOffer, updateOffer, getSale } from "../../clients/finance";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function EditOffer() {
  let { sale_id: saleId, offer_id: id } = useParams();
  const getSaleRequest = useAutoRequest(getSale, saleId);
  const updateOfferRequest = useRequest(updateOffer);
  const getOfferRequest = useAutoRequest(getOffer, id);
  let history = useHistory();

  const sale = getSaleRequest.response.data || {};
  const offer = getOfferRequest.response.data || {};
  const loading = getOfferRequest.loading || updateOfferRequest.loading;
  const error = getOfferRequest.error || updateOfferRequest.error;

  return (
    <OfferForm
      initialValues={getOfferRequest.response.data}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateOfferRequest.execute({ id, data: values }, () => {
          history.goBack();
          toast(`The offer was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[
              <Link to={`/sales/${saleId}`}>{`${
                sale.title || sale.externalId || ""
              }`}</Link>,
              `${offer.offerType || ""} ${offer.sequenceNumber || ""}`,
            ]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </OfferForm>
  );
}

export default EditOffer;
