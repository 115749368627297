import FeeForm from "../../forms/FeeForm";
import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Link } from "react-router-dom";
import { Save } from "react-feather";
import { createFee, getOffer, getSale } from "../../clients/finance";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function NewFee() {
  let { sale_id: saleId, offer_id: offerId } = useParams();
  const getSaleRequest = useAutoRequest(getSale, saleId);
  const getOfferRequest = useAutoRequest(getOffer, offerId);
  const createRequest = useRequest(createFee);
  let history = useHistory();

  const sale = getSaleRequest.response.data || {};
  const offer = getOfferRequest.response.data || {};

  return (
    <FeeForm
      onSubmit={(values) => {
        createRequest.execute({ ...values, offerId }, (response) => {
          history.push(`/sales/${saleId}/offers/${offerId}`);
          toast("The fee was created");
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[
              <Link to={`/sales/${saleId}`}>{`${
                sale.title || sale.externalId || ""
              }`}</Link>,
              <Link to={`/sales/${saleId}/offers/${offerId}`}>{`${
                offer.offerType || ""
              } ${offer.sequenceNumber || ""}`}</Link>,
              "New fee",
            ]}
            actions={
              <IconButton
                type="submit"
                disabled={createRequest.loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer
              loading={createRequest.loading}
              error={createRequest.error}
            >
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </FeeForm>
  );
}

export default NewFee;
