import { omit } from "lodash";

function IconButton(props) {
  return (
    <button
      className={`btn d-inline-flex align-items-center ${props.className}`}
      onClick={props.disabled ? null : props.onClick}
      disabled={props.disabled}
      type={props.type}
      form={props.form}
      title={props.title}
      {...omit(props, [
        "className",
        "onClick",
        "disabled",
        "type",
        "form",
        "title",
        "iconSize",
        "children",
      ])}
    >
      <props.icon
        size={props.iconSize ? props.iconSize : props.children ? 16 : 21}
      />
      {props.children ? <span className="ms-2">{props.children}</span> : null}
    </button>
  );
}

export default IconButton;
