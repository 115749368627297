import AuctionsTable from "../../tables/AuctionsTable";
import IconLinkButton from "../../components/IconLinkButton";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";
import { listAuctions } from "../../clients/import";
import { usePersistentState } from "../../hooks/usePersistentState";

function Auctions() {
  const [params, setParams] = usePersistentState(
    {
      skip: 0,
      limit: 100,
    },
    "Auctions:params"
  );

  return (
    <>
      <ViewHeader
        breadcrumbs={[<Link to={`/jobs`}>Imports</Link>, "Monitored auctions"]}
        actions={
          <IconLinkButton
            to="/jobs"
            icon={ArrowLeft}
            className="btn btn-secondary"
          >
            Back
          </IconLinkButton>
        }
      />
      <ViewContents>
        <AuctionsTable
          params={params}
          onParamsChange={setParams}
          request={listAuctions}
          paginated={true}
        />
      </ViewContents>
    </>
  );
}

export default Auctions;
