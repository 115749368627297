import DeleteSalePriceModal from "../modals/DeleteSalePriceModal";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import RequestContainer from "../components/RequestContainer";
import { formatDate } from "../lib/date";
import { useAutoRequest } from "../hooks/useRequest";
import { useState } from "react";

function SalePricesTable(props) {
  const [params] = useState(props.params);
  const request = useAutoRequest(props.request, params);

  const sale_prices = request.response.data || [];

  return (
    <RequestContainer loading={request.loading}>
      <ObjectTable columns={["Price", "Currency", "Sale date"]}>
        {sale_prices.map((sale_price) => (
          <ObjectTableRow
            actionAuthorizerRole={["admin", "editor"]}
            deleteAuthorizerRole="admin"
            editPath={`/sales/${props.offer.saleId}/offers/${props.offer.id}/sale_prices/${sale_price.id}/edit`}
            deleteModal={DeleteSalePriceModal}
            deleteModalParams={{
              id: sale_price.id,
              onDelete: () => request.execute(),
            }}
            key={sale_price.id}
            columns={[
              sale_price.price,
              sale_price.currency,
              formatDate(sale_price.saleDate),
            ]}
          />
        ))}
      </ObjectTable>
    </RequestContainer>
  );
}

export default SalePricesTable;
