import Modal from "../components/Modal";
import ObjectPropertyList from "../components/ObjectPropertyList";

function TaskInfoModal(props) {
  return (
    <Modal
      title="QA Task"
      body={
        <ObjectPropertyList
          items={[
            ["Type", props.task.type],
            ["Dimension", props.task.dimension],
            ["Reason", props.task.reason],
            ["Fields", { json: props.task.fields }],
            ["Extra info", { json: props.task.extra_info }],
            ["Notes", props.task.notes],
            ["Resolved at", { date: props.task.resolved_at }],
          ]}
        />
      }
      onClose={props.onClose}
      size="lg"
    />
  );
}

export default TaskInfoModal;
