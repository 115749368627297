import config from "../config";
import { request as baseRequest } from "./common";

const request = async (token, endpoint, options = {}) =>
  await baseRequest(config.quality_api.url, token, endpoint, options);

const listGroups = async (token, params) =>
  await request(token, "groups", { params });

const assignGroup = async (token, { id, assignee }) =>
  await request(token, `groups/${id}/assign`, {
    method: "patch",
    data: { assignee },
  });

const resolveGroup = async (token, { id, status, notes }) =>
  await request(token, `groups/${id}/resolve`, {
    method: "patch",
    data: { status, notes },
  });

const getGroup = async (token, id) => await request(token, `groups/${id}`);

const assignTask = async (token, { id, assignee }) =>
  await request(token, `tasks/${id}/assign`, {
    method: "patch",
    data: { assignee },
  });

const resolveTask = async (token, { id, status, notes }) =>
  await request(token, `tasks/${id}/resolve`, {
    method: "patch",
    data: { status, notes },
  });

export {
  listGroups,
  assignGroup,
  resolveGroup,
  getGroup,
  assignTask,
  resolveTask,
};
