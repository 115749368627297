import { Link } from "react-router-dom";

function IconLinkButton(props) {
  return (
    <Link
      to={props.to}
      className={`btn d-inline-flex align-items-center ${props.className}`}
    >
      <props.icon size={16} />
      <span className="ms-2">{props.children}</span>
    </Link>
  );
}

IconLinkButton.isLink = true;

export default IconLinkButton;
