import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import toast from "../lib/toast";
import { Link } from "react-feather";
import { flatMapDeep } from "lodash";
import { linkEventWork } from "../clients/art";
import { listOffersBySale, getSale } from "../clients/finance";
import { useRequest } from "../hooks/useRequest";
import { virtualBulk } from "../clients/common";

const relinkWorks = async (token, id) => {
  const sale = await getSale(token, id);

  if (!sale.data.eventIds) {
    return { data: null };
  }

  const offers = await listOffersBySale(token, id);

  if (!offers.data) {
    return { data: null };
  }

  return await virtualBulk(
    token,
    flatMapDeep(offers.data, (offer) =>
      (offer.workIds || []).map((workId) =>
        sale.data.eventIds.map((eventId) => ({
          eventId,
          workId,
        }))
      )
    ),
    linkEventWork
  );
};

function SaleRelinkWorksModal(props) {
  const relinkWorksRequest = useRequest(relinkWorks);

  const handleSave = () => {
    relinkWorksRequest.execute(props.id, () => {
      toast("Work/event links restored");
      props.onClose();
    });
  };

  const loading = relinkWorksRequest.loading;
  const error = relinkWorksRequest.error;

  return (
    <Modal
      title="Link all works/events"
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          This will link all the works associated with this sale's offers with
          all the events associated with the sale.
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            onClick={handleSave}
            icon={Link}
            className="btn btn-success"
          >
            Restore links
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default SaleRelinkWorksModal;
