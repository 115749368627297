import CollectionForm from "../../forms/CollectionForm";
import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { createCollection } from "../../clients/collection";
import { getUid } from "../../lib/firebase";
import { merge } from "lodash";
import { useHistory } from "react-router-dom";
import { useRequest } from "../../hooks/useRequest";

function NewCollection() {
  const createCollectionRequest = useRequest(createCollection);
  let history = useHistory();

  const loading = createCollectionRequest.loading;
  const error = createCollectionRequest.error;

  return (
    <CollectionForm
      initialValues={{
        owner: getUid(),
      }}
      onSubmit={(values) => {
        createCollectionRequest.execute(
          merge(values, {
            type: "arthur_admin",
          }),
          (response) => {
            history.push(`/collections/${response.data.id}`);
            toast("The collection was created");
          }
        );
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={["New collection"]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </CollectionForm>
  );
}

export default NewCollection;
