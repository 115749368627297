import LinkModal from "../components/LinkModal";
import { getEvent } from "../clients/art";

function LinkEventModal(props) {
  return (
    <LinkModal
      linkRequest={props.linkRequest}
      params={props.params}
      onClose={props.onClose}
      onLink={props.onLink}
      placeholder="Search events&hellip;"
      keyName="eventId"
      nameAttribute="name"
      getRequest={getEvent}
      searchIndex="events"
      searchTransform={(hit) => ({
        resultType: "event",
        name: hit.name,
        flavor: hit.eventType,
        img: hit.image_url,
        id: hit.objectID,
      })}
      resultType="event"
      title="Link event"
      toastMessage="The event was linked"
    />
  );
}

export default LinkEventModal;
