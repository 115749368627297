import * as filestack from "filestack-js";
import axios from "axios";
import { getToken } from "./firebase";

let client = null;

const getFilestackClient = async () => {
  if (!client) {
    const response = await axios.get(
      "https://admin.arthuranalytics.com/api/getSecret/FILESTACK_API_KEY",
      { headers: { Authorization: `Bearer ${await getToken()}` } }
    );

    client = filestack.init(response.data);
  }

  return client;
};

const upload = async (file) =>
  await (await getFilestackClient()).upload(file, {}, { location: "gcs" });

const storeURL = async (url) =>
  await (await getFilestackClient()).storeURL(url, { location: "gcs" });

export { upload, storeURL };
