function ListGroup(props) {
  return (
    <div className={`list-group shadow-sm ${props.className}`}>
      {props.items.map(
        (item, index) =>
          item && (
            <div className="list-group-item" key={index}>
              {item}
            </div>
          )
      )}
    </div>
  );
}

export default ListGroup;
