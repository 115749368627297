const currencies = [
  "USD",
  "EUR",
  "JPY",
  "GBP",
  "AED",
  "AUD",
  "BRL",
  "CAD",
  "CHF",
  "CNY",
  "ETH",
  "HKD",
  "IDR",
  "INR",
  "KRW",
  "MATIC",
  "NGN",
  "NOK",
  "PHP",
  "SEK",
  "SGD",
  "TEZ",
  "TWD",
  "ZAR",
];

export { currencies };
