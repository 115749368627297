import AddWorkModal from "../components/AddWorkModal";
import { addToCollection } from "../clients/collection";

function AddWorkToCollectionModal(props) {
  return (
    <AddWorkModal
      addRequest={addToCollection}
      onAddMessage="The work was added to the collection"
      modalTitle="Add works to collection"
      {...props}
    />
  );
}

export default AddWorkToCollectionModal;
