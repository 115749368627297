import IconButton from "../../components/IconButton";
import FeeForm from "../../forms/FeeForm";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Link } from "react-router-dom";
import { Save } from "react-feather";
import { getFee, updateFee, getOffer, getSale } from "../../clients/finance";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function EditFee() {
  let { sale_id: saleId, offer_id: offerId, fee_id: id } = useParams();
  const getSaleRequest = useAutoRequest(getSale, saleId);
  const getOfferRequest = useAutoRequest(getOffer, offerId);
  const updateFeeRequest = useRequest(updateFee);
  const getFeeRequest = useAutoRequest(getFee, id);
  let history = useHistory();

  const fee = getFeeRequest.response.data || {};
  const sale = getSaleRequest.response.data || {};
  const offer = getOfferRequest.response.data || {};
  const loading = getFeeRequest.loading || updateFeeRequest.loading;
  const error = getFeeRequest.error || updateFeeRequest.error;

  return (
    <FeeForm
      initialValues={getFeeRequest.response.data}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateFeeRequest.execute({ id, data: values }, () => {
          history.goBack();
          toast(`The fee was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[
              <Link to={`/sales/${saleId}`}>{`${
                sale.title || sale.externalId || ""
              }`}</Link>,
              <Link to={`/sales/${saleId}/offers/${offerId}`}>{`${
                offer.offerType || ""
              } ${offer.sequenceNumber || ""}`}</Link>,
              fee.name,
            ]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </FeeForm>
  );
}

export default EditFee;
