import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import toast from "../lib/toast";
import { Save } from "react-feather";
import { countBy, toPairs, maxBy, last, head, map } from "lodash";
import { currencies } from "../lib/currency";
import { updateOffer, listOffersBySale } from "../clients/finance";
import { useRequest, useAutoRequest } from "../hooks/useRequest";
import { useState, useEffect } from "react";
import { virtualBulk } from "../clients/common";

const setOfferCurrencies = async (token, { offers, currency }) =>
  virtualBulk(
    token,
    offers.map((offer) => ({ id: offer.id, data: { ...offer, currency } })),
    updateOffer
  );

function SaleCurrencyModal(props) {
  const [currency, setCurrency] = useState("");
  const listOffersBySaleRequest = useAutoRequest(listOffersBySale, props.id);
  const setOfferCurrenciesRequest = useRequest(setOfferCurrencies);

  useEffect(() => {
    const offers = listOffersBySaleRequest.response.data || [];
    const mostCommonCurrency = head(
      maxBy(toPairs(countBy(map(offers, "currency"))), last)
    );
    if (!currency) {
      setCurrency(mostCommonCurrency);
    }
  }, [listOffersBySaleRequest, currency]);

  const handleSave = () => {
    const offers = listOffersBySaleRequest.response.data || [];
    setOfferCurrenciesRequest.execute({ offers, currency }, () => {
      toast("Sale currency changed");
      props.onClose();
    });
  };

  const loading =
    setOfferCurrenciesRequest.loading || listOffersBySaleRequest.loading;
  const error =
    setOfferCurrenciesRequest.error || listOffersBySaleRequest.error;

  return (
    <Modal
      title="Change currency"
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          <div className="form-floating">
            <select
              id="currency"
              className="form-select"
              value={currency}
              onChange={(event) => setCurrency(event.target.value)}
            >
              <option value=""></option>
              {currencies.map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
            <label htmlFor="currency">Currency</label>
          </div>
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            onClick={handleSave}
            icon={Save}
            className="btn btn-success"
          >
            Save
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default SaleCurrencyModal;
