import DeleteModal from "../components/DeleteModal";
import { updateWork } from "../clients/art";

const deleteToken = async (authToken, { work, token }) =>
  updateWork(authToken, {
    id: work.id,
    data: {
      ...work,
      physicalTokenIds: work.physicalTokenIds.filter((t) => t !== token),
    },
  });

function DeleteTokenModal(props) {
  return (
    <DeleteModal
      request={deleteToken}
      id={{ work: props.work, token: props.token }}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The token was deleted"
      title="Delete token?"
      confirmText={props.token}
      message={
        <p className="m-0">
          Do you want to delete&nbsp;
          <span className="text-muted">{props.token}</span>
          &nbsp;permanently?
        </p>
      }
    />
  );
}

export default DeleteTokenModal;
