import config from "../config";
import { logEvent } from "../lib/log";
import { merge } from "lodash";
import { request as baseRequest } from "./common";
import { v4 as uuidv4 } from "uuid";

const request = async (token, endpoint, options = {}) =>
  await baseRequest(config.import_api.url, token, endpoint, options);

const listJobs = async (token, params) =>
  await request(token, "jobs", { params });

const getJob = async (token, id) => await request(token, `jobs/${id}`);

const getJobData = async (token, id) => await request(token, `jobs/${id}/data`);

const getJobLogs = async (token, id) => await request(token, `jobs/${id}/logs`);

const getJobOutputs = async (token, id) =>
  await request(token, `jobs/${id}/outputs`);

const createJob = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "jobs", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateJob", data, { type: "job", id });
  return response;
};

const deleteJob = async (token, id) => {
  const response = await request(token, `jobs/${id}`, {
    method: "delete",
  });
  logEvent("DeleteJob", {}, { type: "job", id });
  return response;
};

const addImageToIndex = async (token, { id, url, workId, workTitle }) => {
  const response = await request(token, `images/index/${id}`, {
    method: "post",
    params: { url, work_id: workId, work_title: workTitle },
  });
  logEvent("AddImageToIndex", { work_id: workId }, { type: "image", id });
  return response;
};

const deleteImageFromIndex = async (token, { id, workId }) => {
  const response = await request(token, `images/index/${id}`, {
    method: "delete",
    params: { work_id: workId },
  });
  logEvent("DeleteImageFromIndex", { work_id: workId }, { type: "image", id });
  return response;
};

const uploadJob = async (token, content_type) =>
  await request(token, "jobs/upload", {
    method: "post",
    data: { content_type },
  });

const listAuctions = async (token, params) =>
  await request(token, "auctions", { params });

export {
  listJobs,
  getJob,
  getJobData,
  getJobLogs,
  getJobOutputs,
  createJob,
  deleteJob,
  addImageToIndex,
  deleteImageFromIndex,
  uploadJob,
  listAuctions,
};
