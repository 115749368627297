import ProgramForm from "../../forms/ProgramForm";
import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { getProgram, updateProgram } from "../../clients/collection";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function EditProgram() {
  let { program_id: id } = useParams();
  const updateProgramRequest = useRequest(updateProgram);
  const getProgramRequest = useAutoRequest(getProgram, id);
  let history = useHistory();

  const program = getProgramRequest.response.data || {};
  const loading = getProgramRequest.loading || updateProgramRequest.loading;
  const error = getProgramRequest.error || updateProgramRequest.error;

  return (
    <ProgramForm
      initialValues={getProgramRequest.response.data}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateProgramRequest.execute({ id, data: values }, () => {
          history.goBack();
          toast(`The program was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[`${program.name || ""}`]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </ProgramForm>
  );
}

export default EditProgram;
