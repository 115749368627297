import AddWorkModal from "../components/AddWorkModal";
import { addToCrate } from "../clients/collection";
import { get } from "lodash";

function AddWorkToCrateModal(props) {
  return (
    <AddWorkModal
      addRequest={addToCrate}
      onAddMessage="The work was added to the crate"
      modalTitle="Add works to crate"
      existingWorks={get(props, ["crate", "artwork_populations", "works"])}
      {...props}
    />
  );
}

export default AddWorkToCrateModal;
