import LinkModal from "../components/LinkModal";
import { getVenue } from "../clients/art";

function LinkVenueModal(props) {
  return (
    <LinkModal
      linkRequest={props.linkRequest}
      params={props.params}
      onClose={props.onClose}
      onLink={props.onLink}
      placeholder="Search venues&hellip;"
      keyName="venueId"
      nameAttribute="name"
      getRequest={getVenue}
      searchIndex="venues"
      searchTransform={(hit) => ({
        resultType: "venue",
        name: hit.name,
        flavor: hit.subheading || hit.city,
        img: hit.imageUrl,
        id: hit.objectID,
      })}
      resultType="venue"
      title="Link venue"
      toastMessage="The venue was linked"
    />
  );
}

export default LinkVenueModal;
