import DeleteModal from "../components/DeleteModal";
import { deleteEvent } from "../clients/art";

function DeleteEventModal(props) {
  return (
    <DeleteModal
      request={deleteEvent}
      id={props.id}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The event was deleted"
      title="Delete event?"
      confirmText={props.name}
      message={
        <p className="m-0">
          Do you want to delete&nbsp;
          <span className="text-muted">{props.name}</span>
          &nbsp;permanently?
        </p>
      }
    />
  );
}

export default DeleteEventModal;
