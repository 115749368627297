import Authorizer from "../../components/Authorizer";
import ProgramsTable from "../../tables/ProgramsTable";
import IconLinkButton from "../../components/IconLinkButton";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import { PlusCircle } from "react-feather";
import { listPrograms } from "../../clients/collection";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useRefresh } from "../../hooks/useRefresh";

function Programs() {
  const [params, setParams] = usePersistentState({}, "Programs:params");
  const [refreshToken, refresh] = useRefresh();

  return (
    <>
      <ViewHeader
        breadcrumbs={["Programs"]}
        actions={
          <Authorizer role={["admin", "editor"]}>
            <IconLinkButton
              to="/programs/new"
              icon={PlusCircle}
              className="btn btn-success"
            >
              Create program
            </IconLinkButton>
          </Authorizer>
        }
      />
      <ViewContents>
        <ProgramsTable
          request={listPrograms}
          params={params}
          onParamsChange={(newParams) => {
            setParams(newParams);
            refresh();
          }}
          key={refreshToken}
        />
      </ViewContents>
    </>
  );
}

export default Programs;
