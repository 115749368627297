import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import toast from "../lib/toast";
import { ArrowLeft, ArrowRight } from "react-feather";
import { formatDate } from "../lib/date";
import { getEvent, updateEvent } from "../clients/art";
import { getSale, updateSale } from "../clients/finance";
import { useRequest, useAutoRequest } from "../hooks/useRequest";

function SaleSyncModal(props) {
  const getEventRequest = useAutoRequest(getEvent, props.eventId);
  const getSaleRequest = useAutoRequest(getSale, props.saleId);

  const updateEventRequest = useRequest(updateEvent);
  const updateSaleRequest = useRequest(updateSale);

  const handleCopyToEvent = () => {
    updateEventRequest.execute(
      {
        id: props.eventId,
        data: {
          ...getEventRequest.response.data,
          name: getSaleRequest.response.data.title,
          startDate: getSaleRequest.response.data.startDate,
          endDate: getSaleRequest.response.data.endDate,
          dates: formatDate(
            getSaleRequest.response.data.startDate || null,
            getSaleRequest.response.data.endDate || null
          ),
          url: getSaleRequest.response.data.url,
          eventLive: getSaleRequest.response.data.saleType,
        },
      },
      () => {
        toast("Event updated");
        props.onSync();
        props.onClose();
      }
    );
  };

  const handleCopyToSale = () => {
    updateSaleRequest.execute(
      {
        id: props.saleId,
        data: {
          ...getSaleRequest.response.data,
          title: getEventRequest.response.data.name,
          startDate: getEventRequest.response.data.startDate,
          endDate: getEventRequest.response.data.endDate,
          url: getEventRequest.response.data.url,
          saleType: getEventRequest.response.data.eventLive,
        },
      },
      () => {
        toast("Sale updated");
        props.onSync();
        props.onClose();
      }
    );
  };

  const loading =
    getEventRequest.loading ||
    getSaleRequest.loading ||
    updateEventRequest.loading ||
    updateSaleRequest.loading;
  const error =
    getEventRequest.error ||
    getSaleRequest.error ||
    updateEventRequest.error ||
    updateSaleRequest.error;

  return (
    <Modal
      title="Sync sale"
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          Please decide in which direction to copy information between the sale
          and the event.
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            onClick={handleCopyToEvent}
            icon={ArrowLeft}
            className="btn btn-success"
          >
            Sale to event
          </IconButton>
          <IconButton
            onClick={handleCopyToSale}
            icon={ArrowRight}
            className="btn btn-success"
          >
            Event to sale
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default SaleSyncModal;
