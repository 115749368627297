import AddWorkToProgramModal from "../../modals/AddWorkToProgramModal";
import ArtistsTable from "../../tables/ArtistsTable";
import Authorizer from "../../components/Authorizer";
import EventBatchUpdateDatesModal from "../../modals/EventBatchUpdateDatesModal";
import EventsTable from "../../tables/EventsTable";
import IconButton from "../../components/IconButton";
import IconLinkButton from "../../components/IconLinkButton";
import Image from "../../components/Image";
import ImageDimensions from "../../components/ImageDimensions";
import LinkArtistModal from "../../modals/LinkArtistModal";
import LinkEventModal from "../../modals/LinkEventModal";
import LogModal from "../../modals/LogModal";
import ObjectPropertyList from "../../components/ObjectPropertyList";
import Placeholder from "../../components/Placeholder";
import RequestContainer from "../../components/RequestContainer";
import TabCard from "../../components/TabCard";
import UnlinkWorkModal from "../../modals/UnlinkWorkModal";
import UserName from "../../components/UserName";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import {
  Edit,
  PlusCircle,
  XCircle,
  RefreshCw,
  Calendar,
  Clock,
  Copy,
} from "react-feather";
import { get, isEmpty } from "lodash";
import {
  addArtistToProgram,
  addEventToProgram,
  getProgram,
  removeArtistFromProgram,
  removeEventFromProgram,
  removeFromProgram,
  syncProgram,
} from "../../clients/collection";
import { getArtists } from "../../clients/art";
import { getEvents } from "../../clients/art";
import { getWorks } from "../../clients/art";
import { useModal } from "../../hooks/useModal";
import { useParams, Link } from "react-router-dom";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function ViewProgram() {
  const [activeTab, setActiveTab] = usePersistentState(
    null,
    "ViewArtist:activeTab"
  );
  let { program_id: id } = useParams();
  const getWorksRequest = useRequest(getWorks);
  const getProgramRequest = useAutoRequest(getProgram, id, (response) =>
    getWorksRequest.execute(response.data.artwork_populations.works)
  );
  const syncProgramRequest = useRequest(syncProgram, id, () =>
    getProgramRequest.execute()
  );
  let [showLinkEventModal, linkEventModal] = useModal(LinkEventModal, {
    linkRequest: addEventToProgram,
    params: { id },
    onLink: () => getProgramRequest.execute(),
  });
  let [showLinkArtistModal, linkArtistModal] = useModal(LinkArtistModal, {
    linkRequest: addArtistToProgram,
    params: { id },
    onLink: () => getProgramRequest.execute(),
  });
  const program = getProgramRequest.response.data || {
    artwork_populations: { works: [] },
  };

  const reloadProgram = () => getProgramRequest.execute();

  let [showAddWorkToCollectionModal, addWorkToProgramModal] = useModal(
    AddWorkToProgramModal,
    {
      params: { id },
      onAdd: reloadProgram,
      program,
    }
  );

  let [showUnlinkWorkModal, unlinkWorkModal] = useModal(UnlinkWorkModal, {
    onDelete: reloadProgram,
    request: removeFromProgram,
  });

  let [showEventBatchUpdateDatesModal, eventBatchUpdateDatesModal] = useModal(
    EventBatchUpdateDatesModal,
    { onSave: reloadProgram, program }
  );

  let [showLogModal, logModal] = useModal(LogModal, {
    filter: {
      type: "program",
      id,
    },
  });

  const stitchedWorks = (
    get(getProgramRequest, "response.data.artwork_populations.works") || []
  ).map((workId) => ({
    id: workId,
    work: (getWorksRequest.response.data || []).find(
      (work) => work.id === workId
    ),
  }));

  const loading =
    getProgramRequest.loading ||
    getWorksRequest.loading ||
    syncProgramRequest.loading;
  const error =
    getProgramRequest.error ||
    getWorksRequest.error ||
    syncProgramRequest.error;

  return (
    <>
      <ViewHeader
        breadcrumbs={[`${program.flavor || ""}`]}
        actions={
          <Authorizer role={["admin", "editor"]}>
            <IconButton
              onClick={showLogModal}
              icon={Clock}
              className="btn btn-secondary me-2"
            >
              Log
            </IconButton>
            <IconButton
              onClick={() => syncProgramRequest.execute()}
              icon={RefreshCw}
              className="btn btn-secondary me-2"
            >
              Refresh works from events
            </IconButton>
            <IconLinkButton
              to={`/programs/${id}/edit`}
              icon={Edit}
              className="btn btn-secondary me-2"
            >
              Edit
            </IconLinkButton>
          </Authorizer>
        }
      />
      <ViewContents>
        <RequestContainer loading={loading} error={error}>
          <ObjectPropertyList
            items={[
              ["ID", program.id],
              ["Type", program.type],
              ["Subtype", program.subtype],
              ["Description", program.flavor],
              ["Starts at", { date: program.start_time }],
              ["Ends at", { date: program.end_time }],
              ["Latest", { bool: program.latest }],
              ["Thumbnail", { image: program.thumbnail }],
              ["Venue name", get(program, "metadata.venueName")],
              ["URL", { url: get(program, "metadata.url") }],
              ["City", get(program, "metadata.city")],
              ["Country", get(program, "metadata.country")],
              ["Address", get(program, "metadata.address")],
              ["Google Places ID", get(program, "metadata.placesId")],
              ["Latitude", get(program, "metadata.latitude")],
              ["Longitude", get(program, "metadata.longitude")],
              ["Created at", { date: program.created_at }],
              ["Updated at", { date: program.updated_at }],
              ["Creator", <UserName id={program.creator} />],
            ]}
          />

          <TabCard
            activeTab={activeTab}
            onTabChange={setActiveTab}
            tabs={{
              Artists: {
                actions: (
                  <Authorizer role={["admin", "editor"]}>
                    <IconButton
                      onClick={showLinkArtistModal}
                      icon={PlusCircle}
                      className="btn btn-success"
                    >
                      Add artist
                    </IconButton>
                  </Authorizer>
                ),
                body: (
                  <ArtistsTable
                    params={{ id }}
                    request={getArtists}
                    parentRequest={getProgramRequest}
                    parentAttribute="artists"
                    unlinkRequest={removeArtistFromProgram}
                    unlinkParams={{ id }}
                  />
                ),
              },
              Events: {
                actions: (
                  <Authorizer role={["admin", "editor"]}>
                    <IconButton
                      onClick={() =>
                        showEventBatchUpdateDatesModal({
                          events: get(program, "events", []),
                        })
                      }
                      icon={Calendar}
                      className="btn btn-secondary me-2"
                    >
                      Change dates
                    </IconButton>
                    <IconButton
                      onClick={showLinkEventModal}
                      icon={PlusCircle}
                      className="btn btn-success"
                    >
                      Add event
                    </IconButton>
                  </Authorizer>
                ),
                body: (
                  <EventsTable
                    params={{ id }}
                    request={getEvents}
                    parentRequest={getProgramRequest}
                    parentAttribute="events"
                    unlinkRequest={removeEventFromProgram}
                    unlinkParams={{ id }}
                    extraActions={(event) => (
                      <IconLinkButton
                        to={`/events/copy/${event.id}?programId=${id}`}
                        icon={Copy}
                        className="btn-sm btn-outline-primary me-2"
                      >
                        Duplicate
                      </IconLinkButton>
                    )}
                  />
                ),
              },
              Works: {
                actions: (
                  <Authorizer role={["admin", "editor"]}>
                    <IconButton
                      onClick={showAddWorkToCollectionModal}
                      icon={PlusCircle}
                      className="btn btn-success"
                    >
                      Add works
                    </IconButton>
                  </Authorizer>
                ),
                body: (
                  <Placeholder empty={isEmpty(stitchedWorks)}>
                    <table className="table table-hover align-middle">
                      <tbody>
                        {stitchedWorks.map((work, index) =>
                          work.work === undefined ? (
                            <tr key={work.id} className="table-danger">
                              <td colSpan="2">Missing work: {work.id}</td>
                              <td className="text-end text-nowrap">
                                <IconButton
                                  onClick={() =>
                                    showUnlinkWorkModal({
                                      title: work.id,
                                      params: { id, work_id: work.id },
                                    })
                                  }
                                  icon={XCircle}
                                  className="btn-sm btn-outline-secondary"
                                >
                                  Delete
                                </IconButton>
                              </td>
                            </tr>
                          ) : (
                            <tr key={work.id}>
                              <td
                                className="text-center"
                                style={{ width: "7rem" }}
                              >
                                {work.work.images &&
                                work.work.images.length > 0 ? (
                                  <Image
                                    src={work.work.images[0].url}
                                    alt=""
                                    style={{
                                      maxWidth: "6rem",
                                      maxHeight: "6rem",
                                    }}
                                  />
                                ) : null}
                              </td>
                              <td>
                                <p className="m-0 fw-bold">
                                  <Link
                                    to={`/artists/${get(work.work, [
                                      "artists",
                                      0,
                                      "id",
                                    ])}`}
                                    className="link-dark"
                                  >
                                    {get(work.work, ["artists", 0, "name"])}
                                  </Link>
                                </p>
                                <p className="m-0">
                                  <Link
                                    to={`/works/${work.id}`}
                                    className="link-secondary"
                                  >
                                    {work.work.title}
                                  </Link>
                                </p>
                                <span className="text-muted">
                                  {work.work.dateText}
                                </span>
                                {work.work.images &&
                                work.work.images.length > 0 ? (
                                  <ImageDimensions
                                    src={work.work.images[0].url}
                                  />
                                ) : null}
                              </td>
                              <td className="text-end text-nowrap">
                                <IconButton
                                  onClick={() =>
                                    showUnlinkWorkModal({
                                      title: work.work.title,
                                      params: { id, work_id: work.id },
                                    })
                                  }
                                  icon={XCircle}
                                  className="btn-sm btn-outline-secondary"
                                >
                                  Delete
                                </IconButton>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </Placeholder>
                ),
              },
            }}
          />
        </RequestContainer>
      </ViewContents>

      {addWorkToProgramModal}
      {unlinkWorkModal}
      {linkEventModal}
      {linkArtistModal}
      {eventBatchUpdateDatesModal}
      {logModal}
    </>
  );
}

export default ViewProgram;
