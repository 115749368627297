import "./LinkModal.css";
import IconButton from "./IconButton";
import Image from "../components/Image";
import LoadIndicator from "./LoadIndicator";
import Modal from "../components/Modal";
import Placeholder from "./Placeholder";
import RequestContainer from "./RequestContainer";
import algoliasearch from "algoliasearch";
import config from "../config";
import toast from "../lib/toast";
import { Link } from "react-feather";
import { get, trim } from "lodash";
import { useRequest } from "../hooks/useRequest";
import { useState, useRef, useEffect } from "react";
import { useThrottle } from "../hooks/useThrottle";

const isUUID = (value) =>
  /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i.test(
    trim(value)
  );

const client = algoliasearch(
  config.algolia.applicationId,
  config.algolia.apiKey
);

function LinkModal(props) {
  const [id, setId] = useState(null);
  const [query, setQuery] = useState("");
  const linkRequest = useRequest(props.linkRequest);
  const getRequest = useRequest(props.getRequest);
  const searchRequest = useRequest(props.searchRequest);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const searchField = useRef(null);

  useEffect(() => searchField.current && searchField.current.focus(), []);

  const getRequestExecute = useThrottle(
    async (value, callback) => getRequest.execute(value),
    () => {},
    1000
  );
  const searchRequestExecute = useThrottle(
    async (value, callback) => {
      if (props.searchIndex) {
        setIsSearching(true);
        client
          .initIndex(props.searchIndex)
          .search(value)
          .then(({ hits }) => {
            setSearchResults(hits);
          })
          .finally(() => setIsSearching(false));
      } else {
        searchRequest.execute(value);
      }
    },
    () => {},
    1000
  );

  const handleLink = () => {
    linkRequest.execute({ [props.keyName]: id, ...props.params }, () => {
      toast(props.toastMessage);
      props.onClose();
      props.onLink();
    });
  };

  let results = [];

  if (get(searchRequest.response, "data")) {
    results = [...results, ...get(searchRequest.response, "data")];
  }

  if (props.searchTransform && searchResults) {
    results = [...results, ...searchResults.map(props.searchTransform)];
  }

  if (isUUID(query) && get(getRequest.response, "data")) {
    results = [
      {
        id: getRequest.response.data.id,
        resultType: props.resultType,
        flavor: query,
        name: get(getRequest.response.data, props.nameAttribute),
      },
      ...results,
    ];
  }

  results = results.filter((result) =>
    [result.result_type, result.resultType].includes(props.resultType)
  );

  const search = (value) => {
    setId(null);

    if (trim(value)) {
      searchRequestExecute(trim(value));

      if (props.getRequest && isUUID(value)) {
        getRequestExecute(trim(value));
      }
    }
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
    search(event.target.value);
  };

  const handleFocus = () => search(query);

  return (
    <Modal
      title={props.title}
      size="lg"
      body={
        <RequestContainer
          loading={linkRequest.loading}
          error={linkRequest.error}
          fullscreen={true}
        >
          {props.description}
          <input
            className="form-control mb-3"
            type="search"
            placeholder={props.placeholder}
            value={query}
            onChange={handleChange}
            onFocus={handleFocus}
            ref={searchField}
          />

          {trim(query) && results.length > 0 && (
            <LoadIndicator
              loading={
                searchRequest.loading || isSearching || getRequest.loading
              }
              minHeight="3.8125rem"
              spinnerSize="2.5rem"
            >
              <Placeholder empty={results.length === 0}>
                {results.map((result, index) => (
                  <div
                    key={index}
                    onClick={() => setId(id === result.id ? null : result.id)}
                    className={`p-2 clearfix link-result ${
                      index > 0 ? "border-top" : ""
                    } ${id === result.id ? "active" : ""}`}
                  >
                    {result.img && (
                      <div
                        className="text-center float-start me-2"
                        style={{ width: "3rem" }}
                      >
                        <Image
                          src={result.img}
                          alt=""
                          style={{
                            maxWidth: "3rem",
                            maxHeight: "3rem",
                          }}
                        />
                      </div>
                    )}
                    {result.name && (
                      <p className="link-result-title m-0 text-truncate">
                        {result.name}
                      </p>
                    )}
                    {result.flavor && (
                      <p className="link-result-subtitle small text-truncate m-0">
                        {result.flavor}
                      </p>
                    )}
                  </div>
                ))}
              </Placeholder>
            </LoadIndicator>
          )}
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            icon={props.buttonIcon || Link}
            className="btn btn-success"
            disabled={id === null}
            onClick={handleLink}
          >
            {props.buttonCaption || "Link"}
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default LinkModal;
