import DeleteModal from "../components/DeleteModal";
import { deleteFee } from "../clients/finance";

function DeleteFeeModal(props) {
  return (
    <DeleteModal
      request={deleteFee}
      id={props.id}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The fee was deleted"
      title="Delete fee?"
      confirmText="DELETE"
      message={
        <p className="m-0">Do you want to delete the fee permanently?</p>
      }
    />
  );
}

export default DeleteFeeModal;
