import ArtistsTable from "../../tables/ArtistsTable";
import Authorizer from "../../components/Authorizer";
import DeleteEventModal from "../../modals/DeleteEventModal";
import IconButton from "../../components/IconButton";
import IconLinkButton from "../../components/IconLinkButton";
import LinkArtistModal from "../../modals/LinkArtistModal";
import LinkSaleModal from "../../modals/LinkSaleModal";
import LinkVenueModal from "../../modals/LinkVenueModal";
import LogModal from "../../modals/LogModal";
import MergeEventModal from "../../modals/MergeEventModal";
import ObjectPropertyList from "../../components/ObjectPropertyList";
import RequestContainer from "../../components/RequestContainer";
import SaleSyncModal from "../../modals/SaleSyncModal";
import SalesTable from "../../tables/SalesTable";
import TabCard from "../../components/TabCard";
import UploadImageModal from "../../modals/UploadImageModal";
import VenuesTable from "../../tables/VenuesTable";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import WorksTable from "../../tables/WorksTable";
import toast from "../../lib/toast";
import {
  Edit,
  PlusCircle,
  Link,
  Copy,
  RefreshCw,
  XCircle,
  UploadCloud,
  GitMerge,
  Clock,
} from "react-feather";
import {
  getEvent,
  countByEvent,
  updateEvent,
  listArtistsByEvent,
  listWorksByEvent,
  linkEventArtist,
  linkEventVenue,
  unlinkEventArtist,
  unlinkEventVenue,
  unlinkEventWork,
  mergeEvent,
  syncEventArtistsFromWorks,
} from "../../clients/art";
import { get } from "lodash";
import {
  listSalesByEvent,
  linkSaleEvent,
  unlinkSaleEvent,
} from "../../clients/finance";
import { useAutoRequest, useRequest } from "../../hooks/useRequest";
import { useHistory } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import { useParams } from "react-router-dom";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useRefresh } from "../../hooks/useRefresh";

function ViewEvent() {
  const [activeTab, setActiveTab] = usePersistentState(
    null,
    "ViewEvent:activeTab"
  );
  let { event_id: id } = useParams();
  const [refreshToken, refresh] = useRefresh(id);
  const getEventRequest = useAutoRequest(getEvent, id);
  const countByEventRequest = useAutoRequest(countByEvent, id);
  const updateEventRequest = useRequest(updateEvent);
  const syncEventArtistsFromWorksRequest = useRequest(
    syncEventArtistsFromWorks,
    id
  );
  let history = useHistory();

  const event = getEventRequest.response.data || {};

  let [showMergeEventModal, mergeEventModal] = useModal(MergeEventModal, {
    linkRequest: mergeEvent,
    params: { targetId: id },
    targetName: event.name,
    onLink: refresh,
  });
  let [showLinkArtistModal, linkArtistModal] = useModal(LinkArtistModal, {
    linkRequest: linkEventArtist,
    params: { eventId: id },
    onLink: refresh,
  });
  let [showLinkSaleModal, linkSaleModal] = useModal(LinkSaleModal, {
    linkRequest: linkSaleEvent,
    params: { eventId: id },
    onLink: refresh,
  });
  let [showLinkVenueModal, linkVenueModal] = useModal(LinkVenueModal, {
    linkRequest: linkEventVenue,
    params: { eventId: id },
    onLink: () => getEventRequest.execute(null, refresh),
  });
  let [showSaleSyncModal, saleSyncModal] = useModal(SaleSyncModal, {
    eventId: id,
    onSync: () => getEventRequest.execute(null, refresh),
  });
  let [showLogModal, logModal] = useModal(LogModal, {
    filter: {
      type: "event",
      id,
    },
  });

  let [showUploadImageModal, uploadImageModal] = useModal(UploadImageModal, {
    skipCreateImage: true,
    onSave: (url) =>
      updateEventRequest.execute(
        { id, data: { ...event, image_url: url } },
        () => getEventRequest.execute(id, () => toast("The image was uploaded"))
      ),
  });

  let [showDeleteEventModal, deleteEventModal] = useModal(DeleteEventModal, {
    id: event.id,
    name: event.name,
    onDelete: () => {
      history.push("/events");
    },
  });

  return (
    <>
      <ViewHeader
        breadcrumbs={[`${event.name || ""}`]}
        actions={
          <>
            <Authorizer role={["admin", "editor"]}>
              <IconButton
                onClick={showMergeEventModal}
                icon={GitMerge}
                className="btn btn-success me-2"
              >
                Merge
              </IconButton>
              <IconButton
                onClick={showUploadImageModal}
                icon={UploadCloud}
                className="btn btn-success me-2"
              >
                Upload image
              </IconButton>
              <IconLinkButton
                to={`/events/copy/${id}`}
                icon={Copy}
                className="btn btn-secondary me-2"
              >
                Duplicate
              </IconLinkButton>
              <IconButton
                onClick={showLogModal}
                icon={Clock}
                className="btn btn-secondary me-2"
              >
                Log
              </IconButton>
              <IconLinkButton
                to={`/events/${id}/edit`}
                icon={Edit}
                className="btn btn-secondary me-2"
              >
                Edit
              </IconLinkButton>
            </Authorizer>
            <Authorizer role="admin">
              <IconButton
                icon={XCircle}
                onClick={showDeleteEventModal}
                className="btn btn-secondary"
              >
                Delete
              </IconButton>
            </Authorizer>
          </>
        }
      />
      <ViewContents>
        <RequestContainer
          loading={syncEventArtistsFromWorksRequest.loading}
          error={syncEventArtistsFromWorksRequest.error}
        >
          <RequestContainer
            loading={getEventRequest.loading}
            error={getEventRequest.error}
          >
            <ObjectPropertyList
              items={[
                ["ID", event.id],
                ["Name", event.name],
                ["Featured", { bool: event.featured }],
                ["Image", { image: event.image_url }],
                ["Dates", event.dates],
                ["Description", event.description],
                ["Alternate Location", event.locationAlt],
                ["URL", { url: event.url }],
                ["Online Event URL", { url: event.online_event_url }],
                ["Start Date", { date: event.startDate }],
                ["End Date", { date: event.endDate }],
                ["Type", event.eventType],
                ["Live/Online", event.eventLive],
                ["Priority", event.priority],
              ]}
            />
          </RequestContainer>

          <TabCard
            activeTab={activeTab}
            onTabChange={setActiveTab}
            tabs={{
              Artists: {
                title:
                  get(countByEventRequest, "response.data.artists") !==
                  undefined
                    ? `Artists (${get(
                        countByEventRequest,
                        "response.data.artists"
                      )})`
                    : null,
                actions: (
                  <Authorizer role={["admin", "editor"]}>
                    <IconButton
                      onClick={showLinkArtistModal}
                      icon={Link}
                      className="btn btn-secondary me-2"
                    >
                      Link artist
                    </IconButton>
                    <IconLinkButton
                      to={`/artists/new?eventId=${id}`}
                      icon={PlusCircle}
                      className="btn btn-success"
                    >
                      Create artist
                    </IconLinkButton>
                  </Authorizer>
                ),
                body: (
                  <ArtistsTable
                    key={refreshToken}
                    params={{ id }}
                    request={listArtistsByEvent}
                    unlinkRequest={unlinkEventArtist}
                    unlinkParams={{ eventId: id }}
                  />
                ),
              },
              Sales: {
                actions: (
                  <Authorizer role={["admin", "editor"]}>
                    <IconButton
                      onClick={showLinkSaleModal}
                      icon={Link}
                      className="btn btn-secondary me-2"
                    >
                      Link sale
                    </IconButton>
                    <IconLinkButton
                      to={`/sales/new?eventId=${id}`}
                      icon={PlusCircle}
                      className="btn btn-success"
                    >
                      Create sale
                    </IconLinkButton>
                  </Authorizer>
                ),
                body: (
                  <SalesTable
                    key={refreshToken}
                    params={{ id }}
                    request={listSalesByEvent}
                    unlinkRequest={unlinkSaleEvent}
                    unlinkParams={{ eventId: id }}
                    extraActions={(sale) => (
                      <IconButton
                        onClick={() => showSaleSyncModal({ saleId: sale.id })}
                        icon={RefreshCw}
                        className="btn-sm btn-outline-primary me-2"
                      >
                        Sync
                      </IconButton>
                    )}
                  />
                ),
              },
              Venues: {
                title: get(event, "venues")
                  ? `Venues (${event.venues.length})`
                  : null,
                actions: (
                  <Authorizer role={["admin", "editor"]}>
                    <IconButton
                      onClick={showLinkVenueModal}
                      icon={Link}
                      className="btn btn-secondary me-2"
                    >
                      Link venue
                    </IconButton>
                    <IconLinkButton
                      to={`/venues/new?eventId=${id}`}
                      icon={PlusCircle}
                      className="btn btn-success"
                    >
                      Create venue
                    </IconLinkButton>
                  </Authorizer>
                ),
                body: (
                  <VenuesTable
                    key={refreshToken}
                    params={{ id }}
                    request={getEventRequest}
                    unlinkRequest={unlinkEventVenue}
                    unlinkParams={{ eventId: id }}
                    eagerLoaded="venues"
                  />
                ),
              },
              Works: {
                title:
                  get(countByEventRequest, "response.data.works") !== undefined
                    ? `Works (${get(
                        countByEventRequest,
                        "response.data.works"
                      )})`
                    : null,
                actions: (
                  <Authorizer role={["admin", "editor"]}>
                    <IconButton
                      onClick={() => syncEventArtistsFromWorksRequest.execute()}
                      icon={RefreshCw}
                      className="btn btn-secondary me-2"
                    >
                      Sync artists from works
                    </IconButton>
                    <IconLinkButton
                      to={`/works/new?eventId=${id}`}
                      icon={PlusCircle}
                      className="btn btn-success"
                    >
                      Create work
                    </IconLinkButton>
                  </Authorizer>
                ),
                body: (
                  <WorksTable
                    params={{ id }}
                    request={listWorksByEvent}
                    unlinkRequest={unlinkEventWork}
                    unlinkParams={{ eventId: id }}
                  />
                ),
              },
            }}
          />
        </RequestContainer>
      </ViewContents>

      {linkArtistModal}
      {linkSaleModal}
      {linkVenueModal}
      {mergeEventModal}
      {uploadImageModal}
      {saleSyncModal}
      {deleteEventModal}
      {logModal}
    </>
  );
}

export default ViewEvent;
