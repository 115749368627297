import "./Search.css";
import Image from "../components/Image";
import LoadIndicator from "./LoadIndicator";
import Placeholder from "./Placeholder";
import Popover from "./Popover";
import algoliasearch from "algoliasearch";
import config from "../config";
import { HelpCircle } from "react-feather";
import { Link } from "react-router-dom";
import { get, trim } from "lodash";
import { search as financeSearch } from "../clients/finance";
import { useLocation } from "react-router-dom";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { usePersistentState } from "../hooks/usePersistentState";
import { useRequest } from "../hooks/useRequest";
import { useState, useRef, useEffect } from "react";
import { useThrottle } from "../hooks/useThrottle";

const client = algoliasearch(
  config.algolia.applicationId,
  config.algolia.apiKey
);

const pathFor = (result) => {
  if (result.resultType === "event") {
    return `/events/${result.id}`;
  } else if (result.resultType === "venue") {
    return `/venues/${result.id}`;
  } else if (result.resultType === "artist") {
    return `/artists/${result.id}`;
  } else if (result.resultType === "work") {
    return `/works/${result.id}`;
  } else if (result.resultType === "offer") {
    return `/sales/${result.parentId}/offers/${result.id}`;
  } else if (result.resultType === "sale") {
    return `/sales/${result.id}`;
  } else {
    return null;
  }
};

function Search() {
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const [query, setQuery] = usePersistentState("", "Search:query");
  const financeSearchRequest = useRequest(financeSearch);
  const [artResults, setArtResults] = useState([]);
  const [artSearchInProgress, setArtSearchInProgress] = useState(false);
  const artSearchExecute = useThrottle(
    async (value, callback) => {
      setArtSearchInProgress(true);
      client
        .multipleQueries([
          { indexName: "venues", query: value, params: { hitsPerPage: 10 } },
          { indexName: "artists", query: value, params: { hitsPerPage: 10 } },
          { indexName: "events", query: value, params: { hitsPerPage: 10 } },
          { indexName: "works", query: value, params: { hitsPerPage: 10 } },
        ])
        .then((res) => setArtResults(res.results))
        .finally(() => setArtSearchInProgress(false));
    },
    () => {},
    1000
  );
  const financeSearchExecute = useThrottle(
    async (value, callback) => financeSearchRequest.execute(value),
    () => {},
    1000
  );
  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, hide);

  const location = useLocation();
  useEffect(hide, [location]);

  let results = [];

  if (artResults) {
    for (const index of artResults) {
      switch (index.index) {
        case "artists":
          results = [
            ...results,
            ...index.hits.map((hit) => ({
              resultType: "artist",
              name: hit.name,
              flavor: hit.dates,
              img: hit.artistImage,
              id: hit.objectID,
            })),
          ];
          break;
        case "events":
          results = [
            ...results,
            ...index.hits.map((hit) => ({
              resultType: "event",
              name: hit.name,
              flavor: hit.eventType,
              img: hit.image_url,
              id: hit.objectID,
            })),
          ];
          break;
        case "venues":
          results = [
            ...results,
            ...index.hits.map((hit) => ({
              resultType: "venue",
              name: hit.name,
              flavor: hit.subheading || hit.city,
              img: hit.imageUrl,
              id: hit.objectID,
            })),
          ];
          break;
        case "works":
          results = [
            ...results,
            ...index.hits.map((hit) => ({
              resultType: "work",
              name: hit.title,
              flavor: hit.artists && hit.artists.join(", "),
              img: hit.imageUrl,
              id: hit.objectID,
            })),
          ];
          break;
        default:
          break;
      }
    }
  }

  if (get(financeSearchRequest.response, "data")) {
    results = [...results, ...get(financeSearchRequest.response, "data")];
  }

  const handleChange = (event) => {
    show();
    setQuery(event.target.value);

    const value = trim(event.target.value);

    if (value) {
      artSearchExecute(value);
      financeSearchExecute(value);
    }
  };

  const handleFocus = () => {
    show();

    const value = trim(query);

    if (value) {
      artSearchExecute(value);
      financeSearchExecute(value);
    }
  };

  return (
    <div
      className="position-relative d-flex align-items-center"
      ref={wrapperRef}
    >
      <Popover
        content={
          <>
            <p>Search for sales and offers by name, external ID or URL.</p>
            <p className="mb-0">
              Search for artists, events, and venues by name.
            </p>
          </>
        }
        width={300}
      >
        {(onClick) => (
          <div
            className="text-white-50"
            style={{ cursor: "pointer" }}
            onClick={onClick}
          >
            <HelpCircle size={20} />
          </div>
        )}
      </Popover>

      <input
        className="form-control ms-2"
        type="search"
        placeholder="Search everything&hellip;"
        value={query}
        onChange={handleChange}
        onFocus={handleFocus}
      />

      {visible && trim(query) && results.length > 0 && (
        <div className="position-absolute end-0 bg-white shadow-sm rounded-2 border search-results">
          <LoadIndicator
            loading={artSearchInProgress || financeSearchRequest.loading}
            minHeight="3.8125rem"
            spinnerSize="2.5rem"
          >
            <Placeholder empty={results.length === 0}>
              {results
                .filter((result) => pathFor(result))
                .map((result, index) => (
                  <Link
                    to={pathFor(result)}
                    key={index}
                    className={`d-block text-nowrap text-decoration-none search-result ${
                      index > 0 ? "border-top" : ""
                    }`}
                  >
                    <div className="d-flex">
                      <div className="p-2 pe-0 small search-result-type">
                        <span className="badge bg-dark">
                          {result.resultType}
                        </span>
                      </div>
                      <div className="p-2 ps-0 search-result-name">
                        {result.name && (
                          <p className="text-dark m-0 text-truncate">
                            {result.name}
                          </p>
                        )}
                        {result.flavor && (
                          <p className="small text-truncate text-muted m-0">
                            {result.flavor}
                          </p>
                        )}
                      </div>
                      {result.img && (
                        <div
                          className="text-center p-2"
                          style={{
                            width: "4rem",
                            minWidth: "4rem",
                            maxWidth: "4rem",
                          }}
                        >
                          <Image
                            src={result.img}
                            alt=""
                            style={{
                              maxWidth: "3rem",
                              maxHeight: "3rem",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Link>
                ))}
            </Placeholder>
          </LoadIndicator>
        </div>
      )}
    </div>
  );
}

export default Search;
