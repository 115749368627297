import IconButton from "../../components/IconButton";
import BidForm from "../../forms/BidForm";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Link } from "react-router-dom";
import { Save } from "react-feather";
import { getBid, updateBid, getOffer, getSale } from "../../clients/finance";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function EditBid() {
  let { sale_id: saleId, offer_id: offerId, bid_id: id } = useParams();
  const getSaleRequest = useAutoRequest(getSale, saleId);
  const getOfferRequest = useAutoRequest(getOffer, offerId);
  const updateBidRequest = useRequest(updateBid);
  const getBidRequest = useAutoRequest(getBid, id);
  let history = useHistory();

  const bid = getBidRequest.response.data || {};
  const sale = getSaleRequest.response.data || {};
  const offer = getOfferRequest.response.data || {};
  const loading = getBidRequest.loading || updateBidRequest.loading;
  const error = getBidRequest.error || updateBidRequest.error;

  return (
    <BidForm
      initialValues={getBidRequest.response.data}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateBidRequest.execute({ id, data: values }, () => {
          history.goBack();
          toast(`The bid was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[
              <Link to={`/sales/${saleId}`}>{`${
                sale.title || sale.externalId || ""
              }`}</Link>,
              <Link to={`/sales/${saleId}/offers/${offerId}`}>{`${
                offer.offerType || ""
              } ${offer.sequenceNumber || ""}`}</Link>,
              `Bid ${bid.bidNumber}`,
            ]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </BidForm>
  );
}

export default EditBid;
