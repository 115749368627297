import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import PaginatedContainer from "../components/PaginatedContainer";
import RequestContainer from "../components/RequestContainer";
import TruncatedText from "../components/TruncatedText";
import { get } from "lodash";
import { useAutoRequest } from "../hooks/useRequest";
import { useState } from "react";

function UsersTable(props) {
  const [params, setParams] = useState({
    ...props.params,
  });
  const request = useAutoRequest(props.request, params);

  const users = request.response.data || [];
  const responseParams = get(request.response, "config.params") || params;

  const handleParamsChange = (newParams) => {
    const allParams = { ...params, ...newParams };

    if (!("skip" in newParams)) {
      allParams.skip = 0;
    }

    setParams(allParams);

    if (props.onParamsChange) {
      props.onParamsChange(allParams);
    }
  };

  const table = (
    <ObjectTable
      columns={[
        {
          title: "Username",
          filterable: true,
          filterSearch: true,
          currentFilter:
            params.searchBy === "profile.userName" ? params.query : null,
          onFilter: (query) =>
            handleParamsChange({
              searchBy: "profile.userName",
              query,
            }),
        },
        {
          title: "Display name",
          filterable: true,
          filterSearch: true,
          currentFilter:
            params.searchBy === "profile.displayName" ? params.query : null,
          onFilter: (query) =>
            handleParamsChange({
              searchBy: "profile.displayName",
              query,
            }),
        },
        {
          title: "Email",
          filterable: true,
          filterSearch: true,
          currentFilter:
            params.searchBy === "profile.email" ? params.query : null,
          onFilter: (query) =>
            handleParamsChange({
              searchBy: "profile.email",
              query,
            }),
        },
        {
          title: "Role",
          sortable: false,
          filterable: true,
          currentFilter: params.role,
          filterOptions: ["admin", "curator", "editor"],
          onFilter: (role) => handleParamsChange({ role }),
        },
      ]}
    >
      {users.map((user) => (
        <ObjectTableRow
          actionAuthorizerRole="admin"
          viewPath={`/users/${user.id}`}
          clipboardValue={user.id}
          key={user.id}
          columns={[
            <TruncatedText max={40}>{user["profile.userName"]}</TruncatedText>,
            <TruncatedText max={40}>
              {user["profile.displayName"]}
            </TruncatedText>,
            <TruncatedText max={40}>{user["profile.email"]}</TruncatedText>,
            user.role,
          ]}
        />
      ))}
    </ObjectTable>
  );

  return (
    <RequestContainer loading={request.loading}>
      {props.paginated ? (
        <PaginatedContainer
          enabled={!request.loading}
          count={users.length}
          params={responseParams}
          onNavigate={handleParamsChange}
        >
          {table}
        </PaginatedContainer>
      ) : (
        table
      )}
    </RequestContainer>
  );
}

export default UsersTable;
