import DeleteImageModal from "../modals/DeleteImageModal";
import Image from "../components/Image";
import ImageDimensions from "../components/ImageDimensions";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import RequestContainer from "../components/RequestContainer";
import TruncatedText from "../components/TruncatedText";
import { CheckSquare } from "react-feather";
import { get } from "lodash";

function ImagesTable(props) {
  const event = props.request.response.data || {};
  const images = get(event, "images") || [];

  return (
    <RequestContainer loading={props.request.loading}>
      <ObjectTable
        columns={[
          "",
          "Name",
          "Size",
          "Type",
          "Copyright",
          "Attribution",
          "Source",
          "Priority",
          "Design background",
        ]}
      >
        {images.map((image) => (
          <ObjectTableRow
            actionAuthorizerRole={["admin", "editor"]}
            deleteAuthorizerRole="admin"
            editPath={`/works/${props.work.id}/images/${image.id}/edit`}
            deleteModal={DeleteImageModal}
            deleteModalParams={{
              id: image.id,
              title: image.title,
              work: props.work,
              onDelete: () => props.request.execute(),
            }}
            key={image.id}
            columns={[
              image.url ? (
                <div className="text-center" style={{ width: "7rem" }}>
                  <Image
                    src={image.url}
                    alt=""
                    style={{
                      maxWidth: "6rem",
                      maxHeight: "6rem",
                    }}
                  />
                </div>
              ) : null,
              <TruncatedText max={40}>{image.name}</TruncatedText>,
              <ImageDimensions src={image.url} />,
              image.imageType,
              <TruncatedText max={40}>{image.copyright}</TruncatedText>,
              <TruncatedText max={40}>{image.attribution}</TruncatedText>,
              <TruncatedText max={40}>{image.source}</TruncatedText>,
              image.priority,
              image.design_background ? (
                <span className="text-success">
                  <CheckSquare size={20} />
                </span>
              ) : (
                ""
              ),
            ]}
          />
        ))}
      </ObjectTable>
    </RequestContainer>
  );
}

export default ImagesTable;
