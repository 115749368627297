import { Key } from "react-feather";
import { useState, useEffect } from "react";
import {
  authenticateUsingGoogle,
  authenticateUsingEmail,
  reauthenticate,
} from "../lib/firebase";

function Authenticator(props) {
  const [signedIn, setSignedIn] = useState(false);
  const [signingIn, setSigningIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signIn = (success) => {
    setSigningIn(false);
    setSignedIn(success);
  };

  const signInError = () => {
    setSigningIn(false);
    setSignedIn(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSigningIn(true);
    authenticateUsingEmail(email, password, signIn, signInError);
  };

  useEffect(() => {
    setSigningIn(true);
    reauthenticate(signIn);
  }, []);

  return signedIn ? (
    props.children
  ) : (
    <div className="bg-dark text-light vw-100 vh-100 d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h3 className="mb-5">Arthur Admin</h3>

        {signingIn ? (
          <>
            <div
              className="spinner-grow"
              style={{ width: "5rem", height: "5rem" }}
            ></div>
          </>
        ) : (
          <>
            <button
              className="btn btn-light btn-lg d-inline-flex justify-content-center align-items-center w-100"
              onClick={() => {
                setSigningIn(true);
                authenticateUsingGoogle(signIn, signInError);
              }}
            >
              <Key size={16} />
              <span className="ms-2">Sign In with Google</span>
            </button>
            <p className="text-muted my-4">or</p>
            <form onSubmit={handleSubmit}>
              <input
                className="form-control mb-2"
                type="email"
                autoComplete="email"
                required={true}
                placeholder="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <input
                className="form-control mb-2"
                type="password"
                autoComplete="current-password"
                required={true}
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <input
                type="submit"
                value="Sign in"
                className="btn btn-secondary w-100"
              />
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default Authenticator;
