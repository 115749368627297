import { ExternalLink as Icon } from "react-feather";

function ExternalLink(props) {
  return props.href ? (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.icon ? (
        <Icon size={20} />
      ) : props.caption ? (
        props.caption
      ) : (
        props.href
      )}
    </a>
  ) : null;
}

ExternalLink.isLink = true;

export default ExternalLink;
