function ErrorMEssage(props) {
  if (props.error.stack) {
    return (
      <div className="alert alert-danger mb-4">
        <h5 className="alert-heading">An error occurred</h5>
        <hr />
        <pre>
          <code>{props.error.stack}</code>
        </pre>
      </div>
    );
  } else if (props.error.status === 400) {
    return (
      <div className="alert alert-info mb-4">
        <h5 className="alert-heading">No records found</h5>
        <p>{props.error.message}</p>
        <hr />
        <p className="mb-0 small">
          Encountered&nbsp;
          <code>
            HTTP {props.error.status} {props.error.statusText}
          </code>
          &nbsp;while accessing <code>{props.error.url}</code>
        </p>
      </div>
    );
  } else {
    return (
      <div className="alert alert-danger mb-4">
        <h5 className="alert-heading">An error occurred</h5>
        <p>{props.error.message}</p>
        <hr />
        <p className="mb-0 small">
          Encountered&nbsp;
          <code>
            HTTP {props.error.status} {props.error.statusText}
          </code>
          &nbsp;while accessing <code>{props.error.url}</code>
        </p>
      </div>
    );
  }
}

export default ErrorMEssage;
