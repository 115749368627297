import * as yup from "yup";
import ColumnRow from "../components/ColumnRow";
import GeocodedField from "../components/GeocodedField";
import ValidatedField from "../components/ValidatedField";
import { Formik, Form } from "formik";
import { prepareFormValues, returnFormValues } from "../lib/form";
import { useState } from "react";

function SaleForm(props) {
  const [geocodedLocation, setGeocodedLocation] = useState(null);

  const handleGeocode = (location) => {
    if (location) {
      setGeocodedLocation(location.geometry.location);
    } else {
      setGeocodedLocation(null);
    }
  };

  return (
    <Formik
      initialValues={prepareFormValues(
        {
          externalId: "",
          externalDisplayId: "",
          sellerName: "",
          source: "bonhams",
          saleType: "",
          startDate: "",
          startTime: "",
          endDate: "",
          title: "",
          location: "",
          url: "",
          pricesAvailable: false,
          ...props.initialValues,
        },
        {
          types: {
            startDate: "date",
            startTime: "datetime",
            endDate: "date",
          },
        }
      )}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(returnFormValues(values), ...args)
      }
      validationSchema={yup.object({
        externalId: yup.string().required(),
        source: yup.string().required(),
        externalDisplayId: yup.string(),
        sellerName: yup.string(),
        saleType: yup.string(),
        startDate: yup.object({ date: yup.date().nullable() }),
        startTime: yup.object({ datetime: yup.date().nullable() }),
        endDate: yup.object({ date: yup.date().nullable() }),
        title: yup.string(),
        location: yup.string(),
        url: yup.string().url(),
        pricesAvailable: yup.boolean(),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <h5>Basic Information</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Source"
                    name="source"
                    as="select"
                  >
                    <option value="arthur">arthur</option>
                    <option value="bonhams">bonhams</option>
                    <option value="christies">christies</option>
                    <option value="invaluable">invaluable</option>
                    <option value="phillips">phillips</option>
                    <option value="sothebys">sothebys</option>
                  </ValidatedField>,
                  <ValidatedField
                    formik={formik}
                    label="External ID"
                    name="externalId"
                    type="text"
                  />,
                ]}
              />

              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Seller name"
                    name="sellerName"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Display ID"
                    name="externalDisplayId"
                    type="text"
                  />,
                ]}
              />

              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Title"
                    name="title"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Type"
                    name="saleType"
                    as="select"
                  >
                    <option value=""></option>
                    <option value="live">live</option>
                    <option value="online">online</option>
                    <option value="live + online">live + online</option>
                  </ValidatedField>,
                ]}
              />

              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Start date"
                    name="startDate"
                    type="date"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="End date"
                    name="endDate"
                    type="date"
                  />,
                ]}
              />

              <ColumnRow
                columns={[
                  <GeocodedField
                    formik={formik}
                    label="Location"
                    name="location"
                    type="text"
                    onGeocode={handleGeocode}
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Start time"
                    name="startTime"
                    type="datetime-local"
                    location={geocodedLocation}
                  />,
                ]}
              />

              <ValidatedField
                formik={formik}
                label="URL"
                name="url"
                type="url"
              />

              <ValidatedField
                formik={formik}
                label="Prices available"
                name="pricesAvailable"
                type="checkbox"
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default SaleForm;
