import { formatInTimeZone, toDate } from "date-fns-tz";
import { formatRange } from "./range";
import { isString } from "lodash";

const formatDate = (...values) => {
  if (values.length > 1) {
    return formatRange(formatDate(values[0]), formatDate(values[1]));
  } else if (isString(values[0])) {
    if (values[0].includes("T")) {
      return formatInTimeZone(
        toDate(values[0], { timeZone: "UTC" }),
        "UTC",
        "MMMM d y, h:mm:ss a"
      );
    } else {
      return formatInTimeZone(
        toDate(values[0], { timeZone: "UTC" }),
        "UTC",
        "eee, MMMM d y"
      );
    }
  } else {
    return values[0];
  }
};

export { formatDate };
