import RequestContainer from "../components/RequestContainer";
import ViewContents from "../components/ViewContents";
import { formatNumber } from "../lib/number";
import { get } from "lodash";
import { getStats } from "../clients/finance";
import { useAutoRequest } from "../hooks/useRequest";

function Home() {
  const getStatsRequest = useAutoRequest(getStats);

  const stats = get(getStatsRequest.response, "data") || [];

  const loading = getStatsRequest.loading;
  const error = getStatsRequest.error;

  return (
    <ViewContents>
      <RequestContainer loading={loading} error={error}>
        <div className="row py-5">
          <div className="col text-center">
            <p className="lead m-0">Sales</p>
            <h1>{formatNumber(stats.saleCount)}</h1>
          </div>
          <div className="col text-center">
            <p className="lead m-0">Offers</p>
            <h1>{formatNumber(stats.offerCount)}</h1>
          </div>
          <div className="col text-center">
            <p className="lead m-0">Bids</p>
            <h1>{formatNumber(stats.bidCount)}</h1>
          </div>
        </div>
      </RequestContainer>
    </ViewContents>
  );
}

export default Home;
