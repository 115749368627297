import ProgramForm from "../../forms/ProgramForm";
import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { createProgram } from "../../clients/collection";
import { getUid } from "../../lib/firebase";
import { merge } from "lodash";
import { useHistory } from "react-router-dom";
import { useRequest } from "../../hooks/useRequest";

function NewProgram() {
  const createProgramRequest = useRequest(createProgram);
  let history = useHistory();

  const loading = createProgramRequest.loading;
  const error = createProgramRequest.error;

  return (
    <>
      <ProgramForm
        onSubmit={(values) => {
          createProgramRequest.execute(
            merge(
              {
                type: "program",
                creator: getUid(),
                artwork_populations: { works: [] },
              },
              values
            ),
            (response) => {
              history.push(`/programs/${response.data.id}`);
              toast("The program was created");
            }
          );
        }}
      >
        {(fields) => (
          <>
            <ViewHeader
              breadcrumbs={["New program"]}
              actions={
                <IconButton
                  type="submit"
                  disabled={loading}
                  icon={Save}
                  className="btn btn-success"
                >
                  Save
                </IconButton>
              }
            />
            <ViewContents>
              <RequestContainer loading={loading} error={error}>
                {fields}
              </RequestContainer>
            </ViewContents>
          </>
        )}
      </ProgramForm>
    </>
  );
}

export default NewProgram;
