import LinkModal from "../components/LinkModal";
import { getArtist } from "../clients/art";

function LinkArtistModal(props) {
  return (
    <LinkModal
      linkRequest={props.linkRequest}
      params={props.params}
      onClose={props.onClose}
      onLink={props.onLink}
      placeholder="Search artists&hellip;"
      keyName="artistId"
      nameAttribute="name"
      getRequest={getArtist}
      searchIndex="artists"
      searchTransform={(hit) => ({
        resultType: "artist",
        name: hit.name,
        flavor: hit.dates,
        img: hit.artistImage,
        id: hit.objectID,
      })}
      resultType="artist"
      title="Link artist"
      toastMessage="The artist was linked"
    />
  );
}

export default LinkArtistModal;
