import { map, keys } from "lodash";
import { useLocation } from "react-router-dom";
import { useState } from "react";

function TabCard(props) {
  const [activeTab, setActiveTab] = useState(
    props.activeTab && keys(props.tabs).includes(props.activeTab)
      ? props.activeTab
      : keys(props.tabs)[0]
  );
  const location = useLocation();

  const handleClick = (event, key) => {
    event.preventDefault();
    setActiveTab(key);
    if (props.onTabChange) {
      props.onTabChange(key);
    }
  };

  return (
    <div className="card mb-4 shadow-sm">
      <div className="card-header">
        <div className="row align-items-end" style={{ minHeight: "38px" }}>
          <div className="col">
            <ul className="nav nav-tabs card-header-tabs">
              {map(props.tabs, (value, key) => (
                <li className="nav-item" key={key}>
                  <a
                    className={`nav-link ${key === activeTab ? "active" : ""}`}
                    href={location.pathname}
                    onClick={(event) => handleClick(event, key)}
                  >
                    {value.title || key}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-auto text-end">
            {props.tabs &&
              props.tabs[activeTab] &&
              props.tabs[activeTab].actions}
            {props.actions}
          </div>
        </div>
      </div>
      <div className="card-body">
        {props.tabs && props.tabs[activeTab] && props.tabs[activeTab].body}
      </div>
    </div>
  );
}

export default TabCard;
