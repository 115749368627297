import * as yup from "yup";
import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import UserSearchField from "../components/UserSearchField";
import toast from "../lib/toast";
import { Formik, Form } from "formik";
import { Save } from "react-feather";
import { getUid } from "../lib/firebase";
import { prepareFormValues, returnFormValues } from "../lib/form";
import { useRequest } from "../hooks/useRequest";

function AssignUserModal(props) {
  const assignRequest = useRequest(props.request);

  const loading = assignRequest.loading;
  const error = assignRequest.error;

  const handleAssign = ({ assignee }) =>
    assignRequest.execute({ id: props.id, assignee }, () => {
      toast("Tasks assigned");
      props.onAssign();
      props.onClose();
    });

  return (
    <Modal
      title="Assign user"
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          <Formik
            initialValues={prepareFormValues({ assignee: getUid() })}
            onSubmit={(values) => handleAssign(returnFormValues(values))}
            validationSchema={yup.object({
              assignee: yup.string().required(),
            })}
          >
            {(formik) => (
              <Form id="assign-user">
                <UserSearchField
                  formik={formik}
                  label="Assignee"
                  name="assignee"
                  inline={true}
                  role={["admin", "editor"]}
                />
              </Form>
            )}
          </Formik>
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            type="submit"
            form="assign-user"
            disabled={loading}
            icon={Save}
            className="btn btn-success"
          >
            Save
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default AssignUserModal;
