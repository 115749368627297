import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import VenueForm from "../../forms/VenueForm";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { getVenue, updateVenue } from "../../clients/art";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function EditVenue() {
  let { venue_id: id } = useParams();
  const updateVenueRequest = useRequest(updateVenue);
  const getVenueRequest = useAutoRequest(getVenue, id);
  let history = useHistory();

  const venue = getVenueRequest.response.data || {};
  const loading = getVenueRequest.loading || updateVenueRequest.loading;
  const error = getVenueRequest.error || updateVenueRequest.error;

  return (
    <VenueForm
      initialValues={getVenueRequest.response.data}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateVenueRequest.execute({ id, data: values }, () => {
          history.goBack();
          toast(`The venue was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[`${venue.name || ""}`]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </VenueForm>
  );
}

export default EditVenue;
