import DeleteModal from "../components/DeleteModal";
import { deleteWork } from "../clients/art";

function DeleteWorkModal(props) {
  return (
    <DeleteModal
      request={deleteWork}
      id={props.id}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The work was deleted"
      title="Delete work?"
      confirmText={props.title}
      message={
        <p className="m-0">
          Do you want to delete&nbsp;
          <span className="text-muted">{props.title}</span>
          &nbsp;permanently?
        </p>
      }
    />
  );
}

export default DeleteWorkModal;
