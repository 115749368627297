import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import UserForm from "../../forms/UserForm";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import axios from "axios";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { pick } from "lodash";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

const getUserProfile = async (token, id) =>
  axios.get(`https://admin.arthuranalytics.com/api/getUserProfile/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

const updateUserProfile = async (token, data) =>
  axios.post(
    `https://admin.arthuranalytics.com/api/updateUserProfile/${data.id}`,
    pick(data, [
      "twitterHandle",
      "facebookHandle",
      "instagramHandle",
      "linkedinHandle",
      "website",
      "bio",
      "verified",
    ]),
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

function EditUser() {
  let { user_id: id } = useParams();
  const updateUserProfileRequest = useRequest(updateUserProfile);
  const getUserProfileRequest = useAutoRequest(getUserProfile, id);
  let history = useHistory();

  const user = getUserProfileRequest.response.data || {};
  const loading =
    getUserProfileRequest.loading || updateUserProfileRequest.loading;
  const error = getUserProfileRequest.error || updateUserProfileRequest.error;

  return (
    <UserForm
      initialValues={getUserProfileRequest.response.data}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateUserProfileRequest.execute({ id, ...values }, () => {
          history.goBack();
          toast(`The user was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[`${user.title || user.externalId || ""}`]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </UserForm>
  );
}

export default EditUser;
