import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import toast from "../lib/toast";
import { Calendar } from "react-feather";
import { formatDate } from "../lib/date";
import { getEvents, updateEvent } from "../clients/art";
import { parseISO, formatISO } from "date-fns";
import { useRequest } from "../hooks/useRequest";
import { useState } from "react";
import { virtualBulk } from "../clients/common";

const updateEventDates = async (token, params) => {
  const dates = formatDate(params.startDate, params.endDate);
  const events = await getEvents(token, params.events);

  if (!events.data) {
    return { data: null };
  }

  return await virtualBulk(
    token,
    events.data.map((event) => ({
      id: event.id,
      data: {
        ...event,
        dates,
        startDate: params.startDate,
        endDate: params.endDate,
      },
    })),
    updateEvent
  );
};

function EventBatchUpdateDatesModal(props) {
  const updateEventDatesRequest = useRequest(updateEventDates);
  const [startDate, setStartDate] = useState(
    formatISO(parseISO(props.program.start_time), {
      representation: "date",
    })
  );
  const [endDate, setEndDate] = useState(
    formatISO(parseISO(props.program.end_time), {
      representation: "date",
    })
  );

  const handleSave = () => {
    updateEventDatesRequest.execute(
      { events: props.events, startDate, endDate },
      () => {
        toast("Dates changed");
        props.onSave();
        props.onClose();
      }
    );
  };

  const loading = updateEventDatesRequest.loading;
  const error = updateEventDatesRequest.error;

  return (
    <Modal
      title="Change event dates"
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          <p>
            This will change the start and end date of{" "}
            {props.events.length > 1
              ? `all ${props.events.length} events`
              : "the event"}{" "}
            associated with this program.
          </p>

          <div className="row">
            <div className="col">
              <div className="form-floating mb-3">
                <input
                  id="startDate"
                  type="date"
                  className="form-control"
                  value={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
                  required={true}
                />
                <label htmlFor="startDate">Start date</label>
              </div>
            </div>
            <div className="col">
              <div className="form-floating mb-3">
                <input
                  id="endDate"
                  type="date"
                  className="form-control"
                  value={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
                  required={true}
                />
                <label htmlFor="endDate">End date</label>
              </div>
            </div>
          </div>
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            onClick={handleSave}
            icon={Calendar}
            className="btn btn-success"
            disabled={!startDate || !endDate}
          >
            Change dates
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default EventBatchUpdateDatesModal;
