import { isEmpty } from "lodash";

function PaginatedContainerLinks(props) {
  const previousEnabled = props.enabled && props.params.skip > 0;
  const nextEnabled = props.enabled && props.count >= props.params.limit;

  return (
    <nav>
      <ul className="pagination justify-content-center">
        <li className={`page-item ${previousEnabled ? "" : "disabled"}`}>
          <button
            className="page-link"
            onClick={() =>
              previousEnabled &&
              props.onNavigate({
                ...props.params,
                skip: Math.max(0, props.params.skip - props.params.limit),
              })
            }
          >
            Previous
          </button>
        </li>
        {!isEmpty(props.params) && (
          <li className="page-item disabled">
            <span className="page-link">
              Showing {props.params.skip} to {props.params.skip + props.count}
            </span>
          </li>
        )}
        <li className={`page-item ${nextEnabled ? "" : "disabled"}`}>
          <button
            className="page-link"
            onClick={() =>
              nextEnabled &&
              props.onNavigate({
                ...props.params,
                skip: props.params.skip + props.params.limit,
              })
            }
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
}

function PaginatedContainer(props) {
  return (
    <>
      <PaginatedContainerLinks
        enabled={props.enabled}
        params={props.params}
        count={props.count}
        onNavigate={props.onNavigate}
      />
      {props.children}
      <PaginatedContainerLinks
        enabled={props.enabled}
        params={props.params}
        count={props.count}
        onNavigate={props.onNavigate}
      />
    </>
  );
}

export default PaginatedContainer;
