import { useRef, useEffect } from "react";

function usePortal(id) {
  const root = useRef(null);

  useEffect(() => {
    const parent = document.getElementById(id);
    parent.appendChild(root.current);
    return () => root.current.remove();
  }, [id]);

  return (() => {
    if (!root.current) {
      root.current = document.createElement("div");
    }
    return root.current;
  })();
}

export { usePortal };
