import { Toast } from "bootstrap";

const toast = (message) => {
  const toast = document.createElement("div");
  toast.classList.add(
    "toast",
    "d-flex",
    "align-items-center",
    "text-light",
    "bg-dark"
  );
  toast.innerHTML = `<div class="toast-body">${message}</div><button type="button" class="btn-close btn-close-white ms-auto me-2" data-bs-dismiss="toast"></button>`;
  document.getElementById("toasts").appendChild(toast);

  const instance = new Toast(toast, { delay: 5000 });
  toast.addEventListener("hidden.bs.toast", () => {
    instance.dispose();
    toast.remove();
  });
  toast.addEventListener("click", () => instance.hide());
  instance.show();
};

export default toast;
