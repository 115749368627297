import DeleteVideoModal from "../modals/DeleteVideoModal";
import ExternalLink from "../components/ExternalLink";
import Image from "../components/Image";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import RequestContainer from "../components/RequestContainer";
import TruncatedText from "../components/TruncatedText";
import { get } from "lodash";

function VideosTable(props) {
  const event = props.request.response.data || {};
  const videos = get(event, "videos") || [];

  return (
    <RequestContainer loading={props.request.loading}>
      <ObjectTable
        columns={[
          "",
          "Name",
          "Type",
          "Copyright",
          "Attribution",
          "Source",
          "Priority",
          "Link",
        ]}
      >
        {videos.map((video) => (
          <ObjectTableRow
            actionAuthorizerRole={["admin", "editor"]}
            deleteAuthorizerRole="admin"
            editPath={`/works/${props.work.id}/videos/${video.id}/edit`}
            deleteModal={DeleteVideoModal}
            deleteModalParams={{
              id: video.id,
              title: video.title,
              work: props.work,
              onDelete: () => props.request.execute(),
            }}
            key={video.id}
            columns={[
              video.thumb_url ? (
                <div className="text-center" style={{ width: "7rem" }}>
                  <Image
                    src={video.thumb_url}
                    alt=""
                    style={{
                      maxWidth: "6rem",
                      maxHeight: "6rem",
                    }}
                  />
                </div>
              ) : null,
              <TruncatedText max={40}>{video.name}</TruncatedText>,
              video.videoType,
              <TruncatedText max={40}>{video.copyright}</TruncatedText>,
              <TruncatedText max={40}>{video.attribution}</TruncatedText>,
              <TruncatedText max={40}>{video.source}</TruncatedText>,
              video.priority,
              <ExternalLink
                href={`https://cdn.filestackcontent.com/${
                  video.converted_handle || video.handle
                }`}
                icon={true}
              />,
            ]}
          />
        ))}
      </ObjectTable>
    </RequestContainer>
  );
}

export default VideosTable;
