import * as yup from "yup";
import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import ValidatedField from "../components/ValidatedField";
import toast from "../lib/toast";
import { Formik, Form } from "formik";
import { Save } from "react-feather";
import { updateWork } from "../clients/art";
import { prepareFormValues, returnFormValues } from "../lib/form";
import { useRequest } from "../hooks/useRequest";

function AddWorkPriceModal(props) {
  const updateWorkRequest = useRequest(updateWork);

  const loading = updateWorkRequest.loading;
  const error = updateWorkRequest.error;

  const handleSave = ({ title, price, currency }) =>
    updateWorkRequest.execute(
      {
        id: props.work.id,
        data: {
          ...props.work,
          additionalPrices: {
            ...(props.work.additionalPrices || {}),
            [title]: { price, currency },
          },
        },
      },
      () => {
        toast("The price was added");
        props.onAdd();
        props.onClose();
      }
    );

  return (
    <Modal
      title="Add price"
      body={
        <RequestContainer loading={loading} error={error} fullscreen={true}>
          <Formik
            initialValues={prepareFormValues({
              title: "",
              price: "",
              currency: "",
            })}
            onSubmit={(values) => handleSave(returnFormValues(values))}
            validationSchema={yup.object({
              title: yup.string().required(),
              price: yup.number().positive().required(),
              currency: yup
                .string()
                .matches(/[A-Z]{3}/)
                .required(),
            })}
          >
            {(formik) => (
              <Form id="add-work-price">
                <ValidatedField
                  formik={formik}
                  label="Title"
                  name="title"
                  type="text"
                />
                <ValidatedField
                  formik={formik}
                  label="Price"
                  name="price"
                  type="text"
                />
                <ValidatedField
                  formik={formik}
                  label="Currency"
                  name="currency"
                  as="select"
                  currency={true}
                />
              </Form>
            )}
          </Formik>
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            type="submit"
            form="add-work-price"
            disabled={loading}
            icon={Save}
            className="btn btn-success"
          >
            Save
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default AddWorkPriceModal;
