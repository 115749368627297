import * as yup from "yup";
import ColumnRow from "../components/ColumnRow";
import ValidatedField from "../components/ValidatedField";
import { Formik, Form } from "formik";
import { prepareFormValues, returnFormValues } from "../lib/form";

function UserForm(props) {
  return (
    <Formik
      initialValues={prepareFormValues({
        twitterHandle: "",
        facebookHandle: "",
        instagramHandle: "",
        linkedinHandle: "",
        website: "",
        bio: "",
        verified: false,
        ...props.initialValues,
      })}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(returnFormValues(values), ...args)
      }
      validationSchema={yup.object({
        twitterHandle: yup.string(),
        facebookHandle: yup.string(),
        instagramHandle: yup.string(),
        linkedinHandle: yup.string(),
        website: yup.string().url(),
        bio: yup.string(),
        verified: yup.boolean(),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <h5>Basic Information</h5>
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Twitter handle"
                    name="twitterHandle"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Instagram handle"
                    name="instagramHandle"
                    type="text"
                  />,
                ]}
              />

              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Facebook handle"
                    name="facebookHandle"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="LinkedIn handle"
                    name="linkedinHandle"
                    type="text"
                  />,
                ]}
              />
              <ValidatedField
                formik={formik}
                label="Website"
                name="website"
                type="url"
              />
              <ValidatedField
                formik={formik}
                label="Bio"
                name="bio"
                as="textarea"
                style={{ height: "6rem" }}
              />
              <ValidatedField
                formik={formik}
                label="Verified"
                name="verified"
                type="checkbox"
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default UserForm;
