import * as yup from "yup";
import ColumnRow from "../components/ColumnRow";
import ValidatedField from "../components/ValidatedField";
import { Formik, Form } from "formik";
import { formatDate } from "../lib/date";
import { prepareFormValues, returnFormValues } from "../lib/form";

function EventForm(props) {
  const handleDateChange = (formik, event) => {
    const values = returnFormValues({
      target: event.target.value,
      startDate: formik.values.startDate,
      endDate: formik.values.endDate,
    });

    if (["startDate", "endDate"].includes(event.target.name)) {
      formik.setFieldValue(
        "dates",
        (event.target.name === "startDate"
          ? formatDate(values.target, values.endDate)
          : formatDate(values.startDate, values.target)) || ""
      );
    }

    return formik.handleChange(event);
  };

  return (
    <Formik
      initialValues={prepareFormValues(
        {
          name: "",
          dates: "",
          description: "",
          locationAlt: "",
          url: "",
          online_event_url: "",
          startDate: "",
          endDate: "",
          eventType: "",
          eventLive: "",
          priority: "",
          featured: "",
          ...props.initialValues,
        },
        {
          types: {
            startDate: "date",
            endDate: "date",
          },
        }
      )}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(returnFormValues(values), ...args)
      }
      validationSchema={yup.object({
        name: yup.string().required(),
        description: yup.string(),
        locationAlt: yup.string(),
        url: yup.string().url(),
        online_event_url: yup.string().url(),
        dates: yup.string().required(),
        startDate: yup.object({ date: yup.date().nullable() }),
        endDate: yup.object({ date: yup.date().nullable() }),
        eventType: yup.string(),
        eventLive: yup.string(),
        priority: yup.number().integer(),
        featured: yup.boolean(),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <h5>Basic Information</h5>
              <ValidatedField
                formik={formik}
                label="Name"
                name="name"
                type="text"
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Event Type"
                    name="eventType"
                    as="select"
                  >
                    <option value=""></option>
                    <option value="Art Fair">Art Fair</option>
                    <option value="Auction">Auction</option>
                    <option value="Book Launch">Book Launch</option>
                    <option value="Charity Event">Charity Event</option>
                    <option value="Class">Class</option>
                    <option value="Conference">Conference</option>
                    <option value="Exhibition">Exhibition</option>
                    <option value="Hackathon">Hackathon</option>
                    <option value="Open Studio">Open Studio</option>
                    <option value="OVR">OVR</option>
                    <option value="Panel Discussion">Panel Discussion</option>
                    <option value="Performance">Performance</option>
                    <option value="Reception">Reception</option>
                    <option value="Residency">Residency</option>
                    <option value="Screening">Screening</option>
                    <option value="Selling Exhibition">
                      Selling Exhibition
                    </option>
                    <option value="Talk">Talk</option>
                    <option value="Tour">Tour</option>
                    <option value="Virtual Tour">Virtual Tour</option>
                    <option value="Webinar">Webinar</option>
                    <option value="Workshop">Workshop</option>
                  </ValidatedField>,
                  <ValidatedField
                    formik={formik}
                    label="Live/Online"
                    name="eventLive"
                    as="select"
                  >
                    <option value=""></option>
                    <option value="live">live</option>
                    <option value="online">online</option>
                    <option value="live + online">live + online</option>
                  </ValidatedField>,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Start Date"
                    name="startDate"
                    type="date"
                    onChange={(event) => handleDateChange(formik, event)}
                  />,
                  <ValidatedField
                    formik={formik}
                    label="End Date"
                    name="endDate"
                    type="date"
                    onChange={(event) => handleDateChange(formik, event)}
                  />,
                ]}
              />
              <ValidatedField
                formik={formik}
                label="Dates"
                name="dates"
                type="text"
              />
              <ValidatedField
                formik={formik}
                label="Description"
                name="description"
                as="textarea"
                style={{ height: "6rem" }}
              />
              <ValidatedField
                formik={formik}
                label="Alternate Location"
                name="locationAlt"
                type="text"
              />
              <ValidatedField
                formik={formik}
                label="URL"
                name="url"
                type="url"
              />
              <ValidatedField
                formik={formik}
                label="Online Event URL"
                name="online_event_url"
                type="url"
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Priority"
                    name="priority"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Featured"
                    name="featured"
                    type="checkbox"
                  />,
                ]}
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default EventForm;
