import axios from "axios";
import { getToken } from "./firebase";

const logEvent = async (action, actionData, relatedEntity) =>
  axios.post(
    "https://admin.arthuranalytics.com/api/logEvent",
    { action, actionData, relatedEntity },
    { headers: { Authorization: `Bearer ${await getToken()}` } }
  );

const getEvents = async (type, id) =>
  axios.post(
    "https://admin.arthuranalytics.com/api/getEvents",
    { filter: { type, id } },
    { headers: { Authorization: `Bearer ${await getToken()}` } }
  );

export { logEvent, getEvents };
