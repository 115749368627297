import Image from "../components/Image";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import RequestContainer from "../components/RequestContainer";
import UserName from "../components/UserName";
import ZonedDateTime from "../components/ZonedDateTime";
import { CheckSquare } from "react-feather";
import { sortBy } from "lodash";
import { useAutoRequest } from "../hooks/useRequest";
import { useState } from "react";

function ProgramsTable(props) {
  const [params, setParams] = useState({
    order: ["created_at", "desc"],
    startTime: [null, null],
    endTime: [null, null],
    type: null,
    subtype: null,
    ...props.params,
  });

  const [requestParams, setRequestParams] = useState({
    type: props.params.type,
    subtype: props.params.subtype,
  });

  const request = useAutoRequest(props.request, requestParams);

  let programs = request.response.data || [];

  programs = programs.filter((program) => {
    if (
      params.startTime[0] &&
      program.start_time &&
      program.start_time < params.startTime[0]
    ) {
      return false;
    }

    if (
      params.startTime[1] &&
      program.start_time &&
      program.start_time > params.startTime[1]
    ) {
      return false;
    }

    if (
      params.endTime[0] &&
      program.end_time &&
      program.end_time < params.endTime[0]
    ) {
      return false;
    }

    if (
      params.endTime[1] &&
      program.end_time &&
      program.end_time > params.endTime[1]
    ) {
      return false;
    }

    return true;
  });

  programs = programs.map((program) => ({
    ...program,
    artists: program.artists || [],
    events: program.events || [],
    artwork_populations: {
      ...(program.artwork_populations || {}),
      works: program.artwork_populations.works || [],
    },
  }));

  programs = sortBy(programs, params.order[0]);

  if (params.order[1] === "desc") {
    programs = programs.reverse();
  }

  const handleParamsChange = (newParams) => {
    setParams(newParams);
    setRequestParams({ ...requestParams, type: newParams.type });

    if (props.onParamsChange) {
      props.onParamsChange(newParams);
    }
  };

  return (
    <RequestContainer loading={request.loading} error={request.error}>
      <ObjectTable
        columns={[
          "",
          {
            title: "Description",
            sortable: true,
            filterable: false,
            currentSortBy: params.order[0] === "flavor",
            currentSortOrder: params.order[1],
            onSort: (order) =>
              handleParamsChange({ ...params, order: ["flavor", order] }),
          },
          {
            title: "Starts at",
            sortable: true,
            filterable: true,
            currentSortBy: params.order[0] === "start_time",
            currentSortOrder: params.order[1],
            currentFilter: params.startTime,
            filterDates: true,
            onFilter: ([after, before]) =>
              handleParamsChange({ ...params, startTime: [after, before] }),
            onSort: (order) =>
              handleParamsChange({ ...params, order: ["start_time", order] }),
          },
          {
            title: "Ends at",
            sortable: true,
            filterable: true,
            currentSortBy: params.order[0] === "end_time",
            currentSortOrder: params.order[1],
            currentFilter: params.endTime,
            filterDates: true,
            onFilter: ([after, before]) =>
              handleParamsChange({ ...params, endTime: [after, before] }),
            onSort: (order) =>
              handleParamsChange({ ...params, order: ["end_time", order] }),
          },
          {
            title: "Type",
            sortable: true,
            filterable: true,
            currentSortBy: params.order[0] === "type",
            currentSortOrder: params.order[1],
            currentFilter: params.type,
            filterOptions: ["art_fair", "auction_week"],
            onFilter: (type) => handleParamsChange({ ...params, type }),
            onSort: (order) =>
              handleParamsChange({ ...params, order: ["type", order] }),
          },
          {
            title: "Subtype",
            sortable: true,
            filterable: true,
            currentSortBy: params.order[0] === "subtype",
            currentSortOrder: params.order[1],
            currentFilter: params.subtype,
            filterOptions: ["featured"],
            onFilter: (subtype) => handleParamsChange({ ...params, subtype }),
            onSort: (order) =>
              handleParamsChange({ ...params, order: ["subtype", order] }),
          },
          {
            title: "Artists",
            sortable: true,
            filterable: false,
            currentSortBy: params.order[0] === "artists.length",
            currentSortOrder: params.order[1],
            onSort: (order) =>
              handleParamsChange({
                ...params,
                order: ["artists.length", order],
              }),
          },
          {
            title: "Events",
            sortable: true,
            filterable: false,
            currentSortBy: params.order[0] === "events.length",
            currentSortOrder: params.order[1],
            onSort: (order) =>
              handleParamsChange({
                ...params,
                order: ["events.length", order],
              }),
          },
          {
            title: "Works",
            sortable: true,
            filterable: false,
            currentSortBy:
              params.order[0] === "artwork_populations.works.length",
            currentSortOrder: params.order[1],
            onSort: (order) =>
              handleParamsChange({
                ...params,
                order: ["artwork_populations.works.length", order],
              }),
          },
          {
            title: "Latest",
            sortable: true,
            filterable: false,
            currentSortBy: params.order[0] === "latest",
            currentSortOrder: params.order[1],
            onSort: (order) =>
              handleParamsChange({ ...params, order: ["latest", order] }),
          },
          {
            title: "Created at",
            sortable: true,
            filterable: false,
            currentSortBy: params.order[0] === "created_at",
            currentSortOrder: params.order[1],
            onSort: (order) =>
              handleParamsChange({ ...params, order: ["created_at", order] }),
          },
          "Creator",
        ]}
      >
        {programs.map((program) => (
          <ObjectTableRow
            actionAuthorizerRole={["admin", "editor"]}
            viewPath={`/programs/${program.id}`}
            editPath={`/programs/${program.id}/edit`}
            clipboardValue={program.id}
            key={program.id}
            columns={[
              program.thumbnail ? (
                <div className="text-center" style={{ width: "7rem" }}>
                  <Image
                    src={program.thumbnail}
                    alt=""
                    style={{
                      maxWidth: "6rem",
                      maxHeight: "6rem",
                    }}
                  />
                </div>
              ) : null,
              program.flavor,
              <ZonedDateTime date={program.start_time} />,
              <ZonedDateTime date={program.end_time} />,
              program.type,
              program.subtype,
              program.artists.length,
              program.events.length,
              program.artwork_populations.works.length,
              program.latest ? (
                <span className="text-success">
                  <CheckSquare size={20} />
                </span>
              ) : (
                ""
              ),
              <ZonedDateTime date={program.created_at} />,
              <UserName id={program.creator} />,
            ]}
          />
        ))}
      </ObjectTable>
    </RequestContainer>
  );
}

export default ProgramsTable;
