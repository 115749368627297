import Placeholder from "./Placeholder";
import Popover from "./Popover";
import { Filter, ChevronUp, ChevronDown } from "react-feather";
import { isObject, isArray } from "lodash";
import { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

function OptionFilter(props) {
  const [value, setValue] = useState(props.value || "");

  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChange(event.target.value || null);
  };

  return (
    <select className="form-select" value={value} onChange={handleChange}>
      <option>{props.defaultOptionLabel}</option>
      {props.filterOptions.map((option, index) =>
        isArray(option) ? (
          <option key={index} value={option[1]}>
            {option[0]}
          </option>
        ) : (
          <option key={index} value={option}>
            {option}
          </option>
        )
      )}
    </select>
  );
}

function DateFilter(props) {
  const [afterValue, setAfterValue] = useState(props.value[0] || "");
  const [beforeValue, setBeforeValue] = useState(props.value[1] || "");

  const handleChange = (event, type) => {
    if (type === "after") {
      setAfterValue(event.target.value);
      props.onChange([event.target.value || null, beforeValue || null]);
    } else {
      setBeforeValue(event.target.value);
      props.onChange([afterValue || null, event.target.value || null]);
    }
  };

  return (
    <>
      <div className="row g-0 align-items-center mb-2">
        <div className="col-3">After</div>
        <div className="col">
          <input
            type="date"
            className="form-control"
            value={afterValue}
            onChange={(event) => handleChange(event, "after")}
          />
        </div>
      </div>
      <div className="row g-0 align-items-center">
        <div className="col-3">Before</div>
        <div className="col">
          <input
            type="date"
            className="form-control"
            value={beforeValue}
            onChange={(event) => handleChange(event, "before")}
          />
        </div>
      </div>
    </>
  );
}

function SearchFilter(props) {
  const [value, setValue] = useState(props.value || "");
  const searchField = useRef(null);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChange(event.target.value || null);
  };

  useEffect(() => searchField.current && searchField.current.focus(), []);

  return (
    <input
      type="text"
      className="form-control"
      value={value}
      onChange={(event) => handleChange(event)}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          props.onApply(event);
        }
      }}
      ref={searchField}
    />
  );
}

function BooleanFilter(props) {
  const [value, setValue] = useState(props.value || false);

  const handleChange = (event) => {
    setValue(event.target.checked);
    props.onChange(event.target.checked || null);
  };

  const id = uuidv4();

  return (
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        onChange={(event) => handleChange(event)}
        checked={value}
        id={id}
      />
      <label htmlFor={id} className="form-check-label">
        {props.title}
      </label>
    </div>
  );
}

function DataColumn(props) {
  const [currentFilter, setCurrentFilter] = useState(props.currentFilter);

  const applyFilter = () => {
    props.onFilter(currentFilter);
  };

  const clearFilter = () => {
    props.onFilter(props.filterDates ? [null, null] : null);
  };

  const handleSort = () => {
    if (props.currentSortBy) {
      props.onSort(props.currentSortOrder === "asc" ? "desc" : "asc");
    } else {
      props.onSort(props.currentSortOrder);
    }
  };

  const isFiltered = () =>
    props.filterDates
      ? props.currentFilter.some((v) => v)
      : props.currentFilter;

  return (
    <div className="d-flex align-items-center" style={{ gap: "0.5rem" }}>
      {props.sortable ? (
        <>
          <div style={{ cursor: "pointer" }} onClick={handleSort}>
            {props.title}
            {props.currentSortBy && (
              <span className="ps-1 text-primary">
                {props.currentSortOrder === "asc" ? (
                  <ChevronUp size={20} />
                ) : (
                  <ChevronDown size={20} />
                )}
              </span>
            )}
          </div>
        </>
      ) : (
        <div>{props.title}</div>
      )}
      {props.filterable && (
        <Popover
          content={
            <>
              {props.filterDates ? (
                <DateFilter value={currentFilter} onChange={setCurrentFilter} />
              ) : props.filterSearch ? (
                <SearchFilter
                  value={currentFilter}
                  onChange={setCurrentFilter}
                  onApply={applyFilter}
                />
              ) : props.filterBoolean ? (
                <BooleanFilter
                  title={props.filterBoolean}
                  value={currentFilter}
                  onChange={setCurrentFilter}
                  onApply={applyFilter}
                />
              ) : (
                <OptionFilter
                  defaultOptionLabel={props.defaultOptionLabel}
                  value={currentFilter}
                  onChange={setCurrentFilter}
                  filterOptions={props.filterOptions}
                />
              )}
              <button
                className="btn btn-outline-primary w-100 mt-3"
                onClick={applyFilter}
              >
                Apply
              </button>
              <button
                className="btn btn-link btn-sm mt-2 w-100"
                onClick={clearFilter}
              >
                Clear
              </button>
            </>
          }
          width={300}
        >
          {(onClick) => (
            <>
              <div
                style={{ cursor: "pointer" }}
                onClick={onClick}
                className={isFiltered() ? "text-primary" : ""}
              >
                <Filter size={16} />
              </div>
            </>
          )}
        </Popover>
      )}
    </div>
  );
}

function ObjectTable(props) {
  return (
    <table className="table table-hover align-middle">
      <thead>
        <tr>
          {props.columns.map((column, index) => (
            <th key={index}>
              {isObject(column) ? <DataColumn {...column} /> : column}
            </th>
          ))}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.children.length > 0 ? (
          props.children
        ) : (
          <tr>
            <td colSpan={props.columns.length + 1} className="p-0">
              <Placeholder empty={true} />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default ObjectTable;
