import IconButton from "../../components/IconButton";
import SalePriceForm from "../../forms/SalePriceForm";
import RequestContainer from "../../components/RequestContainer";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Link } from "react-router-dom";
import { Save } from "react-feather";
import {
  getSalePrice,
  updateSalePrice,
  getOffer,
  getSale,
} from "../../clients/finance";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function EditSalePrice() {
  let { sale_id: saleId, offer_id: offerId, sale_price_id: id } = useParams();
  const getSaleRequest = useAutoRequest(getSale, saleId);
  const getOfferRequest = useAutoRequest(getOffer, offerId);
  const updateSalePriceRequest = useRequest(updateSalePrice);
  const getSalePriceRequest = useAutoRequest(getSalePrice, id);
  let history = useHistory();

  const sale_price = getSalePriceRequest.response.data || {};
  const sale = getSaleRequest.response.data || {};
  const offer = getOfferRequest.response.data || {};
  const loading = getSalePriceRequest.loading || updateSalePriceRequest.loading;
  const error = getSalePriceRequest.error || updateSalePriceRequest.error;

  return (
    <SalePriceForm
      initialValues={sale_price}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateSalePriceRequest.execute({ id, data: values }, () => {
          history.goBack();
          toast(`The price was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[
              <Link to={`/sales/${saleId}`}>{`${
                sale.title || sale.externalId || ""
              }`}</Link>,
              <Link to={`/sales/${saleId}/offers/${offerId}`}>{`${
                offer.offerType || ""
              } ${offer.sequenceNumber || ""}`}</Link>,
              "Edit price",
            ]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </SalePriceForm>
  );
}

export default EditSalePrice;
