import IconButton from "../../components/IconButton";
import RequestContainer from "../../components/RequestContainer";
import ArtistForm from "../../forms/ArtistForm";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import { Save } from "react-feather";
import { getArtist, updateArtist } from "../../clients/art";
import { useParams, useHistory } from "react-router-dom";
import { useRequest, useAutoRequest } from "../../hooks/useRequest";

function EditArtist() {
  let { artist_id: id } = useParams();
  const updateArtistRequest = useRequest(updateArtist);
  const getArtistRequest = useAutoRequest(getArtist, id);
  let history = useHistory();

  const artist = getArtistRequest.response.data || {};
  const loading = getArtistRequest.loading || updateArtistRequest.loading;
  const error = getArtistRequest.error || updateArtistRequest.error;

  return (
    <ArtistForm
      initialValues={getArtistRequest.response.data}
      enableReinitialize={true}
      onSubmit={(values) => {
        updateArtistRequest.execute({ id, data: values }, () => {
          history.goBack();
          toast(`The artist was saved`);
        });
      }}
    >
      {(fields) => (
        <>
          <ViewHeader
            breadcrumbs={[`${artist.name || ""}`]}
            actions={
              <IconButton
                type="submit"
                disabled={loading}
                icon={Save}
                className="btn btn-success"
              >
                Save
              </IconButton>
            }
          />
          <ViewContents>
            <RequestContainer loading={loading} error={error}>
              {fields}
            </RequestContainer>
          </ViewContents>
        </>
      )}
    </ArtistForm>
  );
}

export default EditArtist;
