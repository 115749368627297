import DeleteTokenModal from "../modals/DeleteTokenModal";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";

function TokensTable(props) {
  const tokens = props.work.physicalTokenIds || [];

  return (
    <ObjectTable columns={["Token ID"]}>
      {tokens.map((token) => (
        <ObjectTableRow
          deleteAuthorizerRole="admin"
          deleteModal={DeleteTokenModal}
          deleteModalParams={{
            work: props.work,
            token,
            onDelete: props.onDelete,
          }}
          clipboardValue={token}
          key={token}
          columns={[token]}
        />
      ))}
    </ObjectTable>
  );
}

export default TokensTable;
