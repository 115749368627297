import Authorizer from "../../components/Authorizer";
import ArtistsTable from "../../tables/ArtistsTable";
import IconLinkButton from "../../components/IconLinkButton";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import { PlusCircle } from "react-feather";
import { listArtists } from "../../clients/art";
import { usePersistentState } from "../../hooks/usePersistentState";

function Artists() {
  const [params, setParams] = usePersistentState(
    {
      skip: 0,
      limit: 100,
    },
    "Artists:params"
  );

  return (
    <>
      <ViewHeader
        breadcrumbs={["Artists"]}
        actions={
          <Authorizer role="admin">
            <IconLinkButton
              to="/artists/new"
              icon={PlusCircle}
              className="btn btn-success"
            >
              Create artist
            </IconLinkButton>
          </Authorizer>
        }
      />
      <ViewContents>
        <ArtistsTable
          params={params}
          onParamsChange={setParams}
          request={listArtists}
          paginated={true}
        />
      </ViewContents>
    </>
  );
}

export default Artists;
