import DeleteBidModal from "../modals/DeleteBidModal";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import RequestContainer from "../components/RequestContainer";
import ZonedDateTime from "../components/ZonedDateTime";
import { useAutoRequest } from "../hooks/useRequest";
import { useState } from "react";

function BidsTable(props) {
  const [params] = useState(props.params);
  const request = useAutoRequest(props.request, params);

  const bids = request.response.data || [];

  return (
    <RequestContainer loading={request.loading}>
      <ObjectTable
        columns={["Number", "Type", "Bidder identity", "Price", "Placed at"]}
      >
        {bids.map((bid) => (
          <ObjectTableRow
            actionAuthorizerRole={["admin", "editor"]}
            deleteAuthorizerRole="admin"
            editPath={`/sales/${props.offer.saleId}/offers/${props.offer.id}/bids/${bid.id}/edit`}
            deleteModal={DeleteBidModal}
            deleteModalParams={{
              id: bid.id,
              onDelete: () => request.execute(),
            }}
            key={bid.id}
            columns={[
              bid.bidNumber,
              bid.bidType,
              bid.bidderIdentity,
              bid.price,
              <ZonedDateTime date={bid.placedAt} />,
            ]}
          />
        ))}
      </ObjectTable>
    </RequestContainer>
  );
}

export default BidsTable;
