import * as yup from "yup";
import ColumnRow from "../components/ColumnRow";
import UserSearchField from "../components/UserSearchField";
import ValidatedField from "../components/ValidatedField";
import { Formik, Form } from "formik";
import { getRole } from "../lib/firebase";
import { prepareFormValues, returnFormValues } from "../lib/form";
import { useEffect, useState } from "react";

function CollectionForm(props) {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(
    () =>
      (async () => setIsAdmin(["admin", "editor"].includes(await getRole())))(),
    []
  );

  return (
    <Formik
      initialValues={prepareFormValues(
        {
          name: "",
          access: "private",
          subtype: "regular",
          publish_status: 0,
          desc: "",
          byline: "",
          owner: "",
          for_sale: false,
          publish_time: "",
          ...props.initialValues,
        },
        {
          types: {
            publish_time: "datetime",
          },
        }
      )}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(returnFormValues(values), ...args)
      }
      validationSchema={yup.object({
        name: yup.string().required(),
        access: yup.string().required(),
        subtype: yup.string().required(),
        publish_status: yup.number().integer(),
        desc: yup.string(),
        byline: yup.string(),
        owner: yup.string().required(),
        for_sale: yup.boolean(),
        publish_time: yup.object({ datetime: yup.date().nullable() }),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <h5>Basic Information</h5>
              <ValidatedField
                formik={formik}
                label="Name"
                name="name"
                type="text"
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Status"
                    name="publish_status"
                    as="select"
                  >
                    <option value="0">Not ready</option>
                    <option value="1">In edit</option>
                    <option value="2">Image review</option>
                    <option value="3">Info review</option>
                    <option value="4">Ready</option>
                    <option value="5">Queued</option>
                    <option value="6">Current</option>
                    <option value="7">Published</option>
                  </ValidatedField>,
                  <ValidatedField
                    formik={formik}
                    label="Subtype"
                    name="subtype"
                    as="select"
                  >
                    <option value="regular">regular</option>
                    <option value="featured">featured</option>
                  </ValidatedField>,
                ]}
              />
              <ColumnRow
                columns={[
                  <ValidatedField
                    formik={formik}
                    label="Byline"
                    name="byline"
                    type="text"
                  />,
                  <ValidatedField
                    formik={formik}
                    label="Access"
                    name="access"
                    as="select"
                  >
                    <option value="private">private</option>
                    <option value="public">public</option>
                  </ValidatedField>,
                ]}
              />
              <ValidatedField
                formik={formik}
                label="Description"
                name="desc"
                type="textarea"
              />
              {isAdmin ? (
                <>
                  <ColumnRow
                    columns={[
                      <UserSearchField
                        formik={formik}
                        label="Owner"
                        name="owner"
                      />,
                      <ValidatedField
                        formik={formik}
                        label="For sale"
                        name="for_sale"
                        type="checkbox"
                      />,
                    ]}
                  />
                </>
              ) : null}
              <ValidatedField
                formik={formik}
                label="Published at"
                name="publish_time"
                type="datetime-local"
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default CollectionForm;
