import { formatInTimeZone, toDate } from "date-fns-tz";
import { formatRange } from "../lib/range";
import { getTimeZones } from "@vvo/tzdb";
import { isArray, isString } from "lodash";

const timeZones = getTimeZones();
let systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
systemTimeZone = systemTimeZone == 'Asia/Calcutta' ? 'Asia/Kolkata' : systemTimeZone;
const systemTimeZoneAbbreviation = timeZones.find(
  (tz) => tz.name === systemTimeZone
).abbreviation;

const formatDate = (date) => {
  if (isString(date)) {
    if (date.includes("T")) {
      return formatInTimeZone(
        toDate(date, { timeZone: "UTC" }),
        systemTimeZone,
        "MMMM d y, h:mm:ss a"
      );
    } else {
      return formatInTimeZone(
        toDate(date, { timeZone: "UTC" }),
        "UTC",
        "eee, MMMM d y"
      );
    }
  }

  return date;
};

const formatTime = (time) => {
  if (time) {
    return formatInTimeZone(
      toDate(time, { timeZone: "UTC" }),
      systemTimeZone,
      "h:mm:ss a"
    );
  }

  return time;
};

function ZonedDateTime(props) {
  if (props.date) {
    const hasTimeZone = isString(props.date) && props.date.includes("T");
    const value = isArray(props.date)
      ? formatRange(formatDate(props.date[0]), formatDate(props.date[1]))
      : formatDate(props.date);

    return (
      <span>
        {value}
        {value && hasTimeZone && (
          <sup className="text-primary ps-1">{systemTimeZoneAbbreviation}</sup>
        )}
      </span>
    );
  } else if (props.time) {
    const value = isArray(props.time)
      ? formatRange(formatTime(props.time[0]), formatTime(props.time[1]))
      : formatTime(props.time);

    return (
      <span>
        {value}
        {value && (
          <sup className="text-primary ps-1">{systemTimeZoneAbbreviation}</sup>
        )}
      </span>
    );
  }

  return null;
}

export default ZonedDateTime;
