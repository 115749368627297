import { throttle } from "lodash";
import { useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

function useThrottle(func, callback, wait) {
  const lastRequestId = useRef(null);
  const cancel = useRef(false);
  const throttleRef = useRef(
    throttle(
      (...funcArgs) => {
        if (cancel.current) {
          return;
        }
        const requestId = uuidv4();
        lastRequestId.current = requestId;
        func(...funcArgs, (...callbackArgs) => {
          if (cancel.current) {
            return;
          }
          if (requestId === lastRequestId.current) {
            callback(...callbackArgs);
          }
        });
      },
      wait,
      { leading: true, trailing: true }
    )
  );

  useEffect(() => {
    cancel.current = false;
    return () => (cancel.current = true);
  }, []);

  return (...args) => throttleRef.current(...args);
}

export { useThrottle };
