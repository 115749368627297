import { useState, useRef, useEffect } from "react";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { useLocation } from "react-router-dom";

function Popover(props) {
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, hide);

  const location = useLocation();
  useEffect(hide, [location]);

  let align = props.align || "auto";

  if (align === "auto") {
    align = "center";

    if (wrapperRef.current) {
      if (wrapperRef.current.getBoundingClientRect().left < props.width / 2) {
        align = "left";
      } else if (
        document.body.offsetWidth <
        wrapperRef.current.getBoundingClientRect().left +
          wrapperRef.current.offsetWidth / 2 +
          props.width / 2
      ) {
        align = "right";
      }
    }
  }

  const calculateOffsetY = () => {
    if (wrapperRef.current) {
      return wrapperRef.current.offsetHeight;
    }
    return 0;
  };

  const calculateOffsetX = () => {
    if (align === "center") {
      if (wrapperRef.current) {
        return -1 * (props.width / 2) + wrapperRef.current.offsetWidth / 2;
      }
    } else if (align === "left") {
      return -8;
    } else if (align === "right") {
      return props.width * -1 + 24;
    }
    return 0;
  };

  const calculateArrowOffsetX = () => {
    if (align === "center") {
      if (wrapperRef.current) {
        return props.width / 2 - 8;
      }
    } else if (align === "left") {
      if (wrapperRef.current) {
        return wrapperRef.current.offsetWidth / 2;
      }
    } else if (align === "right") {
      if (wrapperRef.current) {
        return props.width - 16 - wrapperRef.current.offsetWidth / 2;
      }
    }
    return 0;
  };

  return (
    <div className="position-relative" ref={wrapperRef}>
      {props.children(show, hide)}
      {visible && (
        <div
          className="position-absolute popover bs-popover-bottom show"
          style={{
            top: `${calculateOffsetY()}px`,
            left: `${calculateOffsetX()}px`,
            width: `${props.width}px`,
            maxWidth: "none",
          }}
        >
          <div
            className="popover-arrow position-abslute m-0"
            style={{ left: `${calculateArrowOffsetX()}px` }}
          />
          <div className="popover-body">{props.content}</div>
        </div>
      )}
    </div>
  );
}

export default Popover;
