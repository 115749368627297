import * as yup from "yup";
import ColumnRow from "../components/ColumnRow";
import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import ValidatedField from "../components/ValidatedField";
import toast from "../lib/toast";
import { Formik, Form } from "formik";
import { Save } from "react-feather";
import { getWork, workBeginSale, workEndSale } from "../clients/art";
import { listOffersByWork } from "../clients/finance";
import { prepareFormValues, returnFormValues } from "../lib/form";
import { useRequest, useAutoRequest } from "../hooks/useRequest";
import { useState } from "react";

function WorkForSaleModal(props) {
  const getWorkRequest = useAutoRequest(getWork, props.id);
  const listOffersByWorkRequest = useAutoRequest(listOffersByWork, props.id);
  const workBeginSaleRequest = useRequest(workBeginSale);
  const workEndSaleRequest = useRequest(workEndSale);

  const [sold, setSold] = useState(true);
  const [useAuctionRecord, setUseAuctionRecord] = useState(false);

  let auctionRecord = null;
  const auctionOffers = (listOffersByWorkRequest.response.data || []).filter(
    (offer) => offer.externalId !== props.id
  );

  if (auctionOffers.length > 0) {
    auctionRecord = auctionOffers[0];
  }

  const work = getWorkRequest.response.data || {};

  const loading =
    getWorkRequest.loading ||
    listOffersByWorkRequest.loading ||
    workBeginSaleRequest.loading;
  const error =
    getWorkRequest.error ||
    listOffersByWorkRequest.error ||
    workBeginSaleRequest.error;

  const handleBeginSale = ({ price, currency }) =>
    workBeginSaleRequest.execute(
      {
        id: props.id,
        price,
        currency,
        shouldCreateOffer: !auctionRecord || !useAuctionRecord,
      },
      () => {
        toast("Sale created");
        props.onSave();
        props.onClose();
      }
    );

  const handleEndSale = ({ price, currency }) =>
    workEndSaleRequest.execute({ id: props.id, sold, price, currency }, () => {
      toast("Sale ended");
      props.onSave();
      props.onClose();
    });

  if (work.for_sale) {
    return (
      <Modal
        title="End sale"
        body={
          <RequestContainer loading={loading} error={error}>
            <div className="form-check mb-3">
              <input
                id="sold-checkbox"
                type="checkbox"
                checked={sold}
                onChange={(event) => setSold(event.target.checked)}
                className="form-check-input"
              />
              <label htmlFor="sold-checkbox" className="form-check-label">
                Did the work sell?
              </label>
            </div>
            {sold ? (
              <Formik
                initialValues={prepareFormValues({
                  price: work.lastPrice || "",
                  currency: work.currency || "USD",
                })}
                onSubmit={(values) => handleEndSale(returnFormValues(values))}
                validationSchema={yup.object({
                  price: yup.number().positive().required(),
                  currency: yup.string().matches(/[A-Z]{3}/),
                })}
              >
                {(formik) => (
                  <Form id="work-for-sale">
                    <ColumnRow
                      columns={[
                        <ValidatedField
                          formik={formik}
                          label="Price"
                          name="price"
                          type="text"
                        />,
                        <ValidatedField
                          formik={formik}
                          label="Currency"
                          name="currency"
                          as="select"
                          currency={true}
                        />,
                      ]}
                    />
                  </Form>
                )}
              </Formik>
            ) : null}
          </RequestContainer>
        }
        actions={
          sold ? (
            <IconButton
              type="submit"
              form="work-for-sale"
              disabled={loading}
              icon={Save}
              className="btn btn-success"
            >
              End sale
            </IconButton>
          ) : (
            <IconButton
              disabled={loading}
              icon={Save}
              className="btn btn-success"
              onClick={() => handleEndSale({ price: null, currency: null })}
            >
              End sale
            </IconButton>
          )
        }
        onClose={props.onClose}
      />
    );
  } else {
    return (
      <Modal
        title="Put work up for sale"
        body={
          <RequestContainer loading={loading} error={error}>
            {auctionRecord ? (
              <div className="form-check mb-3">
                <input
                  id="auction-checkbox"
                  type="checkbox"
                  checked={useAuctionRecord}
                  onChange={(event) =>
                    setUseAuctionRecord(event.target.checked)
                  }
                  className="form-check-input"
                />
                <label htmlFor="auction-checkbox" className="form-check-label">
                  Use existing auction record?
                </label>
              </div>
            ) : null}
            {useAuctionRecord ? null : (
              <Formik
                initialValues={prepareFormValues({
                  price: work.lastPrice || "",
                  currency: "USD",
                })}
                onSubmit={(values) => handleBeginSale(returnFormValues(values))}
                validationSchema={yup.object({
                  price: yup.number().positive().required(),
                  currency: yup.string().matches(/[A-Z]{3}/),
                })}
              >
                {(formik) => (
                  <Form id="work-for-sale">
                    <ColumnRow
                      columns={[
                        <ValidatedField
                          formik={formik}
                          label="Price"
                          name="price"
                          type="text"
                        />,
                        <ValidatedField
                          formik={formik}
                          label="Currency"
                          name="currency"
                          as="select"
                          currency={true}
                        />,
                      ]}
                    />
                  </Form>
                )}
              </Formik>
            )}
          </RequestContainer>
        }
        actions={
          useAuctionRecord ? (
            <IconButton
              disabled={loading}
              icon={Save}
              className="btn btn-success"
              onClick={() =>
                handleBeginSale({
                  price: auctionRecord.price,
                  currency: auctionRecord.currency,
                })
              }
            >
              Begin sale
            </IconButton>
          ) : (
            <IconButton
              type="submit"
              form="work-for-sale"
              disabled={loading}
              icon={Save}
              className="btn btn-success"
            >
              Begin sale
            </IconButton>
          )
        }
        onClose={props.onClose}
      />
    );
  }
}

export default WorkForSaleModal;
