import * as yup from "yup";
import ValidatedField from "../components/ValidatedField";
import { Formik, Form } from "formik";
import { prepareFormValues, returnFormValues } from "../lib/form";

function SalePriceForm(props) {
  return (
    <Formik
      initialValues={prepareFormValues(
        {
          price: "",
          currency: "",
          saleDate: "",
          ...props.initialValues,
        },
        {
          types: {
            saleDate: "date",
          },
        }
      )}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, ...args) =>
        props.onSubmit(returnFormValues(values), ...args)
      }
      validationSchema={yup.object({
        price: yup.number().positive(),
        currency: yup.string().matches(/[A-Z]{3}/),
        saleDate: yup.object({ date: yup.date().nullable() }),
      })}
    >
      {(formik) => (
        <Form>
          {props.children(
            <>
              <h5>Basic Information</h5>
              <ValidatedField
                formik={formik}
                label="Price"
                name="price"
                type="text"
              />
              <ValidatedField
                formik={formik}
                label="Currency"
                name="currency"
                as="select"
                currency={true}
              />
              <ValidatedField
                formik={formik}
                label="Sale date"
                name="saleDate"
                type="date"
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default SalePriceForm;
