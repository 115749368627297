import IconButton from "../components/IconButton";
import Modal from "../components/Modal";
import RequestContainer from "../components/RequestContainer";
import toast from "../lib/toast";
import { XCircle } from "react-feather";
import { removeFromQueue } from "../clients/collection";
import { useRequest } from "../hooks/useRequest";

function RemoveCrateFromQueueModal(props) {
  const removeFromQueueRequest = useRequest(removeFromQueue);

  const handleDelete = () => {
    removeFromQueueRequest.execute(props.id, () => {
      toast("Crate removed from queue");
      props.onClose();
      props.onDelete();
    });
  };

  return (
    <Modal
      title="Remove crate from queue"
      body={
        <RequestContainer
          loading={removeFromQueueRequest.loading}
          error={removeFromQueueRequest.error}
          fullscreen={true}
        >
          This will remove the crate from the queue, but the crate itself will
          not be removed.
        </RequestContainer>
      }
      actions={
        <>
          <IconButton
            icon={XCircle}
            className="btn btn-danger"
            onClick={handleDelete}
          >
            Delete
          </IconButton>
        </>
      }
      onClose={props.onClose}
    />
  );
}

export default RemoveCrateFromQueueModal;
