import QaTasksTable from "../../tables/QaTasksTable";
import ViewHeader from "../../components/ViewHeader";
import { listGroups } from "../../clients/quality";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useRefresh } from "../../hooks/useRefresh";

function Users() {
  const [refreshToken, refresh] = useRefresh();
  const [assignedParams, setAssignedParams] = usePersistentState(
    {
      skip: 0,
      limit: 20,
    },
    "QaTasks:assigned:params"
  );
  const [unassignedParams, setUnassignedParams] = usePersistentState(
    {
      skip: 0,
      limit: 20,
    },
    "QaTasks:unassigned:params"
  );

  return (
    <>
      <ViewHeader breadcrumbs={["QA task groups"]} />
      <div className="d-xl-flex flex-xl-grow-1">
        <div className="p-4 bg-light" style={{ flexBasis: "50%" }}>
          <h5>My task groups</h5>
          <QaTasksTable
            key={refreshToken}
            params={assignedParams}
            onParamsChange={setAssignedParams}
            request={listGroups}
            assigned={true}
            onRefresh={refresh}
          />
        </div>
        <div className="p-4" style={{ flexBasis: "50%" }}>
          <h5>Unassigned task groups</h5>
          <QaTasksTable
            key={refreshToken}
            params={unassignedParams}
            onParamsChange={setUnassignedParams}
            request={listGroups}
            onRefresh={refresh}
          />
        </div>
      </div>
    </>
  );
}

export default Users;
