import ArtistVenuesTable from "../../tables/ArtistVenuesTable";
import Authorizer from "../../components/Authorizer";
import DeleteArtistModal from "../../modals/DeleteArtistModal";
import EventsTable from "../../tables/EventsTable";
import IconButton from "../../components/IconButton";
import IconLinkButton from "../../components/IconLinkButton";
import LinkEventModal from "../../modals/LinkEventModal";
import LinkSaleModal from "../../modals/LinkSaleModal";
import LogModal from "../../modals/LogModal";
import MergeArtistModal from "../../modals/MergeArtistModal";
import ObjectPropertyList from "../../components/ObjectPropertyList";
import OffersTable from "../../tables/OffersTable";
import RequestContainer from "../../components/RequestContainer";
import SalesTable from "../../tables/SalesTable";
import TabCard from "../../components/TabCard";
import UploadImageModal from "../../modals/UploadImageModal";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import WorksTable from "../../tables/WorksTable";
import toast from "../../lib/toast";
import {
  Edit,
  PlusCircle,
  Link,
  UploadCloud,
  XCircle,
  GitMerge,
  Clock,
} from "react-feather";
import {
  getArtist,
  updateArtist,
  listEventsByArtist,
  listWorksByArtist,
  linkEventArtist,
  unlinkEventArtist,
  unlinkWorkArtist,
  mergeArtist,
} from "../../clients/art";
import {
  listOffersByArtist,
  listSalesByArtist,
  linkSaleArtist,
  unlinkOfferArtist,
  unlinkSaleArtist,
} from "../../clients/finance";
import { useAutoRequest, useRequest } from "../../hooks/useRequest";
import { useHistory } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import { useParams } from "react-router-dom";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useRefresh } from "../../hooks/useRefresh";

function ViewArtist() {
  const [activeTab, setActiveTab] = usePersistentState(
    null,
    "ViewArtist:activeTab"
  );
  let { artist_id: id } = useParams();
  const [refreshToken, refresh] = useRefresh(id);
  const getArtistRequest = useAutoRequest(getArtist, id);
  const updateArtistRequest = useRequest(updateArtist);
  let history = useHistory();
  let [showLinkEventModal, linkEventModal] = useModal(LinkEventModal, {
    linkRequest: linkEventArtist,
    params: { artistId: id },
    onLink: refresh,
  });
  let [showLinkSaleModal, linkSaleModal] = useModal(LinkSaleModal, {
    linkRequest: linkSaleArtist,
    params: { artistId: id },
    onLink: refresh,
  });

  const artist = getArtistRequest.response.data || {};

  let [showMergeArtistModal, mergeArtistModal] = useModal(MergeArtistModal, {
    linkRequest: mergeArtist,
    params: { targetId: id },
    targetName: artist.name,
    onLink: refresh,
  });
  let [showUploadImageModal, uploadImageModal] = useModal(UploadImageModal, {
    skipCreateImage: true,
    onSave: (url) =>
      updateArtistRequest.execute(
        { id, data: { ...artist, artistImage: url } },
        () =>
          getArtistRequest.execute(id, () => toast("The image was uploaded"))
      ),
  });
  let [showUploadSignatureImageModal, uploadSignatureImageModal] = useModal(
    UploadImageModal,
    {
      skipCreateImage: true,
      onSave: (url) =>
        updateArtistRequest.execute(
          { id, data: { ...artist, signatureImage: url } },
          () =>
            getArtistRequest.execute(id, () => toast("The image was uploaded"))
        ),
    }
  );
  let [showDeleteArtistModal, deleteArtistModal] = useModal(DeleteArtistModal, {
    id: artist.id,
    name: artist.name,
    onDelete: () => {
      history.push("/artists");
    },
  });
  let [showLogModal, logModal] = useModal(LogModal, {
    filter: {
      type: "artist",
      id,
    },
  });

  return (
    <>
      <ViewHeader
        breadcrumbs={[`${artist.name || ""}`]}
        actions={
          <>
            <Authorizer role={["admin", "editor"]}>
              <IconButton
                onClick={showMergeArtistModal}
                icon={GitMerge}
                className="btn btn-success me-2"
              >
                Merge
              </IconButton>
              <IconButton
                onClick={showUploadImageModal}
                icon={UploadCloud}
                className="btn btn-success me-2"
              >
                Upload image
              </IconButton>
              <IconButton
                onClick={showUploadSignatureImageModal}
                icon={UploadCloud}
                className="btn btn-success me-2"
              >
                Upload signature image
              </IconButton>
              <IconButton
                onClick={showLogModal}
                icon={Clock}
                className="btn btn-secondary me-2"
              >
                Log
              </IconButton>
              <IconLinkButton
                to={`/artists/${id}/edit`}
                icon={Edit}
                className="btn btn-secondary me-2"
              >
                Edit
              </IconLinkButton>
            </Authorizer>
            <Authorizer role={["admin", "editor"]}>
              <IconButton
                icon={XCircle}
                onClick={showDeleteArtistModal}
                className="btn btn-secondary"
              >
                Delete
              </IconButton>
            </Authorizer>
          </>
        }
      />
      <ViewContents>
        <RequestContainer
          loading={getArtistRequest.loading}
          error={getArtistRequest.error}
        >
          <ObjectPropertyList
            items={[
              ["ID", artist.id],
              ["Name", artist.name],
              ["Image", { image: artist.artistImage }],
              ["Signature image", { image: artist.signatureImage }],
              ["Matchable", { bool: artist.matchable }],
              ["Featured", { bool: artist.featured }],
              ["First Name", artist.firstName],
              ["Last Name", artist.lastName],
              ["Alternate Names", { array: artist.alternateNames }],
              ["Gender", artist.gender],
              ["Dates", artist.dates],
              ["Date of Birth", { date: artist.dateOfBirth }],
              ["Date of Death", { date: artist.dateOfDeath }],
              ["Nationality", artist.nationality],
              ["Nationalities", { array: artist.nationalities }],
              ["Birth City", artist.birthCity],
              ["Birth Region", artist.birthRegion],
              ["Birth Country", artist.birthCountry],
              ["Death City", artist.deathCity],
              ["Death Region", artist.deathRegion],
              ["Death Country", artist.deathCountry],
              ["Role", artist.role],
              ["Roles", { array: artist.roles }],
              ["Style", artist.style],
              ["Styles", { array: artist.styles }],
              ["Awards", { array: artist.awards }],
              ["Education", { array: artist.education }],
              ["Related Artists", { array: artist.relatedArtists }],
              ["Bio", artist.bio],
              ["Bio Source", artist.bioSource],
              ["Official Website", { url: artist.officialWebsite }],
              ["LinkedIn", artist.linkedinProfile],
              ["Facebook", artist.facebookProfile],
              ["Twitter", artist.twitterProfile],
              ["Instagram", artist.instagramProfile],
              ["Representation", artist.representation],
              ["Distributor", artist.distributor],
              ["Copyright Collective", artist.copyrightCollective],
              ["Collections", { array: artist.collections }],
            ]}
          />
        </RequestContainer>

        <TabCard
          activeTab={activeTab}
          onTabChange={setActiveTab}
          tabs={{
            Events: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showLinkEventModal}
                    icon={Link}
                    className="btn btn-secondary me-2"
                  >
                    Link event
                  </IconButton>
                  <IconLinkButton
                    to={`/events/new?artistId=${id}`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create event
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <EventsTable
                  key={refreshToken}
                  params={{ id }}
                  request={listEventsByArtist}
                  unlinkRequest={unlinkEventArtist}
                  unlinkParams={{ artistId: id }}
                />
              ),
            },
            Offers: {
              body: (
                <OffersTable
                  params={{ id }}
                  request={listOffersByArtist}
                  unlinkRequest={unlinkOfferArtist}
                  unlinkParams={{ artistId: id }}
                />
              ),
            },
            Sales: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showLinkSaleModal}
                    icon={Link}
                    className="btn btn-secondary me-2"
                  >
                    Link sale
                  </IconButton>
                  <IconLinkButton
                    to={`/sales/new?artistId=${id}`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create sale
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <SalesTable
                  key={refreshToken}
                  params={{ id }}
                  request={listSalesByArtist}
                  unlinkRequest={unlinkSaleArtist}
                  unlinkParams={{ artistId: id }}
                />
              ),
            },
            Works: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconLinkButton
                    to={`/works/new?artistId=${id}`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create work
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <WorksTable
                  params={{ id }}
                  request={listWorksByArtist}
                  unlinkRequest={unlinkWorkArtist}
                  unlinkParams={{ artistId: id }}
                />
              ),
            },
            Venues: {
              body: <ArtistVenuesTable parentRequest={getArtistRequest} />,
            },
          }}
        />
      </ViewContents>

      {linkEventModal}
      {linkSaleModal}
      {mergeArtistModal}
      {uploadImageModal}
      {uploadSignatureImageModal}
      {deleteArtistModal}
      {logModal}
    </>
  );
}

export default ViewArtist;
