import algoliasearch from "algoliasearch";
import config from "../config";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { useOnScreen } from "../hooks/useOnScreen";
import { useState, useRef, memo } from "react";

const client = algoliasearch(
  config.algolia.applicationId,
  config.algolia.apiKey
);
const index = client.initIndex("arthur_users");

const UserName = memo((props) => {
  const [userName, setUserName] = useState("");
  const ref = useRef(null);

  const renderFieldValue = (hit) =>
    get(hit, ["profile.displayName"])
      ? hit["profile.displayName"]
      : hit["profile.email"];

  useOnScreen(ref, () => {
    if (props.id && !userName) {
      index.getObject(props.id).then((hit) => {
        setUserName(renderFieldValue(hit));
      });
    }
  });

  return (
    <Link to={`/users/${props.id}`} ref={ref}>
      {userName}
    </Link>
  );
});

UserName.isLink = true;

export default UserName;
