import DeleteOfferModal from "../modals/DeleteOfferModal";
import ExternalLink from "../components/ExternalLink";
import Image from "../components/Image";
import ObjectTable from "../components/ObjectTable";
import ObjectTableRow from "../components/ObjectTableRow";
import RequestContainer from "../components/RequestContainer";
import TruncatedText from "../components/TruncatedText";
import UnlinkOfferModal from "../modals/UnlinkOfferModal";
import { CheckSquare } from "react-feather";
import { Link } from "react-router-dom";
import { flatMap, get } from "lodash";
import { formatNumber } from "../lib/number";
import { getWorks } from "../clients/art";
import { useAutoRequest, useRequest } from "../hooks/useRequest";
import { useState } from "react";

function OffersTable(props) {
  const [params, setParams] = useState({
    sortby: "sequenceNumber",
    sortorder: "asc",
    ...props.params,
  });

  const getWorksRequest = useRequest(getWorks);
  const request = useAutoRequest(props.request, params, (response) =>
    getWorksRequest.execute(
      flatMap(response.data.map((offer) => offer.workIds))
    )
  );

  const offers = request.response.data || [];
  const works = getWorksRequest.response.data || [];

  const getWork = (offer) =>
    works.find((work) => offer.workIds.includes(work.id));

  const getArtist = (offer) => get(getWork(offer), "artists[0].name");

  const workTitle = (offer) => {
    const work = getWork(offer);

    if (!work) {
      return null;
    }

    return <TruncatedText max={40}>{work.title}</TruncatedText>;
  };

  const workImage = (offer) => {
    const work = getWork(offer);

    if (!work || !work.images || work.images.length === 0) {
      return null;
    }

    return (
      <div className="text-center" style={{ width: "7rem" }}>
        <Image
          src={work.images[0].url}
          alt=""
          style={{
            maxWidth: "6rem",
            maxHeight: "6rem",
          }}
        />
      </div>
    );
  };

  return (
    <RequestContainer loading={request.loading}>
      <ObjectTable
        columns={[
          "",
          {
            title: "Type",
            sortable: true,
            currentSortBy: params.sortby === "offerType",
            currentSortOrder: params.sortorder,
            onSort: (order) =>
              setParams({
                ...params,
                sortby: "offerType",
                sortorder: order,
              }),
          },
          {
            title: "Seq. No.",
            sortable: true,
            currentSortBy: params.sortby === "sequenceNumber",
            currentSortOrder: params.sortorder,
            onSort: (order) =>
              setParams({
                ...params,
                sortby: "sequenceNumber",
                sortorder: order,
              }),
          },
          {
            title: "Sold",
            sortable: true,
            currentSortBy: params.sortby === "sold",
            currentSortOrder: params.sortorder,
            onSort: (order) =>
              setParams({
                ...params,
                sortby: "sold",
                sortorder: order,
              }),
          },
          {
            title: "Low estimate",
            sortable: true,
            currentSortBy: params.sortby === "estimateLow",
            currentSortOrder: params.sortorder,
            onSort: (order) =>
              setParams({
                ...params,
                sortby: "estimateLow",
                sortorder: order,
              }),
          },
          {
            title: "High estimate",
            sortable: true,
            currentSortBy: params.sortby === "estimateHigh",
            currentSortOrder: params.sortorder,
            onSort: (order) =>
              setParams({
                ...params,
                sortby: "estimateHigh",
                sortorder: order,
              }),
          },
          {
            title: "Price",
            sortable: true,
            currentSortBy: params.sortby === "price",
            currentSortOrder: params.sortorder,
            onSort: (order) =>
              setParams({
                ...params,
                sortby: "price",
                sortorder: order,
              }),
          },
          {
            title: "Total fee",
            sortable: true,
            currentSortBy: params.sortby === "totalFee",
            currentSortOrder: params.sortorder,
            onSort: (order) =>
              setParams({
                ...params,
                sortby: "totalFee",
                sortorder: order,
              }),
          },
          "Artist",
          "Title",
          "Link",
        ]}
      >
        {offers.map((offer) => (
          <ObjectTableRow
            actionAuthorizerRole={["admin", "editor"]}
            deleteAuthorizerRole={props.unlinkRequest ? null : "admin"}
            viewPath={`/sales/${offer.saleId}/offers/${offer.id}`}
            editPath={`/sales/${offer.saleId}/offers/${offer.id}/edit`}
            deleteModal={
              props.unlinkRequest ? UnlinkOfferModal : DeleteOfferModal
            }
            deleteModalParams={{
              id: offer.id,
              onDelete: () => request.execute(),
              request: props.unlinkRequest,
              params: { offerId: offer.id, ...props.unlinkParams },
            }}
            clipboardValue={offer.id}
            key={offer.id}
            extraActions={() =>
              getWork(offer) ? (
                <Link
                  to={`/works/${getWork(offer).id}`}
                  className="btn btn-sm btn-outline-primary me-2"
                >
                  View work
                </Link>
              ) : null
            }
            columns={[
              workImage(offer),
              offer.offerType,
              offer.sequenceNumber,
              offer.sold ? (
                <span className="text-success">
                  <CheckSquare size={20} />
                </span>
              ) : (
                ""
              ),
              offer.estimateLow,
              offer.estimateHigh,
              offer.publishedPrice ? (
                <>
                  {formatNumber(offer.price)}{" "}
                  <span className="text-muted">
                    ({formatNumber(offer.publishedPrice)})
                  </span>
                </>
              ) : (
                offer.price
              ),
              offer.totalFee,
              getArtist(offer),
              workTitle(offer),
              <ExternalLink href={offer.url} icon={true} />,
            ]}
          />
        ))}
      </ObjectTable>
    </RequestContainer>
  );
}

export default OffersTable;
