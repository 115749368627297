import config from "../config";
import { logEvent } from "../lib/log";
import { merge, uniq, flatMap } from "lodash";
import {
  request as baseRequest,
  scroll as baseScroll,
  defaultParam,
  otherParams,
  virtualBulk,
  bulk,
} from "./common";
import { deleteEvent, getWork, getWorks, updateWork, deleteWork } from "./art";
import { v4 as uuidv4 } from "uuid";

const request = async (token, endpoint, options = {}) =>
  await baseRequest(config.finance_api.url, token, endpoint, options);

const scroll = async (token, endpoint, options = {}) =>
  await baseScroll(config.finance_api.url, token, endpoint, options);

const listSales = async (token, params) =>
  await request(token, "sales", {
    params: merge({ shallow: "true" }, params),
  });

const listSalesByArtist = async (token, params) =>
  await scroll(token, "sales", {
    params: merge(
      {
        shallow: "true",
        artist_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const listSalesByEvent = async (token, params) =>
  await scroll(token, "sales", {
    params: merge(
      {
        shallow: "true",
        event_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const listSalesByVenue = async (token, params) =>
  await scroll(token, "sales", {
    params: merge(
      {
        shallow: "true",
        venue_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const getSale = async (token, id) =>
  await request(token, `sales/${id}`, {
    params: { shallow: "true" },
  });

const getSaleWithOffers = async (token, id) =>
  await request(token, `sales/${id}`, {
    params: { shallow: "false" },
  });

const createSale = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "sales", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateSale", data, { type: "sale", id });
  if (data.artistId) {
    await linkSaleArtist(token, { saleId: id, artistId: data.artistId });
  }
  if (data.eventId) {
    await linkSaleEvent(token, { saleId: id, eventId: data.eventId });
  }
  if (data.venueId) {
    await linkSaleVenue(token, { saleId: id, venueId: data.venueId });
  }
  return response;
};

const updateSale = async (token, { id, data }) => {
  const response = await request(token, `sales/${id}`, {
    method: "put",
    data: data,
  });
  logEvent("UpdateSale", data, { type: "sale", id });
  return response;
};

const deleteSale = async (token, id) => {
  const sale = await getSale(token, id);

  if (sale.data.source !== "arthur") {
    const eventIds = (
      await virtualBulk(token, sale.data.eventIds, listSalesByEvent)
    ).data
      .filter(
        (eventSales) => eventSales.length === 1 && eventSales[0].id === id
      )
      .map((eventSales) => eventSales._params);

    virtualBulk(
      token,
      eventIds.map((eventId) => ({ id: eventId, nested: true })),
      deleteEvent
    );

    const offers = await listOffersBySale(token, id);
    const workIds = uniq(flatMap(offers.data, (offer) => offer.workIds));

    virtualBulk(
      token,
      workIds.map((workId) => ({ id: workId, nested: true })),
      deleteWork
    );
  }

  const response = await request(token, `sales/${id}`, { method: "delete" });
  logEvent("DeleteSale", {}, { type: "sale", id });
  return response;
};

const linkSaleArtist = async (token, { saleId, artistId }) => {
  const response = await request(token, `sale_artist/${saleId}`, {
    method: "post",
    params: { artist_id: artistId },
  });
  logEvent(
    "LinkSaleArtist",
    { artist_id: artistId },
    { type: "sale", id: saleId }
  );
  return response;
};

const linkSaleEvent = async (token, { saleId, eventId }) => {
  const response = await request(token, `sale_event/${saleId}`, {
    method: "post",
    params: { event_id: eventId },
  });
  logEvent(
    "LinkSaleEvent",
    { event_id: eventId },
    { type: "sale", id: saleId }
  );
  return response;
};

const linkSaleVenue = async (token, { saleId, venueId }) => {
  const response = await request(token, `sale_venue/${saleId}`, {
    method: "post",
    params: { venue_id: venueId },
  });
  logEvent(
    "LinkSaleVenue",
    { venue_id: venueId },
    { type: "sale", id: saleId }
  );
  return response;
};

const unlinkSaleArtist = async (token, { saleId, artistId }) => {
  const response = await request(token, `sale_artist/${saleId}`, {
    method: "delete",
    params: { artist_id: artistId },
  });
  logEvent(
    "UnlinkSaleArtist",
    { artist_id: artistId },
    { type: "sale", id: saleId }
  );
  return response;
};

const unlinkSaleEvent = async (token, { saleId, eventId }) => {
  const response = await request(token, `sale_event/${saleId}`, {
    method: "delete",
    params: { event_id: eventId },
  });
  logEvent(
    "UnlinkSaleEvent",
    { event_id: eventId },
    { type: "sale", id: saleId }
  );
  return response;
};

const unlinkSaleVenue = async (token, { saleId, venueId }) => {
  const response = await request(token, `sale_venue/${saleId}`, {
    method: "delete",
    params: { venue_id: venueId },
  });
  logEvent(
    "UnlinkSaleVenue",
    { venue_id: venueId },
    { type: "sale", id: saleId }
  );
  return response;
};

const listOffersByArtist = async (token, params) =>
  await scroll(token, "offers", {
    params: merge(
      {
        shallow: "true",
        artist_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const listOffersBySale = async (token, params) =>
  await scroll(token, "offers", {
    params: merge(
      {
        shallow: "true",
        sale_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const listOffersByWork = async (token, params) =>
  await scroll(token, "offers", {
    params: merge(
      {
        shallow: "true",
        work_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const getOffer = async (token, id) =>
  await request(token, `offers/${id}`, {
    params: { shallow: "true" },
  });

const getOffers = async (token, ids) =>
  await bulk(config.finance_api.url, token, "bulk_offers", null, ids);

const getOffersByWorks = async (token, ids) =>
  await bulk(config.finance_api.url, token, "bulk_offers_by_work", null, ids);

const createOffer = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "offers", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateOffer", data, { type: "offer", id });
  return response;
};

const updateOffer = async (token, { id, data }) => {
  const offer = await request(token, `offers/${id}`, {
    method: "put",
    data: data,
  });
  logEvent("UpdateOffer", data, { type: "offer", id });

  if (offer.data.price) {
    const works = await getWorks(token, offer.data.workIds);

    if (works.data) {
      await virtualBulk(
        token,
        works.data.map((work) => ({
          id: work.id,
          data: {
            ...work,
            lastPrice: offer.data.price,
            currency: offer.data.currency,
          },
        })),
        updateWork
      );
    }
  }

  return offer;
};

const deleteOffer = async (token, id) => {
  const offer = await getOffer(token, id);

  if (offer.data.offerType === "lot") {
    virtualBulk(
      token,
      offer.data.workIds.map((workId) => ({ id: workId, nested: true })),
      deleteWork
    );
  }

  const response = await request(token, `offers/${id}`, { method: "delete" });
  logEvent("DeleteOffer", {}, { type: "offer", id });
  return response;
};

const linkOfferArtist = async (token, { offerId, artistId }) => {
  const response = await request(token, `offer_artist/${offerId}`, {
    method: "post",
    params: { artist_id: artistId },
  });
  logEvent(
    "LinkOfferArtist",
    { artist_id: artistId },
    { type: "offer", id: offerId }
  );
  return response;
};

const linkOfferWork = async (token, { offerId, workId }) => {
  const offer = await getOffer(token, offerId);

  if (offer.data.price) {
    const work = await getWork(token, workId);

    if (work.data) {
      await updateWork(token, {
        id: workId,
        data: {
          ...work.data,
          lastPrice: offer.data.price,
          currency: offer.data.currency,
        },
      });
    }
  }

  const response = await request(token, `offer_work/${offerId}`, {
    method: "post",
    params: { work_id: workId },
  });
  logEvent(
    "LinkOfferWork",
    { work_id: workId },
    { type: "offer", id: offerId }
  );
  return response;
};

const unlinkOfferArtist = async (token, { offerId, artistId }) => {
  const response = await request(token, `offer_artist/${offerId}`, {
    method: "delete",
    params: { artist_id: artistId },
  });
  logEvent(
    "UnlinkOfferArtist",
    { artist_id: artistId },
    { type: "offer", id: offerId }
  );
  return response;
};

const unlinkOfferWork = async (token, { offerId, workId }) => {
  const response = await request(token, `offer_work/${offerId}`, {
    method: "delete",
    params: { work_id: workId },
  });
  logEvent(
    "UnlinkOfferWork",
    { work_id: workId },
    { type: "offer", id: offerId }
  );
  return response;
};

const listBidsByOffer = async (token, params) =>
  await scroll(token, "bids", {
    params: merge(
      {
        shallow: "true",
        offer_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const getBid = async (token, id) => await request(token, `bids/${id}`);

const createBid = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "bids", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateBid", data, { type: "bid", id });
  return response;
};

const updateBid = async (token, { id, data }) => {
  const response = await request(token, `bids/${id}`, {
    method: "put",
    data: data,
  });
  logEvent("UpdateBid", data, { type: "bid", id });
  return response;
};

const deleteBid = async (token, id) => {
  const response = await request(token, `bids/${id}`, {
    method: "delete",
  });
  logEvent("DeleteBid", {}, { type: "bid", id });
  return response;
};

const listFeesByOffer = async (token, params) =>
  await scroll(token, "fees", {
    params: merge(
      {
        shallow: "true",
        offer_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const getFee = async (token, id) => await request(token, `fees/${id}`);

const createFee = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "fees", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateFee", data, { type: "fee", id });
  return response;
};

const updateFee = async (token, { id, data }) => {
  const response = await request(token, `fees/${id}`, {
    method: "put",
    data: data,
  });
  logEvent("UpdateFee", data, { type: "fee", id });
  return response;
};

const deleteFee = async (token, id) => {
  const response = await request(token, `fees/${id}`, {
    method: "delete",
  });
  logEvent("DeleteFee", {}, { type: "fee", id });
  return response;
};

const listSalePricesByOffer = async (token, params) =>
  await scroll(token, "sale_prices", {
    params: merge(
      {
        shallow: "true",
        offer_id: defaultParam(params, "id"),
      },
      otherParams(params, "id")
    ),
  });

const getSalePrice = async (token, id) =>
  await request(token, `sale_prices/${id}`);

const createSalePrice = async (token, data) => {
  const id = uuidv4();
  const response = await request(token, "sale_prices", {
    method: "post",
    data: merge({ id }, data),
  });
  logEvent("CreateSalePrice", data, { type: "sale_price", id });
  return response;
};

const updateSalePrice = async (token, { id, data }) => {
  const response = await request(token, `sale_prices/${id}`, {
    method: "put",
    data: data,
  });
  logEvent("UpdateSalePrice", data, { type: "sale_price", id });
  return response;
};

const deleteSalePrice = async (token, id) => {
  const response = await request(token, `sale_prices/${id}`, {
    method: "delete",
  });
  logEvent("DeleteSalePrice", {}, { type: "sale_price", id });
  return response;
};

const getStats = async (token) => await request(token, `stats`);

const search = async (token, query) =>
  await request(token, `search`, { params: { search: query, limit: 10 } });

export {
  listSales,
  listSalesByArtist,
  listSalesByEvent,
  listSalesByVenue,
  getSale,
  getSaleWithOffers,
  createSale,
  updateSale,
  deleteSale,
  linkSaleArtist,
  linkSaleEvent,
  linkSaleVenue,
  unlinkSaleArtist,
  unlinkSaleEvent,
  unlinkSaleVenue,
  listOffersByArtist,
  listOffersBySale,
  listOffersByWork,
  getOffer,
  getOffers,
  getOffersByWorks,
  createOffer,
  updateOffer,
  deleteOffer,
  linkOfferArtist,
  linkOfferWork,
  unlinkOfferArtist,
  unlinkOfferWork,
  listBidsByOffer,
  getBid,
  createBid,
  updateBid,
  deleteBid,
  listFeesByOffer,
  getFee,
  createFee,
  updateFee,
  deleteFee,
  listSalePricesByOffer,
  getSalePrice,
  createSalePrice,
  updateSalePrice,
  deleteSalePrice,
  getStats,
  search,
};
