import DeleteModal from "../components/DeleteModal";
import { deleteSalePrice } from "../clients/finance";

function DeleteSalePriceModal(props) {
  return (
    <DeleteModal
      request={deleteSalePrice}
      id={props.id}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The price was deleted"
      title="Delete price?"
      confirmText="DELETE"
      message={
        <p className="m-0">Do you want to delete the price permanently?</p>
      }
    />
  );
}

export default DeleteSalePriceModal;
