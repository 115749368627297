import {
  formatInTimeZone,
  utcToZonedTime,
  toDate,
  zonedTimeToUtc,
} from "date-fns-tz";
import { mapValues, isNil, trim, isString, isObject, get, has } from "lodash";
import { subSeconds } from "date-fns";

const parseDate = (value) => {
  if (!value) {
    return null;
  }

  if (isString(value)) {
    return utcToZonedTime(toDate(value, { timeZone: "UTC" }), "UTC");
  }

  return value;
};

const prepareFormValues = (values, options) =>
  mapValues(values, (v, k) =>
    isNil(v)
      ? ""
      : get(options, ["types", k]) === "datetime"
      ? { datetime: parseDate(v), offset: 0 }
      : get(options, ["types", k]) === "date"
      ? { date: v }
      : v
  );

const returnFormValues = (values) =>
  mapValues(values, (v) =>
    v === ""
      ? null
      : isString(v)
      ? trim(v)
      : isObject(v) && has(v, "datetime")
      ? (v.datetime &&
          formatInTimeZone(
            subSeconds(zonedTimeToUtc(v.datetime, "UTC"), v.offset || 0),
            "UTC",
            "yyyy-MM-dd'T'HH:mm:ss"
          )) ||
        null
      : isObject(v) && has(v, "date")
      ? (v.date &&
          formatInTimeZone(
            zonedTimeToUtc(v.date, "UTC"),
            "UTC",
            "yyyy-MM-dd"
          )) ||
        null
      : v
  );

export { prepareFormValues, returnFormValues };
