import IconButton from "./IconButton";
import toast from "../lib/toast";
import { Clipboard } from "react-feather";

function ClipboardCopier(props) {
  return (
    <IconButton
      icon={Clipboard}
      iconSize={14}
      className="btn-sm btn-link text-muted"
      title="Copy UUID to clipboard"
      onClick={() => {
        navigator.clipboard.writeText(props.value);
        toast("UUID copied to clipboard");
      }}
    />
  );
}

export default ClipboardCopier;
