import Authorizer from "../../components/Authorizer";
import DeleteVenueModal from "../../modals/DeleteVenueModal";
import EventsTable from "../../tables/EventsTable";
import ExternalLink from "../../components/ExternalLink";
import IconButton from "../../components/IconButton";
import IconLinkButton from "../../components/IconLinkButton";
import LinkEventModal from "../../modals/LinkEventModal";
import LinkSaleModal from "../../modals/LinkSaleModal";
import LinkVenueModal from "../../modals/LinkVenueModal";
import LogModal from "../../modals/LogModal";
import MergeVenueModal from "../../modals/MergeVenueModal";
import ObjectPropertyList from "../../components/ObjectPropertyList";
import RequestContainer from "../../components/RequestContainer";
import SalesTable from "../../tables/SalesTable";
import TabCard from "../../components/TabCard";
import UploadImageModal from "../../modals/UploadImageModal";
import VenuesTable from "../../tables/VenuesTable";
import ViewContents from "../../components/ViewContents";
import ViewHeader from "../../components/ViewHeader";
import toast from "../../lib/toast";
import {
  Edit,
  PlusCircle,
  Link,
  XCircle,
  UploadCloud,
  GitMerge,
  Clock,
} from "react-feather";
import {
  getVenue,
  listChildVenues,
  updateVenue,
  linkChildVenue,
  linkEventVenue,
  listEventsByVenue,
  unlinkChildVenue,
  unlinkEventVenue,
  mergeVenue,
} from "../../clients/art";
import {
  linkSaleVenue,
  listSalesByVenue,
  unlinkSaleVenue,
} from "../../clients/finance";
import { useAutoRequest, useRequest } from "../../hooks/useRequest";
import { useHistory } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import { useParams } from "react-router-dom";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useRefresh } from "../../hooks/useRefresh";

function ViewVenue() {
  const [activeTab, setActiveTab] = usePersistentState(
    null,
    "ViewVenue:activeTab"
  );
  let { venue_id: id } = useParams();
  const [refreshToken, refresh] = useRefresh(id);
  const getVenueRequest = useAutoRequest(getVenue, id);
  const updateVenueRequest = useRequest(updateVenue);
  let history = useHistory();
  let [showLinkEventModal, linkEventModal] = useModal(LinkEventModal, {
    linkRequest: linkEventVenue,
    params: { venueId: id },
    onLink: refresh,
  });
  let [showLinkSaleModal, linkSaleModal] = useModal(LinkSaleModal, {
    linkRequest: linkSaleVenue,
    params: { venueId: id },
    onLink: refresh,
  });
  let [showLinkVenueModal, linkVenueModal] = useModal(LinkVenueModal, {
    linkRequest: linkChildVenue,
    params: { parentId: id },
    onLink: () => getVenueRequest.execute(null, refresh),
  });

  const venue = getVenueRequest.response.data || {};

  let [showMergeVenueModal, mergeVenueModal] = useModal(MergeVenueModal, {
    linkRequest: mergeVenue,
    params: { targetId: id },
    targetName: venue.name,
    onLink: refresh,
  });
  let [showUploadImageModal, uploadImageModal] = useModal(UploadImageModal, {
    skipCreateImage: true,
    onSave: (url) =>
      updateVenueRequest.execute(
        { id, data: { ...venue, imageUrl: url } },
        () => getVenueRequest.execute(id, () => toast("The image was uploaded"))
      ),
  });
  let [showDeleteVenueModal, deleteVenueModal] = useModal(DeleteVenueModal, {
    id: venue.id,
    name: venue.name,
    onDelete: () => {
      history.push("/venues");
    },
  });
  let [showLogModal, logModal] = useModal(LogModal, {
    filter: {
      type: "venue",
      id,
    },
  });

  return (
    <>
      <ViewHeader
        breadcrumbs={[`${venue.name || ""}`]}
        actions={
          <>
            <Authorizer role={["admin", "editor"]}>
              <IconButton
                onClick={showMergeVenueModal}
                icon={GitMerge}
                className="btn btn-success me-2"
              >
                Merge
              </IconButton>
              <IconButton
                onClick={showUploadImageModal}
                icon={UploadCloud}
                className="btn btn-success me-2"
              >
                Upload image
              </IconButton>
              <IconButton
                onClick={showLogModal}
                icon={Clock}
                className="btn btn-secondary me-2"
              >
                Log
              </IconButton>
              <IconLinkButton
                to={`/venues/${id}/edit`}
                icon={Edit}
                className="btn btn-secondary me-2"
              >
                Edit
              </IconLinkButton>
            </Authorizer>
            <Authorizer role="admin">
              <IconButton
                icon={XCircle}
                onClick={showDeleteVenueModal}
                className="btn btn-secondary"
              >
                Delete
              </IconButton>
            </Authorizer>
          </>
        }
      />
      <ViewContents>
        <RequestContainer
          loading={getVenueRequest.loading}
          error={getVenueRequest.error}
        >
          <ObjectPropertyList
            items={[
              ["ID", venue.id],
              ["Name", venue.name],
              ["Image", { image: venue.imageUrl }],
              ["Subheading", venue.subheading],
              ["Alternate names", { array: venue.alternateNames }],
              ["URL", { url: venue.url }],
              ["City", venue.city],
              ["Country", venue.country],
              ["Address", venue.address],
              ["Alternate addresses", { array: venue.altAddresses }],
              ["Google Places ID", venue.placesId],
              ["Latitude", venue.latitude],
              ["Longitude", venue.longitude],
              ["Type", venue.venueType],
              ["Style", venue.venueStyle],
              ["Description", venue.description],
              ["Founder", venue.founder],
              ["Email", { email: venue.email }],
              ["Facebook", venue.facebookProfile],
              ["Twitter", venue.twitterProfile],
              ["Instagram", venue.instagramProfile],
              [
                "Other social URLs",
                {
                  array: (venue.otherSocials || []).map((value) => (
                    <ExternalLink href={value} />
                  )),
                },
              ],
            ]}
          />
        </RequestContainer>

        <TabCard
          activeTab={activeTab}
          onTabChange={setActiveTab}
          tabs={{
            Events: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showLinkEventModal}
                    icon={Link}
                    className="btn btn-secondary me-2"
                  >
                    Link event
                  </IconButton>
                  <IconLinkButton
                    to={`/events/new?venueId=${id}`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create event
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <EventsTable
                  key={refreshToken}
                  params={{ id }}
                  request={listEventsByVenue}
                  unlinkRequest={unlinkEventVenue}
                  unlinkParams={{ venueId: id }}
                />
              ),
            },
            Sales: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showLinkSaleModal}
                    icon={Link}
                    className="btn btn-secondary me-2"
                  >
                    Link sale
                  </IconButton>
                  <IconLinkButton
                    to={`/sales/new?venueId=${id}`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create sale
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <SalesTable
                  key={refreshToken}
                  params={{ id }}
                  request={listSalesByVenue}
                  unlinkRequest={unlinkSaleVenue}
                  unlinkParams={{ venueId: id }}
                />
              ),
            },
            Children: {
              actions: (
                <Authorizer role={["admin", "editor"]}>
                  <IconButton
                    onClick={showLinkVenueModal}
                    icon={Link}
                    className="btn btn-secondary me-2"
                  >
                    Link venue
                  </IconButton>
                  <IconLinkButton
                    to={`/venues/new?parentId=${id}`}
                    icon={PlusCircle}
                    className="btn btn-success"
                  >
                    Create venue
                  </IconLinkButton>
                </Authorizer>
              ),
              body: (
                <VenuesTable
                  key={refreshToken}
                  params={id}
                  request={listChildVenues}
                  unlinkRequest={unlinkChildVenue}
                  unlinkParams={{ parentId: id }}
                />
              ),
            },
          }}
        />
      </ViewContents>

      {deleteVenueModal}
      {linkEventModal}
      {linkSaleModal}
      {linkVenueModal}
      {mergeVenueModal}
      {uploadImageModal}
      {logModal}
    </>
  );
}

export default ViewVenue;
