import UnlinkModal from "../components/UnlinkModal";

function UnlinkArtistModal(props) {
  return (
    <UnlinkModal
      request={props.request}
      params={props.params}
      onClose={props.onClose}
      onDelete={props.onDelete}
      toastMessage="The artist was unlinked"
      title="Unlink artist?"
      message={
        <p className="m-0">
          Do you want to unlink&nbsp;
          <span className="text-muted">{props.name}</span>? This will not delete
          the artist.
        </p>
      }
    />
  );
}

export default UnlinkArtistModal;
